import Layout4Register from "components/layout/Layout4Register";
import LandingPageHeadMenu from "components/layout/LandingPageHeadMenu";
import Css from './Login.module.css';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";

export default function ForgotPassword() {
    const navigate = useNavigate();

    const api = new ApiConection();
    const [validated, setValidated] = useState(false);
    const [input, setInput] = useState({
        email: ''
    });
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        const { name, type, value } = event.target;

        setInput(prevData => {
            return { ...prevData, [name]: value }
        })
    }

    const handleRegister = (event) => {
        navigate('/register');
    }

    const handleLogin = (event) => {
        navigate('/login');
    }

    const handleHomePage = () => {
        navigate('/');
    }

    const handelForgotPassword = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        let data = {
            email: input.email
        }
        setLoading(true);
        const response = await api.callLandingPagePostV2('api/authentication/forgot-password', data);
        setLoading(false);

        if (response.status === false) {
            setAlert(response.message);
        } else {
            navigate('/forgot/confirm');
        }
    }
    return (
        <LayoutLandingPageV2 showContactUs={false}>
            <div className={Css.Login_outer_layout}>
                <div className={`${Css.Login_inner_layout} ${Css.pad} mt-5 `}>
                    <Form noValidate validated={validated} onSubmit={handelForgotPassword} >
                        <img src={require('images/DCasS.png')} alt={'DCasS'} height={80} className="mt-3 mb-3" onClick={handleHomePage} />
                        <h5 className="mt-3">กู้คืนรหัสผ่าน</h5>
                        <Form.Group as={Row} className="text-start mb-2 mt-3">
                            <Form.Label className={Css.body2} >อีเมล</Form.Label>
                            <Col>
                                <Form.Control type={'email'} name={'email'} onChange={event => handleChange(event)} />
                            </Col>
                        </Form.Group>
                        {
                            alert !== '' &&
                            <div className="alert alert-danger" role="alert">
                                <div className="text-end">
                                    <button type="button" className="btn-close" onClick={(event) => setAlert('')}></button>
                                </div>

                                {alert}
                            </div>
                        }
                        <div className="mt-4">
                            <Button type={'submit'} disabled={loading} className={'w-100'} >
                                {
                                    loading === true &&
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                }
                                ยืนยัน</Button>
                        </div>
                    </Form>
                </div>
                <div className="mt-3 mb-3">
                    <span className={`me-4 ${Css.body2}`}>คุณยังไม่มีบัญชี DCaaS?</span>
                    <a className={`${Css.Alink_blue} ${Css.body2}`} onClick={handleRegister}>คลิกเข้าสู่ระบบ</a>
                </div>
            </div>
        </LayoutLandingPageV2>
    )
}