import Css from 'components/pages/customer/Customer.module.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Col, Row } from 'react-bootstrap';
import { useEffect } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function StorageUsage({ total = undefined, used = undefined, config = undefined }) {
    let totalSpace = total * 1024 * 1024 * 1024;
    let usedPercent = (used / totalSpace) * 100;
    usedPercent = usedPercent.toFixed(0);
    if (usedPercent < 1) {
        usedPercent = 1;
    }
    const data = {
        labels: ['Incurrent', 'Left'],
        datasets: [
            {
                label: 'Storage',
                data: [used, ((totalSpace - used < 0) ? 0 : totalSpace - used)],
                backgroundColor: [
                    '#A19FFB',
                    '#EDECFE',

                ],
                borderColor: [
                    '#A19FFB',
                    '#EDECFE',

                ],
                borderWidth: 1,
                cutout: '80%',
                responsive: true,
            },
        ],
    };
    const options = {
        plugins: {
            legend: {
                display: false
            }
        }
    }

    const textCenter = {
        id: 'textCenter',
        beforeDatasetsDraw(chart, args, pluginOptions) {
            const { ctx, data, chartArea: { left, right, top, bottom, width, height } } = chart;

            ctx.save();
            ctx.font = '30px Prompt';
            ctx.fillStyle = '#AD7BE9';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText((usedPercent + '%'), chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y - 10);
            ctx.restore();

            ctx.font = '18px Prompt';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText('Used', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 20);
            ctx.restore();
        }
    }

    const CurencyByte = (byte) => {
        if (byte == undefined) {
            return;
        }
        if (byte >= 1024 * 1024 * 1024) {
            let gb = byte / (1024 * 1024 * 1024);
            return gb.toFixed(2) + ' GB';
        } else if (byte >= 1024 * 1024) {
            let mb = byte / (1024 * 1024);
            return mb.toFixed(2) + ' MB';
        } else if (byte >= 1024) {
            let kb = byte / 1024;
            return kb.toFixed(2) + ' KB';
        } else {
            return byte.toFixed(2) + ' bytes';
        }
    }
    return (
        <div className={Css.Graph_Box + ' shadow'}>
            <div className={Css.Content}>
                <div className={Css.Header}>
                    <h6>Storage Usage</h6>
                </div>
                <div className={Css.Content}>
                    <div className={Css.Doughnut_Usage + " flex-fill"}>
                        {
                            total !== undefined && used !== undefined &&
                            <Doughnut data={data} options={options} plugins={[textCenter]} ></Doughnut>
                        }
                        {
                            (total === undefined || used === undefined) &&
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        }
                    </div>
                    <div>
                        <Row>
                            <Col>
                                <i className={'fa-solid fa-circle me-1 ' + Css.Color_Purple_02}></i>
                                Total Storage
                                <br />
                                {total} GB
                            </Col>
                            <Col>
                                <i className={'fa-solid fa-circle me-1 ' + Css.Color_Purple_01}></i>
                                Incurrent
                                <br />
                                {CurencyByte(used)}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className={Css.Footer + ' d-none'}>
                <a href={'#'}>View Full Plan</a>
            </div>
        </div>
    )
}