import RegisterCustomerPayment from "components/pages/application/register/RegisterCustomerPayment";
import ServicePayment from "components/pages/application/register/ServicePayment";
import { Col, Form, Row, Button } from "react-bootstrap";
import PackageChanged from "./PackageChanged";
import { useNavigate } from "react-router-dom";
import UtilClass from "api_ref/UtilClass";
import ApiConection from "api_ref/ApiConection";
import { useState, useEffect } from "react";
import moment from 'moment';

export default function CustomerUpPackageFlowStep2({ data = undefined,
    onChangeData = undefined,
    onHandleSubmit = undefined,
    error = '',
    SetRegisterData = undefined,
    onChangeStep = undefined }) {
    const navigate = useNavigate();
    const util = new UtilClass();
    const api = new ApiConection();

    const customer = util.getSessionStorage('customer');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [estimateData, setEstimateData] = useState(data);
    const [calData, setCalData] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [saveAndSendData, setSaveAndSendData] = useState(false);

    const handleEstimate = (event) => {
        const { name, value, type, checked, files } = event.target;

        if (type === 'file') {
            setEstimateData(prevData => {
                return { ...prevData, [name]: (files ? files[0] : undefined || value) }
            })
        } else if (type === 'checkbox') {
            setEstimateData((prevData) => {
                return { ...prevData, [name]: checked };
            });
        } else {
            setEstimateData((prevData) => {
                return { ...prevData, [name]: value };
            });
        }

        //setDataChangeCount(dataChangeCount + 1);
    }

    const handleThisSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (document.querySelector('[name=payee_tax_id]')?.setCustomValidity)
            document.querySelector('[name=payee_tax_id]')?.setCustomValidity('');

        if (estimateData.is_pay_now === true || estimateData.is_pay_now === 'true') {
            if (estimateData.paid_file_slip === undefined || estimateData.paid_file_slip === null) {
                setAlert('กรุณาแนบหลักฐานการชำระค่าบริการ');
            }
        }

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        if (estimateData.is_pay_now === true || estimateData.is_pay_now === 'true') {
            const IdCard = estimateData.payee_tax_id;
            if (!util.checkID(IdCard)) {
                //form.classList.remove('was-validated');
                document.querySelector('[name=payee_tax_id]')?.setCustomValidity('invalid');

                event.stopPropagation();
                setValidated(true);
                setLoading(false);

                return;
            }
        }

        //-------------- บันทึก และตรวจสอบ ไฟล์แนบ-------------

        let slip_file_id = null;
        let paid_date = null;

        if (estimateData.is_pay_now === true || estimateData.is_pay_now === 'true') {
            if (estimateData.paid_file_slip !== undefined && estimateData.paid_file_slip !== null) {
                let formdata = new FormData();
                formdata.append("files", estimateData.paid_file_slip);
                formdata.append("path", `customer/${estimateData.register_number}`);
                formdata.append("ref_code", estimateData.register_number);

                const responseUpload = await api.postFile(`api/file/upload/paymentslip`, formdata);
                if (responseUpload.status === true) {
                    onChangeData({ target: { name: 'slip_file_id', value: responseUpload.data.id } });
                    slip_file_id = responseUpload.data.id;
                }
                else {
                    setAlert('ไม่สามารถอัปโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง !!');
                    setLoading(false);
                    return;
                }
            }

            paid_date = moment(estimateData.paid_date_only + ' ' + estimateData.paid_hour + ":" + estimateData.paid_minute, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ss')
            onChangeData({ target: { name: 'paid_date', value: paid_date } });
        }

        let tmpData = estimateData;

        tmpData.paid_bank = estimateData.paid_bank;
        tmpData.paid_amount = estimateData.paid_amount;

        tmpData.is_pay_now = estimateData.is_pay_now;
        tmpData.payee_name = estimateData.payee_name;
        tmpData.payee_address = estimateData.payee_address;
        tmpData.payee_branch = estimateData.payee_branch;
        tmpData.payee_tax_id = estimateData.payee_tax_id;
        tmpData.payee_tel = estimateData.payee_tel;

        tmpData.slip_file_id = slip_file_id;
        tmpData.paid_date = paid_date;
        tmpData.is_pay_now = estimateData.is_pay_now;
        tmpData.is_accept_agreement = true;

        SetRegisterData(tmpData);
        setEstimateData(tmpData);

        setSaveAndSendData(true);
    }

    useEffect(() => {
        const fetchData = async () => {
            if (saveAndSendData === true) {
                await onHandleSubmit();
                setValidated(false);
                setLoading(false);
                setAlert('');
                setSaveAndSendData(false);
            }
        }

        fetchData();
    }, [saveAndSendData])

    return (
        <Form encType="multipart/form-data" noValidate validated={validated} onSubmit={event => handleThisSubmit(event)}>
            <div className='text-start mb-2'>
                <Button type={'button'} variant="outline-secondary" onClick={event => onChangeStep(1)} >
                    <i className="fa-solid fa-chevron-left me-2"></i>
                    ย้อนกลับ</Button>
            </div>
            <Row>
                <Col sm={7}>
                    <ServicePayment
                        data={estimateData}
                        onChangeData={handleEstimate}
                        error={alert + error}
                        loading={loading}
                        className='w-auto' />
                </Col>

                <Col>
                    <RegisterCustomerPayment data={estimateData} disabled={true} className='w-auto mb-3' />
                    <PackageChanged data={estimateData} />

                </Col>
            </Row>
        </Form>
    )
}