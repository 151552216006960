import React, { useEffect, useState } from "react"
import Css from './Mockup.module.css'
import { Link, useNavigate, useParams } from "react-router-dom";
import ApiConection from "api_ref/ApiConection";
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";
import { ConvertDateToText, DisplayPaging } from "components/MyControl";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";

export default function Article() {
    const nav = useNavigate();
    const api = new ApiConection();
    const [loading, setLoading] = useState([]);
    const [dataCard, setDataCard] = useState({
        transformedData:[],
        record_start: 0,
        record_end: 0,
        record_count: 0,
        page_count: 0
      });

    const [search, setSearch] = useState({
        page: 1,
        size: 3
    });

    const callAipBlogs = async () => {

        debugger;
        let data = {
            search: {
                release_date_to: moment().format("DD/MM/yyyy")
            },
            pagination: {
                page: search.page,
                size: search.size
            }
        }
        setLoading(true);
debugger;
        const response_pagination = await api.callLandingPagePostV2('api/BlogManagement/Pagination', data);
        if (response_pagination.status === true) {
            handleSetDataCard('record_start', response_pagination.data.record_start);
            handleSetDataCard('record_count', response_pagination.data.record_count);
            handleSetDataCard('page_count', response_pagination.data.page_count);
        }

        const response_result = await api.callLandingPagePostV2(`api/BlogManagement/result`, data);
        if (response_result.status == true) {
            let transformedData = []
            for(let i = 0;i < response_result.data.length;i++){

                const response_photo = await getSlipFile(response_result.data[i].image_id);

                transformedData.push(
                    {
                        id:response_result.data[i].id,
                        title:response_result.data[i].title,
                        description:response_result.data[i].description,
                        release_date: ConvertDateToText(response_result.data[i].release_date),
                        written_by:response_result.data[i].written_by,
                        photo : (response_photo !== undefined)? URL.createObjectURL(response_photo) : undefined
                    }
                )
            }
            if (response_result.status == true) {
                handleSetDataCard('transformedData', transformedData);
            } else {
                handleSetDataCard('transformedData', []);
            }
        }

        let record_end = response_pagination.data.record_start + (search.size - 1);
            if (record_end > response_pagination.data.record_count) {
                record_end = response_pagination.data.record_count;
            }

            handleSetDataCard('record_end', record_end);

            setLoading(false);
    }

    const handleSetDataCard = (name, value) => {
        setDataCard(prevData => {
            return { ...prevData, [name]: value };
        });
    }

    const getSlipFile = async (id) => {
        if (id !== null && id !== undefined) {
            return await api.callLandingPageGetFile(`api/file/${id}/preview`);
        } else {
            return undefined;
        }
    }

    const handleSetSearch = (name, value) => {
        setSearch(prevData => {
            return { ...prevData, [name]: value };
        });
    }

    const handleSizeCardChange = (event) => {
        const { name, value } = event.target;
        handleSetSearch('page', 1);
        handleSetSearch('size', value);
      }

    const handlePagingChange = (page_no) => {
   
        handleSetSearch('page', page_no);
    }

    useEffect(() => {
       console.log(search.page)
        callAipBlogs();
    }, [search])

    const handleReadMore = async (id) => {
            nav(`/article/detail/${id}`);

    };


    return (
        <LayoutLandingPageV2 showContactUs={false}>
            <div className={`${Css.outline_layout} container m-auto mt-3 mb-3 p-5`}>
                <h2 className="mb-5" >บทความ</h2>
                <div className={Css.Content + ' position-relative mb-5'}>
                                {
                                    loading &&
                                    <div className="position-absolute w-100 h-100 bg-white bg-opacity-50" style={{ zIndex: 5 }}>
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {dataCard.transformedData.map((obj, index) => (
                        <div key={index} className="col">
                            <div className="card h-100">
                            {
                                obj?.photo !== undefined && (
                                    <a onClick={() => handleReadMore(obj.id)}>
                                        <img 
                                            src={obj.photo} 
                                            width={'100%'} 
                                            alt="Article Photo"
                                        />
                                    </a>
                                )
                            }
                                <div className="card-body">
                                    <h4 className="card-title" style={{ cursor: 'pointer' }} onClick={event => handleReadMore(obj.id)}>{obj.title}</h4>
                                    <p className="card-text">{obj.description}</p>
                                </div>

                                <div className="card-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                        <p className="read-more" style={{ cursor: 'pointer' }} onClick={event => handleReadMore(obj.id)}>อ่านเพิ่มเติม</p>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                        <p className="card-text"><small className="text-muted">เขียนโดย : {obj.written_by}</small></p>
                                        <p className="card-text" style={{ marginTop: '-15px' }}><small className="text-muted"><FontAwesomeIcon icon={faClock} /> {obj.release_date}</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <DisplayPaging
                rec_start={dataCard.record_start}
                rec_end={dataCard.record_end}
                rec_count={dataCard.record_count}
                onSizeChange={handleSizeCardChange}
                cur_page={search.page}
                size={search.size}
                page_count={dataCard.page_count}
                onPagingChange={handlePagingChange}/>
            </div>
        </LayoutLandingPageV2>
    );
}
