import React, { useEffect, useState } from "react"
import Css from './Manual.module.css'
import { Row, Col, Form, Button } from "react-bootstrap";

export default function ManualForgetPassword() {
    return (
        <div className={Css.imgManaul}>
            <h3 className="mb-4">ลืมรหัสผ่าน</h3>
            <label className="mt-0">1. เมื่อท่านลืมรหัสผ่านให้กดไปที่ ลืมรหัสผ่าน?</label>
            <img src={require("images/forgotpassword2.png")} className='mt-3' />
            <label>2. ให้ท่านใส่อีเมลที่ท่านได้สมัครสมาชิกไว้ เพื่อกู้คืนรหัสผ่าน เมื่อใส่อีเมลแล้วทำการกด ยืนยัน</label>
            <img src={require("images/forgotpassword3.png")} className='mt-3' />
            <label>3. หลังจากกดยืนยันแล้ว ให้ท่านไปตรวจสอบอีเมลที่ใช้สำหรับการ รีเซ็ตรหัสผ่านใหม่ทางอีเมล</label>
            <img src={require("images/forgotpassword4.png")} className='mt-3' />
            <label>4. จากนั้นให้ไปตรวจสอบเมลที่ได้ทำการรีเซ็ตรหัสผ่านใหม่</label>
            <img src={require("images/reset4.jpg")} className='mt-3' />
            <label>5. เมื่อกดเข้ามาแล้วก็จะพบข้อมมูลในการรีเซ็ตรหัสผ่านใหม่ ซึ่งจะมีให้ท่านเลือก 2 ช่องทางในการยืนยัน </label>
            <img src={require("images/forgotpassword6.png")} className='mt-3' />
            <label>6. หลังจากที่ท่านได้กด 1. ที่นี่ หรือ 2. ลิงก์ที่แปะไว้ ให้ท่านทำการเปลี่ยนรหัสผ่านใหม่ของท่านในช่องรหัสผ่านและ ช่องยืนยันรหัสผ่าน แล้วกดยืนยัน</label>
            <img src={require("images/forgotpassword7.png")} className='mt-3' />
            <label>7. หลังจากที่ท่านได้กดยืนยันเป็นที่เรียบร้อยแล้วก็จะมาที่หน้าเปลี่ยนรหัสผ่าน ทำการเปลี่ยนรหัสผ่านสำเร็จ และเข้าสู่ระบบเพื่อใช้งานเว็บไซต์ </label>
            <img src={require("images/forgotpassword8.png")} className='mt-3' />
        </div>
    )
}