import React from "react";
import Footer4Bottom from "./Foot4Bottom";
import Css from './Layout.module.css'
import PolicyCookie from "components/pages/application/PolicyCookie";
import ScrollBackToTop from "components/pages/manual/ScrollBackToTop";
import ModulePageHeadMenu from "./ModulePageHeadMenu";

export default function LayoutModulePage({ children }) {
    return (
        <>
            <ModulePageHeadMenu />
            <div className={Css.Main_Content_Register + ' fade-in'}>
                {children}
            </div>
            <Footer4Bottom />
            <ScrollBackToTop />
            <PolicyCookie />
        </>
    );
}