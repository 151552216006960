import { json, useNavigate } from "react-router-dom";
import Css from 'components/pages/customer/Customer.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import ATSApiConnection from "api_ref/ATSApiConnection";
import CustomerUpPackage from "./CustomerUpPackage";
import CustomerUpAddon from "./CustomerUpAddon";
import QuontationStatus1 from "./QuontationStatus1";
import LandingPageHeadMenu from "components/layout/LandingPageHeadMenu";
import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import UtilClass from "api_ref/UtilClass";

export default function CustomerUp() {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');
    const register = util.getSessionStorage('register');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loadingCheckPackage, setLoadingCheckPackage] = useState(false);
    const [loadingCheckAddon, setLoadingCheckAddon] = useState(false);

    const navigate = useNavigate();

    const [disabledChangePackage, setDisabledChangePackage] = useState(false);
    const [disabledChangeAddon, setDisabledChangeAddon] = useState(false);

    const getCanChangePackage = async () => {
        setLoadingCheckPackage(true);

        const response = await api.getData(`api/quontation/register/${register.register_number}/can-change-package`);
        //console.log('package=',response);
        setDisabledChangePackage(response);
        setLoadingCheckPackage(false);
    }

    const getCanChangeAddon = async () => {
        setLoadingCheckAddon(true);

        const response = await api.getData(`api/quontation/register/${register.register_number}/can-change-addon`);
        //console.log('addon=',response);
        setDisabledChangeAddon(response);
        setLoadingCheckAddon(false);
    }

    useEffect(() => {
        getCanChangePackage();
        getCanChangeAddon();
    }, []);

    return (
        <LayoutCustomerMain role="customer" >
            <QuontationStatus1 />
            <div className={Css.Block_Transparent}><br />
                <h5 className={Css.color1}>อัปเกรดแพ็กเกจ</h5><br />
                <div className={Css.Graph_Group}>
                    <div className="container row justify-content-center m-auto">
                        <CustomerUpPackage loading={loadingCheckPackage} disabled={!disabledChangePackage.status} message={disabledChangePackage.message} />
                        {
                            register.quontation.package_type !== 'free' &&
                            <CustomerUpAddon loading={loadingCheckAddon} disabled={!disabledChangeAddon.status} message={disabledChangeAddon.message} />
                        }

                    </div>
                </div>
            </div>
        </LayoutCustomerMain>
    )
}