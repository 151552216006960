import Css from 'components/pages/application/login/Login.module.css';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function CustomerUpAddon({ loading = true, disabled = true, message = '' }) {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const handleLoginPage = () => {
        navigate('/customer/upgrade/addon');
    }

    return (
        <div className={`${Css.Login_inner_layout1} ${Css.pad}`}>
            <Form noValidate validated={validated} onSubmit={handleLoginPage} >
                <h6 className={Css.color}>อัปเกรดแพ็กเกจเสริม</h6><br />
                <div className={Css.body2}>
                    <ul>
                        <li>มีผลในรอบบิลนี้ทันที</li>
                        <li>หมดอายุพร้อมกับแพ็กเกจหลัก</li>
                        <li>คิดค่าบริการตามจริง ตามระยะเวลาที่แพ็กเกจหลักเหลืออยู่</li>
                    </ul>
                </div>
                <div className="mt-5 text-end">
                    {
                        loading === true &&
                        <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>

                        </>
                    }
                    {
                        loading === false &&
                        <Button type={'submit'} className={Css.btn} disabled={disabled}>
                            {disabled ?
                                message.search(/คุณมียอดค้างชำระ/) > -1 ? 'คุณมียอดค้างชำระ'
                                    : message.search(/ตรวจสอบ/) > -1 ? 'ระบบกำลังตรวจสอบข้อมูล' : message : 'อัปเกรดเลย'}
                        </Button>
                    }

                </div>
            </Form>
        </div>
    )
}