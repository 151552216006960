import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import YouTube from 'react-youtube';

const YouTubeVideo = () => {
    const [videoId] = useState('xxPEzK6Hcv8'); // รหัสวิดีโอของคุณ
    const opts = {
        width: '100%',
        height: '410px',
        playerVars: {
            autoplay: 1, // เปิดใช้งานโหมด autoplay
            controls: 1, // แสดงตัวควบคุมวิดีโอ
            mute: 1, // ปิดการเสียง (0 = ปิดเสียง)
            loop: 1,
            playlist: 'xxPEzK6Hcv8' //loop ต้องใส่ playlist={videoId}
        },
    };

    return (
        <div className='video-container'>
            <h1></h1>
            <Row>
                <Col md={2}></Col>
                <Col md={8}>
                    <YouTube videoId={videoId} opts={opts} />
                </Col>
                <Col md={2}></Col>
            </Row>

        </div>
    );
};

export default YouTubeVideo;