import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Css from './Register.module.css';
import { Row, Col, Form, Button } from "react-bootstrap";
import moment from 'moment';
import React from 'react';

export default function RegisterCustomerStep6({ getValue, onChangeData = undefined, onChangeStep = undefined }) {
    const navigate = useNavigate();

    const handleHomePage = () => {
        navigate('/customer/home');
    }

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);

        const handleBackButton = () => {
            // เพิ่มสถานะใหม่ลงในประวัติเพื่อป้องกันการกด back
            navigate('/customer/payment');
        };

        // ตั้งค่าเหตุการณ์เมื่อผู้ใช้กดปุ่ม back
        window.addEventListener('popstate', handleBackButton);

        // คืนค่าฟังก์ชันเมื่อ Component ถูก unmount เพื่อลบเหตุการณ์ที่ถูกติดตั้งไว้
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const print = () => window.print();

    return (
        <div className={Css.Register_customer_layout}>
            <div className={Css.Register_inner_layout1}>
                <Form className={Css.Register_Form} onSubmit={(_) => handleHomePage()}>
                    <div className={`mb-3 ${Css.thxpage} ${Css.body2}`}>
                        <h5 className={`mb-4 ${Css.colorh5}`}>ขอบคุณลูกค้าที่สนับสนุน</h5>
                        <label className=' mt-5'>ทางทีมงานกำลังจัดเตรียม ISO DCaaS ให้พร้อมให้ใช้งาน และจะแจ้งผลการดำเนินการให้ทราบผ่านทางระบบ Email ภายใน 15 นาที</label>
                        <img src={require('images/DCasS.png')} alt={'DCasS'} height={80} className="mt-2 mb-4" />
                        <label className='mb-3'>ทางบริษัทจะทำการตรวจสอบ และจัดส่งเอกสารเสร็จ/ใบกำกับภาษี ภายใน 15 วันทำการ</label>

                        <Button type={'submit'} className='btn-primary w-100 mt-5'>เริ่มต้นใช้งาน</Button>
                    </div>

                </Form>
            </div>
        </div>
    )
}