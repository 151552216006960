import Layout4Main from "components/layout/Layout4Main";
import { Row, Col, Form, Button, Tab, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Css from 'components/pages/customer/Customer.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import QuontationStatus from "../home/QuontationStatus";
import UtilClass from "api_ref/UtilClass";

export default function Config() {
    const bussiness_category = [
        { id: "01", name: "เกษตรกรรม การป่าไม้ และการประมง" },
        { id: "02", name: "การทำเหมืองแร่ และเหมืองหิน" },
        { id: "03", name: "การผลิต" },
        { id: "04", name: "ไฟฟ้า ก๊าซ ไอน้ำ และระบบการปรับอากาศ" },
        { id: "05", name: "การจัดหาน้ำการจัดการน้ำเสียและของเสียรวมถึง กิจกรรมที่เกี่ยวข้อง" },
        { id: "06", name: "การก่อสร้าง" },
        { id: "07", name: "การขายส่งและการขายปลีก การซ่อมยานยนต์และ จักรยานยนต์" },
        { id: "08", name: "การขนส่งและสถานที่เก็บสินค้า" },
        { id: "09", name: "ที่พักแรมและบริการด้านอาหาร" },
        { id: "10", name: "ข้อมูลข่าวสารและการสื่อสาร" },
        { id: "11", name: "กิจกรรมทางการเงินและการประกันภัย" },
        { id: "12", name: "กิจกรรมเกี่ยวกับอสังหาริมทรัพย์" },
        { id: "13", name: "กิจกรรมวิชาชีพวิทยาศาสตร์และ กิจกรรมทางวิชาการ" },
        { id: "14", name: "กิจกรรมการบริหารและบริการสนับสนุน" },
        { id: "15", name: "การบริหารราชการ การป้องกันประเทศและ การประกันสังคมภาคบังคั" },
        { id: "16", name: "การศึกษา" },
        { id: "17", name: "กิจกรรมด้านสุขภาพและงานสังคมสงเคราะห์" },
        { id: "18", name: "ศิลปะ ความบันเทิงและนันทนาการ" },
        { id: "19", name: "กิจกรรมการบริการด้านอื่นๆ" },
        { id: "20", name: "กิจกรรมการจ้างงานในครัวเรือน กิจกรรมการผลิต สินค้าและบริการที่ทำขึ้นเองเพื่อใช้ในครัวเรือน" },
        { id: "21", name: "กิจกรรมขององค์การระหว่างประเทศและ ภาคีสมาชิก" },

    ]

    const api = new ApiConection();
    const util = new UtilClass();
    const navigate = useNavigate();

    const customer = util.getSessionStorage('customer');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [Register, setRegister] = useState({});
    const [RegisterEdit, setRegisterEdit] = useState({});
    const [user, setUser] = useState({});
    const [userEdit, setUserEdit] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingRegister, setLoadingRegister] = useState(false);
    const [loadingUser, setLoadingUser] = useState(false);

    const [companyEditMode, setCompanyEditMode] = useState(false);
    const [userEditMode, setUserEditMode] = useState(false);

    const [businessType, setBusinessType] = useState([]);

    const gotoChangePass = () => {
        navigate('/customer/configpassword');
    }

    const getRegister = async () => {
        //console.log(customer);
        //if(customer.user_id === undefined) return;
        //return;
        setLoading(true);

        const response = await api.getData(`api/register/customer/${customer.user_id}`);

        if (response.status === true) {
            setRegister(response.data[0]);
            setRegisterEdit(response.data[0]);

            util.setSessionStorage('register', response.data[0]);
        }

        const response_user = await api.getData(`api/Users/${customer.user_id}`);
        if (response_user.status === true) {
            setUser(response_user.data);
            setUserEdit(response_user.data);

            let tmp_customer = customer;
            tmp_customer.show_name = response_user.data.show_name;
            tmp_customer.tel = response_user.data.tel;

            util.setSessionStorage('customer', tmp_customer);
        }

        setLoading(false);
    }

    const getBusinessType = async () => {
        const response = await api.getData(`api/master/business-type`);
        if (response.status === true) {
            setBusinessType(response.data);

            response.data.map(obj => {
                if (obj.is_default === true) {
                }

                return 0;
            })
        }
    }

    const handleChangeRegister = (event) => {
        const { type, name, value, checked, files } = event.target;

        event.target?.setCustomValidity('');

        if (type === 'file') {
            setRegisterEdit(prevData => {
                return { ...prevData, [name]: files ? files[0] : undefined }
            });
        }
        else if (type === 'checkbox') {
            setRegisterEdit(prevData => {
                return { ...prevData, [name]: checked }
            });
        }
        else {
            setRegisterEdit(prevData => {
                return { ...prevData, [name]: value }
            });
        }
    }

    const handleChangeUser = (event) => {
        const { type, name, value, checked, files } = event.target;

        event.target?.setCustomValidity('');

        if (type === 'file') {
            setUserEdit(prevData => {
                return { ...prevData, [name]: files ? files[0] : undefined }
            });
        }
        else if (type === 'checkbox') {
            setUserEdit(prevData => {
                return { ...prevData, [name]: checked }
            });
        }
        else {
            setUserEdit(prevData => {
                return { ...prevData, [name]: value }
            });
        }
    }

    const handleSaveRegister = async (event) => {
        const form = document.querySelectorAll('form')[0];
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoadingRegister(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoadingRegister(false);
            return;
        }

        let data = {
            address: RegisterEdit.company_address,
            business_type_id: RegisterEdit.business_type_id,
            employee_count_id: (RegisterEdit.employee_count_id == '') ? 0 : RegisterEdit.employee_count_id,
            description: RegisterEdit.description
        }

        const response = await api.putData(`api/register/${RegisterEdit.register_number}`, data);
        if (response.status === true) {
            setRegister(response.data);
            setCompanyEditMode(false);
            getRegister();
        } else {
            setAlert(response.message);
        }
        setLoadingRegister(false);
    }

    const handleSaveUser = async (event) => {
        const form = document.querySelectorAll('form')[1];
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoadingUser(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoadingUser(false);
            return;
        }

        let data = {
            first_name: userEdit.first_name,
            last_name: userEdit.last_name,
            tel: userEdit.tel
        }

        const response = await api.putData(`api/users/${customer.user_id}`, data);
        if (response.status === true) {
            setUser(response.data);
            //setUserEdit(response.data);
            setUserEditMode(false);

            getRegister();
        } else {
            setAlert(response.message);
        }
        setLoadingUser(false);
    }

    useEffect(() => {
        getBusinessType();
        getRegister();
    }, [])

    return (
        <Layout4Main role="customer">
            <QuontationStatus register_number={Register.register_number} />
            <div className={Css.Block_Transparent}>
                <div className={Css.Graph_Group}>
                    {
                        loading === false &&
                        <div className={Css.Graph_Box + ' shadow'}>
                            <div className={Css.Content}>
                                <div className={Css.Header}>
                                    <h6>ข้อมูลของคุณ</h6>
                                </div>
                                <div className={Css.Content}>
                                    <Row>
                                        <Col>
                                            <Form noValidate validated={validated} >
                                                <div className="border rounded ">
                                                    <h6 className={'bg-light border-bottom d-block p-2 ps-3 text-start'}>
                                                        ข้อมูลบริษัท
                                                    </h6>
                                                    <div className=" text-start w-100 m-auto d-flex flex-column p-4">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm={4} className="text-end text-secondary">ชื่อบริษัท</Form.Label>
                                                            <Form.Label column className="text-start">{Register.company_name}</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm={4} className="text-end text-secondary">ที่อยู่</Form.Label>
                                                            {
                                                                companyEditMode === true &&
                                                                <Col className="mb-1">
                                                                    <Form.Control as="textarea" rows={3} placeholder="เพิ่มที่อยู่" name={'company_address'} required={true} value={RegisterEdit.company_address} onChange={event => handleChangeRegister(event)} />
                                                                </Col>
                                                            }
                                                            {
                                                                companyEditMode === false &&
                                                                <Form.Label column className="text-start">{Register.company_address}</Form.Label>
                                                            }

                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm={4} className="text-end text-secondary">หมายเลขโทรศัพท์</Form.Label>
                                                            {
                                                                companyEditMode === true &&
                                                                <Col>
                                                                    <Form.Control type={'tel'} placeholder="หมายเลขโทรศัพท์" name={'tel'} required={true} value={RegisterEdit.company_tel} onChange={event => handleChangeRegister(event)} />
                                                                </Col>
                                                            }
                                                            {
                                                                companyEditMode === false &&
                                                                <Form.Label column className="text-start">{Register.company_tel}</Form.Label>
                                                            }
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm={4} className="text-end text-secondary">เลขประจำตัวผู้เสียภาษี</Form.Label>
                                                            <Form.Label column className="text-start">{Register.tax_id}</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm={4} className="text-end text-secondary">ที่อยู่ไซต์ (Site URL)</Form.Label>
                                                            <Form.Label column className="text-start">{Register.site_url}</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm={4} className="text-end text-secondary">ประเภทธุรกิจ</Form.Label>
                                                            {
                                                                companyEditMode === true &&
                                                                <Col>
                                                                    <Form.Select name={'business_type_id'} value={RegisterEdit.business_type_id} onChange={event => handleChangeRegister(event)} >
                                                                        {
                                                                            businessType.map((obj, index) => {
                                                                                return (
                                                                                    <option key={index} value={obj.id} >{obj.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Form.Select>
                                                                </Col>
                                                            }
                                                            {
                                                                companyEditMode === false &&
                                                                <Form.Label column className="text-start">{Register.business_category_name}</Form.Label>
                                                            }
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm={4} className="text-end text-secondary">ลักษณะธุรกิจ</Form.Label>
                                                            {
                                                                companyEditMode === true &&
                                                                <Col>
                                                                    <Form.Select name={'business_category_id'} value={RegisterEdit.business_type_id} onChange={event => handleChangeRegister(event)} >
                                                                        {
                                                                            bussiness_category.map((obj, index) => {
                                                                                return (
                                                                                    <option key={index} value={obj.id} >{obj.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Form.Select>
                                                                </Col>
                                                            }
                                                            {
                                                                companyEditMode === false &&
                                                                <Form.Label column className="text-start">{Register.business_type_name}</Form.Label>
                                                            }
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm={4} className="text-end text-secondary">จำนวนพนักงาน</Form.Label>
                                                            {
                                                                companyEditMode === true &&
                                                                <Col>
                                                                    <Form.Control type={'number'} placeholder="จำนวนพนักงาน" name={'employee_count_id'} min={1} max={100000} value={RegisterEdit.employee_count_id} onChange={event => handleChangeRegister(event)} required={true} />

                                                                </Col>
                                                            }
                                                            {
                                                                companyEditMode === false &&
                                                                <Form.Label column className="text-start">{Register.employee_count_id}</Form.Label>
                                                            }
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm={4} className="text-end text-secondary">รายละเอียดอื่นๆ</Form.Label>
                                                            {
                                                                companyEditMode === true &&
                                                                <Col>
                                                                    <Form.Control type={'textarea'} rows={3} placeholder="ข้อมูลอื่นๆ เพิ่มเติม" name={'other'} value={RegisterEdit.description} onChange={event => handleChangeRegister(event)} />
                                                                </Col>
                                                            }
                                                            {
                                                                companyEditMode === false &&
                                                                <Form.Label column className="text-start">{Register.description}</Form.Label>
                                                            }

                                                        </Form.Group>
                                                    </div>
                                                    {
                                                        companyEditMode === false &&
                                                        <div className="text-end p-4">
                                                            <Button type="button" onClick={event => setCompanyEditMode(true)}>แก้ไขข้อมูลบริษัทของคุณ</Button>
                                                        </div>
                                                    }
                                                    {
                                                        companyEditMode === true &&
                                                        <div className="text-end p-4">
                                                            <Button type="button" variant="secondary" className="me-2" onClick={event => setCompanyEditMode(false)}>ยกเลิก</Button>
                                                            <Button type="button" onClick={event => handleSaveRegister(event)}>
                                                                {
                                                                    loadingRegister === true &&
                                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                }
                                                                บันทึก</Button>

                                                        </div>
                                                    }
                                                </div>
                                            </Form>
                                        </Col>
                                        <Col>
                                            <Form noValidate validated={validated} className="h-100 d-flex flex-column " >
                                                <div className="border rounded flex-fill">
                                                    <h6 className={'bg-light border-bottom d-block p-2 ps-3 text-start'}>
                                                        ข้อมูลผู้ใช้งาน
                                                    </h6>
                                                    <div className=" text-start w-100 m-auto flex-fill  p-4">
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm={4} className="text-end text-secondary">ล็อคอิน</Form.Label>
                                                            <Form.Label column className="text-start">{user.login_name}</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Col sm={4} >
                                                            </Col>
                                                            <Col>
                                                                <Button type="button" onClick={event => gotoChangePass()}>เปลี่ยนรหัสผ่าน</Button>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mt-5">
                                                            <Form.Label column sm={4} className="text-end text-secondary">ชื่อ-สกุล</Form.Label>
                                                            {
                                                                userEditMode === false &&
                                                                <Form.Label column className="text-start">{user.show_name}</Form.Label>
                                                            }
                                                            {
                                                                userEditMode === true &&
                                                                <>
                                                                    <Col>
                                                                        <Form.Control type={'text'} name={'first_name'} value={userEdit.first_name} onChange={event => handleChangeUser(event)} required={true} />
                                                                    </Col>
                                                                    <Col>
                                                                        <Form.Control type={'text'} name={'last_name'} value={userEdit.last_name} onChange={event => handleChangeUser(event)} required={true} />
                                                                    </Col>
                                                                </>
                                                            }

                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm={4} className="text-end text-secondary">เบอร์มือถือ</Form.Label>
                                                            {
                                                                userEditMode === false &&
                                                                <Form.Label column className="text-start">{user.tel}</Form.Label>
                                                            }
                                                            {
                                                                userEditMode === true &&
                                                                <Col>
                                                                    <Form.Control type={'tel'} pattern="[0-9]{9,10}" name={'tel'} value={userEdit.tel} onChange={event => handleChangeUser(event)} required={true} />
                                                                </Col>
                                                            }
                                                        </Form.Group>
                                                        <Form.Group as={Row}>
                                                            <Form.Label column sm={4} className="text-end text-secondary">อีเมล</Form.Label>
                                                            <Form.Label column className="text-start">{user.email}</Form.Label>
                                                        </Form.Group>
                                                    </div>
                                                    {
                                                        userEditMode === false &&
                                                        <div className="text-end p-4">
                                                            <Button type="button" onClick={event => setUserEditMode(true)} >แก้ไขข้อมูลของคุณ</Button>
                                                        </div>
                                                    }
                                                    {
                                                        userEditMode === true &&
                                                        <div className="text-end p-4">
                                                            <Button type="button" variant="secondary" className="me-2" onClick={event => setUserEditMode(false)}>ยกเลิก</Button>
                                                            <Button type="button" onClick={event => handleSaveUser(event)}>
                                                                {
                                                                    loadingUser === true &&
                                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                }
                                                                บันทึก</Button>

                                                        </div>
                                                    }

                                                </div>
                                            </Form>
                                        </Col>
                                    </Row>

                                </div>
                                {
                                    alert !== '' &&
                                    <div className="alert alert-danger" role="alert">
                                        <div className="text-end">
                                            <button type="button" className="btn-close" onClick={(event) => setAlert('')}></button>
                                        </div>

                                        {alert}
                                    </div>
                                }
                            </div>
                        </div>
                    }

                </div>
            </div>
        </Layout4Main>
    )
}