import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import { Row, Col, Form, Button, Tab, Table, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Css from "components/pages/customer/Customer.module.css";
import StorageUsage from "./StorageUsage";
import AccountUsage from "./AccountUsage";
import SystemUploadFileType from "./SystemUploadFileType";
import { useEffect, useState } from "react";
import ApiConection from "api_ref/ApiConection";
import { AddCommar, DateToText } from "components/MyControl";
import SubscriptionHistory from "./SubscriptionHistory";
import QuontationStatus1 from "./QuontationStatus1";
import UtilClass from "api_ref/UtilClass";

export default function Home() {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [Register, setRegister] = useState({});
    const [Quontation, setQuontation] = useState({});
    const [currentUsed, setCurrentUsed] = useState({
        status: "",
        totalUserActive: undefined,
        totalFileSizeByte: undefined,
    });
    const [currentFile, setCurrentFile] = useState({
        status: "",
        totalFileCount: undefined,
        totalFileSizeByte: undefined,
        list_file_extension: [],
    });

    const [Config, setConfig] = useState({});

    const getRegister = async () => {
        const response = await api.getData(
            `api/register/customer/${customer.user_id}`
        );
        if (response.status === false) navigate("/customer/register");
        else {
            setRegister(response.data[0]);
            util.setSessionStorage('register', response.data[0]);
        }
    };

    const getQuonation = async () => {
        const response = await api.getData(
            `api/quontation/register/${Register.register_number}`
        );
        if (response.status === true) {
            setQuontation(response.data);
            util.setSessionStorage('quontation', response.data);
        }
    };

    const getCurrentUsed = async () => {
        if (Register.site_url == undefined) return;
        let data = {
            subDomain: Register.site_url,
        };
        const response = await api.getData(
            `api/ATS/CurrentUsed/${Register.register_number}`
        );
        if (response.status == "completed") {
            setCurrentUsed(response);
        }
    };
    const getCurrentFile = async () => {
        if (Register.site_url == undefined) return;
        let data = {
            subDomain: Register.site_url,
        };
        const response = await api.getData(
            `api/ATS/CurrentFile/${Register.register_number}`
        );
        if (response.status == "completed") {
            setCurrentFile(response);
        }
    };
    const getConfigApp = async () => {
        const response = await api.getData(`api/ConfigApp/53`);
        if (response.status === true) {
            setConfig(response);
        }
    };
    useEffect(() => {
        getRegister();
        getConfigApp();
    }, []);
    useEffect(() => {
        getQuonation();
        getCurrentUsed();
        getCurrentFile();
    }, [Register]);

    return (
        Register &&
        Quontation && (
            <LayoutCustomerMain role="customer" isApproved={Quontation.is_approved}>
                <QuontationStatus1 />
                {Quontation != undefined && (
                    <div className={Css.Block_Transparent}>
                        <div className={Css.Graph_Group}>
                            <div className={`shadow ${Css.Graph_item} ${Css.bgcolor} `}>
                                <div className={Css.Content}>
                                    <div className={Css.headCard}>
                                        <i className="fa-solid fa-box-open d-inline ms-3"></i>
                                        <h6 className="d-inline text-white ms-2">รายละเอียดแพ็กเกจ</h6>
                                    </div>
                                    <div className={Css.Content}>
                                        <div className="flex-fill">
                                            <div className={`ps-5 ${Css.contentCard} ${Css.bgcolor}`}>
                                                <Row className="mt-5 mb-0">
                                                    <Col>
                                                        <span className={Css.fontColorHead}>
                                                            ชื่อบริษัท
                                                        </span>
                                                    </Col>
                                                    <Col>
                                                        <span className={Css.fontColorBody}>
                                                            {Register.company_name}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3 mb-0">
                                                    <Col>
                                                        <span className={Css.fontColorHead}>
                                                            แพ็กเกจ
                                                        </span>
                                                    </Col>
                                                    <Col>
                                                        <span className={Css.fontColorBody}>
                                                            {Register.quontation?.package_master_name}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3 mb-0">
                                                    <Col>
                                                        <span className={Css.fontColorHead}>จำนวนผู้ใช้งาน</span>
                                                    </Col>
                                                    <Col>
                                                        <span className={Css.fontColorBody}>
                                                            {Register.quontation?.user_volume} A/C
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3 mb-0">
                                                    <Col>
                                                        <span className={Css.fontColorHead}>พื้นที่จัดเก็บไฟล์</span>
                                                    </Col>
                                                    <Col>
                                                        <span className={Css.fontColorBody}>
                                                            {Register.quontation?.storage_volume} GB
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3 mb-0">
                                                    <Col>
                                                        <span className={Css.fontColorHead}>
                                                            วันที่ต้องต่ออายุ
                                                        </span>
                                                    </Col>
                                                    <Col>
                                                        <span className={Css.fontColorBody}>
                                                            {DateToText(Register.quontation?.expiry_date)}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="border-top pt-2 mt-2 d-none">
                                            <Row>
                                                <Col className="text-start">
                                                    <Form.Text className="d-block">
                                                        ไม่พบยอดค้างชำระ
                                                    </Form.Text>
                                                    <span>0.00 บาท</span>
                                                </Col>
                                                <Col className="text-end">
                                                    <button type="button" className="btn btn-primary">
                                                        ชำระเงิน
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className={Css.Footer + " d-none"}>
                                    <a href={"#"}>View Full Plan</a>
                                </div>
                            </div>
                            {Register.quontation != undefined && Config.data != undefined && (
                                <StorageUsage
                                    total={Register.quontation.storage_volume}
                                    used={currentUsed.totalFileSizeByte}
                                    config={Config.data.value}
                                />
                            )}
                            {Register.quontation != undefined && currentUsed != undefined && (
                                <AccountUsage
                                    total={Register.quontation.user_volume}
                                    used={currentUsed.totalUserActive}
                                />
                            )}
                        </div>
                    </div>
                )}
                <div className={Css.Block_Transparent}>
                    <div className={Css.Graph_Group}>
                        <SystemUploadFileType
                            listFileExtension={currentFile.list_file_extension}
                        />
                        <SubscriptionHistory
                            user_id={customer.user_id}
                            register_number={Register.register_number}
                        />
                    </div>
                </div>
            </LayoutCustomerMain>
        )
    );
}