import Css from './Mockup.module.css'

export default function FeatureMockup() {
    return (
        <div id='div_feature_mockup' className={Css.layout_feature}>
            <div className={Css.feature}>
                <label className='mb-3 fw-semibold' >Magic Software (Thailand) Corp., Ltd.</label>
                <label>บริษัทซอฟต์แวร์แห่งแรกในประเทศไทยที่ได้รับการรับรองมาตรฐาน ISO 9001 ตั้งแต่ปี 2001</label>
                <label>โดยเรายังคงมุ่งมั่นพัฒนาซอฟต์แวร์ระบบงาน ISO อย่างต่อเนื่องมากกว่า 20 ปี</label>
                <label>และให้บริการลูกค้าองค์กรขนาดใหญ่ทั้งภาครัฐและเอกชนมากกว่า 150 ราย</label>
                <label>จนปัจจุบันได้รับการรับรองมาตรฐาน ISO 9001:2015</label>
            </div>
            <div className={`${Css.borderfeature}`}>
                <div className={`${Css.borderfeature1}`}></div>
            </div>
        </div>

    )
}