import Css from 'components/pages/application/login/Login.module.css';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ApiConection from 'api_ref/ApiConection';
import { useEffect, useState } from "react";

export default function NotificationSetting({ data = [], handleParentSubmit = undefined }) {
    const api = new ApiConection();
    const navigate = useNavigate();
    const { id } = useParams();

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const [simulate, setSimulate] = useState();

    const onSimulateChange = (event) => {
        const { name, value, checked } = event.target;

        setSimulate(prevData => {
            return { ...prevData, [name]: checked }
        });

        handleParentSubmit(value, checked);
    }

    useEffect(() => {
    }, [])

    return (
        <div className='container m-auto'><br />
            <div className={`${Css.Login_inner_layout2} w-100`}>
                <Form noValidate validated={validated}  >
                    <h6 className='mb-4'>รายการการแจ้งเตือน</h6>
                    <div >
                        {
                            data?.length > 0 &&
                            data?.map((obj, index) => (
                                <Row key={index} className={'border-bottom pt-3 pb-3'}>
                                    <Col className='text-start'>{obj.name}</Col>
                                    <Col className='text-end'>
                                        <Form.Check // prettier-ignore
                                            type="switch"
                                            id={`chk_notification_${index}`}
                                            name={`chk_notification_${index}`}
                                            defaultChecked={obj.active}
                                            value={obj.notification_id}
                                            onChange={event => onSimulateChange(event)} />
                                    </Col>
                                </Row>
                            ))
                        }
                    </div>
                </Form>
            </div>
        </div>
    )
}