import Css from './Register.module.css';
import { Row, Col, Form, Button, OverlayTrigger, Tooltip, InputGroup, Carousel } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import { useNavigate } from "react-router-dom";
import UtilClass from 'api_ref/UtilClass';
import RegisterCustomerPayment from "./RegisterCustomerPayment";
import Dropdown from 'react-bootstrap/Dropdown';
import HeadRegister from "components/layout/HeadRegister";
import { AddCommar } from 'components/MyControl';
import RegisterCustomerStep2p2Payment from './RegisterCustomerStep2p2Payment';

export default function RegisterCustomerStep2p2({
    data = undefined,
    error = '',
    SetRegisterData = undefined,
    onHandleSubmit = undefined,
    onChangeStep = undefined }
) {
    //console.log(data);

    const nav = useNavigate();
    const api = new ApiConection();
    const util = new UtilClass();

    const package_selected = util.getSessionStorage('package_selected');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [estimateData, setEstimateData] = useState(
        {
            package_master_period_id: data.package_master_period_id,
            package_master_period_name: data.package_master_period_name,

            arr_package: data.arr_package,

            is_addon_user: data.is_addon_user || data.arr_package.findIndex(x => x.package_type === 'addon-user') > 0,
            is_addon_storage: data.is_addon_storage || data.arr_package.findIndex(x => x.package_type === 'addon-storage') > 0,

            discount_code: data.discount_code,
            is_with_holding_tax: data.is_with_holding_tax,
            net: data.net,
            vat: data.vat,
            discount: data.discount,
            tax: data.tax,
            total: data.total,
            grand_total: data.grand_total,
            grand_total_sub_withholding_tax: data.grand_total_sub_withholding_tax,
            is_pay_now: data.is_pay_now,
            is_accept_agreement: data.is_accept_agreement
        });
    const [dataChangeCount, setDataChangeCount] = useState(0);

    const [calData, setCalData] = useState(undefined);

    const [packagePeriod, setPackagePeriod] = useState([]);
    const [packageMaster, setPackageMaster] = useState([]);
    const [loadingPackage, setLoadingPackage] = useState(false);
    const [loadingAddon, setLoadingAddon] = useState(false);
    const [packageAddon, setPackageAddon] = useState([]);
    const [packageAddonUser, setpackageAddonUser] = useState([]);
    const [packageAddonStorage, setPackageAddonStorage] = useState([]);
    const [changePackage,setChangePackage] = useState(1)

    const handleEstimate = (event) => {
        const { name, value, type, checked } = event.target;

        if (type === 'checkbox') {
            setEstimateData((prevData) => {
                return { ...prevData, [name]: checked };
            });
        } else {
            setEstimateData((prevData) => {
                return { ...prevData, [name]: value };
            });
        }

        setDataChangeCount(dataChangeCount + 1);
    }

    const assignEstitmatePeriod = (id, name) => {
        setEstimateData((prevData) => {
            return { ...prevData, "package_master_period_id": id };
        });
        setEstimateData((prevData) => {
            return { ...prevData, "package_master_period_name": name };
        });
        setDataChangeCount(dataChangeCount + 1);
    }
    const assingEstitmatePackage = (package_master_id,
        package_master_name,
        package_master_name_short,
        package_master_users_volume_id,
        package_master_storage_volume_id,
        package_type,
        net,
        vat,
        tax,
        total) => {
        let tmpEstimate = estimateData;
        let index_package = tmpEstimate.arr_package.findIndex(x => x.package_type === 'package' || x.package_type === 'free');
        if (index_package > -1) {
            tmpEstimate.arr_package.splice(index_package, 1);
        }

        if (package_master_id !== undefined) {
            tmpEstimate.arr_package.push({
                package_master_id: package_master_id,
                package_master_name: package_master_name,
                package_master_name_short: "แพ็กเกจ : " + package_master_name_short + "\nระยะเวลา" + tmpEstimate.package_master_period_name,
                package_master_users_volume_id: package_master_users_volume_id,
                package_master_storage_volume_id: package_master_storage_volume_id,
                package_type: package_type,
                net: Number(net.toString().replace(",", "")).toFixed(2),
                vat: Number(vat.toString().replace(",", "")).toFixed(2),
                tax: Number(tax.toString().replace(",", "")).toFixed(2),
                total: Number(total.toString().replace(",", "")).toFixed(2),
            });
        }

        if (package_type === 'free') {
            tmpEstimate.discount_code = '';
            tmpEstimate.is_with_holding_tax = false;
            tmpEstimate.is_pay_now = false;

            let index_package = tmpEstimate.arr_package.findIndex(x => x.package_type === 'addon-user');
            if (index_package > -1) {
                tmpEstimate.arr_package.splice(index_package, 1);
            }
            index_package = tmpEstimate.arr_package.findIndex(x => x.package_type === 'addon-storage');
            if (index_package > -1) {
                tmpEstimate.arr_package.splice(index_package, 1);
            }
        } else {
            tmpEstimate.is_pay_now = true;
        }

        tmpEstimate.arr_package.sort((a, b) => (a.package_master_id > b.package_master_id) ? 1 : -1);

        setEstimateData((prevData) => {
            return { ...prevData, tmpEstimate };
        });
        setDataChangeCount(dataChangeCount + 1);
        setChangePackage(changePackage + 1);
        window.scrollTo(0, 0);
    }

    const assingEstitmateUserAddon = (event) => {
        const { name, value, type } = event.target;

        let tmpEstimate = estimateData.arr_package;
        let index_package = tmpEstimate.findIndex(x => x.package_type === 'addon-user');
        if (index_package > -1) {
            tmpEstimate.splice(index_package, 1);
        }

        if (value != 99) {
            const estimat_package = packageAddonUser.find(x => x.users_valume_id == value);
            //console.log(estimat_package);
            if (estimat_package !== undefined) {
                tmpEstimate.push({
                    package_master_id: estimat_package.id,
                    package_master_name: estimat_package.name,
                    package_master_name_short: estimat_package.description + ' ' + estimat_package.number_of_user,
                    package_master_users_volume_id: estimat_package.users_valume_id,
                    package_master_storage_volume_id: estimat_package.storage_valume_id,
                    package_type: estimat_package.type,
                    net: estimat_package.net,
                    vat: estimat_package.vat,
                    tax: estimat_package.tax,
                    total: estimat_package.total
                });
            }
        }

        tmpEstimate.sort((a, b) => (a.package_master_id > b.package_master_id) ? 1 : -1);

        handleEstimate({ target: { name: 'arr_package', value: tmpEstimate } });
        setDataChangeCount(dataChangeCount + 1);
    }

    const assingEstitmateStorageAddon = (event) => {
        const { name, value, type } = event.target;

        let tmpEstimate = estimateData.arr_package;
        let index_package = tmpEstimate.findIndex(x => x.package_type === 'addon-storage');
        if (index_package > -1) {
            tmpEstimate.splice(index_package, 1);
        }

        if (value != 99) {
            const estimat_package = packageAddonStorage.find(x => x.storage_valume_id == value);
            //console.log(estimat_package);
            if (estimat_package !== undefined) {
                tmpEstimate.push({
                    package_master_id: estimat_package.id,
                    package_master_name: estimat_package.name,
                    package_master_name_short: estimat_package.description + ' ' + estimat_package.number_of_storage,
                    package_master_users_volume_id: estimat_package.users_valume_id,
                    package_master_storage_volume_id: estimat_package.storage_valume_id,
                    package_type: estimat_package.type,
                    net: estimat_package.net,
                    vat: estimat_package.vat,
                    tax: estimat_package.tax,
                    total: estimat_package.total
                });
            }
        }

        tmpEstimate.sort((a, b) => (a.package_master_id > b.package_master_id) ? 1 : -1);

        handleEstimate({ target: { name: 'arr_package', value: tmpEstimate } });
        setDataChangeCount(dataChangeCount + 1);
    }

    const getPackagePeriod = async () => {
        const response = await api.getData('api/master/package-period');
        if (response.status === true) {
            setPackagePeriod(response.data);
            let is_declare = false;

            response.data.map(obj => {
                if (estimateData.package_master_period_id === undefined && !is_declare) {
                    assignEstitmatePeriod(obj.id, obj.name);
                    is_declare = true;
                }
                else if (obj.id == estimateData.package_master_period_id) {
                    assignEstitmatePeriod(obj.id, obj.name);
                }

                return 0;
            });
        }
    }
    const getPackageMaster = async () => {
        if (estimateData.package_master_period_id === undefined) return;

        setLoadingPackage(true);
        const response = await api.getData(`api/package/type/package/period/${estimateData.package_master_period_id}`);
        if (response.status === true) {
            setPackageMaster(response.data);

            let is_declare = false;
            response.data.map(obj => {
                if (estimateData.arr_package[0].package_master_id === undefined && !is_declare) {
                    /*assingEstitmatePackage(
                        obj.id,
                        obj.name,
                        obj.description,
                        obj.users_valume_id,
                        obj.storage_valume_id,
                        obj.type,
                        obj.net == "ฟรี" ? 0 : obj.net,
                        obj.vat == "ฟรี" ? 0 : obj.vat ,
                        obj.tax == "ฟรี" ? 0 : obj.tax,
                        obj.total == "ฟรี" ? 0 : obj.total
                        );*/
                    is_declare = true;
                }
                else if (obj.id == estimateData.arr_package[0].package_master_id) {
                    assingEstitmatePackage(
                        obj.id,
                        obj.name,
                        obj.description,
                        obj.users_valume_id,
                        obj.storage_valume_id,
                        obj.type,
                        obj.net == "ฟรี" ? 0 : obj.net,
                        obj.vat == "ฟรี" ? 0 : obj.vat,
                        obj.tax == "ฟรี" ? 0 : obj.tax,
                        obj.total == "ฟรี" ? 0 : obj.total
                    );
                }

                return 0;
            })
        }
        setLoadingPackage(false);
    }

    const getPackageAddonUser = async () => {
        if (estimateData.package_master_period_id === undefined) return;

        setLoadingAddon(true);
        const response6 = await api.getData(`api/Package/type/addon-users/period/${estimateData.package_master_period_id}/master_package_id/${estimateData?.arr_package[0]?.package_master_id}`);

        response6.splice(0, 0, { users_valume_id: 99, number_of_user: 'N/A', total: '0' });
        setpackageAddonUser(response6);

        const response7 = await api.getData(`api/Package/type/addon-storages/period/${estimateData.package_master_period_id}/master_package_id/${estimateData?.arr_package[0]?.package_master_id}`);
        response7.splice(0, 0, { storage_valume_id: 99, number_of_storage: 'N/A', total: '0' });
        setPackageAddonStorage(response7);

        setLoadingAddon(false);
    }

    const checkPrice = async () => {
        if (estimateData.package_master_period_id === undefined) return;

        let arr_quontations = [];
        //debugger;

        //console.log('คำนวณ');
        estimateData.arr_package.forEach(obj => {
            arr_quontations.push(
                {
                    package_master_id: obj.package_master_id,
                    package_master_period_id: estimateData.package_master_period_id,
                    package_master_users_volume_id: obj.package_master_users_volume_id,
                    package_master_storage_volume_id: obj.package_master_storage_volume_id
                });
        });

        let requests = {
            arr_quontations: arr_quontations,
            discount_code: estimateData.discount_code,
            is_with_holding_tax: estimateData.is_with_holding_tax,
            is_accept_agreement: true
        }
        //console.log(requests);
        const response = await api.postData(`api/quontation/register/${data.register_number}/price-check/auto-approved/true`, requests)
        //console.log(response);

        let tmpData = estimateData;

        tmpData.total = Number(response.data.total).toFixed(2);
        tmpData.discount = Number(response.data.discount).toFixed(2);
        tmpData.net = Number(response.data.net).toFixed(2);
        tmpData.vat = Number(response.data.vat).toFixed(2);
        tmpData.vat_rate = Number(response.data.vat_rate * 100).toFixed(0);

        tmpData.tax = Number(response.data.tax).toFixed(2);
        tmpData.tax_rate = Number(response.data.tax_rate * 100).toFixed(0);

        tmpData.grand_total = Number(response.data.grand_total).toFixed(2);
        tmpData.grand_total_sub_withholding_tax = Number(response.data.grand_total_sub_withholding_tax).toFixed(2);
        setEstimateData(tmpData);
        setCalData(tmpData);
    }

    const handleGoToFree = () => {
        util.setCookie('package_id', 1, 1);
    }

    useEffect(() => {
        getPackagePeriod();
    }, [data]);

    useEffect(() => {
        if (estimateData.package_master_period_id !== undefined) {
            getPackageMaster();
            getPackageAddonUser();
        }
    }, [estimateData.package_master_period_id]);


    useEffect(() => {
        packageAddonStorage.map(obj => {
            if (obj.storage_valume_id == estimateData.arr_package.find(x => x.package_type === 'addon-storage')?.package_master_storage_volume_id) {
                assingEstitmateStorageAddon({ target: { name: 'package_addon_storage', value: obj.storage_valume_id } });
            }

            return 0;
        })
    }, [packageAddonStorage]);

    useEffect(() => {
        packageAddonUser.map(obj => {
            if (obj.users_valume_id == estimateData.arr_package.find(x => x.package_type === 'addon-user')?.package_master_users_volume_id) {
                assingEstitmateUserAddon({ target: { name: 'package_addon_user', value: obj.users_valume_id } });
            }

            return 0;
        })
    }, [packageAddonUser]);

    useEffect(() => {

        if (estimateData.package_master_period_id !== undefined && estimateData.arr_package[0].package_master_id !== undefined) {
            const delay = setTimeout( async() => {
                // เรียกใช้ฟังก์ชันสำหรับการคำนวณผลลัพธ์ที่ต้องการ
              
                await checkPrice();
            }, 500);

            return () => clearTimeout(delay);
        }
        
    }, [dataChangeCount , changePackage]);

    useEffect(()=>{

        const delay = setTimeout( async() => {
            // เรียกใช้ฟังก์ชันสำหรับการคำนวณผลลัพธ์ที่ต้องการ
          
            await getPackageAddonUser();

        }, 100);

        return () => clearTimeout(delay);

        
    },[changePackage])

    const getPacakgeSelectClass = (package_master_id) => {
        if (estimateData?.arr_package?.findIndex(x => x.package_master_id === package_master_id) > -1)
            return ' ' + Css.Selected;
        else
            return '';
    }

    const handleThisSubmit = async (event) => {
        const form = event.currentTarget;
        //debugger;

        setValidated(false);
        setAlert('');
        setLoading(true);

        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        let tmpData = data;

        tmpData.package_master_period_id = estimateData.package_master_period_id;
        tmpData.package_master_period_name = estimateData.package_master_period_name;
        tmpData.arr_package = estimateData.arr_package;
        tmpData.is_addon_user = estimateData.is_addon_user;
        tmpData.is_addon_storage = estimateData.is_addon_storage;
        tmpData.discount_code = estimateData.discount_code;
        tmpData.is_with_holding_tax = estimateData.is_with_holding_tax;
        tmpData.net = estimateData.net;
        tmpData.vat = estimateData.vat;
        tmpData.tax = estimateData.tax;
        tmpData.discount = estimateData.discount;
        tmpData.tax = estimateData.tax;
        tmpData.total = estimateData.total;
        tmpData.grand_total = estimateData.grand_total;
        tmpData.grand_total_sub_withholding_tax = estimateData.grand_total_sub_withholding_tax;
        tmpData.is_pay_now = estimateData.is_pay_now;
        tmpData.is_accept_agreement = estimateData.is_accept_agreement

        SetRegisterData(tmpData);

        await onHandleSubmit(tmpData);

        setLoading(false);
    }

    return (
        <Form className={`w-100 d-flex flex-column`} noValidate validated={validated} onSubmit={event => handleThisSubmit(event)}>
            <div className='flex-fill'>
                <h5 className={`text-pink-02 m-0`}>แพ็กเกจที่คุณต้องการ</h5>
                <Form.Text >เลือกแพ็กเกจ และระยะเวลาชำระค่าบริการที่เหมาะกับคุณ</Form.Text>

                <div className='mb-2 mt-4' >
                    <Form.Group>
                        <h6 className="text-blue-01"> ระยะเวลา </h6>
                        <div className={Css.Package_Period}>
                            {
                                packagePeriod.map((obj, index) => (
                                    <Form.Check key={index} type={'radio'} className={`${Css.Form_Check} ${Css.body2}`} name={'package_master_period_id'} id={'package_master_period_id_' + index} label={obj.name} value={obj.id} defaultChecked={obj.id == estimateData.package_master_period_id}
                                        onChange={event => { handleEstimate(event); assignEstitmatePeriod(obj.id, obj.name) }} />
                                ))
                            }
                        </div>
                    </Form.Group>
                    <Form.Group style={{ width: '460px' }}>
                        <div className="custom accordion" >
                            {
                                true &&
                                <>
                                    <div className="accordion-item mt-3" >
                                        <h5 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target=".pack" aria-expanded="true" aria-controls="collapseTwo collapseThree">
                                                <div className='d-flex w-100'>
                                                    <span >แพ็กเกจ {estimateData?.arr_package[0]?.package_master_name + ' '}
                                                        {
                                                            parseFloat(estimateData?.total ?? 0) > 0 &&
                                                            <>
                                                                <br />
                                                                <Form.Text>
                                                                    ผู้ใช้งาน {packageMaster?.find(x => x.id === estimateData?.arr_package[0]?.package_master_id)?.number_of_user.replace('จำนวนผู้ใช้งาน', 'A/C')}
                                                                    <br />พื้นที่ไฟล์แนบ {packageMaster?.find(x => x.id === estimateData?.arr_package[0]?.package_master_id)?.number_of_storage.replace('พื้นที่ไฟล์แนบ', '')}
                                                                </Form.Text>
                                                            </>
                                                        }
                                                    </span>
                                                    {
                                                        parseFloat(estimateData?.total ?? 0) > 0 &&
                                                        <span className='flex-fill text-end me-2'>{AddCommar(estimateData?.arr_package[0]?.total) + ' บาท'}

                                                        </span>
                                                    }
                                                </div>

                                            </button>
                                        </h5>

                                        <div id="collapseTwo" className={`accordion-collapse collapse pack ${parseFloat(estimateData?.total ?? 0) === 0 ? 'show' : ''}`} >
                                            <div className="accordion-body text-center">
                                                <div className={'mb-3 ' + Css.Package_Slide}>
                                                    {
                                                        packageMaster.map((obj_package, index) => (
                                                            <div key={index} className={Css.Package_box + getPacakgeSelectClass(obj_package.id)}>
                                                                {
                                                                    obj_package.recommend === true &&
                                                                    <div className={Css.Recommand}>
                                                                        <i className="fa-regular fa-star"></i>
                                                                        <span>แพ็กเกจแนะนำ</span>
                                                                    </div>
                                                                }
                                                                <h4>
                                                                    {
                                                                        loadingPackage === true &&
                                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    }
                                                                    {obj_package.name}
                                                                </h4>
                                                                <h2>
                                                                    {obj_package.total}
                                                                </h2>
                                                                <div className={Css.Unit}>
                                                                    {obj_package.unit}
                                                                </div>
                                                                <div className="text-center">
                                                                    <div className={Css.Spac}>
                                                                        {obj_package.number_of_user}
                                                                    </div>
                                                                    <div className={Css.Spac}>
                                                                        {obj_package.number_of_storage}
                                                                    </div>
                                                                    <div className={Css.Spac}>
                                                                        {obj_package.number_of_document}
                                                                    </div>
                                                                </div>
                                                                <div className={Css.Feature}>
                                                                    <ul>
                                                                        {/*
                                                                obj_package.arr_feature.map((obj_feature,index) => (
                                                                    <li key={index}>
                                                                        <i className="fa-solid fa-circle-arrow-right"></i>
                                                                        {obj_feature.description}
                                                                    </li>
                                                                ))
                                                            */}
                                                                        <li><i className="fa-solid fa-circle-arrow-right me-2"></i>Document Control</li>
                                                                        <li><i className="fa-solid fa-circle-arrow-right me-2"></i>Document Record</li>
                                                                    </ul>
                                                                </div>
                                                                <div>
                                                                    <Button type={'button'} data-bs-toggle="collapse" data-bs-target=".pack" aria-expanded="true" aria-controls="collapseTwo collapseThree"
                                                                        onClick={event => assingEstitmatePackage(
                                                                            obj_package.id,
                                                                            obj_package.name,
                                                                            obj_package.description,
                                                                            obj_package.users_valume_id,
                                                                            obj_package.storage_valume_id,
                                                                            obj_package.type,
                                                                            obj_package.net == "ฟรี" ? 0 : obj_package.net,
                                                                            obj_package.vat == "ฟรี" ? 0 : obj_package.vat,
                                                                            obj_package.tax == "ฟรี" ? 0 : obj_package.tax,
                                                                            obj_package.total == "ฟรี" ? 0 : obj_package.total
                                                                        )}>สมัครแพ็กเกจนี้</Button>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </Form.Group>
                    <Form.Group style={{ width: '460px' }}>
                        <div className="custom accordion" >
                            <div className="accordion-item mt-3">
                                <h5 className="accordion-header">
                                    <button className="accordion-button" type="button" >
                                        ส่วนเสริม
                                    </button>
                                </h5>
                                {
                                    estimateData?.total !== undefined &&
                                    <div id="collapseThree" className="accordion-collapse show pack" >
                                        <div className="accordion-body text-center">
                                            <Row className={` m-0 ${Css.extra} ${Css.body2}`}>
                                                <Col className={Css.checkboxSize}>
                                                    <Form.Check
                                                        inline
                                                        label="เพิ่มผู้ใช้งาน"
                                                        name="is_addon_user"
                                                        id="is_addon_user"
                                                        type={'checkbox'}
                                                        checked={estimateData.is_addon_user}
                                                        onChange={event => { handleEstimate(event); assingEstitmateUserAddon(event); }}
                                                    />
                                                </Col>

                                                <Col >
                                                    <Form.Select name={'package_addon_user'}
                                                        disabled={estimateData.is_addon_user === false}
                                                        value={estimateData.arr_package.find(x => x.package_type === 'addon-user')?.package_master_users_volume_id ?? 99}
                                                        onChange={event => assingEstitmateUserAddon(event)} >
                                                        {
                                                            packageAddonUser.map((obj, index) => (
                                                                <option key={index} value={obj.users_valume_id} >{obj.number_of_user}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </Col>

                                                <Col className={`text-end font-color-privary fs-5`}>
                                                    {estimateData.arr_package.find(x => x.package_type === 'addon-user')?.total ?? '-'} บาท
                                                </Col>

                                            </Row>

                                            <Row className={`m-0 mt-2 ${Css.extra} ${Css.body2}`}>
                                                <Col className={Css.checkboxSize}>
                                                    <Form.Check
                                                        inline
                                                        label="เพิ่มพื้นที่ไฟล์แนบ"
                                                        name="is_addon_storage"
                                                        id="is_addon_storage"
                                                        type={'checkbox'}
                                                        checked={estimateData.is_addon_storage}
                                                        onChange={event => { handleEstimate(event); assingEstitmateStorageAddon(event); }}
                                                    />
                                                </Col>

                                                <Col >
                                                    <Form.Select name={'package_addon_storage'}
                                                        disabled={estimateData.is_addon_storage === false}
                                                        value={estimateData.arr_package.find(x => x.package_type === 'addon-storage')?.package_master_storage_volume_id ?? 99}
                                                        onChange={event => assingEstitmateStorageAddon(event)} >
                                                        {
                                                            packageAddonStorage.map((obj, index) => (
                                                                <option key={index} value={obj.storage_valume_id} >{obj.number_of_storage}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                </Col>
                                                <Col className={`text-end  font-color-privary fs-5`}>
                                                    {estimateData.arr_package.find(x => x.package_type === 'addon-storage')?.total ?? '-'} บาท
                                                </Col>

                                            </Row>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <RegisterCustomerStep2p2Payment
                            data={estimateData}
                            onChangeData={handleEstimate}
                            show_is_accept_agreement={true}
                            error={error}
                            parentLoading={loading}
                            onChangeStep={onChangeStep} />
                    </Form.Group>
                </div>
            </div>
        </Form>
    );
}