import Css from 'components/pages/application/login/Login.module.css';
import { Form, Button, Modal } from "react-bootstrap";
import ApiConection from 'api_ref/ApiConection';
import { useState } from "react";
import { AddCommar, DateToText } from 'components/MyControl';
import { Helmet } from 'react-helmet-async';

export default function CustomerServiceTransactionTable({ data, record_start }) {
    //console.log("check-data=",data);
    const api = new ApiConection();
    //const navigate = useNavigate();
    //const {id} = useParams();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // eslint-disable-next-line
    const [preview, setPreview] = useState(undefined);
    const [fileType, setFileType] = useState(undefined);

    const [validated, setValidated] = useState(false);
    //const [alert,setAlert] = useState('');
    //const [loading,setLoading] = useState(false);
    //const [dataPic,setDatapic] = useState({});

    //const print = () => window.print();
    const showPic = async (file_id) => {
        //console.log(file_id);

        if (file_id === undefined || file_id === null) {
            setPreview(undefined);
            setShow(true);
            return;
        }
        const response = await api.getFile(`api/file/${file_id}/preview`);

        if(response.status === false){
            setPreview(undefined);
            setShow(true);

            return;
        }
        setFileType(response?.type);
        setPreview(URL.createObjectURL(response));
        setShow(true);
    };

    const packageDataMap = (pacArr) => {
        return pacArr.map((row, index) => (
            `${index + 1}. ${row.package_master_name_short} \n`
        ));
    };

    const getStatusDisplay = (id) => {
        if (id === 4) {
            return 'text-black-50 text-decoration-line-through';
        }
    }

    const getPrint = ()=>{
        const content = document.getElementById("divPrintContents");
        const embed = document.getElementById("divEmbedPrintContents");

        if(embed){
            if (embed.print) {
                // สำหรับบราวเซอร์ที่รองรับการพิมพ์โดยตรง
                embed.print();
            } else {
                // สำหรับบราวเซอร์ที่ไม่รองรับการพิมพ์โดยตรง
                const pri = document.getElementById("ifmcontentstoprint").contentWindow;
                pri.focus();
                pri.print();
               
            }
        }else {
            const pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(content.innerHTML);
            pri.document.close();
            pri.focus();
            pri.print();
        }
      

    }

    return (
        <>
        <Helmet>
            {
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            }
        </Helmet>
            <div className={Css.Login_outer_layout}>
                <div className={`${Css.Login_inner_layout2}  w-100`}>
                    <Form noValidate validated={validated} >
                        <h6 className={Css.color}>รายละเอียดแพ็กเกจ</h6>
                        <div className={Css.body1}>

                            <table className={`table border `}>
                                <thead className={Css.tablewidth}>
                                    <tr className={`'text-start border' ${Css.tablecolor}`}>
                                        <th> </th>
                                        <th>ใบสั่งซื้อ</th>
                                        <th className='text-center '>วันที่สั่งซื้อ</th>
                                        <th className='text-start '>แพ็กเกจ</th>
                                        {/*<th className='text-start '>ระยะเวลา</th>*/}
                                        <th className='text-center '>ค่าบริการ (บาท)</th>
                                        <th className='text-center '>สถานะ</th>
                                        {/*<th className='text-center '>ใบสั่งซื้อ</th>*/}
                                        <th className='text-center '>หลักฐานการชำระ</th>
                                        <th className='text-center '>เอกสารหัก ณ ที่จ่าย</th>
                                        <th className='text-center '>ใบแจ้งหนี้</th>
                                        <th className='text-center '>ใบเสร็จ/ใบกำกับภาษี</th>
                                    </tr>
                                </thead>
                                {(data !== undefined && data.length > 0) ?
                                    <tbody className={Css.body2}>
                                        {data.map((row, index) => (
                                            <tr className='' key={row.id}>
                                                <td className='' >{record_start + index}</td>
                                                <td className={'' + getStatusDisplay(row.payment_status_id)} >{row.quontation_number}</td>
                                                <td className={'text-center ' + getStatusDisplay(row.payment_status_id)} >{DateToText(row.create_date)}</td>
                                                <td className={`text-start  ${Css.fontcolor}`} >
                                                    <pre>{packageDataMap(row.arr_package)}</pre>
                                                    {row.package_master_period_name}<br />
                                                    {`${DateToText(row.effective_date)} - ${DateToText(row.expiry_date)}`}
                                                </td>
                                                {/*<td className={'text-start ' + getStatusDisplay(row.payment_status_id)} >{row.package_master_period_name}

                            </td>*/}
                                                <td className={'' + getStatusDisplay(row.payment_status_id)}  >{AddCommar(row.grand_total_sub_withholding_tax.toFixed(2))}</td>
                                                {/*<td className='' >
                                <Button className={`mt-1 ${Css.printBtn}`} onClick={print}>
                                    <i className='fa-solid fa-print fa-lg mb-4'/><br/></Button>
                            </td>*/}
                                                <td className={'text-center '}><pre>{row.payment_status_name}</pre></td>
                                                <td className={'' + getStatusDisplay(row.payment_status_id)} >
                                                    {
                                                        row.slip_file_id === null &&
                                                        <span>N/A</span>
                                                    }
                                                    {
                                                        row.slip_file_id !== null &&
                                                        <Button className={`${Css.printBtn} text-success`} title='หลักฐานการชำระ' onClick={event => showPic(row.slip_file_id)}>
                                                            <i className='fa-solid fa-file fa-lg ' /></Button>
                                                    }

                                                </td>
                                                <td className={'' + getStatusDisplay(row.payment_status_id)} >
                                                    {
                                                        row.with_holding_text_certficate_id === null &&
                                                        <span>N/A</span>
                                                    }
                                                    {
                                                        row.with_holding_text_certficate_id !== null &&
                                                        <Button className={`${Css.printBtn} text-warning`} title='เอกสารหัก ณ ที่จ่าย'  onClick={event => showPic(row.with_holding_text_certficate_id)}>
                                                            <i className='fa-solid fa-file fa-lg ' /></Button>
                                                    }

                                                </td>
                                                <td className={'' + getStatusDisplay(row.payment_status_id)} >
                                                    {
                                                        row.issued_invoice_file_id === null &&
                                                        <span>N/A</span>
                                                    }
                                                    {
                                                        row.issued_invoice_file_id !== null &&
                                                        <Button className={`${Css.printBtn} text-pink-01`} title='ใบแจ้งหนี้'  onClick={event => showPic(row.issued_invoice_file_id)}>
                                                            <i className='fa-solid fa-file fa-lg ' /></Button>
                                                    }

                                                </td>
                                                <td className={'' + getStatusDisplay(row.payment_status_id)} >
                                                    {
                                                        row.issued_receipt_file_id === null &&
                                                        <span>N/A</span>
                                                    }
                                                    {
                                                        row.issued_receipt_file_id !== null &&
                                                        <Button className={`${Css.printBtn} text-purple-01`} title='ใบเสร็จ/ใบกำกับภาษี'  onClick={event => showPic(row.issued_receipt_file_id)}>
                                                            <i className='fa-solid fa-file fa-lg' /></Button>
                                                    }

                                                </td>
                                                {/*<td>
                                <button type={'button'} className='btn btn-outline-secondary' title=' ขอหลักส่งหลักฐานใหม่' >
                                    <i className="fa-regular fa-pen-to-square"></i>

                                </button>
                            </td>*/}
                                            </tr>
                                        ))}
                                    </tbody>
                                    :
                                    <tbody className={Css.body2}>
                                        <tr className='pt-2' >
                                            <td className='pt-2' colSpan={12}>ไม่พบข้อมูล<br /><br></br></td>
                                        </tr>
                                    </tbody>
                                }
                            </table>

                        </div>
                    </Form>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div  id={'divPrintContents'} >
                    {
                        preview === undefined &&
                        <span>ไม่สามารถแสดงไฟล์ได้</span>
                    }
                    {
                        preview !== undefined &&
                        fileType.indexOf('image') > -1 &&
                        <>
                            <img alt='' src={preview} style={{ marginTop: '0', width: '100%', height: 'auto' }} />
                        </>
                    }
                    {
                        preview !== undefined &&
                        fileType.indexOf('image') === -1 &&
                        <>
                            <embed id={'divEmbedPrintContents'} alt='' src={preview} style={{ marginTop: '0', width: '100%', height: '500px' }} type="application/pdf"  />
                            <iframe id="ifmcontentstoprint" type="application/pdf"  src={preview} className='w-0 h-0 position-absolute d-none' ></iframe>
                        </>
                    }
                    </div>
                    {
                        preview !== undefined &&
                        fileType.indexOf('image') > -1 &&
                        <>
                            <iframe id="ifmcontentstoprint" type="application/pdf" className='w-0 h-0 position-absolute d-none' ></iframe>
                        </>
                    }
                    <button className='btn btn-primary w-100 mt-5' onClick={event => getPrint(event)} >
                        <i className="fa-solid fa-print me-2"></i>
                        พิมพ์/ Print
                    </button>
                    
                </Modal.Body>
            </Modal>
            
        </>

    )
}