import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from "react-bootstrap";
import React from 'react';
import UtilClass from 'api_ref/UtilClass';

export default function RegisterCustomerStep3v2({ getValue, onChangeData = undefined, onChangeStep = undefined }) {
    const util = new UtilClass();
    const navigate = useNavigate();
    const user = util.getSessionStorage('customer');

    const [validated, setValidated] = useState(false);
    const [timer, setTimer] = useState(10);

    const handleHomePage = () => {
        navigate('/customer/home');
    }

    useEffect(() => {
        setTimer(9);

        window.history.pushState(null, null, window.location.href);

        const handleBackButton = () => {
            // เพิ่มสถานะใหม่ลงในประวัติเพื่อป้องกันการกด back
            navigate('/customer/payment');
        };

        // ตั้งค่าเหตุการณ์เมื่อผู้ใช้กดปุ่ม back
        window.addEventListener('popstate', handleBackButton);

        // คืนค่าฟังก์ชันเมื่อ Component ถูก unmount เพื่อลบเหตุการณ์ที่ถูกติดตั้งไว้
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    useEffect(() => {
        //console.log('test');
        if (timer === 0) {
            navigate('/');
        } else {
            window.setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        }
    }, [timer])
    const print = () => window.print();

    return (
        <Form className={`w-100 d-flex flex-column`} noValidate validated={validated} onSubmit={(_) => handleHomePage()} >
            <div className='flex-fill'>
                <h5 className={`text-pink-02 m-0`}>ขอขอบคุณที่สนับสนุน</h5>
                <Form.Text >เราหวังเป็นอย่างยิ่งว่าคุณจะได้รับประสบการณ์ที่ดีในการใช้บริการของเรา</Form.Text>
                <Form.Group className='mb-2 mt-4'>
                    <label>
                        ทางทีมงานกำลังจัดเตรียม ISO DCaaS ให้พร้อมใช้งาน
                        <br /><br />
                        เพื่อให้การสมัครใช้งานเสร็จสมบูรณ์ กรุณาตรวจสอบอีเมลของคุณ<br />
                        <a href={`mailto:${user.email}`}>{user.email}</a><br />
                        เพื่อทำการยืนยันการใช้งานระบบ ISO DCaaS
                    </label>
                </Form.Group>
                <Form.Group className='mt-4 text-primary'>
                    <i>
                        *หากไม่พบอีเมลใน Inbox ของคุณ ให้ตรวจสอบที่ Junk หรือ Spam Mail.
                    </i>
                </Form.Group>
                <Form.Group className='mt-4'>
                    <label>หน้าจอจะปิดตัวอัตโนมัติภายใน {timer} วินาที</label>
                </Form.Group>
            </div>
        </Form>
    )
}