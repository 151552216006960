import Css from 'components/pages/application/login/Login.module.css';
import { useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function ProfileUser({ data = undefined, callSubmitParentData = undefined, parentAlert = '' }) {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const [mode, setMode] = useState('view');

    const [simulate, setSimulate] = useState(data);

    const gotoChangePassword = () => {
        navigate('/customer/change/password');
    }

    const handleSetSimulate = (event) => {
        const { name, value } = event.target;

        setSimulate(prevData => {
            return { ...prevData, [name]: value }
        })
    }

    const handleCancel = (event) => {
        setSimulate(data)
        setMode('view')
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }
        callSubmitParentData(simulate);
        setLoading(false);

        if (parentAlert === '') {
            setMode('view')
        } else {
            setMode('edit')
        }
    }

    return (
        <div className='container m-auto'>
            <div >
                <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>
                    <Row>
                        <Col >
                            <div className={`${Css.Login_inner_layout2} w-100`}>
                                <h6 className='mb-4'>ข้อมูลผู้ใช้งาน</h6>
                                <div>
                                    <Form.Group className='mb-2'>
                                        <Form.Label className='label-title'>ชื่อ</Form.Label>
                                        <Form.Control type={'text'}
                                            plaintext={(mode === 'view') ? true : false}
                                            readOnly={(mode === 'view') ? true : false}
                                            className='border-bottom'
                                            name={'first_name'}
                                            value={simulate?.first_name}
                                            placeholder={'ชื่อ'}
                                            required={true}
                                            onChange={handleSetSimulate} />
                                    </Form.Group>
                                    <Form.Group className='mb-2'>
                                        <Form.Label className='label-title'>นามสกุล</Form.Label>
                                        <Form.Control type={'text'}
                                            plaintext={(mode === 'view') ? true : false}
                                            readOnly={(mode === 'view') ? true : false}
                                            className='border-bottom'
                                            name={'last_name'}
                                            value={simulate?.last_name}
                                            placeholder={'นามสกุล'}
                                            required={true}
                                            onChange={handleSetSimulate} />
                                    </Form.Group>
                                    <Form.Group className='mb-2'>
                                        <Form.Label className='label-title'>หมายเลขโทรศัพท์</Form.Label>
                                        <Form.Control type={'tel'}
                                            plaintext={(mode === 'view') ? true : false}
                                            readOnly={(mode === 'view') ? true : false}
                                            className='border-bottom'
                                            name={'tel'}
                                            value={simulate?.tel}
                                            placeholder={'00-000-0000'}
                                            required={true}
                                            onChange={handleSetSimulate} />
                                    </Form.Group>
                                    <Form.Group className='mb-5'>
                                        <Form.Label className='label-title'>อีเมล</Form.Label>
                                        <Form.Control type={'text'} plaintext readOnly placeholder={'อีเมล'}
                                            className='border-bottom'
                                            value={simulate?.email} />
                                    </Form.Group>

                                    {
                                        alert != '' &&
                                        <div className="alert alert-danger" role="alert">
                                            A simple danger alert—check it out!
                                        </div>
                                    }

                                    {
                                        mode === 'edit' &&
                                        <Row>
                                            <Col>
                                                <Button variant="outline-secondary" type="button" className='ps-5 pe-5 me-1'
                                                    onClick={event => handleCancel(event)}  >ยกเลิก</Button>
                                                <Button variant="primary" type="submit" className='ps-5 pe-5'
                                                    disabled={loading}
                                                >
                                                    {
                                                        loading === true &&
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    }

                                                    บันทึก

                                                </Button>
                                            </Col>
                                        </Row>
                                    }
                                    {
                                        mode === 'view' &&
                                        <Button variant="outline-secondary" type="button" className='ps-5 pe-5'
                                            onClick={event => setMode('edit')}>
                                            ขอเปลี่ยนข้อมูล
                                        </Button>
                                    }

                                </div>
                            </div>

                        </Col>
                        <Col>
                            <div className={`${Css.Login_inner_layout2} w-100`}>
                                <h6 className='mb-4'>ข้อมูลล็อคอิน</h6>
                                <div>
                                    <Form.Group className='mb-2' >
                                        <Form.Label className='label-title'>ล็อคอิน</Form.Label>
                                        <Form.Control type={'text'} className='pt-0' plaintext readOnly
                                            placeholder={'ล็อคอิน'}
                                            value={simulate?.login_name} />
                                    </Form.Group>
                                    <Form.Group className='mb-2' >
                                        <Form.Label className='label-title'>รหัสผ่าน</Form.Label>
                                        <Col>
                                            <Button variant="secondary" type="button" className='ps-5 pe-5' onClick={event => gotoChangePassword()}>ขอเปลี่ยนรหัสผ่านใหม่</Button>
                                        </Col>
                                    </Form.Group>
                                </div>
                            </div>

                        </Col>
                    </Row>

                </Form>
            </div>
        </div>
    )
}