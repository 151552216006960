import Layout4Register from "components/layout/Layout4Register";
import LandingPageHeadMenu from "components/layout/LandingPageHeadMenu";
import Css from './Login.module.css';
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";

export default function ForgotPasswordConfirm() {
    const navigate = useNavigate();

    const [validated, setValidated] = useState(false);

    const handelForgotPassword = async (event) => {
        navigate('/login');
    }

    const handleHomePage = () => {
        navigate('/');
    }

    const handleRegister = (event) => {
        navigate('/register');
    }
    const handleLogin = (event) => {
        navigate('/login');
    }

    return (
        <LayoutLandingPageV2 showContactUs={false}>
            <div className={Css.Login_outer_layout}>
                <div className={`${Css.Login_inner_layout} ${Css.pad} mt-5`}>
                    <Form noValidate validated={validated} onSubmit={handelForgotPassword} >
                        <img src={require('images/DCasS.png')} alt={'DCasS'} height={80} className="mt-3 mb-3" onClick={handleHomePage} />

                        <h5 className="mt-3">กรุณาตรวจสอบอีเมล</h5>
                        <div className={`alert alert-info mt-4 ${Css.body2}`}>
                            ระบบได้ส่ง ลิงก์ สำหรับ รีเซ็ตรหัสผ่าน แล้ว ทางอีเมล
                        </div>
                        <div className="mt-4">
                            <Button type={'submit'} className={'w-100'} >
                                เข้าสู่ระบบ
                            </Button>
                        </div>
                    </Form>
                </div>
                <div className="mt-3 mb-3">
                    <span className={`me-4 ${Css.body2}`}>คุณยังไม่มีบัญชี DCaaS?</span>
                    <a className={`${Css.Alink_blue} ${Css.body2}`} onClick={handleRegister}>คลิกเข้าสู่ระบบ</a>
                </div>
            </div>
        </LayoutLandingPageV2>
    )
}