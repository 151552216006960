import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Banner from './Banner';
import Notices from './Notices';
import KeyFeatures from './KeyFeatures';
import Security from './Security';
import Attribute from './Attribute';
import PackageMockup from './PackageMockup';
import HeaderMockup from './HeaderMockup';
import Css from './Mockup.module.css'
import ContactUs from './ContactUs';
import FooterMockup from './FooterMockup';
import UtilClass from "api_ref/UtilClass";
import ApiConection from "api_ref/ApiConection";
import FeatureMockup from "./FeatureMockup";
import Banner2 from "./Banner2";

export default function HomeMockup() {
    const util = new UtilClass();
    const api = new ApiConection();

    const [loginSecurity, setLoginSecurity] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (util.getSessionStorage('_8086') === null || util.getSessionStorage('_8086') === undefined) {
                await api.loginLandingPage();
                setLoginSecurity(true);
            } else {
                setLoginSecurity(true);
            }
        }

        fetchData().catch(console.error)
    }, [])

    return (loginSecurity &&
        <Container>
            <HeaderMockup />
            <div className={Css.Main_Content_Home}>
                <Notices />
                <Banner2 />
                <Attribute />
                <KeyFeatures />
                <Security />
                <PackageMockup />
                <ContactUs />
                <FooterMockup />
            </div>
        </Container>
    );
}