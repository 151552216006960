import Layout4Register from "components/layout/Layout4Register";
import Css from 'components/pages/application/login/Login.module.css';
import { Row, Col, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ApiConection from 'api_ref/ApiConection';
import { useState, useEffect } from "react";
import Termsofuse from "./Termsofuse"
import LandingPageHeadMenu from "components/layout/LandingPageHeadMenu";
import ReCAPTCHA from "react-google-recaptcha";
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";

export default function Register() {
    const api = new ApiConection();
    const navigate = useNavigate();

    const [validated, setValidated] = useState(false);
    const [input, setInput] = useState({
        first_name: '',
        last_name: '',
        tel: '',
        email: '',
        password: '',
        showPassword: false,
        is_accept_agreement: false,
    });
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [capcha, setCapcha] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleChange = (event) => {
        const { name, type, value, checked } = event.target;

        if (type === 'checkbox') {
            setInput(prevData => {
                return { ...prevData, [name]: checked }
            })
        } else {
            setInput(prevData => {
                return { ...prevData, [name]: value }
            });
        }
    }
    const handleLogin = (event) => {
        navigate('/login');
    }

    const handleClickShowPassword = () => {
        setInput({ ...input, showPassword: !input.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = (prop) => (event) => {
        setInput({ ...input, [prop]: event.target.value });
    };

    const handleTermsofuse = (event) => {
        navigate('/register/Termsofuse');
        //window.open('register/Termsofuse','_blank');
    }
    const handlepolicy = (event) => {
        navigate('/register/Privacypolicy');
        //window.open('register/Privacypolicy','_blank');
    }

    const handleRegister = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        let data = {
            first_name: input.first_name,
            last_name: input.last_name,
            tel: input.tel,
            email: input.email,
            password: input.password,
            is_accept_agreement: input.is_accept_agreement
        }
        setLoading(true);
        const response = await api.callLandingPagePostV2('api/authentication/signon', data);
        setLoading(false);

        if (response.status === false) {
            setAlert(response.message);
        } else {
            setStep(2);
            //navigate(process.env.PUBLIC_URL +'/login');
        }
    }

    const HandleonChangeCapcha = () => {
        setCapcha(true)
    }

    const handleHomePage = () => {
        navigate('/');
    }

    return (
        <LayoutLandingPageV2 showContactUs={false} >

            <div className={Css.Login_outer_layout}>

                <div className={`${Css.Login_inner_layout} ${Css.pad1} mt-5 `}>
                    {
                        step === 1 &&
                        <Form noValidate validated={validated} onSubmit={handleRegister} className={` ${Css.boby2}`}>
                            <img src={require('images/DCasS.png')} alt={'DCasS'} height={80} onClick={handleHomePage} />
                            <h5 className="mt-4">สมัครใช้งาน</h5>
                            <Form.Group as={Row} className="text-start mb-2 mt-4">
                                <Col>
                                    <Form.Label className={Css.body2}>ชื่อ</Form.Label>
                                    <Form.Control type={'text'} name={'first_name'} value={input.first_name} onChange={handleChange} required={true} />
                                </Col>
                                <Col>
                                    <Form.Label className={Css.body2}>นามสกุล</Form.Label>
                                    <Form.Control type={'text'} name={'last_name'} value={input.last_name} onChange={handleChange} required={true} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="text-start mb-2">
                                <Form.Label className={Css.body2}>เบอร์มือถือ</Form.Label>
                                <Col>
                                    <Form.Control type={'tel'} pattern="[0-9]{9,10}" name={'tel'} value={input.tel} onChange={handleChange} required={true} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="text-start mb-2">
                                <Form.Label className={Css.body2}>อีเมล</Form.Label>
                                <Col>
                                    <Form.Control type={'email'} name={'email'} value={input.email} onChange={handleChange} required={true} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="text-start mb-2">
                                <Form.Label className={Css.body2}>รหัสผ่าน (ไม่น้อยกว่า 8 ตัวอักษร)</Form.Label>
                                <Col className={Css.pwd1}>
                                    <Form.Control className={Css.pwd2 + ' CustomPwd'} pattern="(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*]).{8,}" type={input.showPassword ? "text" : "password"} name={'password'} value={input.password} onChange={handlePasswordChange("password")} required={true} />
                                    <i className={Css.pwd3} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>{input.showPassword ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</i>
                                    <Form.Control.Feedback type={'invalid'}>
                                        1 ตัวอักษรภาษาอังกฤษพิมพ์เล็ก และ 1 ตัวอักษรภาษาอังกฤษพิมพ์ใหญ่.<br />
                                        1 ตัวเลข (0-9).<br />
                                        1 อัขระพิเศษดังนี้ (!@#$%^&*).<br />
                                        ความยาวรวมกันอย่างน้อย 8 ตัวอักษร.<br />
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                            {/*<ReCAPTCHA
                                className={`mt-3 ${Css.capchaCenter}`}
                                sitekey="6LcVHtgmAAAAAPOcUwYonbti3bQtkcwAKQBBkhW8"
                                onChange={HandleonChangeCapcha}
                    />*/}

                            {
                                <>
                                    <br />
                                    <Form.Check className='mt-2 mb-2 text-start'>
                                        <Form.Check.Input
                                            name={"is_accept_agreement"}
                                            id={"is_accept_agreement"}
                                            onChange={handleChange}
                                            checked={input.is_accept_agreement}
                                            required={true}
                                        />
                                        <Form.Check.Label className={` ${Css.body2}`} >
                                            <span className={`me-1 ${Css.body2} `}>ยอมรับ</span>
                                            <Link to={'/register/Termsofuse'} target="_blank" className={`${Css.link_contact + " me-1"} ${Css.body2}`} >เงื่อนไขการใช้บริการ</Link>
                                            <span className={`me-1 ${Css.body2} `}>และ</span>
                                            <Link to={'/register/Privacypolicy'} target="_blank" className={`${Css.link_contact + " me-1"} ${Css.body2}`} >นโยบายความเป็นส่วนตัว</Link>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </>
                            }
                            {
                                alert !== '' &&
                                <div className="alert alert-danger" role="alert">
                                    <div className="text-end">
                                        <button type="button" className="btn-close" onClick={(event) => setAlert('')}></button>
                                    </div>

                                    {alert}
                                </div>
                            }
                            <div className="mt-4">
                                <Button type={'submit'}  className={'w-100'}
                                    disabled={ //((input?.is_accept_agreement ?? false) === false)? true : false ||
                                        loading === true
                                    } >
                                    {
                                        loading === true &&
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    }
                                    ยืนยัน
                                </Button>
                            </div>
                            {/*<div className={Css.line}>
                                <span className={`${Css.body2} `}>คุณได้อ่านและยอมรับ</span>
                                <a  className={`${Css.Alink_blue + ' me-1'} ${Css.body2}`} onClick={handleTermsofuse}>เงื่อนไขการใช้บริการ</a>
                                <span className={`${Css.body2} `}>และ</span>
                                <a  className={`${Css.Alink_blue} ${Css.body2}`}onClick={handlepolicy}>นโยบายความเป็นส่วนตัว</a>
                            </div>
                            */}
                        </Form>
                    }
                    {
                        step === 2 &&
                        <Form noValidate validated={validated} onSubmit={handleHomePage} >
                            <h5>ลงทะเบียนเรียบร้อยแล้ว</h5>
                            <div className={`alert alert-success mt-4 ${Css.body2}`}>
                                {
                                    loading === true &&
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                }
                                <i className="fa-regular fa-circle-check me-1"></i>
                                ระบบได้รับข้อมูลลงทะเบียนแล้ว<br /><br />
                                โปรดตรวจสอบ <a href={`mailto:${input.email}`}>{input.email}</a><br />
                                ที่ลงทะเบียนไว้<br />
                                ทำการคลิกลิงก์ยืนยัน เพื่อยืนยันการสมัครเข้าใช้งาน
                            </div>
                            <div className="mt-4">
                                <Button type={'submit'} className={'w-100'} >
                                    กลับหน้าหลัก
                                </Button>
                            </div>
                        </Form>
                    }
                </div>
                <div className="me-4 mt-4">
                    <span className={`me-4 ${Css.body2} `}>คุณมีบัญชีกับเราแล้ว?</span>
                    <a className={`${Css.Alink_blue} ${Css.body2}`} onClick={handleLogin}>คลิกเข้าสู่ระบบ</a>
                </div>
            </div><br />
        </LayoutLandingPageV2>
    )
}