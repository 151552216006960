import Css from './Register.module.css';
import { useEffect, useState } from "react";
import { Row, Col, Form, Button, OverlayTrigger, Tooltip, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ApiConection from 'api_ref/ApiConection';
import UtilClass from 'api_ref/UtilClass';

export default function RegisterCustomerStep2p1({
    data = undefined,
    onChangeData = undefined,
    SetRegisterData = undefined,
    onHandleSubmit = undefined,
    error = '',
    onChangeStep = undefined }) {
    useEffect(() => {
    }, []);

    useEffect(() => {
        setAlert(error);
    }, [error]);

    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [pacakgeData, setPackageData] = useState(undefined);

    const getFreeTrial = async () => {
        const response = await api.getData('api/package/type/free');
        if (response.status === true) {
            setPackageData(response.data[0]);
            //console.log(response.data[0]);
        }
    }

    const handleGoToPackage = () => {
        onChangeData({ target: { name: 'is_accept_agreement', value: false } });
        util.setCookie('package_id', 0, 1);
        onChangeStep(2);
    }

    const handleThisSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        let tmpData = data;

        tmpData.package_master_period_id = 1
        //tmpData.package_master_period_name = estimateData.package_master_period_name;
        tmpData.arr_package = [{
            package_master_id: 1,
            package_master_period_id: 1,
            package_master_users_volume_id: 1,
            package_master_storage_volume_id: 1
        }];
        tmpData.is_addon_user = false;
        tmpData.is_addon_storage = false;
        tmpData.discount_code = '';
        tmpData.is_with_holding_tax = false;
        tmpData.net = 0;
        tmpData.vat = 0;
        tmpData.discount = 0;
        tmpData.tax = 0;
        tmpData.total = 0;
        tmpData.is_pay_now = true;
        tmpData.is_accept_agreement = true;

        //SetRegisterData(tmpData);

        await onHandleSubmit(tmpData);

        setLoading(false);
    }

    useEffect(() => {
        getFreeTrial();
    }, []);

    if (pacakgeData === undefined) return;
    return (
        <>
            <Form className={`w-100 d-flex flex-column`} noValidate validated={validated} onSubmit={event => handleThisSubmit(event)} >
                <div className='flex-fill'>
                    <h5 className={`text-pink-02 m-0`}>Free Trial</h5>
                    <Form.Text >คุณสามารถทดลองใช้ได้{pacakgeData.unit} หากพึ่งพอใจสามารถอัปเกรดได้ทันที</Form.Text>

                    <Form.Group className='mb-2 mt-4' >
                        <h5 className='text-blue-01'>{pacakgeData.unit}</h5>
                    </Form.Group>
                    <Form.Group className='mb-3' >
                        <span className='d-block'>{pacakgeData.number_of_document}</span>
                        <span className='d-block'>{pacakgeData.number_of_storage}</span>
                    </Form.Group>
                    <Form.Group className='mb-3' >
                        <Form.Text>
                            <ul>
                                <li>Document Control</li>
                                <li>Document Record</li>
                            </ul>

                        </Form.Text>

                    </Form.Group>
                    {/*<Form.Check className='mt-4 mb-2'>
                        <Form.Check.Input
                            name={"is_accept_agreement"}
                            id={"is_accept_agreement"}
                            onChange={(event) => onChangeData(event)}
                            checked={data.is_accept_agreement}
                            required={true}
                        />
                        <Form.Check.Label className={` `} >
                            <span className={`me-1  `}>ยอมรับ</span>
                            <Link to={'/register/Termsofuse'} target="_blank" className={`${Css.link_contact + " me-1"} `} >เงื่อนไขการใช้บริการ</Link>
                            <span className={`me-1 `}>และ</span>
                            <Link to={'/register/Privacypolicy'} target="_blank" className={`${Css.link_contact + " me-1"} `} >นโยบายความเป็นส่วนตัว</Link>
                        </Form.Check.Label>
                    </Form.Check>*/}
                </div>

                {
                    alert !== '' &&
                    <div className="alert alert-danger" role="alert">
                        <div className="text-end">
                            <button type="button" className="btn-close" onClick={(event) => setAlert('')}></button>
                        </div>

                        {alert}
                    </div>
                }
                <div className='text-start mt-3 d-flex'>
                    <div className='flex-fill'>
                        <Button type={'submit'} disabled={loading} className='btn-primary me-2' style={{ width: '180px' }}
                        //disabled={ ((data?.is_accept_agreement ?? false) === false) ? true : false }
                        >
                            {
                                loading === true &&
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            }
                            ยืนยัน
                        </Button>
                        <button type='button' disabled={loading}  className='btn btn-secondary-trn' onClick={(_) => onChangeStep(1)}>
                            ย้อนกลับ
                        </button>
                    </div>
                    <div className='d-none'>
                        <Link onClick={(_) => handleGoToPackage()}>ต้องการซื้อแพ็กเกจ?</Link>
                    </div>
                </div>
            </Form>
        </>
    );
}