import React, { useEffect, useState } from "react"
import Css from './Manual.module.css'
import { Accordion } from "react-bootstrap"
import ApiConection from "api_ref/ApiConection"

export default function ManualFAQ(){
    const api = new ApiConection();
    const [loading,setLoading] = useState(false);
    const [masterFaq,setMasterFaq] = useState([]);

    const getData = async()=>{

        setLoading(true);

        const response = await api.callLandingPageGetV2(`api/MasterFaq`);

        if (response.status === false)
            setMasterFaq([]);
        else {
            setMasterFaq(response.data);
        }

        setLoading(false);
    }
    useEffect(()=>{
        
        getData();

    },[])

    return (
        <div className={Css.imgManaul}>
            <h3 className="mb-4">คำถามที่พบบ่อย (FAQ)</h3>
            <div>
                <Accordion>
                    {
                        loading === true &&
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    }
                    {
                    loading === false &&
                        masterFaq.map((data,index) => (
                            <Accordion.Item key={index} eventKey={index + 10}>
                                <Accordion.Header>{data.question}</Accordion.Header>
                                <Accordion.Body>
                                    <div dangerouslySetInnerHTML={{__html:data.answer}}></div>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))
                    }
                    
                </Accordion>
            </div>
        </div>
    )
        
}