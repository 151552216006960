import Css from 'components/pages/application/login/Login.module.css';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ApiConection from 'api_ref/ApiConection';
import { useEffect, useState } from "react";
import moment from 'moment';
import { AddCommar, ButtonUploadFileMini, DateToText } from 'components/MyControl';
import UtilClass from 'api_ref/UtilClass';

export default function CustomerWithholdingTaxTable({ data, reloadTable }) {
    const api = new ApiConection();
    const util = new UtilClass();
    const navigate = useNavigate();

    const registerSession = util.getSessionStorage('register');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    /// [ {"key" : quontation_number, "files" :files , "loading":false }]
    const [fileList, setFileList] = useState([]);
    const [countChange, setCountChange] = useState(1);

    const [state, setState] = useState({
        files: undefined,
        path: '',
        ref_code: '',
        quontation: ''
    });
    const [fileId, setFileId] = useState({
        id: null,
    });

    const packageDataMap = (pacArr) => {
        return pacArr.map((row, index) => (
            `${index + 1}. ${row.package_master_name_short} \n`
        ));
    };

    const handleChangeFile = (event) => {
        const { name, value, type, files } = event.target;

        if (event.target?.setCustomValidity) {
            event.target?.setCustomValidity("");
        }

        let name_key = name.split('_');

        let arr_fileList = fileList;

        if (arr_fileList.findIndex(x => x.key === name_key[0]) > -1) {
            arr_fileList.splice(arr_fileList.findIndex(x => x.key === name_key[0]), 1);
        }

        if (files[0] !== undefined) {
            arr_fileList.push(
                {
                    key: name_key[0],
                    file: files[0],
                    loading: false,
                    error: ''
                });
        }

        setFileList(arr_fileList);
        setCountChange(countChange + 1);
    }

    const disableSendFille = (quontation_number) => {
        if (fileList.findIndex(x => x.key === quontation_number) === -1)
            return true;
        else
            return false;
    }

    const handleSendData = async (event, quontation_number) => {
        event.preventDefault();

        let arr_fileList = fileList;
        let file_data = arr_fileList.find(x => x.key === quontation_number);
        file_data.loading = true;

        setFileList(arr_fileList);
        setCountChange(countChange + 1);

        const responseFile = await getServiceAddFile(quontation_number, file_data.file)
        if (responseFile.status === false) {
            file_data.error = responseFile.messsage;

            setFileList(arr_fileList);
            setCountChange(countChange + 1);

            return;
        }

        const responseData = await putDataApi(quontation_number, responseFile.data.id);

        if (responseFile.status === false) {
            file_data.error = responseFile.messsage;

            setFileList(arr_fileList);
            setCountChange(countChange + 1);
            return;
        }

        arr_fileList.splice(arr_fileList.findIndex(x => x.key === quontation_number), 1);

        setFileList(arr_fileList);
        setCountChange(countChange + 1);

        reloadTable();
        setCountChange(countChange + 1);
        return;
    };

    const getServiceAddFile = async (quontation_number, files) => {
        let formdata = new FormData();
        formdata.append("files", files);
        formdata.append("path", `customer/${registerSession.register_number}`);
        formdata.append("ref_code", quontation_number);

        const response = await api.postFile(
            `api/file/upload/paymentslip`, formdata
        );

        return response;
    };

    const putDataApi = async (quontation_number, file_id) => {
        let request = {
            file_id: file_id
        }
        const response = await api.putData(
            `api/quontation/${quontation_number}/withholding-tax`, request
        );

        return response;
    }

    return (
        <div className={Css.Login_outer_layout}>
            <div className={`${Css.Login_inner_layout2}  w-100`}>
                <Form encType="multipart/form-data" noValidate validated={validated}>
                    <h6 className={Css.color}>รายละเอียดแพ็กเกจ</h6>
                    <div className={Css.body1}>
                        {(data !== undefined && data.length > 0)

                            ?

                            <table className={`table border rounded`}>
                                <thead className={Css.tablewidth}>
                                    <tr className={`text-start border ${Css.tablecolor}`}>
                                        <th></th>
                                        <th>ใบสั่งซื้อ</th>
                                        <th className='text-center '>วันที่สั่งซื้อ</th>
                                        <th className='text-start ' >แพ็กเกจ</th>
                                        <th className='text-end ' >ค่าบริการ (บาท)</th>
                                        <th className='text-center w-200px' >เอกสารหัก ณ ที่จ่าย</th>
                                        <th className='text-center ' ></th>
                                    </tr>
                                </thead>
                                <tbody className={Css.body2}>

                                    {data.map((row, index) => (
                                        <tr key={row.id}>
                                            <td className='text-end'>{index + 1}</td>
                                            <td>{row.quontation_number}</td>
                                            <td className=' text-center  ' >{DateToText(row.create_date)}</td>
                                            <td className={`text-start  ${Css.fontcolor}`} >
                                                <pre>{packageDataMap(row.arr_package)}</pre>
                                                {row.package_master_period_name}<br />
                                                {`${DateToText(row.effective_date)} - ${DateToText(row.expiry_date)}`}
                                            </td>
                                            <td className='text-end'>{AddCommar(row?.grand_total_sub_withholding_tax?.toFixed(2))}</td>
                                            <td className={` text-center  ${Css.fontcolor2}`} >
                                                <ButtonUploadFileMini name={`${row.quontation_number}_file`} label='อัพโหลดไฟล์' onChange={handleChangeFile} />
                                                {
                                                    (fileList.find(x => x.key === row.quontation_number)?.error ?? '') !== '' &&
                                                    <div className="alert alert-danger mt-2" role="alert">
                                                        {fileList.find(x => x.key === row.quontation_number)?.error}
                                                    </div>
                                                }
                                            </td>
                                            <td className={`text-end  ${Css.fontcolor2}`} >
                                                <button type="button"
                                                    onClick={(event) => handleSendData(event, row.quontation_number)}
                                                    disabled={disableSendFille(row.quontation_number)}
                                                    className='btn btn-primary'>
                                                    {
                                                        fileList.find(x => x.key === row.quontation_number)?.loading === true &&
                                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                    }

                                                    <i className="fa-regular fa-paper-plane me-2"></i>
                                                    ส่ง
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            :

                            <table className={`table border rounded`}>
                                <thead className={Css.tablewidth}>
                                    <tr className={`text-start border ${Css.tablecolor}`}>
                                        <th></th>
                                        <th>ใบสั่งซื้อ</th>
                                        <th className='text-start '>ระยะเวลา</th>
                                        <th className='text-start ' >แพ็กเกจ</th>
                                        <th className='text-end ' >ค่าบริการ (บาท)</th>
                                        <th className='text-center ' >เอกสารหัก ณ ที่จ่าย</th>
                                        <th className='text-center ' ></th>
                                    </tr>
                                </thead>
                                <tbody className={Css.body2}>
                                    <tr className='pt-2' >
                                        <td className='pt-2' colSpan={12}>ไม่พบข้อมูล<br /><br></br></td>
                                    </tr>
                                </tbody>
                            </table>
                        }

                    </div>
                </Form>
            </div>
        </div>
    );
}