import { Row, Col, Carousel } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react"
import Css from './Mockup.module.css'

const arr_feature = [
    { image: 'banner_slide1.jpg' },
    { image: 'banner_slide2.jpg' },
    { image: 'banner_slide3.jpg' },
    { image: 'banner_slide4.jpg' }
]

export default function Banner2() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const handleGetClass = (idx) => {
        if (index == idx) return (Css.slideshowDot_active);
        else return Css.slideshowDot;
    }

    return (
        <div className={Css.AllSlide}>
            <Carousel activeIndex={index} onSelect={handleSelect}>
                {arr_feature.map((obj, index) => (
                    <Carousel.Item key={index}>
                        <div className={Css.slide} key={index} style={{ obj }}>
                            <img className={`${Css.slide_img}`} alt={''} src={require(`images/${obj.image}`)} />
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>

            <div className={Css.slideshowDots}>
                {arr_feature.map((_, idx) => (
                    <div
                        key={idx}
                        className={handleGetClass(idx)}
                        onClick={() => { setIndex(idx); }}
                    ></div>
                ))}
            </div>
        </div>

    );
}