import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout4Home from "components/layout/LayoutLandingPage";
import UtilClass from "api_ref/UtilClass";
import Css from "./Register.module.css";
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";

function Cookie() {
    const util = new UtilClass();
    const navigate = useNavigate();

    const handleRegister = (event) => {
        navigate("/register");
    };

    const handleATSPotral = (event) => {
        window.open(process.env.REACT_APP_ATS_POTAL, "_blank");
    };

    const handleFreeRegister = (event) => {
        //---- save period = 1 = month , package free id = 1;
        util.setCookie("period_id", 1, 1);
        util.setCookie("package_id", 1, 1);
        navigate("/register");
    };
    const handleLogin = (event) => {
        navigate("/login");
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <LayoutLandingPageV2
            handleFreeRegister={handleFreeRegister}
            handleRegister={handleRegister}
            handleLogin={handleLogin}
            handleATSPotral={handleATSPotral}
        >
            <div className="container m-auto">
                <div className={Css.TermsAndCondition + " mt-3 mb-3 p-5"}>
                    <h2>นโยบายคุกกี้</h2>
                    <h5 className="mt-0 ">บริษัท เมจิกซอฟท์แวร์ (ประเทศไทย) จำกัด</h5>
                    <p>
                        เว็บไซต์นี้ให้บริการโดยบริษัท เมจิกซอฟท์แวร์ (ประเทศไทย) จำกัด (เรียกว่า “บริษัทฯ”) เว็บไซต์นี้ใช้เพื่อช่วยแยกแยะรูปแบบการใช้งานเว็บไซต์ของ
                        ท่านจากผู้ใช้งานอื่นๆ ซึ่งจะช่วยให้ท่านได้รับประสบการณ์ที่ดีจากการใช้งานเว็บไซต์ และช่วยให้บริษัทฯ สามารถพัฒนาคุณภาพของเว็บไซต์ให้ดียิ่งขึ้น
                        กรณีที่ท่านใช้งานเว็บไซต์นี้ต่อไป ถือว่าท่านได้ยินยอมให้บริษัทฯ ติดตั้งคุกกี้ไว้ในเครื่องคอมพิวเตอร์ของท่าน
                    </p>

                    <h5>1. คุกกี้คืออะไร?</h5>
                    <p>
                        คุกกี้คือข้อความขนาดเล็กที่ประกอบด้วยส่วนของข้อมูลที่มาจากการดาวน์โหลดที่อาจถูกเก็บบันทึกไว้ในเว็บเบราว์เซอร์ที่ท่านใช้งานหรืออุปกรณ์อื่น ๆ
                        ที่ต่อเชื่อมอินเตอร์เน็ต (อาทิ เครื่องคอมพิวเตอร์ สมาร์ทโฟน หรือแท็บเล็ตของท่าน) โดยที่เครื่องเซิร์ฟเวอร์สามารถเรียกดูได้ในภายหลัง
                        คล้ายกับหน่วยความจำของหน้าเว็บ การทำงานของคุกกี้ ช่วยให้บริษัทฯ รวบรวมและจัดเก็บข้อมูลการเยี่ยมชมเว็บไซต์ของท่านดังต่อไปนี้โดยอัตโนมัติ
                    </p>
                    <p>
                        การทำงานของคุกกี้ ช่วยให้บริษัทฯ รวบรวมและจัดเก็บข้อมูลการเยี่ยมชมเว็บไซต์ของท่านดังต่อไปนี้โดยอัตโนมัติ
                    </p>
                    <ul>
                        <li>
                            อินเตอร์เน็ตโดเมนและ IP Address จากจุดที่ท่านเข้าสู่เว็บไซต์
                        </li>
                        <li>
                            ประเภทของเบราว์เซอร์ซอฟต์แวร์ ตลอดจนโครงสร้างและระบบการปฏิบัติงานที่ใช้ในการเข้าสู่เว็บไซต์
                        </li>
                        <li>
                            วันที่และเวลาที่ท่านเข้าสู่เว็บไซต์
                        </li>
                        <li>
                            ที่อยู่ของเว็บไซต์อื่นที่เชื่อมโยงท่านเข้าสู่เว็บไซต์ของบริษัทฯ
                        </li>
                        <li className='mb-4'>
                            หน้าเว็บที่ท่านเข้าเยี่ยมชม และนำท่านออกจากเว็บไซต์ของบริษัทฯ รวมถึงเนื้อหาบนหน้าเว็บที่ท่านเยี่ยมชมและระยะเวลาที่ท่านใช้ในการเยี่ยมชม
                        </li>
                    </ul>

                    <h5>2. การใช้งานคุกกี้</h5>
                    <p>ประเภทของคุกกี้ที่บริษัทฯ ใช้และในการใช้คุกกี้ดังกล่าว ประกอบด้วย แต่ไม่จำกัดเพียง รายการต่อไปนี้</p>
                    <div className={`${Css.fontmargin} mb-3`}>
                        <label className={`${Css.textbold} pe-2`}>2.1 คุกกี้ประเภทที่มีความจำเป็นอย่างยิ่ง (Strictly Necessary Cookies):</label>
                        คุกกี้ประเภทนี้มีความสำคัญต่อการทำงานของเว็บไซต์ ซึ่งรวมถึงคุกกี้ที่ทำให้ท่านสามารถเข้าถึงข้อมูลและใช้งานในเว็บไซต์ของบริษัทฯ ได้อย่างปลอดภัย
                    </div>
                    <div className={`${Css.fontmargin} mb-3`}>
                        <label className={`${Css.textbold} pe-2`}>2.2 คุกกี้เพื่อการวิเคราะห์/วัดผลการทำงานของเว็บไซต์(Analytical/Performance Cookies):</label>
                        คุกกี้ประเภทนี้จะช่วยให้บริษัทฯ สามารถจดจำและนับจำนวนผู้เข้าเยี่ยมชมเว็บไซต์ตลอดจนช่วยให้บริษัทฯ ทราบถึงพฤติกรรมในการเยี่ยมชมเว็บไซต์
                        เพื่อปรับปรุงการทำงานของเว็บไซต์ให้มีคุณภาพดีขึ้นและมีความเหมาะสมมากขึ้น อีกทั้งเพื่อรวบรวมข้อมูลทางสถิติเกี่ยวกับวิธีการเข้าและพฤติกรรมการเยี่ยมชมเว็บไซต์
                        ซึ่งจะช่วยปรับปรุงการทำงานของเว็บไซต์โดยให้ผู้ใช้งานสามารถค้นหาสิ่งที่ต้องการได้อย่างง่ายดาย และช่วยให้บริษัทฯ เข้าใจถึงความสนใจของผู้ใช้ และวัดความมีประสิทธิผลของโฆษณาของบริษัทฯ
                    </div>
                    <div className={`${Css.fontmargin} mb-3`}>
                        <label className={`${Css.textbold} pe-2`}>2.3 คุกกี้เพื่อการทำงานของเว็บไซต์ (Functionality Cookies): </label>
                        คุกกี้ประเภทนี้ใช้ในการจดจำตัวท่านเมื่อท่านกลับมาใช้งานเว็บไซต์อีกครั้ง ซึ่งจะช่วยให้บริษัทฯ สามารถปรับแต่งเนื้อหาสำหรับท่าน ปรับให้เว็บไซต์ของบริษัทฯ
                        ตอบสนองความต้องการใช้งานของท่าน รวมถึงจดจำการตั้งค่าของท่าน อาทิ ภาษา หรือภูมิภาค หรือขนาดของตัวอักษรที่ท่านเลือกใช้ในการใช้งานในเว็บไซต์
                    </div>
                    <div className={`${Css.fontmargin} mb-3`}>
                        <label className={`${Css.textbold} pe-2`}>2.4 คุกกี้เพื่อปรับเนื้อหาเข้ากับกลุ่มเป้าหมาย (Targeting Cookies): </label>
                        คุกกี้ประเภทนี้จะบันทึกการเข้าชมเว็บไซต์ของท่าน หน้าเว็บที่ท่านได้เยี่ยมชม และลิงค์ที่ท่านเยี่ยมชม บริษัทฯ จะใช้ข้อมูลนี้เพื่อปรับให้เว็บไซต์และเนื้อหาใดๆ
                        ที่ปรากฏอยู่บนหน้าเว็บตรงกับความสนใจของคุณมากขึ้น นอกจากนี้ บริษัทฯ ยังอาจแชร์ข้อมูลนี้กับบุคคลที่สามเพื่อวัตถุประสงค์ดังกล่าว
                    </div>
                    <div className={`${Css.fontmargin} mb-4`}>
                        <label className={`${Css.textbold} pe-2`}>2.5 คุกกี้เพื่อการโฆษณา (Advertising Cookies): </label>
                        คุกกี้ประเภทนี้จะจดจำการตั้งค่าของท่านในการเข้าใช้งานหน้าเว็บไซต์ และนำไปใช้เป็นข้อมูลประกอบการปรับเปลี่ยนหน้าเว็บไซต์เพื่อนำเสนอโฆษณาที่เหมาะสมกับท่านมากที่สุดเท่าที่จะเป็นไปได้
                        ตัวอย่างเช่น การเลือกแสดงโฆษณาสินค้าที่ท่านสนใจ การป้องกันหรือการจำกัดจำนวนครั้งที่ท่านจะเห็นหน้าเว็บไซต์ของโฆษณาซ้ำๆ เพื่อช่วยวัดความมีประสิทธิผลของโฆษณา
                    </div>
                    <p>
                        โปรดทราบว่าคุกกี้บางประเภทในเว็บไซต์นี้จัดการโดยบุคคลที่สาม เช่น เครือข่ายการโฆษณา ลักษณะการทำงานต่าง ๆ อาทิ วิดีโอ แผนที่ และโซเชียลมีเดีย และผู้ให้บริการเว็บไซต์ภายนอกอื่น ๆ
                        เช่น บริการวิเคราะห์การเข้าเยี่ยมชมเว็บไซต์ เป็นต้น คุกกี้เหล่านี้มักจะเป็นคุกกี้เพื่อการวิเคราะห์/วัดผลการทำงาน หรือคุกกี้เพื่อปรับเนื้อหาเข้ากับกลุ่มเป้าหมาย ท่านควรต้องศึกษานโยบายการใช้
                        คุกกี้และนโยบายส่วนบุคคลในเว็บไซต์ของบุคคลที่สาม เพื่อให้เข้าใจถึงวิธีการที่บุคคลที่สามอาจนำข้อมูลของท่านไปใช้
                    </p>

                    <h5>3. การตั้งค่าคุกกี้</h5>
                    <p>
                        ท่านสามารถตั้งค่าการทำงานของคุกกี้ได้ โดยการตั้งค่าเบราว์เซอร์ของท่าน และตั้งค่าความเป็นส่วนตัวเพื่อจำกัดการรวบรวมข้อมูลโดยคุกกี้ในอนาคต
                        (รายละเอียดเพิ่มเติมสามารถศึกษาได้จาก https://aboutcookies.org/)
                    </p>
                    <p>
                        อย่างไรก็ตาม บริการบางอย่างบนเว็บไซต์ของบริษัทฯ จำเป็นต้องมีการใช้คุกกี้ หากท่านปิดการทำงานคุกกี้อาจทำให้ท่านใช้งานฟังก์ชันบางอย่างหรือทั้งหมดของบริการดังกล่าวได้อย่างไม่ราบรื่น
                    </p>
                    <p className={Css.fontp}>
                        ประกาศ ณ วันที่ 1 กรกฎาคม 2564
                    </p>

                </div>
            </div>
        </LayoutLandingPageV2>
    );
}
export default Cookie;