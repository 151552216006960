import React from "react";
import {Nav,Navbar} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Css from './Layout.module.css';

export default function Footer4Bottom() {
    const navigate = useNavigate();

    const handlePrivacypolicy =(event)=>{
        navigate('/register/Privacypolicy');
    }

    const handleTermsofuse =(event)=>{
        navigate('/register/Termsofuse');
    }
        return(
            <div className={Css.Footer_Bottom}>
                <Navbar className="justify-content-center">
                    <Nav>
                        <Nav.Link onClick={handleTermsofuse} className={Css.ColorWhite}>เงื่อนไขบริการ</Nav.Link>
                        <Nav.Link disabled className={Css.ColorWhite}>|</Nav.Link>
                        <Nav.Link onClick={handlePrivacypolicy} className={Css.ColorWhite}>นโยบายความเป็นส่วนตัว</Nav.Link>
                        {/*<Nav.Link disabled className={Css.ColorWhite}>|</Nav.Link>
                        <Nav.Link disabled className={`${Css.ColorGrey}`}>สอบถาม</Nav.Link>*/}
                    </Nav>
                </Navbar>
                <label className="d-block text-center">© Copyright 2023. Magic Software (Thailand) Corp., Ltd. - All Rights Reserved.  Version : {process.env.REACT_APP_VERSION}</label>
            </div>
        )
}