import { useNavigate } from "react-router-dom";
import UtilClass from "api_ref/UtilClass";
import React, { useEffect, useState } from "react"
import Css from './Manual.module.css'
import { Row, Col, Form, Button } from "react-bootstrap";
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";
import ManualUpgradeMain from "./ManualUpgradeMain";
import ManualUpgradePlus from "./ManualUpgradePlus";
import ManualLoginISO from "./ManualLoginISO";
import ManualForgetPassword from "./ManualForgetPassword";
import ManualGeneralPackage from "./ManualGeneralPackage";
import ManualFreeTrial2 from "./ManualFreeTrial2";
import ManualLayoutMenu from "./ManualLayoutMenu";
import ManualFAQ from "./ManualFAQ";

export default function Manual() {
    const util = new UtilClass();
    const [step, setStep] = useState(1);

    const handleChangeStep = (i_step) => {
        setStep(i_step);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    return (
        <>
            <LayoutLandingPageV2 showContactUs={false}   >

                <div className={`${Css.outline_layout} container m-auto mt-3 mb-3 p-5`}>
                    <h2 className={Css.headManual}>ช่วยเหลือ</h2>
                    <Row>
                        <Col className={Css.MenuManual} xs={2}>
                            <ManualLayoutMenu onChangeStep={handleChangeStep} currentStep={step} />
                        </Col>
                        <Col className={Css.DetailManual}>
                            <div className={Css.inline_layout_Register} >
                                {step == 1 &&
                                    <ManualFreeTrial2 />}
                                {step == 2 &&
                                    <ManualGeneralPackage />}
                                {step == 3 &&
                                    <ManualUpgradeMain />}
                                {step == 4 &&
                                    <ManualUpgradePlus />}
                                {step == 5 &&
                                    <ManualForgetPassword />}
                                {step == 6 &&
                                    <ManualLoginISO />}
                                {step == 7 &&
                                    <ManualFAQ />
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </LayoutLandingPageV2 >
        </>
    );
}