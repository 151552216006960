import { useEffect, useState } from "react";

export default function Pagination({ page_no = 1, page_count = 1, page_show = 3, onPageChange = undefined }) {
    const [pagination, setPagination] = useState([]);

    const getPaging = () => {
        const max_page = page_show;
        const middle = Math.floor(max_page / 2);

        let tmp_paging = [];

        let start = 1;
        let end = max_page;

        start = page_no - middle;
        start = ((start + max_page - 1) > page_count) ? (page_count - max_page + 1) : start;
        start = (start < 1) ? 1 : start;

        end = start + max_page - 1;
        end = (end > page_count) ? page_count : end;

        for (let i = start; i <= end; i++)
            tmp_paging.push({ page: i });

        setPagination(tmp_paging);
    }

    useEffect(() => {
        getPaging();
    }, [page_no, page_count])

    return (

        <nav aria-label="Page navigation">
            <ul className="pagination pagination justify-content-end" style={{ paddingRight: '20px' }}>
                <li className="page-item">
                    <a className="page-link" aria-label="Previous" onClick={(_) => onPageChange(1)}>
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                {
                    pagination.map((obj, index) => (
                        <li key={index} className={`page-item ${(parseInt(page_no) === parseInt(obj.page)) ? 'active' : ''}`}><a className="page-link" onClick={(_) => onPageChange(obj.page)}>{obj.page}</a></li>
                    ))
                }

                <li className="page-item">
                    <a className="page-link" aria-label="Next" onClick={(_) => onPageChange(page_count)}>
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}