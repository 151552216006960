import { useNavigate } from "react-router-dom";
import Css from 'components/pages/customer/Customer.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import ATSApiConnection from "api_ref/ATSApiConnection";
import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import QuontationStatus1 from "./QuontationStatus1";
import TableCustomer from "./Tablecustomer";
import UtilClass from "api_ref/UtilClass";

export default function CustomerPackageUsed() {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');
    const register = util.getSessionStorage('register');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [Quontation, setQuontation] = useState([]);

    const getQuonation = async () => {
        let request = {
            search: {},
            pagination: {
                page: 1,
                size: 10,
                order_by: 'QuontationNumber',
                order_mode: 'desc'
            }
        }
        const response = await api.postData(`api/quontation/register/${register.register_number}/customer/${customer.user_id}/result`, request);
        //debugger;
        //console.log(response);
        if (response.status === true) {
            setQuontation(response.data);
        }
    }

    useEffect(() => {
        getQuonation();
    }, []);

    return (
        <LayoutCustomerMain role="customer" >
            <QuontationStatus1 />
            <div className={Css.Block_Transparent}><br />
                <h5 className={Css.color1}>แพ็กเกจเคยใช้</h5><br />
                <div >
                    <TableCustomer data={Quontation} />
                </div>
            </div>
        </LayoutCustomerMain>
    )
}