import React, { useEffect, useState } from "react"
import Css from './Manual.module.css'

export default function ManualUpgradePlus() {
    return (
        <div className={Css.imgManaul}>
            <h3 className="mb-4">อัปเกรดแพ็กเกจเสริม</h3>
            <label className="mt-0">1. ให้ท่านกดเข้าสู่ระบบเพื่อทำการอัปเกรดแพ็กเกจ</label>
            <img src={require("images/upgrde.png")} className='mt-3' />
            <label>2. ท่านทำการใส่อีเมล และรหัสผ่าน ที่ท่านได้ทำการสมัครสมาชิกเอาไว้ </label>
            <img src={require("images/login9.png")} className='mt-3' />
            <label>3. หลังจากที่เข้าสู่ระบบเรียบร้อยแล้วก็จะเข้าสู่หน้าหลักของเว็บไซต์ ให้ท่านทำการเลือกที่หน้า อัปเกรดแพ็กเกจ และกดอัปเกรดเลย</label>
            <img src={require("images/upgrade1.png")} className='mt-3' />
            <label>4. ท่านสามารถเลือกเพิ่มส่วนเสริมเพิ่มเติมได้ โดยสามารถเลือกความจุได้ตามที่ต้องการ ถ้าหากท่านมีโค้ดส่วนลดสามารถเพิ่มโค้ดส่วนลดได้ตามรูปด้านล่าง</label>
            <img src={require("images/packageplus1.png")} className='mt-3' />
            <label>5. ตรวจสอบรายละเอียดการชำระเงินให้ครบถ้วนถูกต้องและกดปุ่มดำเนินงานต่อ</label>
            <img src={require("images/packageplus2.png")} className='mt-3' />
            <label>6. ให้ท่านเลือกชำระค่าบริการ โดยจะมีให้เลือก 2 ช่องทางคือ ชำระภายหลังและชำระทันที</label>
            <img src={require("images/packagepluspaylater4.png")} className='mt-3' />
            <label>7. หากท่านต้องการชำระทันที</label>
            <div className={Css.subText}>
                <label>1. ให้ท่านแนบสลิปการโอนเงินตามยอดชำระที่กำหนด, กรอกธนาคารที่ท่านใช้ในการโอนเงิน, วันที่โอน, เวลาที่โอน และจำนวนเงินที่โอน</label>
                <label>2. ตรวจสอบ ชื่อบริษัท, ที่อยู่, หมายเลขโทรศัพท์, เลขประจำตัวผู้เสียภาษีอากร และ สาขา/รหัสสาขา ให้ถูกต้อง กดยอมรับเงื่อนไขการใช้บริการและนโยบายความเป็นส่วนตัว และกดปุ่มยืนยันชำระค่าบริการ</label>
            </div>
            <img src={require("images/packageplus3.png")} className='mt-3' />
            <div className={Css.subText}>
                <label className="mt-3">3. เมื่อทำรายการสำเร็จสามารถกดเริ่มต้นใช้งาน</label>
            </div>
            <img src={require("images/upgrade9.png")} className='mt-3' />
            <div className={Css.subText}>
                <label className="mt-3">4. เมื่อยืนยันการชำระเงินสำเร็จ ท่านจะได้รับอีเมลสรุปรายการคำสั่งซื้อ</label>
            </div>
            <img src={require("images/packageplus4.png")} className='mt-3' />
            <label>8. หากท่านต้องการชำระภายหลัง</label>
            <div className={Css.subText}>
                <label>1. ให้ท่านตรวจสอบ ชื่อบริษัท, ที่อยู่, หมายเลขโทรศัพท์, เลขประจำตัวผู้เสียภาษีอากร และ สาขา/รหัสสาขา ให้ถูกต้อง กดยอมรับเงื่อนไขการใช้บริการและนโยบายความเป็นส่วนตัว และกดปุ่มยืนยัน</label>
            </div>
            <img src={require("images/packagepluspaylater1.png")} className='mt-3' />
            <div className={Css.subText}>
                <label className="mt-3">2. เมื่อท่านต้องการชำระเงิน ให้กดไปที่หน้า ชำระค่าบริการ และกดรายละเอียดเพื่อดูยอดค้างจ่าย</label>
            </div>
            <img src={require("images/packagepluspaylater5.png")} className='mt-3' />
            <div className={Css.subText}>
                <label className="mt-3">3. ให้ท่านตรวจสอบยอดเงินที่ต้องชำระให้ถูกต้องก่อนจะกดปุ่มดำเนินงานต่อ</label>
            </div>
            <img src={require("images/packagepluspaylater6.png")} className='mt-3' />

            <div className={Css.subText}>
                <label className="mt-3">4. ให้ท่านแนบสลิปการโอนเงินตามยอดชำระที่กำหนด, กรอกธนาคารที่ท่านใช้ในการโอนเงิน, วันที่โอน, เวลาที่โอน และจำนวนเงินที่โอน</label>
                <label>5. ให้ท่านตรวจสอบ ชื่อบริษัท, ที่อยู่, หมายเลขโทรศัพท์, เลขประจำตัวผู้เสียภาษีอากร และ สาขา/รหัสสาขา ให้ถูกต้อง กดยอมรับเงื่อนไขการใช้บริการและนโยบายความเป็นส่วนตัว และกดปุ่มยืนยันชำระค่าบริการ</label>
            </div>
            <img src={require("images/packagepluspaylater2.png")} className='mt-3' />
            <div className={Css.subText}>
                <label className="mt-3">6. เมื่อทำรายการสำเร็จสามารถกดเริ่มต้นใช้งาน</label>
            </div>
            <img src={require("images/payment5.png")} className='mt-3' />
            <div className={Css.subText}>
                <label className="mt-3">7. เมื่อยืนยันการชำระเงินสำเร็จ ท่านจะได้รับอีเมลสรุปรายการคำสั่งซื้อ</label>
            </div>
            <img src={require("images/packageplus4.png")} className='mt-3' />
            <label>9. หากท่านต้องการแนบเอกสารหัก ณ ที่จ่าย ให้ท่านไปยังหน้าแนบใบหัก ณ ที่จ่าย และเลือกอัปโหลดไฟล์</label>
            <img src={require("images/upgrade10.png")} className='mt-3' />
            <label>10. เมื่อทำการแนบรูปสำเร็จ ให้กดปุ่มส่งเพื่อทำการแนบรูปให้เจ้าหน้าที่</label>
            <img src={require("images/upgrade11.png")} className='mt-3' />
        </div>
    )
}