import UtilClass from 'api_ref/UtilClass';
import { DateToText } from 'components/MyControl';
import Css from 'components/pages/application/login/Login.module.css';
import { Row, Col, Form, Button } from "react-bootstrap";

export default function PackageChanged({ data }) {
    const util = new UtilClass();

    const register = util.getSessionStorage('register');

    return (
        <div className={`standard-box mb-3`}>
            <Form >
                <h6 className={`text-start border-bottom`}>แพ็กเกจที่เปลี่ยนไป</h6>
                <table className='table table-borderless'>
                    <tbody >
                        <tr>
                            <td className='text-start '>แพ็กเกจ</td>
                            <td className={`text-start w-auto  ${Css.fc}`} >
                                {
                                    (data?.compare === undefined || data?.compare === null) ?
                                        data?.package_master_name : data?.compare?.before_package_name
                                }
                            </td>
                            <td>
                                <i className="fa-solid fa-angles-right fa-lg" style={{ color: "#063D95", }}></i>
                            </td>
                            <td className={`text-start w-auto ${Css.fontcolor}`} >{data?.compare?.after_package_name}</td>
                        </tr>
                        <tr className='pt-2'>
                            <td className='text-start text-nowrap ' >จำนวนผู้ใช้งาน</td>
                            <td className={`text-start  ${Css.fc}`} >{
                                (data?.compare === undefined || data?.compare === null) ?
                                    data?.user_volume + ' A/C' : data?.compare?.before_users_volume
                            }

                            </td>
                            <td><i className="fa-solid fa-angles-right fa-lg" style={{ color: "#063D95", }}></i></td>
                            <td className={`text-start  ${Css.fontcolor}`} >{data?.compare?.after_users_volume}
                                {
                                    (data?.compare?.after_users_volume !== undefined && data?.compare?.after_users_volume !== '') &&
                                    (Number(data?.compare?.before_users_volume.replace(' A/C', '')) > Number(data?.compare?.after_users_volume.replace(' A/C', ''))) &&
                                    <i className="fa-solid fa-triangle-exclamation text-warning ms-1"></i>
                                }
                            </td>
                        </tr>
                        <tr className='pt-2'>
                            <td className='text-start text-nowrap ' >พื้นที่ไฟล์แนบ</td>
                            <td className={`text-start  ${Css.fc}`} >{
                                (data?.compare === undefined || data?.compare === null) ?
                                    data?.storage_volume + ' GB' : data?.compare?.before_storage_volume
                            }
                            </td>
                            <td><i className="fa-solid fa-angles-right fa-lg" style={{ color: "#063D95", }}></i></td>
                            <td className={`text-start  ${Css.fontcolor}`} >{data?.compare?.after_storage_volume}
                                {
                                    (data?.compare?.after_storage_volume !== undefined && data?.compare?.after_storage_volume !== '') &&
                                    (Number(data?.compare?.before_storage_volume.replace(' GB', '')) > Number(data?.compare?.after_storage_volume.replace(' GB', ''))) &&
                                    <i className="fa-solid fa-triangle-exclamation text-warning ms-1"></i>
                                }

                            </td>
                        </tr> 
                        <tr className='pt-2'>
                            <td className='text-start ' >ระยะเวลา</td>
                            <td className={`text-start  ${Css.fc}`} >{
                                (data?.compare === undefined || data?.compare === null) ?
                                    data?.package_master_period_name : data?.compare?.before_period_name
                            }</td>
                            <td><i className="fa-solid fa-angles-right fa-lg" style={{ color: "#063D95", }}></i></td>
                            <td className={`text-start  ${Css.fontcolor}`} >{data?.compare?.after_period_name}</td>
                        </tr>
                        <tr className='pt-2'>
                            <td className='text-start ' >เริ่มวันที่</td>
                            <td className={`text-start  ${Css.fc}`} >
                                {(data?.compare === undefined || data?.compare === null) ?
                                    DateToText(register?.quontation?.effective_date) : data?.compare?.before_effective_date}
                            </td>
                            <td><i className="fa-solid fa-angles-right fa-lg" style={{ color: "#063D95", }}></i></td>
                            <td className={`text-start  ${Css.fontcolor}`} >{data?.compare?.after_effective_date}</td>
                        </tr>
                    </tbody>
                </table>
            </Form>
        </div>
    )
}