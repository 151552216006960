import Css from "components/pages/application/login/Login.module.css";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { AddCommar } from "components/MyControl";

export default function CustomerPaymentYesHave({ data }) {
    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate("/customer/payment/service");
    };

    const calculateDate = () => {
        const dueDate = moment(data.due_date).startOf('day');
        const currentDate = moment().startOf('day');

        const duration = moment.duration(dueDate.diff(currentDate));
        const daysRemaining = duration.asDays();
        if (currentDate.isAfter(dueDate)) {
            const daysOverdue = currentDate.diff(dueDate, 'days');
            const overDue = Math.ceil(daysOverdue);
            return (`เกิน ${overDue}`);
        }
        else {
            const dueDatetime = Math.ceil(daysRemaining);
            return (`เหลือ ${dueDatetime + 1}`);
        }
    };
    return (
        <div className={Css.Login_outer_layout}>
            <br />
            <div className={`${Css.Login_inner_layout4} ${Css.pad}`}>
                <Form onSubmit={handleSubmit}>
                    <h6 className={Css.color}>
                        {
                            data?.package_type?.indexOf('renew') === -1 &&
                            <>มียอดค้างชำระ</>
                        }
                        {
                            data?.package_type?.indexOf('renew') > -1 &&
                            <>ค่าบริการต่ออายุ</>
                        }
                    </h6>
                    <br />
                    <Row>
                        <Col xs={8} className="text-start">
                            <div className={Css.body2}>
                                แพ็กเกจ : {data.package_master_name_short}
                                <div className="mt-2 mb-2">
                                    <label className={`text-start text-blue-01 fs-4`}>
                                        {AddCommar(data.grand_total_sub_withholding_tax.toFixed(2))}
                                    </label>
                                    <label>&nbsp;บาท</label>
                                </div>
                                กรุณาชำระก่อนวันที่ {moment(data.due_date).format('DD/MM/YYYY')} ({calculateDate()} วัน)
                                <br />
                                ใบสั่งซื้อ {data.quontation_number}
                            </div>
                        </Col>

                        <Col className="">
                            <Button type={"submit"} className={`${Css.rt} ${Css.btn3}`}  >
                                รายละเอียด
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
}