import Layout4Register from "components/layout/Layout4Register";
import LandingPageHeadMenu from "components/layout/LandingPageHeadMenu";
import Css from './Login.module.css';
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";

export default function ResetPassword() {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const api = new ApiConection();
    const [validated, setValidated] = useState(false);
    const [input, setInput] = useState({
        password: '',
        confirm_password: '',
        showPassword: false,
        showPassword2: false
    });
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        const { name, type, value } = event.target;

        setInput(prevData => {
            return { ...prevData, [name]: value }
        })
    }

    const handleClickShowPassword = (name) => {
        //console.log(input[name])
        setInput({ ...input, [name]: !input[name] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = (prop) => (event) => {
        setInput({ ...input, [prop]: event.target.value });
    };

    const handleHomePage = () => {
        navigate('/');
    }

    const handleRegister = (event) => {
        navigate('/register');
    }

    const handleLogin = (event) => {
        navigate('/login');
    }

    const handleReset = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }
        const id = searchParams.get('id');

        let data = {
            request_id: encodeURIComponent(id),
            password: input.password,
            confirm_password: input.confirm_password
        }
        setLoading(true);
        const response = await api.callLandingPagePostV2('api/authentication/reset-password', data);
        setLoading(false);

        if (response.status === false) {
            setAlert(response.message);
        } else {
            navigate('/reset/confirm');
        }
    }

    return (

        <LayoutLandingPageV2 showContactUs={false}>
            <div className={Css.Login_outer_layout}>
                <div className={`${Css.Login_inner_layout} ${Css.pad} mt-5`}>
                    <Form noValidate validated={validated} onSubmit={handleReset}>
                        <img src={require('images/DCasS.png')} alt={'DCasS'} height={80} className="mb-3" onClick={handleHomePage} />
                        <h5>รีเซ็ตรหัสผ่าน</h5>
                        <Form.Group as={Row} className="text-start mb-2 mt-4">
                            <Form.Label className={Css.body2} >รหัสผ่าน (ไม่น้อยกว่า 8 ตัวอักษร)</Form.Label>
                            <Col className={Css.pwd1}>
                                <Form.Control className={Css.pwd2 + ' CustomPwd'} pattern="(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*]).{8,}" type={input.showPassword ? "text" : "password"} name={'password'} value={input.password} onChange={handlePasswordChange("password")} required={true} />
                                <i className={Css.pwd3} onClick={event => handleClickShowPassword('showPassword')} onMouseDown={handleMouseDownPassword}>{input.showPassword ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</i>
                                <Form.Control.Feedback type={'invalid'}>
                                    1 ตัวอักษรภาษาอังกฤษพิมพ์เล็ก และ 1 ตัวอักษรภาษาอังกฤษพิมพ์ใหญ่.<br />
                                    1 ตัวเลข (0-9).<br />
                                    1 อัขระพิเศษดังนี้ (!@#$%^&*).<br />
                                    ความยาวรวมกันอย่างน้อย 8 ตัวอักษร.<br />
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="text-start mb-2">
                            <Form.Label className={Css.body2} >ยืนยันรหัสผ่าน (รหัสผ่านต้องเหมือนกัน)</Form.Label>
                            <Col className={Css.pwd1}>
                                <Form.Control className={Css.pwd2 + ' CustomPwd'} pattern="(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*]).{8,}" type={input.showPassword2 ? "text" : "password"} name={'confirm_password'} value={input.confirm_password} onChange={handlePasswordChange("confirm_password")} required={true} />
                                <i className={Css.pwd3} onClick={event => handleClickShowPassword('showPassword2')} onMouseDown={handleMouseDownPassword}>{input.showPassword2 ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</i>
                                <Form.Control.Feedback type={'invalid'}>
                                    1 ตัวอักษรภาษาอังกฤษพิมพ์เล็ก และ 1 ตัวอักษรภาษาอังกฤษพิมพ์ใหญ่.<br />
                                    1 ตัวเลข (0-9).<br />
                                    1 อัขระพิเศษดังนี้ (!@#$%^&*).<br />
                                    ความยาวรวมกันอย่างน้อย 8 ตัวอักษร.<br />
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                        {
                            alert !== '' &&
                            <div className="alert alert-danger" role="alert">
                                <div className="text-end">
                                    <button type="button" className="btn-close" onClick={(event) => setAlert('')}></button>
                                </div>

                                {alert}
                            </div>
                        }
                        <div className="mt-4">
                            <Button type={'submit'} disabled={loading} className={'w-100'} >
                                {
                                    loading === true &&
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                }
                                ยืนยัน</Button>
                        </div>
                    </Form>
                </div>
                <div className="mt-3 mb-3">
                    <span className={`me-4 ${Css.body2}`}>คุณยังไม่มีบัญชี DCaaS?</span>
                    <a className={`${Css.Alink_blue} ${Css.body2}`} onClick={handleRegister}>คลิกเข้าสู่ระบบ</a>
                </div>
            </div>
        </LayoutLandingPageV2>
    );
}