import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from 'components/pages/application/Home';
import Login from 'components/pages/application/login/Login';
import Register from 'components/pages/application/register/Register';
import RegisterConfirm from 'components/pages/application/register/RegisterConfirm';
import CustomerRegister from 'components/pages/application/register/RegisterCustomer';
import CustomerHome from 'components/pages/customer/home/Home';
import CustomerContact from 'components/pages/customer/contact/Contact';
import CustomerConfig from 'components/pages/customer/config/Config';
import CustomerCondition from 'components/pages/customer/home/Condition';
import CustomerChange from 'components/pages/customer/change/Change';
import ResetPassword from 'components/pages/application/login/ResetPassword';
import ForgotPassword from 'components/pages/application/login/ForgotPassword';
import ForgotPasswordConfirm from 'components/pages/application/login/ForgotPasswordConfirm';
import Termsofuse from 'components/pages/application/register/Termsofuse';
import Privacypolicy from 'components/pages/application/register/Privacypolicy';
import ChangePass from 'components/pages/customer/config/ChangePass';
import LoginCustomer from 'components/pages/customer/home/LoginCustomer';
import ResetConfirm from 'components/pages/application/login/ResetConfirm';
import CustomerUp from 'components/pages/customer/home/CustomerUp';
import CustomerUpPackageFlow from 'components/pages/customer/home/CustomerUpPackageFlow';
import CustomerUpAddonFlow from 'components/pages/customer/home/CustomerUpAddonFlow';
import UsedCustomer from 'components/pages/customer/home/CustomerPackageUsed';
import PaymentBill from 'components/pages/customer/home/PaymentBill';
import CustomerWithholdingTax from 'components/pages/customer/home/CustomerWithholdingTax';
import CustomerServiceTransaction from 'components/pages/customer/home/CustomerServiceTransaction';
import Payment2 from 'components/pages/customer/home/CustomerPaymentServiceStep1';
import Payment3 from 'components/pages/customer/home/Payment3';
import Payment4 from 'components/pages/customer/home/Payment4';
import CustomerNotification from 'components/pages/customer/home/CustomerNotification';
import Notification2 from 'components/pages/customer/home/Notification2';
import Payment5 from 'components/pages/customer/home/CustomerPaymentServiceStep2';
import CustomerPayment from 'components/pages/customer/home/CustomerPayment';
import PackageChanged from 'components/pages/customer/home/PackageChanged';
import CustomerPaymentService from 'components/pages/customer/home/CustomerPaymentService';
import CustomerUpPackage from 'components/pages/customer/home/CustomerUpPackage';
import CustomerUpAddon from 'components/pages/customer/home/CustomerUpAddon';
import CustomerPackageUsed from 'components/pages/customer/home/CustomerPackageUsed';
import Manual from 'components/pages/manual/Manual';
import CustomerProfileUser from 'components/pages/customer/home/CustomerProfileUser';
import CustomerProfileCompany from 'components/pages/customer/home/CustomerProfileCompany';
import CustomerProfilePayee from 'components/pages/customer/home/CustomerProfilePayee';
import Cookie from 'components/pages/application/register/Cookie';
import Compare from 'components/pages/application/Compare';
import RegisterCustomerStep6 from 'components/pages/application/register/RegisterCustomerStep6';
import Module from 'components/pages/application/Module';
import HomeMockup from 'components/mockup/HomeMockup';
import Article from 'components/mockup/Article';
import ArticleDetail from 'components/mockup/ArticleDetail'
import ApiConection from 'api_ref/ApiConection';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function App() {
    const api = new ApiConection();
    
    useEffect(() => {
        const login = async () => {
            await api.loginLandingPage();
        }

        login();
    }, [])

    return (
      <>
      <HelmetProvider>
        <Helmet>
            {
                process.env.REACT_APP_ENVIRONMENT !== '' &&
                <meta name="robots" content="noindex" />
            }
        </Helmet>
        <Routes>
            <Route path={"/*"} element={<Home />} />
            <Route path={"/Login"} element={<Login />} />
            <Route path={"/reset"} element={<ResetPassword />} />
            <Route path={"/forgot"} element={<ForgotPassword />} />
            <Route path={"/Module"} element={<Module />} />
            <Route path={"/forgot/confirm"} element={<ForgotPasswordConfirm />} />
            <Route path={"/reset/confirm"} element={<ResetConfirm />} />
            <Route path={"/register"} element={<Register />} />
            <Route path={"/manual"} element={<Manual />} />
            <Route path={"/register/:id/confirm"} element={<RegisterConfirm />} />
            <Route path={"/customer/condition"} element={<CustomerCondition />} />
            <Route path={"/customer/register"} element={<CustomerRegister />} />
            <Route path={"/customer/home"} element={<CustomerHome />} />
            <Route path={"/customer/change"} element={<CustomerChange />} />
            <Route path={"/customer/contact"} element={<CustomerContact />} />
            <Route path={"/customer/config"} element={<CustomerConfig />} />
            <Route path={"/customer/change/password"} element={<ChangePass />} />
            <Route path={"/register/termsofuse"} element={<Termsofuse />} />
            <Route path={"/register/privacypolicy"} element={<Privacypolicy />} />
            <Route path={"/register/cookie"} element={<Cookie />} />
            <Route path={"/Login/Customer"} element={<LoginCustomer />} />
            <Route path={"/customer/upgrade"} element={<CustomerUp />} />
            <Route path={"/customer/upgrade/package"} element={<CustomerUpPackageFlow />} />
            <Route path={"/customer/upgrade/addon"} element={<CustomerUpAddonFlow />} />
            <Route path={"/customer/package-used"} element={<CustomerPackageUsed />} />
            <Route path={"/customer/payment"} element={<CustomerPayment />} />
            {/* <Route path={"/customer/payment/service"} element={<Payment2/>}></Route> */}
            <Route path={"/customer/payment/service"} element={<CustomerPaymentService />} />
            <Route path={"/customer/paymentbill"} element={<PaymentBill />} />
            <Route path={"/customer/send-withholding-tax"} element={<CustomerWithholdingTax />} />
            <Route path={"/customer/service-transaction"} element={<CustomerServiceTransaction />} />
            <Route path={"/customer/profile/user"} element={<CustomerProfileUser />} />
            <Route path={"/customer/profile/company"} element={<CustomerProfileCompany />} />
            <Route path={"/customer/profile/payee"} element={<CustomerProfilePayee />} />
            <Route path={"/customer/customerNotification"} element={<CustomerNotification />} />
            <Route path={"/customer/notification"} element={<CustomerNotification />} />
            <Route path={"/customer/changedpackage"} element={<PackageChanged />} />
            <Route path={"/compare"} element={<Compare />} />
            <Route path={"/mockup"} element={<HomeMockup />} />

            <Route path={"/article"} element={<Article />} />
            <Route path={"/article/detail/:id"} element={<ArticleDetail />} />
        </Routes>
      </HelmetProvider>
          

      </>
    );
}

export default App;