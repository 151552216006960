import React from "react";
import Css from 'components/pages/customer/Customer.module.css';
import { useState, useEffect } from "react";
import ApiConection from "api_ref/ApiConection";
import { useNavigate } from "react-router-dom";
import CustomerPaymentServiceStep1 from "./CustomerPaymentServiceStep1";
import CustomerPaymentServiceStep2 from "./CustomerPaymentServiceStep2";
import RegisterCustomerStep6 from "components/pages/application/register/RegisterCustomerStep6";
import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import QuontationStatus1 from "./QuontationStatus1";
import CustomerPaymentServiceStep0 from "./CustomerPaymentServiceStep0";
import UtilClass from "api_ref/UtilClass";

export default function CustomerPaymentService() {
    const navigate = useNavigate();
    const api = new ApiConection();
    const util = new UtilClass();

    const [alert, setAlert] = useState("");
    // const apiATS = new ATSApiConnection();
    const [billing, setBilling] = useState();
    const [oldbilling, setOldBilling] = useState();
    const [changeCount, setChangeCound] = useState(0);

    const customer = util.getSessionStorage('customer');
    const registerSession = util.getSessionStorage('register');

    const [step, setStep] = useState(1);

    const getBills = async () => {
        const response = await api.getData(
            `api/quontation/register/${registerSession.register_number}/next-bill`
        );
        if (response.status === true) {
            let data = response.data;
            data.paid_amount = null;
            data.paid_bank = '';

            setBilling(data);
        } else {
            return;
        }
    };
    const getBillsForOld = async () => {
        const response = await api.getData(
            `api/quontation/register/${registerSession.register_number}/next-bill`
        );
        if (response.status === true) {
            setOldBilling(response.data);
        } else {
            return;
        }
    };

    // const handleSetRegister = (name, value) => {
    //   SetRegisterData((prevData) => {
    //     return { ...prevData, [name]: value };
    //   });
    // };

    // const handleSetResultBill = (name, value) => {
    //   setbilling((prevData) => {
    //     return { ...prevData, [name]: value };
    //   });
    // };

    const handleChangeStep = (dataStep) => {
        setStep(dataStep);
    };

    const handleGotoStep1 = async () => {
        setStep(1);
    }

    const handleGotoStep2 = async () => {
        setStep(2);
    }
    const handleGotoStep3 = async (slip_file_id, paid_date) => {
        setAlert('');

        let arr_quontations = []
        billing.arr_package.forEach(obj => {
            arr_quontations.push({
                package_master_id: obj.package_master_id,
                package_master_period_id: billing.package_master_period_id,
                package_master_users_volume_id: obj.package_master_users_volume_id,
                package_master_storage_volume_id: obj.package_master_storage_volume_id
            })
        })
        const request = {
            arr_quontations: arr_quontations,
            discount_code: billing.discount_code,
            is_with_holding_tax: billing.is_with_holding_tax,
            //is_pay_now : (billing.is_pay_now == true || billing.is_pay_now == 'true'),
            payment: {
                payment_method_id: 3,// ผ่านบัญชีธนาคาร
                payment_method_name: '',
                paid_bank: billing.paid_bank,
                slip_file_id: billing.slip_file_id,
                paid_amount: billing.paid_amount === undefined ? parseFloat(billing.grand_total_sub_withholding_tax?.toString().replace(',', '')) : billing.paid_amount,
                paid_date: billing.paid_date
            },
            payee: {
                is_issue_tax_invoice: false,
                payee_name: billing.payee_name,
                payee_address: billing.payee_address,
                payee_tel: billing.payee_tel,
                payee_tax_id: billing.payee_tax_id,
                payee_branch: billing.payee_branch
            },
            is_accept_agreement: billing.is_accept_agreement
        }

        if (JSON.stringify(oldbilling?.arr_package) === JSON.stringify(billing.arr_package)) {
            const response = await api.postData(
                `api/quontation/${billing.quontation_number}/payment/`,
                request
            );

            if (response.status === true) {
                setStep(3);
                setAlert('');
            } else {
                setAlert(response.message);
            }
        } else {
            const responseRenew = await api.postData(`api/quontation/register/${billing.register_number}/renew-package`, request);

            if (responseRenew.status === true) {
                const response = await api.postData(`api/quontation/${responseRenew.data.quontation_number}/payment/`, request);

                if (response.status === true) {
                    setStep(3);
                    setAlert('');
                } else {
                    setAlert(response.message);
                }
            } else {
                setAlert(responseRenew.message);
            }
        }
    }
    const handleChangeData = (event) => {
        const { name, value, type, checked, files } = event.target;

        if (event.target?.setCustomValidity) {
            event.target?.setCustomValidity("");
        }

        if (type === 'file') {
            setBilling(prevData => {
                return { ...prevData, [name]: (files ? files[0] : undefined || value) }
            })
        } else if (type === 'checkbox') {
            setBilling((prevData) => {
                return { ...prevData, [name]: checked };
            });
        } else {
            setBilling((prevData) => {
                return { ...prevData, [name]: value };
            });
        }

        setChangeCound(changeCount + 1);
    };

    useEffect(() => {
        //if(billing === undefined) navigate('/login');
        const handleBackButton = () => {
            // เพิ่มสถานะใหม่ลงในประวัติเพื่อป้องกันการกด back
            if (window.location.href.indexOf('customer/payment/service') > -1)
                navigate('/customer/payment');
        };

        // ตั้งค่าเหตุการณ์เมื่อผู้ใช้กดปุ่ม back
        window.addEventListener('popstate', handleBackButton);

        async function fetchData() {
            await getBills();
            await getBillsForOld();
        }
        fetchData();
    }, []);

    return (
        billing !== undefined && oldbilling !== undefined && (
            <LayoutCustomerMain role="customer" >
                <QuontationStatus1 />
                <div className={Css.Block_Transparent}>
                    <h5 className={Css.color1}>ชำระค่าบริการ &gt; {billing.quontation_number}</h5><br />
                </div>
                {
                    step === 0 && (
                        <CustomerPaymentServiceStep0
                            data={billing}
                            onChangeData={handleChangeData}
                            handleSetResultBill={setBilling}
                            onHandleSubmit={handleGotoStep1}
                            onChangeStep={handleChangeStep}
                        />
                    )
                }
                {step === 1 && (
                    <CustomerPaymentServiceStep1
                        data={billing}
                        oldData={oldbilling}
                        onChangeData={handleChangeData}
                        handleSetResultBill={setBilling}
                        onHandleSubmit={handleGotoStep2}
                        onChangeStep={handleChangeStep}
                    />
                )}
                {step === 2 && (
                    <CustomerPaymentServiceStep2
                        data={billing}
                        onChangeData={handleChangeData}
                        handleSetResultBill={setBilling}
                        onHandleSubmit={handleGotoStep3}
                        onChangeStep={handleChangeStep}
                        error={alert}
                    />
                )
                }
                {step === 3 && (
                    <RegisterCustomerStep6 />
                )}
            </LayoutCustomerMain>
        )
    );
}