import Css from './Mockup.module.css'
import { Row, Col, Carousel } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react"

const arr_feature = [
    { image: 'convenience.png' },
    { image: 'DCaaS_web_icon_02.png' },
    { image: 'secure.png' },
    { image: 'paperless.png' }
]

export default function Attribute() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const handleGetClass = (idx) => {
        if (index == idx) return (Css.slideshowDot_active);
        else return Css.slideshowDot;
    }
    return (
        <>
            <div className={Css.imgAttribute}>
                <img src={require('../../images/DCasS.png')} className={`${Css.img_nav_mobile}`} alt={''} height={'97px'} />
            </div>

            {/*XX-Large*/}
            <div className={`${Css.layout_outside_attribute } flex-wrap`}>
                <div className={`${Css.layout_outside_attribute1}`}>
                    <div className={`ms-0 ${Css.layout_attribute}`}>
                        <img className="" alt={''} height={160} src={require("images/convenience.png")} />
                        <h5 className={Css.fontConvenience1}>CONVENIENCE</h5>
                        <h6 className={Css.fontdesc}>เข้าถึงและจัดการงานเอกสาร <br /> ได้ทุกที่ ทุกเวลา จากทุกอุปกรณ์</h6>
                    </div>
                    <div className={Css.layout_attribute}>
                        <img className="" alt={''} height={160} src={require("images/DCaaS_web_icon_02.png")} />
                        <h5 className={Css.fontConvenience2}>EFFICIENCY</h5>
                        <h6 className={Css.fontdesc}>ยืดหยุ่นในการปรับตั้งค่าให้เหมาะ <br /> สมกับความต้องการขององค์กร</h6>
                    </div>
                </div>
                <div className={`${Css.layout_outside_attribute1} ${Css.Medium_Attribute}`}>
                <div className={`${Css.layout_attribute} ${Css.Medium_Attribute1}`}>
                        <img className="" alt={''} height={160} src={require("images/secure.png")} />
                        <h5 className={Css.fontConvenience3}>SECURE</h5>
                        <h6 className={Css.fontdesc}>มีมาตรการป้องกันและดูแลรักษา <br /> ความปลอดภัยของข้อมูล</h6>
                    </div>
                    <div className={Css.layout_attribute}>
                        <img className="" alt={''} height={160} src={require("images/paperless.png")} />
                        <h5 className={Css.fontConvenience4}>PAPERLESS</h5>
                        <h6 className={Css.fontdesc}>ลดการใช้กระดาษ ลดค่าใช้จ่าย <br /> ในการดูแลงานเอกสารแบบเดิม</h6>
                    </div>
                </div>
            </div>
            

            {/*Moblie*/}
            <div className={Css.AllSlide_Moblie_Attribute}>
            <Carousel activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item>
                    <div className={Css.layout_attribute}>
                        <img className="" alt={''} height={160} src={require("images/convenience.png")} />
                        <h5 className={Css.fontConvenience1}>CONVENIENCE</h5>
                        <h6 className={Css.fontdesc}>เข้าถึงและจัดการงานเอกสาร <br /> ได้ทุกที่ ทุกเวลา จากทุกอุปกรณ์</h6>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className={Css.layout_attribute}>
                        <img className="" alt={''} height={160} src={require("images/DCaaS_web_icon_02.png")} />
                        <h5 className={Css.fontConvenience2}>EFFICIENCY</h5>
                        <h6 className={Css.fontdesc}>ยืดหยุ่นในการปรับตั้งงค่าให้เหมาะ <br /> สมกับความต้องการขององค์กร</h6>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className={Css.layout_attribute}>
                        <img className="" alt={''} height={160} src={require("images/secure.png")} />
                        <h5 className={Css.fontConvenience3}>SECURE</h5>
                        <h6 className={Css.fontdesc}>มีมาตรการป้องกันและดูแลรักษา <br /> ความปลอดภัยของข้อมูล</h6>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className={Css.layout_attribute}>
                        <img className="" alt={''} height={160} src={require("images/paperless.png")} />
                        <h5 className={Css.fontConvenience4}>PAPERLESS</h5>
                        <h6 className={Css.fontdesc}>ลดการใช้กระดาษ ลดค่าใช้จ่าย <br /> ในการดูแลงานเอกสารแบบเดิม</h6>
                    </div>
                </Carousel.Item>
            </Carousel>
            <div className={Css.slideshowDots}>
                {arr_feature.map((_, idx) => (
                    <div
                        key={idx}
                        className={handleGetClass(idx)}
                        onClick={() => { setIndex(idx); }}
                    ></div>
                ))}
            </div>
        </div>
        </>

    )
}