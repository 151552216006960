import Layout4Main from "components/layout/Layout4Main";
import { Row, Col, Form, Button, Tab, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Css from 'components/pages/customer/Customer.module.css';
import CssRegis from 'components/pages/application/register/Register.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import { AddCommar, DateToText } from "components/MyControl";
import moment from "moment";
import UtilClass from "api_ref/UtilClass";

export default function Change3Confirm({ onChangeData, handleSetChangePackage = undefined, getValue = undefined, validated = false, alert, setAlert, setSubStep, loading = false, onSubmit = undefined }) {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');
    const register = util.getSessionStorage('register');
    const quontation = util.getSessionStorage('quontation');

    const handlecontact = () => {
        window.open('https://magicsoftware.co.th/th/company-profile/#contact', '_blank');
    }
    return (
        <div className={Css.Graph_Group}>
            <div className={Css.Graph_Box + ' shadow'}>
                <Form className={Css.Register_Form} noValidate validated={validated} onSubmit={event => onSubmit(event)}>
                    <div className={Css.Content}>
                        <div className={Css.Header}>
                            <h6 className='mb-3'>สรุปค่าบริการ</h6>
                        </div>
                        <div className={Css.Content + ' align-items-center'}>
                            <div className="border w-800px rounded mb-3">
                                <h6 className={'bg-light border-bottom d-block p-2 ps-4 text-start'}>รายละเอียดค่าบริการ</h6>
                                <div className='p-4'>
                                    <div className='border rounded p-3'>
                                        <Form.Group as={Row} >
                                            <Form.Label column className='text-end p-1 pb-0' sm={4}>ชื่อที่อยู่ไซต์</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{register.full_site_url}</Form.Label>
                                        </Form.Group>
                                    </div>
                                    <div className='border rounded p-3 mt-2'>
                                        <div className='mb-3 text-start'>ข้อมูลบริษัท</div>
                                        <Form.Group as={Row} className=''>
                                            <Form.Label column className='text-end p-1 pb-0 ' sm={4}>ชื่อบริษัท</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{register.company_name}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row}  >
                                            <Form.Label column className='text-end p-1 pb-0 ' sm={4}>ที่อยู่</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{register.company_address}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row}  >
                                            <Form.Label column className='text-end p-1 pb-0' sm={4}>หมายเลขโทรศัพท์</Form.Label>
                                            <Form.Label column className='fw-bold p-1 pb-0'>{register.company_tel}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row}  >
                                            <Form.Label column className='text-end p-1 pb-0' sm={4}>เลขประจำตัวผู้เสียภาษีอากร</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{register.tax_id}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row} className='' >
                                            <Form.Label column className='text-end p-1 pb-0 ' sm={4}>ประเภทธุรกิจ</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{register.business_type_name}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row} className='' >
                                            <Form.Label column className='text-end p-1 pb-0 ' sm={4}>ลักษณะธุรกิจ</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{register.bussiness_category_name}</Form.Label>
                                        </Form.Group>
                                    </div>
                                    <div className='border rounded p-3 mt-2'>
                                        <div className='mb-3 text-start'>แพ็กเกจ</div>
                                        <Form.Group as={Row}>
                                            <Form.Label column className='text-end p-1 pb-0' sm={4}>แพ็กเกจ</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{getValue('package_name')}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column className='text-end p-1 pb-0' sm={4}>งวดชำระ</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{getValue('package_period')}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column className='text-end p-1 pb-0' sm={4}>จำนวนผู้ใช้งาน</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{getValue('users_valume')}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row}>
                                            <Form.Label column className='text-end p-1 pb-0' sm={4}>จำนวนพื้นที่จัดเก็บ</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{getValue('storage_valume')}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row} className=''>
                                            <Form.Label column className='text-end p-1 pb-0' sm={4}>จำนวนเงินสุทธิที่ต้องชำระ</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{getValue('total')} บาท</Form.Label>
                                        </Form.Group>
                                    </div>
                                    <div className='border rounded p-2 mt-2'>
                                        <div className='mb-3 text-start'>ข้อมูลออกใบเสร็จ</div>
                                        <Form.Group as={Row} >
                                            <Form.Label column className='text-end p-1 pb-0' sm={4}>ชื่อที่ออกใบเสร็จ</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{getValue('delivery_name')}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row} >
                                            <Form.Label column className='text-end p-1 pb-0' sm={4}>สาขา/รหัสสาขา</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{getValue('delivery_branch')}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row} >
                                            <Form.Label column className='text-end p-1 pb-0' sm={4}>ที่อยู่ออกใบเสร็จ</Form.Label>
                                            <Form.Label column className='text-start fw-bold p-1 pb-0'>{getValue('delivery_address')}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row} >
                                            <Form.Label column className='text-end p-1 pb-0' sm={4}>หมายเลขโทรศัพท์</Form.Label>
                                            <Form.Label column className='fw-bold p-1 pb-0'>{getValue('delivery_tel')}</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row} className={'d-none'}>
                                            <Form.Label column className='text-end p-1 pb-0' sm={4}>โลโก้/ตราบริษัท</Form.Label>
                                            <div className='text-center'>
                                                <img src={require('images/Ellipse 11.png')} />
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className='w-800px mt-3'>
                                <Form.Text >
                                    ระบบจะต่ออายุอัตโนมัติรายเดือน จนกว่าบริษัทจะได้ทำการยกเลิกการสมัครแพ็กเกจ หากมีข้อสงสัยหรือต้องการสอบถามเพิ่มเติม&nbsp;
                                    <a onClick={handlecontact} className='link_contact'>ติดต่อเจ้าหน้าที่</a>
                                </Form.Text>
                                {
                                    alert !== '' &&
                                    <div className="alert alert-danger" role="alert">
                                        <div className="text-end">
                                            <button type="button" className="btn-close" onClick={(event) => setAlert('')}></button>
                                        </div>

                                        {alert}
                                    </div>
                                }
                                <div className='text-end mt-4'>
                                    <Button type={'button'} disabled={loading}  variant="outline-secondary" className='w-33 me-2' onClick={event => setSubStep(2)} >ย้อนกลับ</Button>
                                    <Button type={'submit'} disabled={loading} className='btn-primary w-33' >
                                        {
                                            loading === true &&
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        }
                                        ดำเนินการต่อ &#62;
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </div>

                </Form>
            </div>
        </div>
    )
}