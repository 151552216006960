import Css from 'components/pages/application/login/Login.module.css';
import { useState } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';

export default function ProfilePayee({
    data = undefined,
    setDataToParent = undefined,
    callSubmitParentData = undefined,
    parentAlert = '' }) {
    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const [mode, setMode] = useState('view');

    const [simulate, setSimulate] = useState(
        {
            payee_name: data?.payee_name,
            payee_address: data?.payee_address,
            payee_tel: data?.payee_tel,
            payee_tax_id: data?.payee_tax_id,
            payee_branch: data?.payee_branch
        });

    const handleSetSimulate = (event) => {
        const { name, value } = event.target;

        setSimulate(prevData => {
            return { ...prevData, [name]: value }
        });
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        //console.log('simulate=', simulate);

        callSubmitParentData(simulate);
        setAlert(parentAlert);

        setLoading(false);
        if (parentAlert === '')
            setMode('view');
        else
            setMode('edit');
    }

    const handleCancel = (event) => {
        setSimulate(
            {
                payee_name: data?.payee_name,
                payee_address: data?.payee_address,
                payee_tel: data?.payee_tel,
                payee_tax_id: data?.payee_tax_id,
                payee_branch: data?.payee_branch
            }
        );
        setMode('view');
    }

    return (
        <div className='container m-auto'>
            <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)} >
                <div className='contrainer'>
                    <div className={`${Css.Login_inner_layout2} w-100`}>
                        <h6 className='mb-4'>ข้อมูลในการออกใบเสร็จ</h6>
                        <div>
                            <Form.Group className='mb-2'>
                                <Form.Label className='label-title'>ชื่อบริษัท/ชื่อที่ใช้ออกใบเสร็จ</Form.Label>
                                <Form.Control placeholder='ชื่อบริษัท/ชื่อที่ใช้ออกใบเสร็จ'
                                    plaintext={(mode == 'view') ? true : false}
                                    readOnly={(mode == 'view') ? true : false}
                                    className='border-bottom'
                                    name={'payee_name'}
                                    value={simulate?.payee_name}
                                    required={true}
                                    onChange={event => handleSetSimulate(event)} />
                            </Form.Group>
                            <Form.Group className='mb-2'>
                                <Form.Label className='label-title'>ที่อยู่</Form.Label>
                                <Form.Control placeholder='ที่อยู่' required={true}
                                    plaintext={(mode == 'view') ? true : false}
                                    readOnly={(mode == 'view') ? true : false}
                                    className='border-bottom'
                                    onChange={event => handleSetSimulate(event)}
                                    name={'payee_address'}
                                    value={simulate?.payee_address} />
                            </Form.Group>
                            <Form.Group className='mb-2'>
                                <Form.Label className='label-title'>หมายเลขโทรศัพท์</Form.Label>
                                <Form.Control placeholder='00-000-0000' required={true}
                                    plaintext={(mode == 'view') ? true : false}
                                    readOnly={(mode == 'view') ? true : false}
                                    className='border-bottom'
                                    onChange={event => handleSetSimulate(event)}
                                    name={'payee_tel'}
                                    value={simulate?.payee_tel} />
                            </Form.Group>
                            <Form.Group className='mb-2'>
                                <Form.Label className='label-title'>เลขประจำตัวผู้เสียภาษีอากร</Form.Label>
                                <Form.Control placeholder='0000000000000' maxLength={13} pattern="[0-9]{13}"
                                    plaintext={(mode == 'view') ? true : false}
                                    readOnly={(mode == 'view') ? true : false}
                                    className='border-bottom'
                                    onChange={event => handleSetSimulate(event)}
                                    required={true}
                                    name={'payee_tax_id'}
                                    value={simulate?.payee_tax_id} />
                                <Form.Control.Feedback type={'invalid'}>รูปแบบเลขประจำตัวผู้เสียภาษีไม่ถูกต้อง</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='mb-5'>
                                <Form.Label className='label-title'>สาขา/รหัสสาขา</Form.Label>
                                <Form.Control placeholder='สาขา/รหัสสาขา' required={true}
                                    plaintext={(mode == 'view') ? true : false}
                                    readOnly={(mode == 'view') ? true : false}
                                    className='border-bottom'
                                    onChange={event => handleSetSimulate(event)}
                                    name={'payee_branch'}
                                    value={simulate?.payee_branch} />
                            </Form.Group>
                            {
                                alert !== '' &&
                                <div className="alert alert-danger" role="alert">
                                    {alert}
                                </div>
                            }
                            {
                                mode === 'edit' &&
                                <Row>
                                    <Col>
                                        <Button variant="outline-secondary" type="button" className='ps-5 pe-5 me-1'
                                            onClick={event => handleCancel(event)}  >ยกเลิก</Button>
                                        <Button variant="primary" type="submit" className='ps-5 pe-5'
                                            disabled={loading}
                                        >บันทึก
                                            {
                                                loading === true &&
                                                <div className="spinner-border spinner-border-sm text-light ms-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            }

                                        </Button>
                                    </Col>
                                </Row>
                            }
                            {
                                mode === 'view' &&
                                <Button variant="outline-secondary" type="button" className='ps-5 pe-5'
                                    onClick={event => setMode('edit')}>
                                    ขอเปลี่ยนข้อมูล
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}