import { useNavigate } from "react-router-dom";
import Css from 'components/pages/customer/Customer.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import ATSApiConnection from "api_ref/ATSApiConnection";
import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import QuontationStatus1 from "./QuontationStatus1";
import CustomerServiceTransactionTable from "./CustomerServiceTransactionTable";
import Pagination from "components/Pagination";
import UtilClass from "api_ref/UtilClass";

export default function PaymentPaper1() {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');
    const register = util.getSessionStorage('register');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const [billData, setBillData] = useState([]);
    const [pagination, setPagination] = useState({
        record_start: 0,
        record_end: 0,
        record_count: 0,
        page_count: 0
    });
    const [search, setSearch] = useState({
        page: 1,
        size: 10
    })

    const getBills = async () => {
        let request = {
            search: {
                is_not_free: true,
            },
            pagination: {
                page: search.page,
                size: search.size,
                order_by: "quontation_number",
                order_mode: "desc"
            }
        }

        const response = await api.postData(
            `api/quontation/register/${register.register_number}/customer/${register.user_id}/result`, request
        );
        //debugger;
        if (response.status === true) {
            setBillData(response.data);
        } else {
            return;
            // setBillData([]);
        }
    };

    const getPaginate = async () => {
        let request = {
            search: {
                is_not_free: true,
            },
            pagination: {
                page: search.page,
                size: search.size,
                order_by: "quontation_number",
                order_mode: "desc"
            }
        }
        const response = await api.postData(
            `api/quontation/register/${register.register_number}/customer/${register.user_id}/pagination`, request
        );
        //debugger;
        if (response.status === true) {
            setPagination(response.data);
        } else {
            return;
            // setBillData([]);
        }
    };

    const handleSetSearch = (name, value) => {
        setSearch(prevData => {
            return { ...prevData, [name]: value };
        });
    }
    const handlePagingChange = (page_no) => {
        handleSetSearch('page', page_no);
    }
    useEffect(() => {
        getBills();
        getPaginate();
    }, []);
    useEffect(() => {
        getBills();
        getPaginate();
    }, [search]);

    return (
        <LayoutCustomerMain role="customer" >
            <QuontationStatus1 />
            <div className={Css.Block_Transparent}><br />
                <h5 className={Css.color1}>ใบเสร็จและใบแจ้งค่าบริการ</h5><br />
                <div className={Css.Graph_Group}>
                    <CustomerServiceTransactionTable
                        data={billData}
                        record_start={pagination.record_start} />
                </div>

            </div>

            <Pagination

                page_no={search.page}
                page_count={pagination.page_count}
                page_show={search.size}
                onPageChange={handlePagingChange} />
        </LayoutCustomerMain>
    )
}