import Layout4Register from "components/layout/Layout4Register";
import Css from './Login.module.css';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ApiConection from 'api_ref/ApiConection';
import { useEffect, useState } from "react";
import LandingPageHeadMenu from "components/layout/LandingPageHeadMenu";
import ReCAPTCHA from "react-google-recaptcha";
import UtilClass from "api_ref/UtilClass";
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";

export default function Login() {
    const navigate = useNavigate();

    const api = new ApiConection();
    const util = new UtilClass();

    const [validated, setValidated] = useState(false);
    const [input, setInput] = useState({
        login_name: '',
        password: '',
        showPassword: false
    });
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [capcha, setCapcha] = useState(true);

    const handleChange = (event) => {
        const { name, type, value } = event.target;

        setInput(prevData => {
            return { ...prevData, [name]: value }
        })
    }

    const handleClickShowPassword = () => {
        setInput({ ...input, showPassword: !input.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = (prop) => (event) => {
        setInput({ ...input, [prop]: event.target.value });
    };

    const handleLogin = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        setLoading(true);

        const response = await api.login(input.login_name, input.password);

        if (response.status === false) {
            setAlert(response.message);
        } else {
            //util.setSessionStorage('customer',response.data);
            navigate('/customer/condition');
        }

        setLoading(false);
    }
    const handleRegister = (event) => {
        navigate('/register');
    }

    const handleForgot = (event) => {
        navigate('/forgot');
    }

    const handleHomePage = () => {
        navigate('/');
    }

    const HandleonChangeCapcha = () => {
        setCapcha(true)
    }

    useEffect(() => {
        window.sessionStorage.removeItem('customer');
        window.sessionStorage.removeItem('admin');
        window.sessionStorage.removeItem('register');
        window.sessionStorage.removeItem('quontation');
    }, []);

    return (
        <>
            <LayoutLandingPageV2 showContactUs={false}>
                <LandingPageHeadMenu />
                <div className={`${Css.Login_outer_layout}`}>
                    <div className={`${Css.Login_inner_layout} ${Css.pad} mt-5`}>
                        <Form noValidate validated={validated} onSubmit={handleLogin}>
                            <img src={require('images/DCasS.png')} alt={'DCasS'} height={80} className=" mb-3" onClick={handleHomePage} />
                            <h5>ยินดีต้อนรับ</h5>
                            <Form.Group as={Row} className="text-start mb-2 mt-4">
                                <Form.Label className={Css.body2}>อีเมล</Form.Label>
                                <Col>
                                    <Form.Control type={'email'} name={'login_name'} value={input.login_name} onChange={handleChange} required={true} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="text-start mb-2 mt-3">
                                <Form.Label className={Css.body2}>รหัสผ่าน</Form.Label>
                                <Col className={Css.pwd1}>
                                    <Form.Control className={Css.pwd2 + ' CustomPwd'} pattern="(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*]).{8,}" type={input.showPassword ? "text" : "password"} name={'password'} value={input.password} onChange={handlePasswordChange("password")} required={true} />
                                    <i className={Css.pwd3} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>{input.showPassword ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</i>
                                </Col>
                            </Form.Group>

                            <Form.Group className="text-end mb-4">
                                <a className={`${Css.Alink_black} ${Css.body2}`} onClick={handleForgot}>ลืมรหัสผ่าน?</a>
                            </Form.Group>
                            {/*
                        <ReCAPTCHA
                            className={Css.capchaCenter}
                            sitekey="6LcVHtgmAAAAAPOcUwYonbti3bQtkcwAKQBBkhW8"
                            onChange={HandleonChangeCapcha}
                        />
                        */}

                            {
                                alert !== '' &&
                                <div className="alert alert-danger" role="alert">
                                    <div className="text-end">
                                        <button type="button" className="btn-close" onClick={(event) => setAlert('')}></button>
                                    </div>

                                    {alert}
                                </div>
                            }

                            <div className="mt-3">
                                <Button type={'submit'} className={'w-100'} disabled={loading} >
                                    {
                                        loading === true &&
                                        <span className={`spinner-border spinner-border-sm ${Css.body2}`} role="status" aria-hidden="true"></span>
                                    }
                                    เข้าสู่ระบบ
                                </Button>
                            </div>

                        </Form>
                    </div>
                    <div className="mt-3 mb-3">
                        <span className={`me-4 ${Css.body2}`}>คุณยังไม่มีบัญชี DCaaS?</span>
                        <a className={`${Css.Alink_blue} ${Css.body2}`} onClick={handleRegister}>สมัครใช้งานฟรี</a>
                    </div>
                </div>
            </LayoutLandingPageV2>
        </>
    )
}