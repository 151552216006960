import { useNavigate } from "react-router-dom";
import Css from "components/pages/customer/Customer.module.css";
import { useEffect, useState } from "react";
import ApiConection from "api_ref/ApiConection";
import ServicePayment from "components/pages/application/register/ServicePayment";
import RegisterCustomerPayment from "components/pages/application/register/RegisterCustomerPayment";
import { Row, Col, Form, } from "react-bootstrap";
import UtilClass from "api_ref/UtilClass";
import moment from "moment";

export default function CustomerPaymentServiceStep2({
    data,
    onChangeData,
    error = '',
    handleSetResultBill,
    onHandleSubmit,
    onChangeStep,
}) {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState("");
    const [loading, setLoading] = useState(false);
    const [saveAndSendData, setSaveAndSendData] = useState(false);

    const navigate = useNavigate();

    const handleThisSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        //console.log(data.paid_date_only + " " + data.paid_hour + ":" + data.paid_minute);
        //return;

        setValidated(false);
        setAlert("");
        setLoading(true);

        if (document.querySelector('[name=payee_tax_id]')?.setCustomValidity)
            document.querySelector('[name=payee_tax_id]')?.setCustomValidity('');

        if (data.is_pay_now === true) {
            if (data.paid_file_slip === undefined || data.paid_file_slip === null) {
                setAlert("กรุณาแนบหลักฐานการชำระค่าบริการ");
            }
        }

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        const IdCard = data.payee_tax_id;
        if (!util.checkID(IdCard)) {
            //form.classList.remove('was-validated');
            document.querySelector("[name=payee_tax_id]")?.setCustomValidity("invalid");

            event.stopPropagation();
            setValidated(true);
            setLoading(false);

            return;
        }
        //-------------- บันทึก และตรวจสอบ ไฟล์แนบ-------------

        let slip_file_id = null;
        let paid_date = null;

        if (data.is_pay_now === true) {
            if (data.paid_file_slip !== undefined && data.paid_file_slip !== null) {
                let formdata = new FormData();
                formdata.append("files", data.paid_file_slip);
                formdata.append("path", `customer/${data.register_number}`);
                formdata.append("ref_code", data.register_number);

                const responseUpload = await api.postFile(
                    `api/file/upload/paymentslip`,
                    formdata
                );
                if (responseUpload.status === true) {
                    onChangeData({ target: { name: 'slip_file_id', value: responseUpload.data.id } });
                    slip_file_id = responseUpload.data.id;
                } else {
                    setAlert('ไม่สามารถอัปโหลดไฟล์ได้ กรุณาลองใหม่อีกครั้ง !!');
                    setLoading(false);
                    return;
                }
            }

            paid_date = moment(
                data.paid_date_only + " " + (data?.paid_hour ?? '00') + ":" + (data?.paid_minute ?? '00'),
                "DD/MM/YYYY HH:mm"
            ).format("YYYY-MM-DDTHH:mm:ss");

            onChangeData({ target: { name: 'paid_date', value: paid_date } });
        }

        setSaveAndSendData(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (saveAndSendData === true) {
                await onHandleSubmit();
                setValidated(false);
                setLoading(false);
                setAlert("");
                setSaveAndSendData(false);
            }
        }

        fetchData();
    }, [saveAndSendData]);

    return (
        <Form
            encType="multipart/form-data"
            noValidate
            validated={validated}
            onSubmit={(event) => handleThisSubmit(event)}
        >
            <div className={`container w-1000px`}>
                <Row>
                    <Col sm={7}>
                        <ServicePayment
                            data={data}
                            onChangeData={onChangeData}
                            error={alert + error}
                            showPayNow={false}
                            loading={loading}
                            className='w-auto' />
                    </Col>
                    <Col>
                        <RegisterCustomerPayment
                            data={data}
                            disabled={true}
                            className='w-auto'
                        />
                    </Col>
                </Row>
            </div>
        </Form>
    )
}