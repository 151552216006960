import { useNavigate } from "react-router-dom";
import Css from 'components/pages/customer/Customer.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import ATSApiConnection from "api_ref/ATSApiConnection";
import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import QuontationStatus1 from "./QuontationStatus1";
import Paymentcom from "./CustomerPaymentNoHave";
import Paymentcom2 from "./CustomerPaymentYesHave";
import UtilClass from "api_ref/UtilClass";

export default function CustomerPayment() {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');
    const registerSession = util.getSessionStorage('register');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [Register, setRegister] = useState({});
    const [Quontation, setQuontation] = useState({});
    const [currentUsed, setCurrentUsed] = useState({
        status: '',
        totalUserActive: undefined,
        totalFileSizeByte: undefined,
    });
    const [currentFile, setCurrentFile] = useState({
        status: '',
        totalFileCount: undefined,
        totalFileSizeByte: undefined,
        listFileExtension: []
    });
    const [billsStatus, setBillsStatus] = useState({})

    const getRegister = async () => {
        const response = await api.getData(`api/register/customer/${customer.user_id}`);

        if (response.status === false)
            navigate('/customer/register');
        else {
            setRegister(response.data[0]);
            util.setSessionStorage('register', response.data[0]);
        }
    }

    const getQuonation = async () => {
        const response = await api.getData(`api/quontation/register/${Register.register_number}`);

        if (response.status === true) {
            setQuontation(response.data);
            util.setSessionStorage('quontation', response.data);
        }
    }
    const getCurrentUsed = async () => {
        if (Register.site_url == undefined) return;
        let data = {
            subDomain: Register.site_url,
        };
        const response = await api.getData(
            `api/ATS/CurrentUsed/${Register.register_number}`
        );
        if (response.status == "completed") {
            setCurrentUsed(response);
        }
    };
    const getCurrentFile = async () => {
        if (Register.site_url == undefined) return;
        let data = {
            subDomain: Register.site_url,
        };
        const response = await api.getData(
            `api/ATS/CurrentFile/${Register.register_number}`
        );
        if (response.status == "completed") {
            setCurrentFile(response);
        }
    };

    const getBills = async () => {
        const response = await api.getData(`api/quontation/register/${registerSession.register_number}/next-bill`);
        if (response.status === true) {
            setBillsStatus(response);
        }
        else {
            setBillsStatus(response);
        }
    }

    useEffect(() => {
        getRegister();
        getBills();
    }, []);
    useEffect(() => {
        getQuonation();
        getCurrentUsed();
        getCurrentFile();
    }, [Register]);

    return (
        Register &&
        Quontation &&
        <LayoutCustomerMain role="customer" >
            <QuontationStatus1 />
            {Quontation != undefined &&
                <div className={Css.Block_Transparent}><br />
                    <h5 className={Css.color1}>ชำระค่าบริการ</h5><br />
                    <div className={Css.Graph_Group}>
                        {billsStatus.status === false && <Paymentcom />}
                        {billsStatus.status === true && billsStatus !== undefined && <Paymentcom2 data={billsStatus.data} />}
                    </div>

                </div>
            }
        </LayoutCustomerMain>
    )
}