import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function PolicyCookie() {
    const [show, setShow] = useState(false);

    const keepCookie = () => {
        window.localStorage.setItem('dcaas-policy-cookie', 'accept');
    }

    useEffect(() => {
        const policy_cookie = window.localStorage.getItem('dcaas-policy-cookie');
        if (policy_cookie !== null) {
            setShow(false);
        } else {
            setShow(true);
        }
    }, [])

    return (
        show &&
        <div className="cookie-policy fixed-bottom" style={{ marginLeft: '1rem', zIndex: 0 }}>
            <div className=" m-auto position-relative ">
                <div className="p-4 m-2 row text-white position-relative" style={{ zIndex: 1 }}>
                    <div className="col">
                        <span>เว็บไซต์นี้มีการใช้งานคุกกี้ เพื่อเพิ่มประสิทธิภาพและประสบการณ์ที่ดีในการใช้งานเว็บไซต์ของคุณ คุณสามารถอ่านรายละเอียดเพิ่มเติมได้ที่ </span>
                        <Link to={'/register/Privacypolicy'}>นโยบายความเป็นส่วนตัว</Link>
                        <span> และ </span>
                        <Link to={'/register/Cookie'}>นโยบายคุกกี้</Link>
                    </div>
                    <div className="col-3">
                        <button className="btn btn-light" onClick={(_) => { setShow(false); keepCookie(); }}>ยอมรับทั้งหมด</button>
                    </div>
                </div>

                <div className=" m-auto w-100 h-100 bg-black opacity-75 top-0 position-absolute rounded" style={{ zIndex: 0 }}>
                    &nbsp;
                </div>
            </div>

        </div>
    )
}