import Css from './Mockup.module.css'

const arr_feature = [
    { title: 'ควบคุมเอกสารคุณภาพ ISO ได้\nหลายมาตรฐาน' },
    { title: 'มี Workflow พร้อมใช้งานได้ทันที' },
    { title: 'ควบคุมเอกสารบังคับใช้, \nเอกสารฉบับเดิม และเอกสารยกเลิก' },
    { title: 'กำหนดผู้ทำหน้าที่ในแต่ละขั้นตอน \nได้อย่างง่ายดาย' },
    { title: 'จัดเก็บเอกสารเป็นหมวดหมู่ ค้นหาง่าย' },
    { title: 'ตรวจสอบสถานะเอกสารได้แบบ \nReal Time' },
    { title: 'สามารถแสดงความคิดเห็นและแบ่งปัน \nเอกสารให้กับสมาชิกในทีม' },
    { title: 'มีการแจ้งเตือนอัตโนมัติผ่านอีเมล' }
]

export default function KeyFeatures() {
    return (
        <>
            <div className={Css.keyfeaturesfont}>
                <img src={require("images/keyfeaturesBG.jpg")} className={`${Css.layout_keyfeatures} text-center`} />
                <div className={`${Css.keyfont}`}>
                    <p className={`${Css.keyfont1} mt-5`}>KEY FEATURES</p>
                    {
                        arr_feature.map((obj, index) => {
                            return (
                                <div className={`${Css.font}`} key={index}>
                                    <i className={`${Css.sizeicon} fa-solid fa-caret-right`}></i>
                                    <pre><label className={`${Css.font}`}>{obj.title}</label></pre>
                                </div>

                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}