import React, { useEffect, useState } from "react"
import Css from './Manual.module.css'
import { Link } from "react-router-dom";

export default function ManualLayoutMenu({ onChangeStep = undefined, currentStep }) {
    const arr_menu = [
        { seq: 1, name: 'ทดลองใช้งานฟรี', type: 'main' },
        { seq: 2, name: 'ซื้อแพ็กเกจทั่วไป', type: 'main' },
        { seq: 3, name: 'อัปเกรดแพ็กเกจหลัก', type: 'main' },
        { seq: 4, name: 'อัปเกรดแพ็กเกจเสริม', type: 'main' },
        { seq: 5, name: 'ลืมรหัสผ่าน', type: 'main' },
        { seq: 6, name: 'การเข้าระบบ ISO DCaaS', type: 'main' },
        { seq: 7, name: 'คำถามที่พบบ่อย (FAQ)', type: 'main' },
    ]

    const handleGetClass = (current, selfStep) => {
        if (current == selfStep) {
            return Css.Selected
        }
    }

    return (
        <div className={Css.inline_layout_Menu}>
            <div className={Css.Menu}>
                <ul>
                    {
                        arr_menu.map((obj, index) => {
                            if (obj.type === 'main') {
                                return (
                                    <li key={index} className={handleGetClass(currentStep, obj.seq)}>
                                        <Link onClick={event => onChangeStep(obj.seq)}>{obj.name}</Link>
                                    </li>
                                )
                            } else {
                                return (
                                    <div>
                                        <li key={index} className={handleGetClass(obj.seq)}>
                                            <Link to={(obj.seq)}>{obj.name}</Link>
                                        </li>
                                    </div>
                                )
                            }
                        })
                    }
                </ul>
            </div>
        </div>
    )
}