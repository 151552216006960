import Css from 'components/pages/customer/Customer.module.css';
import ApiConection from 'api_ref/ApiConection';
import { useEffect, useState } from 'react';
import UtilClass from 'api_ref/UtilClass';

export default function QuontationStatus({ register_number }) {
    const api = new ApiConection();
    const util = new UtilClass();

    const register = util.getSessionStorage('register');

    const [Quontation, setQuontation] = useState({});

    const getQuonationNextBill = async () => {
        const responseNextBill = await api.getData(`api/quontation/register/${register_number}/next-bill`);

        if (responseNextBill.status === false) {
            const response = await api.getData(`api/quontation/register/${register_number}`);
            if (response.status === true) {
                setQuontation(response.data);
            }
        } else {
            setQuontation(responseNextBill.data);
        }
    }

    useEffect(() => {
        //getQuonationNextBill();
    }, [])

    return (
        register?.quontation?.payment_status_name_full !== '' &&
        <div className={`pb-5 ${Css.Topic} ${Css.pad}`}>
            {
                register?.quontation?.payment_status_id === 1 &&
                <span className="ms-2 p-1 rounded bg-info">{register?.quontation?.payment_status_name_full}</span>
            }
        </div>
    )
}