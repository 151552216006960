import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LayoutLandingPage from "components/layout/LayoutLandingPage";
import UtilClass from "api_ref/UtilClass";
import Css from "./Register.module.css";
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";

function Privacypolicy() {
    const util = new UtilClass();
    const navigate = useNavigate();

    const handleCookie = (event) => {
        navigate('/register/Cookie');
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <LayoutLandingPageV2>
            <div className="container m-auto">
                <div className={Css.TermsAndCondition + " mt-3 mb-3 p-5"}>
                    <h2>นโยบายความเป็นส่วนตัว</h2>
                    <h5 >สำหรับลูกค้า</h5>
                    <h5 className="mt-0 ">บริษัท เมจิกซอฟท์แวร์ (ประเทศไทย) จำกัด</h5>
                    <p>
                        บริษัทฯ  เมจิกซอฟท์แวร์ (ประเทศไทย) จำกัด (ต่อไปนี้เรียกว่า “บริษัทฯ ”) เคารพสิทธิความเป็นส่วนตัวของลูกค้า
                        รวมถึงบุคคลธรรมดาที่ดำเนินการให้ลูกค้าซึ่งเป็นนิติบุคคลด้วย (ต่อไปนี้เรียกว่า “ท่าน”) และเพื่อให้เกิดความมั่นใจว่าท่านได้รับความ
                        คุ้มครองข้อมูลส่วนบุคคล จึงได้จัดทำนโยบายความเป็นส่วนตัวฉบับนี้ขึ้น เพื่อแจ้งให้ทราบถึงรายละเอียดที่เกี่ยวข้องกับการเก็บรวบรวม
                        การใช้ และการเปิดเผย (รวมเรียกว่า “การประมวลผล”) รวมตลอดถึงการลบและทำลายข้อมูลส่วนบุคคลของท่าน ทั้งช่องทางออนไลน์
                        และช่องทางอื่น ๆ ตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกำหนด ดังนี้
                    </p>

                    <h5>1. วัตถุประสงค์การประมวลผลข้อมูลส่วนบุคคล </h5>
                    <p>
                        บริษัทฯ จะทำการเก็บรวบรวม หรือใช้ข้อมูลส่วนบุคคลของท่าน เพื่อประโยชน์ในการดำเนินงานของบริษัทฯ  เช่น การจัดซื้อจัดจ้าง การทำสัญญา
                        การทำธุรกรรมทางการเงิน การดำเนินกิจกรรมบริษัทฯ  การให้บริการ การติดต่อประสานงานต่างๆ หรือเพื่อปรับปรุงท่านภาพการทำงานให้มีประสิทธิภาพ
                        มากยิ่งขึ้น เพื่อวัตถุประสงค์อื่นใดที่ไม่ต้องห้ามตามกฎหมาย และ/หรือเพื่อปฏิบัติตามกฎหมายหรือกฎระเบียบที่เกี่ยวข้องต่อการดำเนินงานของบริษัทฯ
                        โดยบริษัทฯ จะจัดเก็บและใช้ข้อมูลดังกล่าวตามระยะเวลาเท่าที่จำเป็นตามวัตถุประสงค์ที่ได้แจ้งท่านหรือตามที่กฎหมายกำหนดไว้เท่านั้น
                    </p>

                    <h5>2. ข้อมูลส่วนบุคคลที่เก็บรวบรวม</h5>
                    <p>
                        2.1 เมื่อท่านจะซื้อ หรือซื้อสินค้า และ/หรือ บริการ รวมทั้งการเข้าเป็นสมาชิกเว็บ Customer Services บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคล ดังนี้
                    </p>
                    <p className={Css.textp}>
                        (1) ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล
                    </p>
                    <p className={Css.textp}>
                        (2) ข้อมูลการติดต่อ เช่น อีเมล เบอร์โทรศัพท์ สถานที่ทำงาน ช่องทางติดต่อในสื่อสังคมออนไลน์
                    </p>
                    <p className={Css.textp}>
                        (3) ข้อมูลเกี่ยวกับงานของท่าน เช่น ตำแหน่ง ผลิตภัณฑ์ที่ใช้งาน
                    </p>
                    <p className={Css.textp}>
                        (4) ข้อมูลเกี่ยวกับการซื้อสินค้า และ/หรือบริการ เช่น ประวัติการซื้อสินค้า ประวัติการเคลมสินค้า ข้อร้องเรียน
                    </p >
                    <p className={`${Css.textp} mb-4`}>
                        (5) ข้อมูลที่ท่านได้ให้ไว้เมื่อท่านติดต่อบริษัทฯ หรือบริษัทฯ ดูแลให้บริการหลังการขาย การประเมินผล การ		บริการ
                    </p>

                    <p >
                        2.2 เมื่อท่านเข้าใช้บริการเว็บ Customer Services บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคล ดังนี้
                    </p>
                    <p className={Css.textp}>
                        (1) ข้อมูลเกี่ยวกับการสมัครสมาชิก (Registration) เช่น ชื่อ-นามสกุล อีเมล ชื่อองค์กร เบอร์โทรศัพท์ รหัสผ่าน ผลิตภัณฑ์ที่ใช้งาน
                    </p>
                    <p className={Css.textp}>
                        (2) ข้อมูลเกี่ยวกับอุปกรณ์อิเล็กทรอนิกส์ที่ท่านใช้ เช่น IP Address และข้อมูลที่อยู่ (Location Data) หรือ Other device identifier
                    </p>
                    <p className={`${Css.textp} mb-4`}>
                        (3) ชนิดและเวอร์ชันของเบราเซอร์ที่ท่านใช้ รวมถึงชนิดและเวอร์ชันของ plug-in ของเบราเซอร์
                    </p>

                    <p>
                        2.3 เมื่อท่านติดต่อบริษัทฯ  หรือร่วมกิจกรรมใด ๆ กับบริษัทฯ  เช่น งานสัมมนา การทำ Customer Satisfaction กิจกรรมสิทธิประโยชน์ต่าง ๆ
                        บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคล เช่น
                    </p>
                    <p className={Css.textp}>
                        (1) ข้อมูลส่วนตัว เช่น ชื่อ นามสกุล ตำแหน่ง อีเมล ชื่อองค์กร เบอร์โทรศัพท์
                    </p>
                    <p className={Css.textp}>
                        (2) ข้อมูลการติดต่อ เช่น ชื่อ นามสกุล ตำแหน่ง อีเมล เบอร์โทรศัพท์ ชื่อและที่ตั้งองค์กร ผลิตภัณฑ์ที่ใช้งาน
                    </p>
                    <p className={Css.textp}>
                        (3) ข้อมูลเกี่ยวกับการร่วมกิจกรรม เช่น ประวัติการร่วมกิจกรรมในครั้งก่อน ๆ ภาพถ่ายในแต่ละกิจกรรม
                    </p>

                    <h5>3. การใช้คุกกี้</h5>
                    <p>
                        บริษัทฯ  มีการใช้คุกกี้เพื่อเก็บรวบรวมข้อมูลส่วนบุคคล ตามที่กำหนดไว้ตาม  <a onClick={handleCookie} className={Css.lineli}>นโยบายการใช้คุกกี้</a>.
                    </p>

                    <h5>4. การขอความยินยอมและผลกระทบที่เป็นไปได้จากการถอนความยินยอม </h5>
                    <p>
                        4.1 ในกรณีที่บริษัทฯ เก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลโดยอาศัยความยินยอมของท่าน ท่านมีสิทธิที่จะถอนความยินยอมของท่านที่ให้ไว้กับ
                        บริษัทฯ ได้ตลอดเวลา ซึ่งการถอนความยินยอมนี้จะไม่ส่งผลกระทบต่อการเก็บรวบรวม ใช้ เปิดเผย หรือประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความ
                        ยินยอมไปแล้ว</p>
                    <p>
                        4.2 หากท่านถอนความยินยอมที่ได้ให้ไว้กับบริษัทฯ หรือปฏิเสธไม่ให้ข้อมูลบางอย่าง อาจส่งผลให้บริษัทฯ ไม่สามารถดำเนินการเพื่อบรรลุวัตถุประสงค์
                        บางส่วนหรือทั้งหมดตามที่ระบุไว้ในนโยบายความเป็นส่วนตัวนี้ได้
                    </p>

                    <h5>5. ระยะเวลาในการเก็บรักษาข้อมูลส่วนบุคคล </h5>
                    <p>
                        5.1 บริษัทฯ จะเก็บข้อมูลส่วนบุคคลของท่านในระยะเวลาที่จำเป็น เพื่อให้บรรลุวัตถุประสงค์ตามประเภทข้อมูลส่วนบุคคลแต่ละประเภท
                        เว้นแต่กฎหมายจะอนุญาตให้มีระยะเวลาการเก็บรักษาที่นานขึ้น ในกรณีที่ไม่สามารถระบุระยะเวลาการเก็บรักษาข้อมูลส่วนบุคคลได้ชัดเจน บริษัทฯ
                        จะเก็บรักษาข้อมูลไว้ตามระยะเวลาที่อาจคาดหมายได้ตามมาตรฐานของการเก็บรวบรวม (เช่น อายุความตามกฎหมายทั่วไปสูงสุด 10 ปี) </p>
                    <p>
                        5.2 กรณีที่บริษัทฯ ใช้ข้อมูลส่วนบุคคลของท่านโดยขอความยินยอมจากท่าน บริษัทฯ จะประมวลผลข้อมูลส่วนบุคคลดังกล่าวจนกว่าท่านจะแจ้งขอยกเลิก
                        ความยินยอมและบริษัทฯ ดำเนินการตามคำขอของท่านเสร็จสิ้นแล้ว อย่างไรก็ดี บริษัทฯ จะยังเก็บข้อมูลส่วนบุคคลของท่านเท่าที่จำเป็นสำหรับบันทึกเป็น
                        ประวัติว่าท่านเคยยกเลิกความยินยอม เพื่อให้บริษัทฯ สามารถตอบสนองต่อคำขอของท่านในอนาคตได้
                    </p>
                    <p>
                        5.3 บริษัทฯ จัดให้มีระบบการตรวจสอบเพื่อดำเนินการลบหรือทำลายข้อมูลส่วนบุคคลเมื่อพ้นกำหนดระยะเวลาการเก็บรักษาหรือที่ไม่เกี่ยวข้องหรือเกิน
                        ความจำเป็นตามวัตถุประสงค์ในการเก็บรวบรวมข้อมูลส่วนบุคคลนั้น
                    </p>

                    <h5>6. การเปิดเผยข้อมูลส่วนบุคคลให้บุคคลอื่น</h5>
                    <p>6.1 บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่ผู้อื่นภายใต้ความยินยอมของท่านหรือที่กฎหมายอนุญาตให้เปิดเผยได้ ดังต่อไปนี้ </p>
                    <p className={Css.textp}>
                        (1) การบริหารจัดการภายในองค์กร
                    </p>
                    <p className={Css.textp1}>
                        บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลของท่านภายในบริษัทฯ เท่าที่จำเป็นเพื่อปรับปรุงและพัฒนาสินค้าหรือบริการของบริษัทฯ
                        บริษัทฯ อาจรวบรวมข้อมูลภายในสำหรับสินค้าหรือบริการต่าง ๆ ภายใต้นโยบายนี้เพื่อประโยชน์ของท่านและผู้อื่นมากขึ้น
                    </p>
                    <p className={Css.textp}>
                        (2) ผู้ให้บริการ
                    </p>
                    <p className={Css.textp1}>
                        บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลของท่านบางอย่างให้กับผู้ให้บริการของบริษัทฯ เท่าที่จำเป็นเพื่อดำเนินงานในด้านต่าง ๆ เช่น การชำระเงิน
                        การตลาด การพัฒนาสินค้าหรือบริการ เป็นต้น ทั้งนี้ ผู้ให้บริการมีนโยบายความเป็นส่วนตัวของตนเอง
                    </p>
                    <p className={Css.textp}>
                        (3) พันธมิตรทางธุรกิจ
                    </p>
                    <p className={Css.textp1}>
                        บริษัทฯอาจเปิดเผยข้อมูลบางอย่างกับพันธมิตรทางธุรกิจเพื่อติดต่อและประสานงานในการให้บริการสินค้าหรือบริการ และให้ข้อมูลเท่าที่จำเป็นเกี่ยวกับ
                        ความพร้อมใช้งานของสินค้าหรือบริการ
                    </p>
                    <p className={Css.textp}>
                        (4) การบังคับใช้กฎหมาย
                    </p>
                    <p className={Css.textp1}>
                        ในกรณีที่มีกฎหมายหรือหน่วยงานราชการร้องขอ บริษัทฯ จะเปิดเผยข้อมูลส่วนบุคคลของท่านเท่าที่จำเป็นให้แก่หน่วยงานราชการ เช่น ศาล หน่วยงานราชการ เป็นต้น
                    </p>
                    <p>
                        6.2 บริษัทฯ มีมาตรการทางกฎหมายกำหนดให้ผู้ที่ได้รับข้อมูลมีมาตรการปกป้องข้อมูลของท่านอย่างเหมาะสมและประมวลผลข้อมูลส่วนบุคคลดังกล่าว
                        เท่าที่จำเป็นเท่านั้น และดำเนินการเพื่อป้องกันไม่ให้บุคคลอื่นใช้หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจโดยมิชอบ
                    </p>

                    <h5>7. การส่งหรือโอนข้อมูลส่วนบุคคลไปต่างประเทศ </h5>
                    <p>
                        7.1 บริษัทฯ อาจเก็บข้อมูลของท่านบนคอมพิวเตอร์เซิร์ฟเวอร์หรือคลาวด์ที่ให้บริการโดยบุคคลอื่น และอาจใช้โปรแกรมหรือแอปพลิเคชันของบุคคลอื่น
                        ในรูปแบบของการให้บริการซอฟต์แวร์สำเร็จรูป และรูปแบบของการให้บริการแพลตฟอร์มสำเร็จรูปในการประมวลผลข้อมูลส่วนบุคคลของท่าน แต่บริษัทฯ
                        จะไม่อนุญาตให้บุคคลที่ไม่เกี่ยวข้องสามารถเข้าถึงข้อมูลส่วนบุคคลได้ และจะกำหนดให้บุคคลอื่นเหล่านั้นต้องมีมาตรการคุ้มครองความมั่นคงปลอดภัยที่เหมาะสม
                    </p>
                    <p>
                        7.2 ในกรณีที่มีการส่งข้อมูลส่วนบุคคลของท่านไปยังต่างประเทศ บริษัทฯ จะปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล และใช้มาตรการที่เหมาะสม
                        เพื่อทำให้มั่นใจได้ว่าข้อมูลส่วนบุคคลของท่านจะได้รับการคุ้มครอง และท่านสามารถใช้สิทธิที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของท่านได้ตามกฎหมาย
                        รวมถึงบริษัทฯ จะกำหนดให้ผู้ที่ได้รับข้อมูลมีมาตรการปกป้องข้อมูลของท่านอย่างเหมาะสมและประมวลผลข้อมูลส่วนบุคคลดังกล่าวเท่าที่จำเป็นเท่านั้น
                        และดำเนินการเพื่อป้องกันไม่ให้บุคคลอื่นใช้หรือเปิดเผยข้อมูลส่วนบุคคลโดยปราศจากอำนาจโดยมิชอบ
                    </p>

                    <h5>8. มาตรการความปลอดภัยสำหรับข้อมูลส่วนบุคคล </h5>
                    <p>
                        8.1 ความปลอดภัยของข้อมูลส่วนบุคคลของท่านเป็นสิ่งสำคัญสำหรับบริษัทฯ และบริษัทฯ ได้นำมาตรฐานความปลอดภัยทางเทคนิคและการบริหารที่
                        เหมาะสมมาใช้ เพื่อปกป้องข้อมูลส่วนบุคคลจากการสูญหาย การเข้าถึงการใช้หรือการเปิดเผยโดยไม่ได้รับอนุญาต การใช้งานในทางที่ผิด
                        การดัดแปลงเปลี่ยนแปลง และการทำลายโดยใช้เทคโนโลยีและขั้นตอนการรักษาความปลอดภัย เช่น การเข้ารหัสและการจำกัดการเข้าถึง
                        เพื่อให้มั่นใจว่าบุคคลที่ได้รับอนุญาตเท่านั้นที่เข้าถึงข้อมูลส่วนบุคคลของท่านและบุคคลเหล่านี้ได้รับการฝึกอบรมเกี่ยวกับความสำคัญของการปกป้อง
                        ข้อมูลส่วนบุคคล
                    </p>
                    <p>
                        8.2 บริษัทฯ จัดให้มีมาตรการรักษาความปลอดภัยที่เหมาะสม เพื่อป้องกันการสูญหาย เข้าถึง ใช้ เปลี่ยนแปลง แก้ไข เปิดเผยข้อมูลส่วนบุคคลจากผู้
                        ที่ไม่มีสิทธิหรือหน้าที่ที่เกี่ยวข้องกับข้อมูลส่วนบุคคลนั้น และจะจัดให้มีการทบทวนมาตรการดังกล่าวเมื่อมีความจำเป็นหรือเมื่อเทคโนโลยีเปลี่ยนแปลง
                        ไปเพื่อให้มีประสิทธิภาพในการรักษาความปลอดภัยที่เหมาะสม
                    </p>
                    <h5>9. สิทธิเกี่ยวกับข้อมูลส่วนบุคคลของท่าน</h5>
                    <p>ท่านมีสิทธิตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล ดังต่อไปนี้ </p>
                    <p>
                        9.1 <a className={Css.lineli1}>สิทธิในการเพิกถอนความยินยอม (right to withdraw consent) </a>
                        ท่านมีสิทธิในการเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับบริษัทฯ ได้ ตลอดระยะเวลาที่ข้อมูลส่วนบุคคล
                        ของท่านอยู่กับบริษัทฯ
                    </p>
                    <p>
                        9.2 <a className={Css.lineli1}>สิทธิการได้รับแจ้งข้อมูล (right to be informed) </a>
                        ท่านมีสิทธิในการรับแจ้งรายละเอียดในการเก็บข้อมูล ตลอดจนการนำไปใช้ หรือเผยแพร่ให้ท่านทราบก่อน หรือขณะเก็บรวบรวมข้อมูล
                        โดยท่านมีสิทธิจะทราบวัตถุประสงค์ของการเก็บข้อมูล การนำไปใช้ หรือเผยแพร่ ระยะเวลาในการเก็บข้อมูล ตลอดจนรายละเอียดของผู้ควบคุมข้อมูล
                    </p>
                    <p>
                        9.3 <a className={Css.lineli1}>สิทธิในการเข้าถึงข้อมูลส่วนบุคคล (right of access) </a>
                        ท่านมีสิทธิในการเข้าถึงข้อมูลส่วนบุคคลของท่าน และขอให้บริษัทฯ ทำสำเนาข้อมูลส่วนบุคคลดังกล่าวให้แก่ท่าน รวมถึงขอให้บริษัทฯ
                        เปิดเผยการได้มาซึ่งข้อมูลส่วนบุคคลที่ท่านไม่ได้ให้ความยินยอมต่อบริษัทฯ ได้
                    </p>
                    <p>
                        9.4 <a className={Css.lineli1}>สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง (right to rectification)</a>
                        ท่านมีสิทธิในการขอให้บริษัทฯ แก้ไขข้อมูลที่ไม่ถูกต้อง หรือ เพิ่มเติมข้อมูลที่ไม่สมบูรณ์ โดยการแก้ไขนั้นจะต้องเป็นไปด้วยความสุจริต
                        และไม่ขัดต่อหลักกฎหมาย
                    </p>
                    <p>
                        9.5 <a className={Css.lineli1}>สิทธิในการลบข้อมูลส่วนบุคคล (right to erasure) </a>
                        ท่านมีสิทธิในการขอให้บริษัทฯ ทำการลบหรือทำลายข้อมูลของท่านเป็นข้อมูลที่ไม่สามารถระบุตัวตน
                    </p>
                    <p>
                        9.6 <a className={Css.lineli1}>สิทธิในการระงับการใช้ข้อมูลส่วนบุคคล (right to restriction of processing)</a>
                        ท่านมีสิทธิในการระงับการใช้ข้อมูลส่วนบุคคล
                    </p>
                    <p>
                        9.7 <a className={Css.lineli1}>สิทธิในการให้โอนย้ายข้อมูลส่วนบุคคล (right to data portability) </a>
                        ท่านมีสิทธิในการโอนย้ายข้อมูลส่วนบุคคลของท่านที่ให้ไว้กับบริษัทฯ ในรูปแบบอิเล็กทรอนิกส์ไปยังผู้ควบคุมข้อมูลรายอื่น
                    </p>
                    <p className="mb-4">
                        9.8 <a className={Css.lineli1}>สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล (right to object) </a>
                        ท่านมีสิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้
                    </p>
                    <p>
                        ทั้งนี้ บริษัทฯ จะพิจารณาและแจ้งผลการพิจารณาตามคำร้องขอใช้สิทธิของท่านภายในระยะเวลา 30 วัน
                        โดยนับแต่วันที่บริษัทฯ ได้รับคำร้องขอดังกล่าว และสิทธิตามที่กล่าวมาข้างต้น เป็นไปตามที่กฎหมายคุ้มครองข้อมูลส่วนบุคคลกำหนด
                    </p>

                    <h5>10. ข้อมูลเกี่ยวกับผู้ควบคุมข้อมูลส่วนบุคคลและเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล</h5>
                    <p>10.1 ควบคุมข้อมูลส่วนบุคคล 	:  บริษัท เมจิกซอฟท์แวร์ (ประเทศไทย) จำกัด</p>
                    <p className={Css.textp2}>สถานที่ติดต่อ		:  9/68  ซอยรัชประชา  แขวงจตุจักร  เขตจตุจักร กรุงเทพฯ 10900 </p>
                    <p>10.2 กรณีที่ท่านมีข้อสอบถามเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล หรือประสงค์ที่จะใช้สิทธิเกี่ยวกับข้อมูลส่วนบุคคลของท่านได้จาก
                        <a className={Css.lineli1}>แบบคำร้องขอใช้สิทธิของเจ้าของข้อมูล </a> หรือติดต่อเบอร์ 0-2911-9988 หรือ e-mail :
                        <a className={Css.lineli2}>pdpa@magicsoftware.co.th </a> </p>
                    <p className='mb-5'>
                        กรณีที่มีการแก้ไขเปลี่ยนแปลงนโยบายความเป็นส่วนตัวนี้ บริษัทฯ จะประกาศนโยบายความเป็นส่วนตัวฉบับใหม่ผ่านทาง
                        <a href="https://magicsoftware.co.th/th/" className={Css.lineli}>www.magicsoftware.co.th</a>
                        ซึ่งท่านควรเข้ามาตรวจสอบความเปลี่ยนแปลงนโยบายความเป็นส่วนตัวเป็นครั้งคราว โดยนโยบายความเป็นส่วนตัวฉบับใหม่จะมีผลบังคับใช้ทันที
                        ในวันที่ประกาศ
                    </p>
                    <p className={Css.fontp}>
                        ประกาศล่าสุดเมื่อวันที่ 1 กันยายน 2566
                    </p>

                </div>
            </div>
        </LayoutLandingPageV2>
    );
}
export default Privacypolicy;