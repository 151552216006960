import UtilClass from "./UtilClass";

class ApiConection{
    constructor(){
		let thisBaseUrl = process.env.REACT_APP_API_URL;

		this._baseUrl = thisBaseUrl;
		this._userLanding = process.env.REACT_APP_DEFAULT_JWT_USER;
		this._passLanding = process.env.REACT_APP_DEFAULT_JWT_PASS;
		this._utilClass = new UtilClass();
	}

	async loginLandingPage(){
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
        myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);

		let raw = JSON.stringify({
			login_name:this._userLanding,
			password:this._passLanding
		});

		let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            mode:'cors',
			body: raw,
            redirect: 'follow'
          };
		  //debugger;
		  try{
			const response = await fetch(`${this._baseUrl}/api/authentication/login-admin`, requestOptions);

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.json();

			if(data.status === false){
				window.sessionStorage.removeItem('_8086');
				console.log('cannot connect to server!!');
				return({status:false,message:data.message});
			}

			this._utilClass.setSessionStorage('_8086',data.data);
			return data.data;
		  }catch(error){
			return({status:false,message:error});
		  }
	}

	async callLandingPageGetV2(url){
        let token = await this.tokenLandingManager();

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
        myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
		myHeaders.append("Authorization", `Bearer ${token}`);

		let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            mode:'cors',
            redirect: 'follow'
          };

		 // console.clear();

		  try{
			const response = await fetch(`${this._baseUrl}/${url}`, requestOptions)
									.catch((error) => {
										// Your error is here!
										return({status:false,message:error});
									});

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.json();

			return(data);
		  }catch(error){
			return({status:false,message:error});
		  }
	}

	async callLandingPagePostV2(url,payLoad){
		let token = await this.tokenLandingManager();

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
        myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
		myHeaders.append("Authorization", `Bearer ${token}`);

		let raw = JSON.stringify(payLoad);

		 let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            mode:'cors',
            body: raw,
            redirect: 'follow'
          };

		  //console.clear();

		  try{
			const response = await fetch(`${this._baseUrl}/${url}`, requestOptions)
									.catch((error) => {
										// Your error is here!
										return({status:false,message:error});
									});

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.json();

		  	return(data);
		  }catch(error){
			return({status:false,message:error});
		  }
	}

	async callLandingPageGet(url){
        let token = await this.tokenLandingManager();

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
        myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
		myHeaders.append("Authorization", `Bearer ${token}`);

		let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            mode:'cors',
            redirect: 'follow'
          };

		  try{
			const response = await fetch(`${this._baseUrl}/${url}`, requestOptions);

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.json();

			return(data);
		  }catch(error){
			return({status:false,message:error});
		  }
	}

	async callLandingPagePost(url,payLoad){
        let token = await this.tokenLandingManager();

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
        myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
		myHeaders.append("Authorization", `Bearer ${token}`);

		let raw = JSON.stringify(payLoad);

		 let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            mode:'cors',
            body: raw,
            redirect: 'follow'
          };

		  try{

			debugger;
			const response = await fetch(`${this._baseUrl}/${url}`, requestOptions);
			console.log(response);

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.json();

		  	return(data);
		  }catch(error){
			return({status:false,message:error});
		  }
	}

	async tokenLandingManager(){
		let token =  this._utilClass.getSessionStorage('_8086');

		if(token === null || token === undefined || token?.status === false){
			// eslint-disable-next-line
			window.sessionStorage.removeItem('_8086');

			const log_response = await this.loginLandingPage();

			this._utilClass.setSessionStorage('_8086',log_response);
			token = log_response;
			return token.jwt_token.access_token;
		}

		//if(token.jwt_token === null) window.location.href = process.env.PUBLIC_URL;

		const currDate = new Date();

		let TokenDate = new Date(token?.jwt_token?.create_at * 1000);
		TokenDate.setUTCMinutes(TokenDate.getUTCMinutes() + token?.jwt_token?.expires_in);

		const year = TokenDate.getUTCFullYear();
		const month = TokenDate.getUTCMonth() ; // เดือนเริ่มนับจาก 0
		const day = TokenDate.getUTCDate();
		const hours = TokenDate.getUTCHours();
		const minutes = TokenDate.getUTCMinutes();
		const seconds = TokenDate.getUTCSeconds();

		const dateToken = new Date(year,month,day,hours,minutes,seconds);

		if(currDate < dateToken){
			return token?.jwt_token?.access_token;
		}
		else{
			let myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);

			let raw = JSON.stringify({
				"access_token": token.jwt_token.access_token,
				"refresh_token": token.jwt_token.refresh_token
			});

			let requestOptions = {
				method: 'POST',
				headers: myHeaders,
				mode:'cors',
				body: raw,
				redirect: 'follow'
			};

			try{
				const response = await fetch(`${this._baseUrl}/api/authentication/refresh-token`, requestOptions);

				if(!response.ok){
					console.log(`you are not autherized!! ,Required Token`);

					const log_response = await this.loginLandingPage();
					this._utilClass.setSessionStorage('_8086',log_response);
					token = log_response;
					return token.jwt_token.access_token;
				}
				else{
					let data = await response.json();

					this._utilClass.setSessionStorage('_8086',data.data);

					token = data.data;
					return token.jwt_token.access_token;
				}
			}catch(e){
				console.log('error',e + ' refresh ไม่ได้');
				window.sessionStorage.removeItem('_8086');
				//window.location.href = process.env.PUBLIC_URL;
			}
		}

		return token.jwt_token.access_token;
	}
	//----------------------------

    async getData(url){
        let token = await this.token_manager();

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
        myHeaders.append("Authorization", `Bearer ${token}`);

		let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            mode:'cors',
            redirect: 'follow'
          };

		  try{
			const response = await fetch(`${this._baseUrl}/${url}`, requestOptions);

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.json();

			return(data);
		  }catch(error){
			return({status:false,message:error});
		  }
	}

	async postData(url,payLoad){
        let token = await this.token_manager();

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
        myHeaders.append("Authorization", `bearer ${token}`);

        let raw = JSON.stringify(payLoad);

		//console.log(raw);
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            mode:'cors',
            body: raw,
            redirect: 'follow'
          };

		  try{
			const response = await fetch(`${this._baseUrl}/${url}`, requestOptions);

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.json();

		  	return(data);
		  }catch(error){
			return({status:false,message:error});
		  }
	}

	async putData(url,payLoad){
        let token = await this.token_manager();

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
        myHeaders.append("Authorization", `bearer ${token}`);

        let raw = JSON.stringify(payLoad);

        let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            mode:'cors',
            body: raw,
            redirect: 'follow'
          };

		  try{
			const response = await fetch(`${this._baseUrl}/${url}`, requestOptions);

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.json();

			return(data);
		  }catch(error){
			return({status:false,message:error});
		  }
	}

	async deleteData(url){
        let token = await this.token_manager();

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
        myHeaders.append("Authorization", `bearer ${token}`);

		let requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            mode:'cors',
            redirect: 'follow'
          };

		try{
			const response = await fetch(`${this._baseUrl}/${url}`, requestOptions);

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.json();

			return(data);
		}catch(error){
			return({status:false,message:error});
		}
	}

	async login(login_name,password){
		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
        myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);

		let raw = JSON.stringify({
			login_name:login_name,
			password:password
		});

		let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            mode:'cors',
			body: raw,
            redirect: 'follow'
          };

		  try{
			const response = await fetch(`${this._baseUrl}/api/authentication/login-customer`, requestOptions);

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.json();
			if(data.status === false){
				window.sessionStorage.removeItem('customer');
				console.log('cannot connect to server!!');
				return({status:false,message:data.message});
			}

			this._utilClass.setSessionStorage('customer',data.data);
			return data.data;
		  }catch(error){
			return({status:false,message:error});
		  }
	}

	async token_manager(){
		let token =  this._utilClass.getSessionStorage('customer');

		if(token === null || token === undefined  || token?.status === false){
			// eslint-disable-next-line
			//throw `you are not autherized!! ,Required Token`;
			console.log(`you are not autherized!! ,Required Token`);
			//window.location.href = process.env.PUBLIC_URL;
			window.sessionStorage.removeItem('customer');
			window.location.href = process.env.PUBLIC_URL;
			return;
		}

		if(token.jwt_token === null) window.location.href = process.env.PUBLIC_URL;

		const currDate = new Date();
		let TokenDate = new Date(token.jwt_token.create_at * 1000);
		TokenDate.setUTCMinutes(TokenDate.getUTCMinutes() + token.jwt_token.expires_in);

		const year = TokenDate.getUTCFullYear();
		const month = TokenDate.getUTCMonth() ; // เดือนเริ่มนับจาก 0
		const day = TokenDate.getUTCDate();
		const hours = TokenDate.getUTCHours();
		const minutes = TokenDate.getUTCMinutes();
		const seconds = TokenDate.getUTCSeconds();

		const dateToken = new Date(year,month,day,hours,minutes,seconds);

		if(currDate < dateToken)
			return token.jwt_token.access_token;

		else{
			let myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);

			let raw = JSON.stringify({
				"access_token": token.jwt_token.access_token,
				"refresh_token": token.jwt_token.refresh_token
			});

			let requestOptions = {
				method: 'POST',
				headers: myHeaders,
				mode:'cors',
				body: raw,
				redirect: 'follow'
			};

			try{
				const response = await fetch(`${this._baseUrl}/api/authentication/refresh-token`, requestOptions);
				//debugger;
				if(!response.ok){
					window.location.href = process.env.PUBLIC_URL;
					return;
				}

				let data = await response.json();

				this._utilClass.setSessionStorage("customer",data.data);

				token = data.data;
				return token.jwt_token.access_token;
			}catch(e){
				window.sessionStorage.removeItem('customer');
				window.location.href = process.env.PUBLIC_URL;
			}
		}

		return token.jwt_token.access_token;
	}

	async postFile(url,payLoad){
		let token = await this.token_manager();

		let myHeaders = new Headers();
		myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
		myHeaders.append("Authorization", `bearer ${token}`);
		// myHeaders.append("Content-Type", 'multipart/form-data');

		let raw = payLoad;

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            mode:'cors',
            body: raw,
            redirect: 'follow'
          };

		  try{
			const response =  await fetch(`${this._baseUrl}/${url}`, requestOptions);

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.json();

			return(data);
		  }catch(error){
			return({status:false,message:error});
		  }
	}

	async getFile(url){
		let token = await this.token_manager();

		let myHeaders = new Headers();
		myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
		myHeaders.append("Authorization", `bearer ${token}`);
		// myHeaders.append("Content-Type", 'multipart/form-data');

		let raw = '';

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            mode:'cors',
            redirect: 'follow'
          };

		  try{
			const response =  await fetch(`${this._baseUrl}/${url}`, requestOptions);

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.blob();

			return(data);
		  }catch(error){
			return({status:false,message:error});
		  }
	}

	async callLandingPageGetFile(url){
        let token = await this.tokenLandingManager();

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
        myHeaders.append("ApiKey", process.env.REACT_APP_API_KEY);
		myHeaders.append("Authorization", `Bearer ${token}`);

		let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            mode:'cors',
            redirect: 'follow'
          };

		  try{
			const response =  await fetch(`${this._baseUrl}/${url}`, requestOptions);

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.blob();

			return(data);
		  }catch(error){
			return({status:false,message:error});
		  }
	}
}

export default ApiConection;