import { Col, Form, Row } from "react-bootstrap";
import Css from "components/pages/application/register/Register.module.css";
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import RegisterCustomerSelectPackage from "components/pages/application/register/RegisterCustomerSelectPackage";
import PackageChanged from "./PackageChanged";
import RegisterCustomerPayment from "components/pages/application/register/RegisterCustomerPayment";

export default function CustomerUpPackageFlowStep1({ data = undefined,
    onChangeData = undefined,
    onHandleSubmit = undefined,
    error = '',
    SetRegisterData = undefined,
    onChangeStep = undefined }) {
    const api = new ApiConection();

    console.log(data);
    const [validated, setValidated] = useState(false);
    const [estimateData, setEstimateData] = useState(
        {
            register_number: data?.register_number,
            package_master_period_id: data?.package_master_period_id,
            package_master_period_name: data?.package_master_period_name,
            package_master_id:data?.package_master_id,
            package_master_name: data?.package_master_name,
            user_volume: data?.user_volume,
            storage_volume: data?.storage_volume,
            arr_package: (data?.total === 0)? [] : (data?.arr_package ?? []),

            is_addon_user: data?.arr_package?.find(x => x.package_type == 'addon-user') !== undefined,
            is_addon_storage: data?.arr_package?.find(x => x.package_type == 'addon-storage') !== undefined,

            discount_code: '',
            is_with_holding_tax: false,
            net: data?.net,
            vat: data?.vat,
            discount: data?.discount,
            tax: data?.tax,
            total: 0,

            compare: data?.compare,
            is_pay_now: false,

            payee_name: data?.payee_name,
            payee_address: data?.payee_address,
            payee_branch: data?.payee_branch,
            payee_tax_id: data?.payee_tax_id,
            payee_tel: data?.payee_tel
        });
    const [dataChangeCount, setDataChangeCount] = useState(0);
    const [calData, setCalData] = useState(undefined);
    const [readyToSumbit, setreadyToSumbit] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const handleEstimate = (event) => {
        const { name, value, type, checked } = event.target;

        if (type === 'checkbox') {
            setEstimateData((prevData) => {
                return { ...prevData, [name]: checked };
            });
        } else {
            setEstimateData((prevData) => {
                return { ...prevData, [name]: value };
            });
        }

        setDataChangeCount(dataChangeCount + 1);
    }

    //console.log(data?.arr_package?.find( x => x.package_type == 'addon-storage') !== undefined);

    const checkPrice = async () => {
        if (estimateData.package_master_period_id === undefined) return;

        let arr_quontations = [];
        //debugger;

        //console.log('คำนวณ');
        estimateData.arr_package.forEach(obj => {
            arr_quontations.push(
                {
                    package_master_id: obj.package_master_id,
                    package_master_period_id: estimateData.package_master_period_id,
                    package_master_users_volume_id: obj.package_master_users_volume_id,
                    package_master_storage_volume_id: obj.package_master_storage_volume_id
                });
        });

        let requests = {
            arr_quontations: arr_quontations,
            discount_code: estimateData.discount_code,
            is_with_holding_tax: estimateData.is_with_holding_tax,
            is_pay_now: false,
            is_accept_agreement: true
        }

        const response = await api.postData(`api/quontation/register/${data.register_number}/upgrade-package/price-check`, requests)

        let tmpData = estimateData;

        tmpData.total = Number(response.data.total).toFixed(2);
        tmpData.discount = Number(response.data.discount).toFixed(2);
        tmpData.net = Number(response.data.net).toFixed(2);
        tmpData.vat = Number(response.data.vat).toFixed(2);
        tmpData.vat_rate = Number(response.data.vat_rate * 100).toFixed(0);

        tmpData.tax = Number(response.data.tax).toFixed(2);
        tmpData.tax_rate = Number(response.data.tax_rate * 100).toFixed(0);

        tmpData.grand_total = Number(response.data.grand_total).toFixed(2);
        tmpData.grand_total_sub_withholding_tax = Number(response.data.grand_total_sub_withholding_tax).toFixed(2);

        tmpData.compare = response.data.compare;

        //console.log(tmpData);
        setEstimateData(tmpData);
        setCalData(tmpData);
    }

    useEffect(() => {
        if (estimateData?.package_master_period_id !== undefined &&
            estimateData?.arr_package[0]?.package_master_id !== undefined) {
            const delay = setTimeout(async () => {
                // เรียกใช้ฟังก์ชันสำหรับการคำนวณผลลัพธ์ที่ต้องการ
                await checkPrice();
            }, 100);

            return () => clearTimeout(delay);
        }
    }, [dataChangeCount]);

    const handleThisSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        let tmpData = data;

        tmpData.package_master_period_id = estimateData.package_master_period_id;
        tmpData.package_master_period_name = estimateData.package_master_period_name;
        tmpData.package_master_name = estimateData.package_master_name;
        tmpData.user_volume = estimateData.user_volume;
        tmpData.storage_volume = estimateData.storage_volume;
        tmpData.arr_package = estimateData.arr_package;
        tmpData.is_addon_user = estimateData.is_addon_user;
        tmpData.is_addon_storage = estimateData.is_addon_storage;
        tmpData.discount_code = estimateData.discount_code;
        tmpData.is_with_holding_tax = estimateData.is_with_holding_tax;
        tmpData.net = estimateData.net;
        tmpData.vat = estimateData.vat;
        tmpData.vat_rate = estimateData.vat_rate;
        tmpData.tax_rate = estimateData.tax_rate;
        tmpData.discount = estimateData.discount;
        tmpData.tax = estimateData.tax;
        tmpData.total = estimateData.total;
        tmpData.grand_total = estimateData.grand_total;
        tmpData.grand_total_sub_withholding_tax = estimateData.grand_total_sub_withholding_tax;
        tmpData.is_pay_now = false;
        tmpData.compare = estimateData.compare;

        SetRegisterData(tmpData);
        setreadyToSumbit(true);
    }

    useEffect(() => {
        if (readyToSumbit === true) {
            const towork = async () => {
                await onHandleSubmit();
                setLoading(false);
            }

            towork();
            setreadyToSumbit(false);
        }
    }, [readyToSumbit])

    return (
        <Form noValidate validated={validated} onSubmit={event => handleThisSubmit(event)} >
            <Row>
                <Col sm={7}>
                    <RegisterCustomerSelectPackage
                        data={estimateData}
                        mode={'upgrade'}
                        onChangeData={handleEstimate}
                        onChangeStep={onChangeStep}
                        className='w-auto' />
                </Col>
                <Col>
                    <PackageChanged data={estimateData} />
                    <RegisterCustomerPayment className='w-auto'
                        mode={'upgrade'}
                        data={estimateData}
                        renewMode={true}
                        onChangeData={handleEstimate} />
                </Col>
            </Row>
        </Form>
    );
}