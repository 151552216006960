import Layout4Main from "components/layout/Layout4Main";
import { Row, Col, Form, Button, Tab, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Css from 'components/pages/customer/Customer.module.css';
import CssRegis from 'components/pages/application/register/Register.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import { AddCommar, DateToText } from "components/MyControl";
import moment from "moment";
import UtilClass from "api_ref/UtilClass";

export default function Change2Transfer({ onChangeData, handleSetChangePackage = undefined, getValue = undefined, validated = false, setSubStep, onSubmit = undefined }) {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');
    const register = util.getSessionStorage('register');
    const quontation = util.getSessionStorage('quontation');

    const [loading, setLoading] = useState(false);

    const handlecontact = () => {
        window.open('https://magicsoftware.co.th/th/company-profile/#contact', '_blank');
    }
    return (
        <div className={Css.Graph_Group}>
            <div className={Css.Graph_Box + ' shadow'}>
                <Form noValidate validated={validated} onSubmit={event => onSubmit(event)}>
                    <div className={Css.Content}>
                        <div className={Css.Header}>
                            <h6 className='mb-3'>ข้อมูลออกใบเสร็จ</h6>
                        </div>
                        <div className={Css.Content + ' align-items-center'}>

                            <div className="border rounded w-800px mb-3">
                                <h6 className={'bg-light border-bottom d-block p-2 ps-4 text-start'}>รายละเอียดค่าบริการ</h6>
                                <div className='p-4 text-start'>
                                    <Form.Group as={Row}>
                                        <Form.Label column className='text-end p-1 pb-0 ' sm={4}>ชื่อบริษัท</Form.Label>
                                        <Form.Label column className='fw-bold p-1 pb-0'>{getValue('company_name')}</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column className='text-end p-1 pb-0' sm={4}>แพ็กเกจ</Form.Label>
                                        <Form.Label column className='fw-bold p-1 pb-0'>{getValue('package_short_name')}</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column className='text-end p-1 pb-0' sm={4}>งวดชำระ</Form.Label>
                                        <Form.Label column className='fw-bold p-1 pb-0'>{getValue('package_period')}</Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column className='text-end p-1 pb-0' sm={4}>ข้อมูลชำระเงิน</Form.Label>
                                        <Col>
                                            <Form.Group as={Row}>
                                                <Form.Label column className={Css.Color_Purple_00 + ' text-start p-1 pb-0'} sm={4}>ราคา</Form.Label>
                                                <Form.Label column className=' text-end p-1 pb-0'>{AddCommar(getValue('price'))}</Form.Label>
                                                <Form.Label column className='text-start p-1 pb-0' sm={2}>บาท</Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Form.Label column className={Css.Color_Purple_00 + ' text-start p-1 pb-0'} sm={4}>บวกภาษีมูลค่าเพิ่ม</Form.Label>
                                                <Form.Label column className=' text-end p-1 pb-0'>{AddCommar(!isNaN(parseFloat(getValue('total').replace(',', ''))) ? parseFloat(getValue('total').replace(',', '')) + parseFloat(getValue('tax').replace(',', '')) - parseFloat(getValue('price').replace(',', '')) : 'ฟรี')}</Form.Label>
                                                <Form.Label column className='text-start p-1 pb-0' sm={2}>บาท</Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Row} className='border-bottom'>
                                                <Form.Label column className={Css.Color_Purple_00 + ' text-start p-1 pb-0'} sm={4}>ราคารวม</Form.Label>
                                                <Form.Label column className=' text-end p-1 pb-0'>{AddCommar(!isNaN(parseFloat(getValue('total').replace(',', ''))) ? parseFloat(getValue('total').replace(',', '')) + parseFloat(getValue('tax').replace(',', '')) : 'ฟรี')}</Form.Label>
                                                <Form.Label column className='text-start p-1 pb-0' sm={2}>บาท</Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Row}>
                                                <Form.Label column className={Css.Color_Purple_00 + ' text-start p-1 pb-0'} sm={4}>หักภาษี ณ ที่จ่าย</Form.Label>
                                                <Form.Label column className=' text-end p-1 pb-0'>{AddCommar(getValue('tax'))}</Form.Label>
                                                <Form.Label column className='text-start p-1 pb-0' sm={2}>บาท</Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Row} className='border-bottom'>
                                                <Form.Label column className={Css.Color_Purple_00 + ' text-start p-1 pb-0'} sm={5}>จำนวนเงินสุทธิที่ต้องชำระ</Form.Label>
                                                <Form.Label column className='fw-bold text-end p-1 pb-0'>{AddCommar(getValue('total'))}</Form.Label>
                                                <Form.Label column className='text-start p-1 pb-0' sm={2}>บาท</Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </Form.Group>

                                </div>
                            </div>
                            <div className="border rounded w-800px mb-3">
                                <h6 className={'bg-light border-bottom d-block p-2 ps-4 text-start'}>รายละเอียดการออกใบเสร็จ</h6>
                                <div className='p-4 text-start'>
                                    <div>
                                        <Form.Group>
                                            ต้องการออกใบกำกับภาษี?
                                        </Form.Group>
                                        <Form.Switch name={'is_want_tax_invoice'} checked={getValue('is_want_tax_invoice')} onChange={(event) => onChangeData(event)} />
                                    </div>
                                    <br />
                                    <label className='mb-2'>กรุณาระบุที่อยู่ในการออกใบเสร็จ/ใบกำกับภาษี</label>
                                    <Form.Group className='mb-2'>
                                        <Form.Label>ชื่อบริษัท</Form.Label>
                                        <Form.Control name={'delivery_name'} value={getValue('delivery_name')} onChange={(event) => onChangeData(event)} required={true} />
                                    </Form.Group>
                                    <Form.Group className='mb-2'>
                                        <Form.Label>ที่อยู่</Form.Label>
                                        <Form.Control name={'delivery_address'} value={getValue('delivery_address')} onChange={(event) => onChangeData(event)} required={true} />
                                    </Form.Group>
                                    <Form.Group className='mb-2' >
                                        <Form.Label>หมายเลขโทรศัพท์</Form.Label>
                                        <Col>
                                            <Form.Control type={'tel'} placeholder="หมายเลขโทรศัพท์" name={'delivery_tel'} value={getValue('delivery_tel')} onChange={(event) => onChangeData(event)} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className='mb-2'>
                                        <Col>
                                            <Form.Label>เลขประจำตัวผู้เสียภาษีอากร</Form.Label>
                                            <Form.Control name={'delivery_tax_id'} maxLength={13} pattern="[0-9]{13}" value={getValue('delivery_tax_id')} onChange={(event) => onChangeData(event)} required={true} />
                                        </Col>
                                        <Col>
                                            <Form.Label>สาขา/รหัสสาขา</Form.Label>
                                            <Form.Control name={'delivery_branch'} value={getValue('delivery_branch')} onChange={(event) => onChangeData(event)} required={true} />
                                        </Col>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className=' w-800px mt-3'>
                                <Form.Text className='d-none' >
                                    ระบบจะต่ออายุอัตโนมัติรายเดือน จนกว่าบริษัทจะได้ทำการยกเลิกการสมัครแพ็กเกจ หากมีข้อสงสัยหรือต้องการสอบถามเพิ่มเติม&nbsp;
                                    <a onClick={handlecontact} className='link_contact'>ติดต่อเจ้าหน้าที่</a>
                                </Form.Text>
                                <div className='text-end mt-2'>
                                    <Button type={'button'} variant="outline-secondary" className='w-33 me-2' onClick={event => setSubStep(1)} >ย้อนกลับ</Button>
                                    <Button type={'submit'} className='btn-primary w-33 '>ดำเนินการต่อ &#62;</Button>
                                </div>

                            </div>

                        </div>
                    </div>
                </Form>
            </div>
        </div>
    )
}