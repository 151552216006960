import Css2 from 'components/pages/application/login/Login.module.css';
import ApiConection from 'api_ref/ApiConection';
import { Col, Form, Row } from 'react-bootstrap';
import { Button } from 'bootstrap/dist/js/bootstrap.bundle';
import { useEffect, useState } from 'react';
import { ToastError, ToastSuccess } from 'components/MyControl';
import UtilClass from 'api_ref/UtilClass';

export default function ContactUs() {
    const api = new ApiConection();
    const util = new UtilClass();

    const register = util.getSessionStorage('register');

    const [input, setInput] = useState({});
    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [showToastSuccess, setShowToastSuccess] = useState(false);
    const [showToastError, setShowToastError] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        setInput(prevData => {
            return { ...prevData, [name]: value }
        });
    }

    const [employeeCount, setEmployeeCount] = useState([]);
    const getEmployeeCount = async () => {
        const response = await api.getData(`api/master/employee-count`);
        if (response.status == true) {
            setEmployeeCount(response.data);
        }
    }
    const getBusinessCategoryValue = (id) => {
        const result = employeeCount.find(x => x.id == id);
        return result?.name;
    }

    const getCompanyData = async () => {
        const response = await api.getData(`api/customerprofile/${register.user_id}/register/${register.register_number}/company`);
        //console.log(response);
        if (response.status === true) {
            handleInputChange({ target: { name: 'company_name', value: response.data.company_name } });
            handleInputChange({ target: { name: 'phone_number', value: response.data.company_tel } });
            handleInputChange({ target: { name: 'employee_count_id', value: response.data.employee_count_id } });
        }
    }

    const getUserData = async () => {
        const response = await api.getData(`api/customerprofile/${register.user_id}/register/${register.register_number}/user`)

        if (response.status === true) {
            handleInputChange({ target: { name: 'first_name', value: response.data.first_name } });
            handleInputChange({ target: { name: 'last_name', value: response.data.last_name } });
            handleInputChange({ target: { name: 'email', value: response.data.email } });
        }
    }

    const callContactUs = async () => {
        const request = {
            first_name: input.first_name,
            last_name: input.last_name,
            email: input.email,
            phone_number: input.phone_number,
            job_title: input.job_title,
            company_name: input.company_name,
            employee_count: getBusinessCategoryValue(input.employee_count_id),
            message: input.message
        }

        const response = await api.postData(`api/customerprofile/${register.user_id}/register/${register.register_number}/contact-us`, request);

        return response;
    }

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        setShowToastSuccess(false);
        setShowToastError(false);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        const response = await callContactUs();

        //console.log(response);
        if (response.status === true) {
            setShowToastSuccess(true);
        } else {
            setShowToastError(true);
            setAlert(response.message);
        }

        setLoading(false);
    }

    useEffect(() => {
        getEmployeeCount();
    }, []);

    useEffect(() => {
        getCompanyData();
        getUserData();
    }, [employeeCount])

    return (

        <div className={`${Css2.Login_inner_layout2} w-100`}>
            <h6 className='mb-4'>ส่งข้อความหาเรา</h6>
            <div>
                <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)}>
                    <Row>
                        <Col>
                            <Form.Group className='mb-2'>
                                <Form.Label className='label-title'>First Name<span className='text-danger'>*</span></Form.Label>
                                <Form.Control name={'first_name'} value={input.first_name} placeholder='first name' required={true} onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-2'>
                                <Form.Label className='label-title'>Last Name<span className='text-danger'>*</span></Form.Label>
                                <Form.Control name={'last_name'} value={input.last_name} placeholder='last name' required={true} onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className='mb-2'>
                                <Form.Label className='label-title'>Business Email<span className='text-danger'>*</span></Form.Label>
                                <Form.Control inputMode='email' name={'email'} value={input.email} placeholder='email' required={true} onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-2'>
                                <Form.Label className='label-title'>Phone Number</Form.Label>
                                <Form.Control inputMode='tel' name={'phone_number'} value={input.phone_number} placeholder='phone number' required={true} onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className='mb-2'>
                        <Form.Label className='label-title'>Job Title<span className='text-danger'>*</span></Form.Label>
                        <Form.Control name={'job_title'} value={input.job_title} placeholder='job title' required={true} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label className='label-title'>Company Name<span className='text-danger'>*</span></Form.Label>
                        <Form.Control name={'company_name'} value={input.company_name} placeholder='company name' required={true} onChange={handleInputChange} />
                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label className='label-title'>Number of Employees<span className='text-danger'>*</span></Form.Label>
                        <Col>
                            {
                                employeeCount.map((obj, index) => (
                                    <Form.Check type="radio" inline
                                        key={index}
                                        name={"employee_count_id"}
                                        id={'employee_count_id' + obj.id}
                                        label={obj.name}
                                        defaultChecked={obj.id == input?.employee_count_id}
                                        value={obj.id}
                                        required={true}
                                        onChange={handleInputChange}
                                    />
                                ))
                            }
                        </Col>

                    </Form.Group>
                    <Form.Group className='mb-2'>
                        <Form.Label className='label-title'>Message</Form.Label>
                        <Form.Control as="textarea" rows={3} name={'message'} value={input.message} placeholder='message' required={true} onChange={handleInputChange} />
                    </Form.Group>
                    <br />
                    <div className="d-grid gap-2">
                        <button type={'submit'} className='btn btn-primary' disabled={loading} >
                            {
                                loading === true &&
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            }
                            ส่งข้อความ
                        </button>
                    </div>
                    {
                        alert != '' &&
                        <div className="alert alert-danger" role="alert">
                            {alert}
                        </div>
                    }
                </Form>
            </div>

            <ToastSuccess show={showToastSuccess} closeFc={setShowToastSuccess} message='ระบบได้ส่งข้อความแล้ว' />
            <ToastError show={showToastError} closeFc={setShowToastError} message={'เกิดข้อผิดพลาด!!'} />
        </div>
    );
}