import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import QuontationStatus1 from "./QuontationStatus1";
import Css from 'components/pages/customer/Customer.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import ProfilePayee from "./ProfilePayee";
import { ToastError, ToastSuccess } from 'components/MyControl';
import UtilClass from "api_ref/UtilClass";

export default function CustomerProfilePayee() {
    const api = new ApiConection();
    const util = new UtilClass();

    const registerSession = util.getSessionStorage('register');

    const [payee, setPayee] = useState(undefined);
    const [alert, setAlert] = useState('');
    const [showToastSuccess, setShowToastSuccess] = useState(false);
    const [showToastError, setShowToastError] = useState(false);

    const getData = async () => {
        const response = await api.getData(`api/customerprofile/${registerSession.user_id}/register/${registerSession.register_number}/payee`);
        //console.log(response);
        if (response.status === true) {
            setPayee(response.data);
        }
    }

    const setData = async (Childdata) => {
        let request = {
            payee_name: Childdata.payee_name,
            payee_address: Childdata.payee_address,
            payee_tel: Childdata.payee_tel,
            payee_tax_id: Childdata.payee_tax_id,
            payee_branch: Childdata.payee_branch
        }

        const response = await api.putData(`api/customerprofile/${registerSession.user_id}/register/${registerSession.register_number}/payee`, request);

        if (response.status === false) {
            setAlert(response.message);
            setShowToastError(true);
        }
        else {
            setAlert('');
            await getData();
            setShowToastSuccess(true);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <LayoutCustomerMain role="customer" >
            <QuontationStatus1 />
            <div className={Css.Block_Transparent}><br />
                <h5 className={Css.color1}>ข้อมูลในการออกใบเสร็จ</h5><br />
                <div className={Css.Graph_Group}>
                    {
                        payee !== undefined &&
                        <ProfilePayee
                            data={payee}
                            setDataToParent={setPayee}
                            callSubmitParentData={setData}
                            parentAlert={alert} />
                    }

                </div>
            </div>
            <ToastSuccess show={showToastSuccess} closeFc={setShowToastSuccess} message='ระบบได้บันทึกข้อมูลแล้ว' />
            <ToastError show={showToastError} closeFc={setShowToastError} message={'เกิดข้อผิดพลาด!!'} />
        </LayoutCustomerMain>
    )
}