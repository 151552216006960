import ApiConection from 'api_ref/ApiConection';
import Css from 'components/pages/application/login/Login.module.css';
import { useState, useEffect } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';

export default function ProfileCompany({ data = undefined, callSubmitParentData = undefined, parentAlert = '' }) {
    const api = new ApiConection();

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const [businessTypeData, setBusinessTypeData] = useState([]);
    const getBusinessTypeData = async () => {
        const response = await api.getData(`api/master/business-type`);
        if (response.status == true) {
            setBusinessTypeData(response.data);
        }

        //console.log(businessTypeData);
    }
    const getBusinessTypeValue = (id) => {
        const result = businessTypeData.find(x => x.id == id);
        return result?.name;
    }

    const [businessCategoryData, setBusinessCategoryData] = useState([]);
    const getBusinessCategoryData = async () => {
        const response = await api.getData(`api/master/business-category`);
        if (response.status == true) {
            setBusinessCategoryData(response.data);
        }
    }
    const getBusinessCategoryValue = (id) => {
        const result = businessCategoryData.find(x => x.id == id);
        return result?.name;
    }

    const [employeeCount, setEmployeeCount] = useState([]);
    const getEmployeeCount = async () => {
        const response = await api.getData(`api/master/employee-count`);
        if (response.status == true) {
            setEmployeeCount(response.data);
        }
    }
    const getBusinessEmployeeCountValue = (id) => {
        const result = employeeCount.find(x => x.id == id);
        return result?.name;
    }

    useEffect(() => {
        getBusinessTypeData();
        getBusinessCategoryData();
        getEmployeeCount();
    }, []);

    const [mode, setMode] = useState('view');
    const [simulate, setSimulate] = useState(data);

    const handleSetSimulate = (event) => {
        const { name, value } = event.target;

        setSimulate(prevData => {
            return { ...prevData, [name]: value }
        })
    }

    const handleCancel = (event) => {
        setSimulate(data)
        setMode('view')
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }
        callSubmitParentData(simulate);
        setLoading(false);

        if (parentAlert === '') {
            setMode('view')
        } else {
            setMode('edit')
        }
    }

    return (
        <div className='container m-auto'>
            <Form noValidate validated={validated} onSubmit={event => handleSubmit(event)} >
                <div className='contrainer'>
                    <div className={`${Css.Login_inner_layout2} w-100`}>
                        <h6 className='mb-4'>ข้อมูลบริษัท</h6>
                        <div>
                            <Row className='mb-5'>
                                <Col className='me-3'>
                                    <Form.Group className='mb-2'>
                                        <Form.Label className='label-title'>ชื่อบริษัท</Form.Label>
                                        <Form.Control type={'text'}
                                            plaintext={true}
                                            readOnly={true}
                                            className='border-bottom'
                                            name={'company_name'}
                                            value={simulate?.company_name}
                                            onChange={handleSetSimulate}
                                            placeholder="ชื่อบริษัท" required={true} />
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='label-title'>ที่อยู่</Form.Label>
                                        {
                                            mode === 'view' &&
                                            <Col>
                                                <Form.Label className='d-block pt-2 pb-1 border-bottom'>{simulate?.company_address}</Form.Label>
                                            </Col>
                                        }
                                        {
                                            mode === 'edit' &&
                                            <Form.Control type={'text'}
                                                plaintext={(mode === 'view') ? true : false}
                                                readOnly={(mode === 'view') ? true : false}
                                                className='border-bottom'
                                                name={'company_address'}
                                                value={simulate?.company_address}
                                                onChange={handleSetSimulate}
                                                placeholder="ชื่อบริษัท" required={true}
                                                as="textarea" rows={3} />
                                        }
                                    </Form.Group>
                                    <Form.Group className='mb-2'>
                                        <Form.Label className='label-title'>หมายเลขโทรศัพท์</Form.Label>
                                        <Form.Control type={'tel'}
                                            plaintext={(mode === 'view') ? true : false}
                                            readOnly={(mode === 'view') ? true : false}
                                            className='border-bottom'
                                            name={'company_tel'}
                                            value={simulate?.company_tel}
                                            placeholder="00-000-0000" required={true}
                                            onChange={handleSetSimulate} />
                                    </Form.Group>
                                    <Form.Group className='mb-2'>
                                        <Form.Label className='label-title'>เลขประจำตัวผู้เสียภาษีอากร</Form.Label>
                                        <Form.Control type={'text'}
                                            plaintext={(mode === 'view') ? true : false}
                                            readOnly={(mode === 'view') ? true : false}
                                            className='border-bottom'
                                            name={'tax_id'}
                                            value={simulate?.tax_id}
                                            placeholder="0000000000000" required={true}
                                            maxLength={13} pattern="[0-9]{13}"
                                            onChange={handleSetSimulate} />
                                        <Form.Control.Feedback type={'invalid'}>รูปแบบเลขประจำตัวผู้เสียภาษีไม่ถูกต้อง</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className='mb-2'>
                                        <Form.Label className='label-title'>ที่อยู่ไซต์ (Site URL)</Form.Label>
                                        <Form.Control type={'text'} plaintext readOnly
                                            className='border-bottom'
                                            placeholder="Site URL"
                                            value={simulate?.full_site_url} />
                                    </Form.Group>
                                </Col>
                                <Col className='ps-4 border-start'>
                                    <Form.Group className='mb-2'  >
                                        <Form.Label className='label-title'>ประเภทธุรกิจ</Form.Label>
                                        {
                                            mode === 'view' &&
                                            <Form.Label className='d-block pt-2 pb-1 border-bottom'>{getBusinessTypeValue(simulate?.business_type_id)}</Form.Label>
                                        }
                                        {
                                            mode === 'edit' &&
                                            <Form.Select name={'business_type_id'}
                                                value={simulate?.business_type_id}
                                                required={true}
                                                placeholder="0000000000000"
                                                onChange={handleSetSimulate} >
                                                <option value={''} >-- กรุณาเลือก --</option>
                                                {
                                                    businessTypeData.map((obj, index) => {
                                                        return (
                                                            <option key={index} value={obj.id} >{obj.name}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        }

                                    </Form.Group>
                                    <Form.Group className='mb-2 mt-3'  >
                                        <Form.Label className='label-title'>ลักษณะธุรกิจ</Form.Label>
                                        {
                                            mode === 'view' &&
                                            <Form.Label className='d-block pt-2 pb-1 border-bottom'>{getBusinessCategoryValue(simulate?.business_category_id)} </Form.Label>
                                        }
                                        {
                                            mode === 'edit' &&
                                            <Form.Select name={'business_category_id'}
                                                value={simulate?.business_category_id}
                                                required={true}
                                                onChange={handleSetSimulate}
                                            >
                                                <option value={''} >-- กรุณาเลือก --</option>
                                                {
                                                    businessCategoryData.map((obj, index) => {
                                                        return (
                                                            <option key={index} value={obj.id} >{obj.name}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        }

                                        {
                                            (simulate?.business_category_id == 99 && mode === 'view') &&
                                            (<Form.Label> : {simulate?.business_category_name}</Form.Label>)
                                        }
                                        {
                                            (simulate?.business_category_id == 99 && mode === 'edit') &&
                                            (<Form.Control as={'textarea'} className='mt-1' rows={3} placeholder="โปรดระบุ" name={'business_category_name'}
                                                value={simulate?.business_category_name}
                                                required={true}
                                                onChange={handleSetSimulate} />)
                                        }
                                    </Form.Group>

                                    <Form.Group className='mb-3 mt-3' >
                                        <Form.Label className='label-title'>จำนวนพนักงาน</Form.Label><br />
                                        {
                                            mode === 'view' &&
                                            <Form.Label className='d-block pt-2 pb-1 border-bottom'>{getBusinessEmployeeCountValue(simulate?.employee_count_id)}</Form.Label>
                                        }
                                        {
                                            mode === 'edit' &&
                                            employeeCount.map((obj, index) => (
                                                <Form.Check type="radio" inline
                                                    key={index}
                                                    name={"employee_count_id"}
                                                    id={'employee_count_id' + obj.id}
                                                    label={obj.name}
                                                    defaultChecked={obj.id == simulate?.employee_count_id}
                                                    value={obj.id}
                                                    required={true}
                                                    onChange={handleSetSimulate}
                                                />
                                            ))
                                        }
                                    </Form.Group>

                                    <Form.Group className='mb-2 mt-3' >
                                        <Form.Label className='label-title' >รายละเอียดอื่นๆ</Form.Label>
                                        {
                                            mode === 'view' &&
                                            <Form.Label className='d-block pt-2 pb-1 border-bottom'>{simulate?.description}</Form.Label>
                                        }
                                        {
                                            mode === 'edit' &&
                                            <Form.Control as={'textarea'} rows={3}
                                                name={'description'}
                                                value={simulate?.description}
                                                placeholder="ข้อมูลอื่นๆ เพิ่มเติม"
                                                onChange={handleSetSimulate} />
                                        }
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                alert != '' &&
                                <div className="alert alert-danger" role="alert">
                                    A simple danger alert—check it out!
                                </div>
                            }

                            {
                                mode === 'edit' &&
                                <Row>
                                    <Col>
                                        <Button variant="outline-secondary" type="button" className='ps-5 pe-5 me-1'
                                            onClick={event => handleCancel(event)}  >ยกเลิก</Button>
                                        <Button variant="primary" type="submit" className='ps-5 pe-5'
                                            disabled={loading}
                                        >บันทึก
                                            {
                                                loading === true &&
                                                <div className="spinner-border spinner-border-sm text-light ms-2" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            }
                            {
                                mode === 'view' &&
                                <Button variant="outline-secondary" type="button" className='ps-5 pe-5'
                                    onClick={event => setMode('edit')}>
                                    ขอเปลี่ยนข้อมูล
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}