import Footer4Bottom from "./Foot4Bottom";
import Css from './Layout.module.css'
import { useNavigate } from "react-router-dom";
import PolicyCookie from "components/pages/application/PolicyCookie";
import ScrollBackToTop from "components/pages/manual/ScrollBackToTop";
import HeadRegister from "./HeadRegister";
import LandingPageHeadMenu from "./LandingPageHeadMenu";
import UtilClass from "api_ref/UtilClass";
import ApiConection from "api_ref/ApiConection";
import { useEffect, useState } from "react";

export default function Layout4Register({ children, middle = undefined }) {
    const nav = useNavigate();

    const util = new UtilClass();
    const api = new ApiConection();
    const [loginSecurity, setLoginSecurity] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (util.getSessionStorage('_8086') === null || util.getSessionStorage('_8086') === undefined) {
                await api.loginLandingPage();
                setLoginSecurity(true);
            } else {
                setLoginSecurity(true);
            }
        }

        fetchData().catch(console.error)
    }, [])
    const getMiddleClass = () => {
        if (middle === undefined) return '';
        return Css.Content_center;
    }

    const handleHomePage = () => {
        nav('/');
    }

    return (
        <>
            {
                loginSecurity &&
                <>
                    <LandingPageHeadMenu />
                    <div className={Css.Main_Content_Register + ' ' + getMiddleClass() + ' fade-in'}>
                        {children}
                    </div>
                    <Footer4Bottom />
                    <ScrollBackToTop />
                    <PolicyCookie />
                </>
            }

        </>
    );
}