import { useNavigate } from "react-router-dom";
import Css from 'components/pages/customer/Customer.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import ATSApiConnection from "api_ref/ATSApiConnection";
import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import QuontationStatus1 from "./QuontationStatus1";
import Paymentcom2 from "./CustomerPaymentYesHave";
import UtilClass from "api_ref/UtilClass";

export default function PaymentBill() {
    const api = new ApiConection();
    const util = new UtilClass();
    const apiATS = new ATSApiConnection();

    const customer = util.getSessionStorage('customer');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [Register, setRegister] = useState({});
    const [Quontation, setQuontation] = useState({});
    const [currentUsed, setCurrentUsed] = useState({
        status: '',
        totalUserActive: undefined,
        totalFileSizeByte: undefined,
    });
    const [currentFile, setCurrentFile] = useState({
        status: '',
        totalFileCount: undefined,
        totalFileSizeByte: undefined,
        listFileExtension: []
    });

    const getRegister = async () => {
        const response = await api.getData(`api/register/customer/${customer.user_id}`);

        if (response.status === false)
            navigate('/customer/register');
        else {
            setRegister(response.data[0]);
            util.setSessionStorage('register', response.data[0]);
        }
    }

    const getQuonation = async () => {
        const response = await api.getData(`api/quontation/register/${Register.register_number}`);

        if (response.status === true) {
            setQuontation(response.data);
            util.setSessionStorage('quontation', response.data);
        }
    }

    const getCurrentUsed = async () => {
        if (Register.site_url == undefined) return;
        let data = {
            subDomain: Register.site_url
        }
        const response = await apiATS.postData(`api/CurrentUsed`, data);
        if (response.status == 'completed') {
            setCurrentUsed(response);
        }
    }

    const getCurrentFile = async () => {
        if (Register.site_url == undefined) return;
        let data = {
            subDomain: Register.site_url
        }
        const response = await apiATS.postData(`api/CurrentFile`, data);
        if (response.status == 'completed') {
            setCurrentFile(response);
        }
    }

    useEffect(() => {
        getRegister();
    }, []);
    useEffect(() => {
        getQuonation();
        getCurrentUsed();
        getCurrentFile();
    }, [Register]);

    return (
        Register &&
        Quontation &&
        <LayoutCustomerMain role="customer" isApproved={Quontation.is_approved}>
            <QuontationStatus1 register_number={Register.register_number} />
            {Quontation != undefined &&
                <div className={Css.Block_Transparent}><br />
                    <h5 className={Css.color1}>ชำระค่าบริการ</h5><br />
                    <div className={Css.Graph_Group}>
                        <Paymentcom2 />
                        {/* currentUsed.totalFileSizeByte */
                        }
                        {/* currentUsed.totalUserActive */
                        }
                    </div>

                </div>
            }
        </LayoutCustomerMain>
    )
}