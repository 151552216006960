import Layout4Register from "components/layout/Layout4Register";
import Css from "./Register.module.css";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RegisterCustomerStep1 from "./RegisterCustomerStep1p2";
//import RegisterCustomerStep2 from "./RegisterCustomerStep2";
import RegisterCustomerStep3 from "./RegisterCustomerStep3";
//import RegisterCustomerStep4 from "./RegisterCustomerStep4";
import RegisterCustomerStep6 from "./RegisterCustomerStep6";
import ApiConection from "api_ref/ApiConection";
//import UtilClass from "api_ref/UtilClass";
import HeadRegister from "components/layout/HeadRegister";
import RegisterCustomerStep2 from "./RegisterCustomerStep2p1";
import RegisterCustomerStep3v2 from "./RegisterCustomerStep3v2";
import RegisterCustomerStep2p1 from "./RegisterCustomerStep2p1";
import UtilClass from "api_ref/UtilClass";
import RegisterCustomerStep2p2 from "./RegisterCustomerStep2p2";
import RegisterCustomerStep1p1 from "./RegisterCustomerStep1p1";
import RegisterCustomerStep1p2 from "./RegisterCustomerStep1p2";
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";

const arr_images_step = [
    { seq: 1, name: "สร้างเว็บไซต์", img: "Group 2297.png" },
    { seq: 2, name: "แพ็กเกจ", img: "Group 2332.png" },
    { seq: 3, name: "สำเร็จ", img: "Group 2311.png" },
];
export default function RegisterCustomer() {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage("customer");

    const nav = useNavigate();

    const [step, setStep] = useState(0);
    const [alert, setAlert] = useState("");
    const [changeCount, setChangeCound] = useState(0);

    const [registerData, SetRegisterData] = useState({
        id: null,
        register_number: "",
        company_name: "",
        company_address: "",
        company_tel: "",
        tax_id: "",
        site_url: "",
        business_type_id: undefined,
        business_type_name: "",
        business_category_id: undefined,
        business_category_name: "",
        employee_count_id: undefined,
        description: "",

        package_master_period_id: util.getCookie('period_id') ?? undefined,
        package_master_period_name: "",

        arr_package: [
            {
                package_master_id: util.getCookie('package_id') ?? undefined,
                package_master_name: "",
                package_master_name_short: "",
                package_master_users_volume_id: 1,
                package_master_storage_volume_id: 1,
                package_type: 'free',
                net: 0,
                vat: 0,
                tax: 0,
                total: 0,
            },
        ],

        is_addon_user: false,
        is_addon_storage: false,

        discount_code: "",
        is_with_holding_tax: false,

        is_pay_now: true,
        payment_method_id: 1,
        paid_bank: '',
        payee_name: "",
        payee_address: "",
        payee_tel: "",
        payee_tax_id: "",
        payee_branch: "สำนักงานใหญ่",
        paid_file_slip: null,// input file slip
        slip_file_id: null,

        is_accept_agreement: false,
    });

    const handleChangeRegisterData = (event) => {
        const { name, value, type, checked, files } = event.target;

        if (event.target.setCustomValidity) {
            event.target?.setCustomValidity("");
        }

        if (type === 'file') {
            SetRegisterData(prevData => {
                return { ...prevData, [name]: (files ? files[0] : undefined || value) }
            })
        } else if (type === 'checkbox') {
            SetRegisterData((prevData) => {
                return { ...prevData, [name]: checked };
            });
        } else {
            SetRegisterData((prevData) => {
                return { ...prevData, [name]: value };
            });
        }

        setChangeCound(changeCount + 1);
        //console.log(registerData);
    };

    const handleSetRegister = (name, value) => {
        SetRegisterData((prevData) => {
            return { ...prevData, [name]: value };
        });
    };

    const handleHomePage = () => {
        nav("/");
    };

    const handleChangeStep = (i_step) => {
        setAlert('');
        setStep(i_step);
    };

    const handleGotoStep2 = async (Childdata) => {
        // eslint-disable-next-line
        let data = registerData;

        data.payee_name = registerData.company_name;
        data.payee_address = registerData.company_address;
        data.payee_tel = registerData.company_tel;
        data.payee_tax_id = registerData.tax_id;
        data.payee_branch = registerData.payee_branch;

        SetRegisterData(data);

        //-------------------------------------------

        const request = {
            company_name: registerData.company_name,
            address: registerData.company_address,
            company_tel: registerData.company_tel,
            is_tax_registry: false,
            site_url: registerData.site_url,
            tax_id: registerData.tax_id,
            business_type_id: registerData.business_type_id,
            business_category_id: registerData.business_category_id,
            business_category_name: registerData.business_category_name,
            employee_count_id: registerData.employee_count_id,
            payee_name: registerData.company_name,
            payee_address: registerData.company_address,
            payee_tel: registerData.company_tel,
            payee_tax_id: registerData.tax_id,
            payee_branch: registerData.payee_branch,
            active: true,
        };

        //debugger;
        if (registerData.register_number !== '') {
            const response = await api.putData(`api/register/${registerData.register_number}/customer/${customer.user_id}`, request);
            if (response.status === true) {
                setStep(2);
            } else {
                setAlert(response.message);
            }
        } else {
            const response = await api.postData(
                `api/register/customer/${customer.user_id}`,
                request
            );

            if (response.status === true) {
                let tmpData = registerData;
                tmpData.id = response.data.id;
                tmpData.register_number = response.data.register_number;
                tmpData.full_site_url = response.data.full_site_url;

                SetRegisterData(tmpData);

                setStep(2);
                setAlert('');
            } else {
                setAlert(response.message);
            }
        }
    };

    const handleGotoStep3 = async (submitData) => {
        setAlert('');
        let arr_quontations = []
        submitData.arr_package.forEach(obj => {
            arr_quontations.push({
                package_master_id: obj.package_master_id,
                package_master_period_id: submitData.package_master_period_id,
                package_master_users_volume_id: obj.package_master_users_volume_id,
                package_master_storage_volume_id: obj.package_master_storage_volume_id
            })
        })
        const request = {
            arr_quontations: arr_quontations,
            discount_code: submitData.discount_code,
            is_with_holding_tax: submitData.is_with_holding_tax,
            is_pay_now: false,
            payee: {
                is_issue_tax_invoice: false,
                payee_name: submitData.payee_name,
                payee_address: submitData.payee_address,
                payee_tel: submitData.payee_tel,
                payee_tax_id: submitData.payee_tax_id,
                payee_branch: submitData.payee_branch
            },
            is_accept_agreement: submitData.is_accept_agreement
        }

        if ((parseInt(util.getCookie('package_id') ?? 1)) === 1) {
            request.is_accept_agreement = true;
        }

        //console.log('request=',request);
        const response = await api.postData(
            `api/quontation/register/${submitData.register_number}/auto-approved/true`,
            request
        );

        if (response.status === true) {
            const workflow_data = {
                quontation_number: response.data.quontation_number,
                payment_status_id: response.data.payment_status_id
            }

            await api.postData(`api/workflow`, workflow_data);

            setStep(3);
            setAlert('');
        } else {
            setAlert(response.message);
        }
    };
    /*
      const handleGotoStep4 = async () => {
        if(registerData.payee_name == undefined || registerData.payee_name == ''){
          handleSetRegister('payee_name',registerData.company_name);
          handleSetRegister('payee_address',registerData.company_address);
          handleSetRegister('payee_tel',registerData.company_tel);
          handleSetRegister('payee_tax_id',registerData.tax_id);
          handleSetRegister('payee_branch',"สำนักงานใหญ่");
        }
        setStep(4);
        setAlert('');
      };

    */
    const getStepSelectClass = (seq) => {
        return seq === step ? Css.active : "";
    };

    const getData = async () => {
        // eslint-disable-next-line

        const responseRegister = await api.getData(
            `api/register/customer/${customer.user_id}`
        );
        //console.log(responseRegister);
        if (responseRegister.status === true) {
            let data = registerData;

            data.id = responseRegister.data[0].id;
            data.register_number = responseRegister.data[0].register_number;
            data.company_name = responseRegister.data[0].company_name;
            data.company_address = responseRegister.data[0].company_address;
            data.company_tel = responseRegister.data[0].company_tel;
            data.tax_id = responseRegister.data[0].tax_id;
            data.site_url = responseRegister.data[0].site_url;
            data.business_type_id = responseRegister.data[0].business_type_id;
            data.business_type_name = responseRegister.data[0].business_type_name;
            data.business_category_id = responseRegister.data[0].business_category_id;
            data.business_category_name = responseRegister.data[0].business_category_name;
            data.employee_count_id = responseRegister.data[0].employee_count_id;
            data.description = responseRegister.data[0].description;

            data.payee_name = responseRegister.data[0].payee_name;
            data.payee_address = responseRegister.data[0].payee_address;
            data.payee_tel = responseRegister.data[0].payee_tel;
            data.payee_tax_id = responseRegister.data[0].payee_tax_id;
            data.payee_branch = "สำนักงานใหญ่";

            SetRegisterData(data);

            const responQuon = await api.getData(`api/quontation/register/${responseRegister.data[0].register_number}`);
            if (responQuon.status === true) {
                nav("/");
            }
            setStep(1);
        } else {
            setStep(1);
        }
    };

    useEffect(() => {
        if (customer?.user_id !== undefined)
            getData();
        else
            setStep(1);
        //console.log(registerData);
        // eslint-disable-next-line
    }, [customer.user_id]);

    return (
        <LayoutLandingPageV2 middle showContactUs={false}>
            <div className={`${Css.Register_Step_Layout} mb-5`}>
                <div className={Css.Controller}>
                    <div className={Css.Nav_step_V2}>
                        <ul className={Css.Progress_Bar}>
                            {arr_images_step.map((obj, index) => (
                                <li key={index} className={getStepSelectClass(obj.seq)}>
                                    <span>{obj.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={Css.Content}>
                        {step === 1 && (parseInt(util.getCookie('package_id') ?? 1)) === 1 && (
                            <RegisterCustomerStep1p1
                                data={registerData}
                                onChangeData={handleChangeRegisterData}
                                onHandleSubmit={handleGotoStep2}
                            />
                        )}
                        {step === 1 && (parseInt(util.getCookie('package_id') ?? 1)) !== 1 && (
                            <RegisterCustomerStep1p2
                                data={registerData}
                                onChangeData={handleChangeRegisterData}
                                onHandleSubmit={handleGotoStep2}
                            />
                        )}
                        {step === 2 && (parseInt(util.getCookie('package_id') ?? 1)) === 1 && (
                            <RegisterCustomerStep2p1
                                data={registerData}
                                onChangeData={handleChangeRegisterData}
                                SetRegisterData={SetRegisterData}
                                onChangeStep={handleChangeStep}
                                onHandleSubmit={handleGotoStep3}
                                error={alert}
                            />
                        )}
                        {step === 2 && (parseInt(util.getCookie('package_id') ?? 1)) !== 1 &&
                            <RegisterCustomerStep2p2
                                data={registerData}
                                onChangeData={handleChangeRegisterData}
                                onHandleSubmit={handleGotoStep3}
                                error={alert}
                                SetRegisterData={SetRegisterData}
                                onChangeStep={handleChangeStep} />
                        }
                        {step === 3 && (
                            <RegisterCustomerStep3v2
                                data={registerData}
                                onChangeData={handleChangeRegisterData}
                                onHandleSubmit={handleGotoStep2}
                            />
                        )}
                    </div>

                </div>
                <div className={Css.Image}>
                    {
                        step === 1 &&
                        <img src={require('images/pexels-andrea-01.png')} alt={''} />
                    }
                    {
                        step === 2 && (parseInt(util.getCookie('package_id') ?? 1)) === 1 &&
                        <img src={require('images/pexels-karolina-01.png')} alt={''} />
                    }
                    {
                        step === 2 && (parseInt(util.getCookie('package_id') ?? 1)) !== 1 &&
                        <img src={require('images/pexels-fauxels-01.png')} alt={''} />
                    }
                    {
                        step === 3 &&
                        <img src={require('images/pexels-sora-shimazaki-01.png')} alt={''} />
                    }
                </div>

            </div>
        </LayoutLandingPageV2>
    );
    // eslint-disable-next-line
}