import React, { useEffect, useState } from "react"
import Css from './Manual.module.css'
import { Row, Col, Form, Button } from "react-bootstrap";

export default function ManualGeneralPackage() {
    return (
        <div className={Css.imgManaul}>
            <h3 className="mb-4">ซื้อแพ็กเกจทั่วไป</h3>
            <label className="mt-0">1. ให้ท่านกดเลือกราคาแพ็กเกจที่หน้าแรกของเว็บ และทำตามขั้นตอนดังนี้</label>
            <div className={Css.subText}>
                <label>1. เลือกระยะเวลาที่ต้องการจะซื้อ โดยมี ราย 3 เดือน, ราย 6 เดือน และ ราย 1 ปี</label>
                <label>2. เลือกแพ็กเกจที่ต้องการซื้อ โดยมี Beginner, Standard และ Professional</label>
            </div>
            <img src={require("images/general1.png")} className='mt-3' />
            <label>2. กรอกรายละเอียดของผู้สมัคร เมื่อกรอกข้อมูลเสร็จเรียบร้อยแล้วให้กด ยืนยัน</label>
            <img src={require("images/logindcaas2.png")} className='mt-3' />
            <label>3. เมื่อกดยืนยันเป็นที่เรียบร้อยแล้วก็จะแสดงว่า ลงทะเบียนเรียบร้อยแล้ว</label>
            <img src={require("images/logindcaas3.png")} className='mt-3' />
            <label>4. จากนั้นให้ไปตรวจสอบเมลที่ได้ทำการสมัครไว้ เพื่อกดยืนยันการสมัคร ซึ่งจะมีให้ท่านเลือก 2 ช่องทางในการยืนยันความถูกต้องของอีเมล</label>
            <img src={require("images/logindcaas4.png")} className='mt-3' />
            <label>5. เมื่อท่านยืนยันการสมัครสำเร็จท่านจะสามารถเข้าสู่ระบบได้ และท่านจะได้รับอีเมลว่าท่านได้ยืนยันการสมัครสมบูรณ์แล้ว</label>
            <img src={require("images/logindcaas5.png")} className='mt-3' />
            <img src={require("images/logindcaas6.png")} className='mt-3' />
            <label>6. ท่านทำการใส่อีเมล และรหัสผ่าน ที่ท่านได้ทำการสมัครสมาชิกเอาไว้</label>
            <img src={require("images/logindcaas7.png")} className='mt-3' />
            <label>7. หลังจากที่เข้าสู่ระบบเรียบร้อยแล้วก็จะมาหน้าการสร้างเว็บไซต์ ซึ่งท่านทำการกรอกรายละเอียดให้ถูกต้องครบถ้วนทุกช่อง และกดดำเนินการต่อ</label>
            <img src={require("images/general2.png")} className='mt-3' />
            <label>8. ท่านสามารถเลือกระยะเวลา แพ็กเกจ และแพ็กเกจเสริมเพิ่มเติมได้ และถ้าหากท่านมีโค้ดส่วนลดสามารถเพิ่มโค้ดส่วนลดได้ตามรูปด้านล่าง</label>
            <img src={require("images/general3.png")} className='mt-3' />
            <label>8. ตรวจสอบรายละเอียดการชำระเงินให้ครบถ้วนถูกต้อง และกดยอมรับเงื่อนไขกับกดปุ่มยืนยัน</label>
            <img src={require("images/general7.png")} className='mt-3' />
            <label>9. เมื่อทำรายการสำเร็จ ท่านจะได้รับอีเมลสรุปรายการคำสั่งซื้อ กดลิงก์ "ที่นี่" เพื่อสมัครเข้าสู่ระบบ ISO DCaaS</label>
            <img src={require("images/general8.png")} className='mt-3' />
            <label>10. นำรหัส OTP ที่ได้จากอีเมลมากรอก กรอกอีเมลและรหัสผ่านเพื่อสมัครระบบ ISO DCaaS </label>
            <img src={require("images/general9.png")} className='mt-3' />
            <label>12. เข้าสู่ระบบของ ISO DCaaS ได้โดยกรอกอีเมลและรหัสผ่านเพื่อเข้าสู่ระบบ</label>
            <img src={require("images/loginiso7.png")} className='mt-3' />
            <label>13. เมื่อทำการเข้าสู่ระบบสำเร็จท่านสามารถเริ่มต้นใช้งานได้หน้า ISO DCaaS </label>
            <img src={require("images/loginiso8.png")} className='mt-3' />
            <label>14. เมื่อท่านต้องการชำระเงิน ให้กดไปที่หน้า ชำระค่าบริการ และกดรายละเอียดเพื่อดูยอดค้างจ่าย</label>
            <img src={require("images/general10.png")} className='mt-3' />
            <label>15. ให้ท่านตรวจสอบยอดเงินที่ต้องชำระให้ถูกต้องก่อนจะกดปุ่มดำเนินงานต่อ</label>
            <img src={require("images/general11.png")} className='mt-3' />
            <label>16. ให้ท่านแนบสลิปการโอนเงินตามยอดชำระที่กำหนด, กรอกธนาคารที่ท่านใช้ในการโอนเงิน, วันที่โอน, เวลาที่โอน และจำนวนเงินที่โอน</label>
            <label className="mt-1">17. ให้ท่านตรวจสอบ ชื่อบริษัท, ที่อยู่, หมายเลขโทรศัพท์, เลขประจำตัวผู้เสียภาษีอากร และ สาขา/รหัสสาขา ให้ถูกต้อง กดยอมรับเงื่อนไขการใช้บริการและนโยบายความเป็นส่วนตัว และกดปุ่มยืนยันชำระค่าบริการ</label>
            <img src={require("images/general12.png")} className='mt-3' />
            <label>18. เมื่อทำรายการสำเร็จสามารถกดเริ่มต้นใช้งาน</label>
            <img src={require("images/general13.png")} className='mt-3' />
            <label>19. เมื่อยืนยันการชำระเงินสำเร็จ ท่านจะได้รับอีเมลสรุปรายการคำสั่งซื้อ</label>
            <img src={require("images/general6.png")} className='mt-3' />
            <label>20. หากท่านต้องการแนบเอกสารหัก ณ ที่จ่าย ให้ท่านไปยังหน้าแนบใบหัก ณ ที่จ่าย และเลือกอัปโหลดไฟล์</label>
            <img src={require("images/general14.png")} className='mt-3' />
            <label>21. เมื่อทำการแนบรูปสำเร็จ ให้กดปุ่มส่งเพื่อทำการแนบรูปให้เจ้าหน้าที่</label>
            <img src={require("images/general15.png")} className='mt-3' />
        </div>
    )
}