import ApiConection from 'api_ref/ApiConection';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import UtilClass from 'api_ref/UtilClass';

export default function QuontationStatus1({ register_number }) {
    const [status, setStatus] = useState('');

    const api = new ApiConection();
    const util = new UtilClass();
    const navigate = useNavigate();

    const register = util.getSessionStorage('register');

    const getRegister = async () => {
        const response = await api.getData(`api/register/${register.register_number}/status`);
        if (response.status === false) {
            navigate('/customer/register');
            return;
        }

        setStatus(response.data);
    }

    useEffect(() => {
        getRegister();
        // eslint-disable-next-line
    }, [])

    /*useEffect(()=>{
        const interval = setInterval(() => {
            getRegister();
          }, 2000);

          return () => clearInterval(interval);
    },[]);*/

    return (
        status !== undefined &&
        status !== '' &&
        <div className={`alert alert-warning m-0 `}>
            {status}
        </div>
    )
}