import cryptoJs from "crypto-js";

class UtilClass{
  constructor(){
		this._secret_pass = process.env.REACT_APP_COOKIE_SECRET;
	}

    setCookie(cname, cvalue, exdays=1) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires="+d.toUTCString();

        const data = cryptoJs.AES.encrypt(
          JSON.stringify(cvalue),
          this._secret_pass
        ).toString();

        document.cookie = cname + "=" + data + ";" + expires + ";path=/";
      }

    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            const cvalue = c.substring(name.length, c.length);

            const bytes = cryptoJs.AES.decrypt(cvalue,this._secret_pass);
            const data = JSON.parse(bytes.toString(cryptoJs.enc.Utf8));

            return data;
          }
        }
        return undefined;
      }

    checkID(id){
      if (id.length !== 13) {
        return false;
      }

      // ตรวจสอบว่าเป็นตัวเลขทั้งหมดหรือไม่
      if (!/^\d+$/.test(id)) {
        return false;
      }

      // ตรวจสอบตัวเลข checksum
      let sum = 0;
      for (let i = 0; i < 12; i++) {
        sum += parseInt(id.charAt(i), 10) * (13 - i);
      }
      if ((11 - sum % 11) % 10 !== parseInt(id.charAt(12), 10)) {
        return false;
      }

      return true;
    }

    setSessionStorage(cname,cvalue){
      const data = cryptoJs.AES.encrypt(
          JSON.stringify(cvalue),
          this._secret_pass
        ).toString();

        window.sessionStorage.setItem(cname,data);
    }

    getSessionStorage(cname){
      try{
        const cvalue = window.sessionStorage.getItem(cname);

        if(cvalue === undefined || cvalue === null) return undefined;

        const bytes = cryptoJs.AES.decrypt(cvalue,this._secret_pass);

        const data = JSON.parse(bytes.toString(cryptoJs.enc.Utf8));

        return data;
      }catch(e){
        window.sessionStorage.removeItem(cname);
        window.location.href = process.env.PUBLIC_URL;
      }
    }

    setLocalStorage(cname,cvalue){
      const data = cryptoJs.AES.encrypt(
          JSON.stringify(cvalue),
          this._secret_pass
        ).toString();

        window.localStorage.setItem(cname,data);
    }

    getLocalStorage(cname){
      try{
        const cvalue = window.localStorage.getItem(cname);

        if(cvalue === undefined || cvalue === null) return undefined;

        const bytes = cryptoJs.AES.decrypt(cvalue,this._secret_pass);

        const data = JSON.parse(bytes.toString(cryptoJs.enc.Utf8));

        return data;
      }catch(e){
        window.localStorage.removeItem(cname);
        window.location.href = process.env.PUBLIC_URL;
      }
    }
}

export default UtilClass;