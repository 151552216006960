import Css from 'components/pages/application/login/Login.module.css';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ApiConection from 'api_ref/ApiConection';
import { useEffect, useState } from "react";

export default function CustomerPaymentNoHave() {
    const api = new ApiConection();
    const navigate = useNavigate();
    const { id } = useParams();

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const handleHomePage = () => {
        navigate('/');
    }
    const handleLoginPage = () => {
        navigate('/customer/service-transaction');
    }

    return (
        <div className={Css.Login_outer_layout}><br />
            <div className={`${Css.Login_inner_layout3} ${Css.pad}`}>
                <Form noValidate validated={validated} onSubmit={handleLoginPage} >
                    <h6 className={Css.color}>ไม่มียอดค้างชำระ</h6><br />
                    <div className={Css.body2}>
                        <h6 className={Css.fontcolor1}>ยังไม่มีใบแจ้งค่าบริการ</h6>
                    </div>
                    <div className='pt-1'>
                        <Button type={'submit'} className={Css.btn2}>
                            ดูประวัติการชำระเงิน
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}