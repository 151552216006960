import React, { useEffect, useState } from "react";
import Css from './Mockup.module.css'
import { useParams } from "react-router-dom";
import { Link, useNavigate} from "react-router-dom";
import ApiConection from "api_ref/ApiConection";
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { ConvertDateToText} from "components/MyControl";
import { Breadcrumb } from "react-bootstrap";


export default function ArticleDetail(){
  const nav = useNavigate();
  const { id } = useParams();
  const api = new ApiConection();
  const [articleDetail, setArticleDetail] = useState({});
  const [photo, setPhoto] = useState(undefined);


  const getArticleDetail = async () => {
    const response = await api.callLandingPageGet(`api/BlogManagement/${id}`);
    if (response.status === true) {
        const response_photo = await getSlipFile(response.data.image_id);
        const imageUrl = (response_photo !== undefined) ? URL.createObjectURL(response_photo) : undefined;
        
        setArticleDetail({ ...response.data, imageUrl });
    }
};

  const getSlipFile = async (id) => {
    if (id !== null && id !== undefined) {
        return await api.callLandingPageGetFile(`api/file/${id}/preview`);
    } else {
        return undefined;
    }
  }
  const handleBreadcrumbClick = (e) => {
    nav('/article');
  };

  useEffect(() => {
    getArticleDetail();
  }, [id]);

  return (
    <LayoutLandingPageV2 showContactUs={false}>
        <div className="container m-auto mt-3 mb-3 p-5">
            <Breadcrumb>
                <Breadcrumb.Item onClick={handleBreadcrumbClick}>จัดการบทความ</Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ whiteSpace: 'nowrap' }}>
                        {
                            <div>{articleDetail.title}</div>
                        }
                    </Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ textAlign: 'center'}} className="container text-center mt-5">
                <div className={`${Css.outline_layout} container  text-center`}>
                   <h1 >{articleDetail.title}</h1>
                </div>
                {articleDetail?.imageUrl !== undefined && (
                    <img
                        src={articleDetail?.imageUrl}
                        width="100%"
                        alt="Article Image"
                        className="mt-5 mb-5"
                    />
                )}
            </div>
            <div className="col">   
                <div id="article_id" style={{ padding: '20px'}}  dangerouslySetInnerHTML={{ __html: articleDetail?.blog_body?.replace(/  /gi,'&nbsp;') }} />
            </div>
            <div className="pt-5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <p className="card-text"><small className="text-muted">เขียนโดย : {articleDetail.written_by}</small></p>
                <p className="card-text" style={{ marginTop: '-15px' }}><small className="text-muted"><FontAwesomeIcon icon={faClock} /> {ConvertDateToText(articleDetail.release_date)}</small></p>
            </div>
        </div>
    </LayoutLandingPageV2>
  );
};