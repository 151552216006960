import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import UtilClass from "api_ref/UtilClass";

export default function Condition() {
    const api = new ApiConection();
    const utilCls = new UtilClass();

    const navigate = useNavigate();

    const customer = utilCls.getSessionStorage('customer');

    const getRegister = async () => {
        const responseRegister = await api.getData(`api/register/customer/${customer.user_id}`);
        if (responseRegister.status === false) {
            navigate('/customer/register');
            return;
        }

        utilCls.setSessionStorage('register', responseRegister.data[0]);

        const responseQuon = await api.getData(`api/quontation/register/${responseRegister.data[0].register_number}`);

        if (responseQuon.status === false)
            navigate('/customer/register');
        else
            navigate('/customer/home');
    }

    useEffect(() => {
        const fetchData = async () => {
            await getRegister();
        }

        fetchData();
    }, []);

    return (
        <></>
    );
}