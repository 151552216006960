import { useEffect, useState } from "react";
import ApiConection from "api_ref/ApiConection";
import RegisterCustomerPayment from "components/pages/application/register/RegisterCustomerPayment";
import { Col, Form, Row } from "react-bootstrap";
import RegisterCustomerSelectPackage from "components/pages/application/register/RegisterCustomerSelectPackage";
import PackageChanged from "./PackageChanged";

export default function CustomerPaymentServiceStep0({
    data = undefined,
    onHandleSubmit = undefined,
    onChangeData = undefined,
    onChangeStep = undefined,
    handleSetResultBill = undefined,
}) {
    const api = new ApiConection();

    const [validated, setValidated] = useState(false);
    const [estimateData, setEstimateData] = useState(
        {
            register_number: data?.register_number,
            package_master_period_id: data?.package_master_period_id,
            package_master_period_name: data?.package_master_period_name,
            package_master_name: data?.package_master_name,
            user_volume: data?.user_volume + ' A/C',
            storage_volume: data?.storage_volume + ' GB',
            arr_package: (data?.package_type !== 'free') ? data?.arr_package : [],

            is_addon_user: data?.arr_package?.find(x => x.package_type === 'addon-user') !== undefined,
            is_addon_storage: data?.arr_package?.find(x => x.package_type === 'addon-storage') !== undefined,

            discount_code: '',
            is_with_holding_tax: false,
            net: data?.net,
            vat: data?.vat,
            discount: data?.discount,
            tax: data?.tax,
            total: 0,

            compare: data?.compare,
            is_pay_now: true
        });
    const [calData, setCalData] = useState(undefined);
    const [dataChangeCount, setDataChangeCount] = useState(0);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const handleEstimate = (event) => {
        const { name, value, type, checked } = event.target;

        if (type === 'checkbox') {
            setEstimateData((prevData) => {
                return { ...prevData, [name]: checked };
            });
        } else {
            setEstimateData((prevData) => {
                return { ...prevData, [name]: value };
            });
        }

        setDataChangeCount(dataChangeCount + 1);
    }

    //console.log(data?.arr_package?.find( x => x.package_type == 'addon-storage') !== undefined);

    const checkPrice = async () => {
        if (estimateData.package_master_period_id === undefined) return;

        let arr_quontations = [];
        //debugger;

        //console.log('คำนวณ');
        estimateData.arr_package.forEach(obj => {
            arr_quontations.push(
                {
                    package_master_id: obj.package_master_id,
                    package_master_period_id: estimateData.package_master_period_id,
                    package_master_users_volume_id: obj.package_master_users_volume_id,
                    package_master_storage_volume_id: obj.package_master_storage_volume_id
                });
        });

        let requests = {
            arr_quontations: arr_quontations,
            discount_code: estimateData.discount_code,
            is_with_holding_tax: estimateData.is_with_holding_tax,
            is_pay_now: true,
            is_accept_agreement: true
        }

        const response = await api.postData(`api/quontation/register/${data.register_number}/upgrade-package/price-check`, requests)

        let tmpData = estimateData;

        tmpData.discount = Number(response.data.discount).toFixed(2);
        tmpData.tax = Number(response.data.tax).toFixed(2);
        tmpData.total = Number(response.data.total).toFixed(2);
        tmpData.compare = response.data.compare;

        setEstimateData(tmpData);
        setCalData(tmpData);
    }

    useEffect(() => {
        if (estimateData?.package_master_period_id !== undefined &&
            estimateData?.arr_package[0]?.package_master_id !== undefined) {
            const delay = setTimeout(() => {
                // เรียกใช้ฟังก์ชันสำหรับการคำนวณผลลัพธ์ที่ต้องการ
                checkPrice();
            }, 100);

            return () => clearTimeout(delay);
        }
    }, [dataChangeCount]);

    const handleThisSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        let tmpData = data;

        tmpData.package_master_period_id = estimateData.package_master_period_id;
        tmpData.package_master_period_name = estimateData.package_master_period_name;
        tmpData.package_master_name = estimateData.package_master_name;
        tmpData.user_volume = estimateData.user_volume;
        tmpData.storage_volume = estimateData.storage_volume;
        tmpData.arr_package = estimateData.arr_package;
        tmpData.is_addon_user = estimateData.is_addon_user;
        tmpData.is_addon_storage = estimateData.is_addon_storage;
        tmpData.discount_code = estimateData.discount_code;
        tmpData.is_with_holding_tax = estimateData.is_with_holding_tax;
        tmpData.net = estimateData.net;
        tmpData.vat = estimateData.vat;
        tmpData.discount = estimateData.discount;
        tmpData.tax = estimateData.tax;
        tmpData.total = estimateData.total;
        tmpData.grand_total = estimateData.grand_total;
        tmpData.grand_total_sub_withholding_tax = estimateData.grand_total_sub_withholding_tax;
        tmpData.is_pay_now = estimateData.is_pay_now;
        tmpData.compare = estimateData.compare;

        handleSetResultBill(tmpData);

        setLoading(false);

        onHandleSubmit();
    }

    return (
        estimateData !== undefined && (
            <div className="container w-1000px">
                <Form noValidate validated={validated} onSubmit={event => handleThisSubmit(event)} >
                    <Row>
                        <Col sm={7}>
                            <RegisterCustomerSelectPackage
                                data={estimateData}
                                mode={'upgrade'}
                                onChangeData={handleEstimate}
                                onChangeStep={onChangeStep}
                                className='w-auto' />
                        </Col>
                        <Col>
                            <PackageChanged data={estimateData} />
                            <RegisterCustomerPayment className='w-auto'
                                data={estimateData}
                                onChangeData={handleEstimate} />
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    );
}