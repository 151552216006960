import Css from './Mockup.module.css'
import { Row, Col, Carousel } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react"

const arr_feature = [
    { image: 'icon1.png', title: 'ISO DCaaS สามารถผสานรวมกันกับระบบและเครื่องมือที่องค์กรคุณใช้อยู่ได้อย่างราบรื่น โดยไม่สร้างการรบกวนให้กับกระบวนการปัจจุบัน พร้อมการอัปเดตเวอร์ชั่นที่ส่งถึงผู้ใช้งานโดยอัตโนมัติ ช่วยลดภาระงานด้านไอที' },
    { image: 'icon2.png', title: 'ระบบคลาวด์ที่มีความยืดหยุ่นในการปรับขยายขนาดให้เหมาะสมกับความต้องการของ องค์กรทุกขนาด จึงช่วยลดความจำเป็นในการใช้งานและบำรุงรักษาเชิร์ฟเวอร์ที่มีราคาสูง ทำให้องค์กรของคุณสามารถประหยัดต้นทุนได้อย่างมีประสิทธิภาพ' },
    { image: 'icon3.png', title: 'มาตรการรักษาความปลอดภัยและผู้ให้บริการคลาวด์ที่ได้มาตรฐานของ ISO DCaaS ระบบจะมีการเข้ารหัสเพื่อป้องกันการเข้าถึงข้อมูลโดยไม่ได้รับอนุญาต และทำการสำรองข้อมูลอย่างสม่ำเสมอเพื่อป้องกันการสูญหายหรือเสียหายของข้อมูล ให้องค์กร ของคุณมั่นใจและไร้กังวลในการดูแลรักษาความปลอดภัยของข้อมูลที่สำคัญ' }
]

export default function Security() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const handleGetClass = (idx) => {
        if (index == idx) return (Css.slideshowDot_active);
        else return Css.slideshowDot;
    }
    return (
        <>
        <div className={Css.security1}>
            <div >
                {
                    arr_feature.map((obj, index) => {
                        return (
                            <div className={Css.layout_security} key={index}>
                                <img alt={''} height={100} src={require(`images/${obj.image}`)} />
                                <span className={Css.fontsecurity}> {obj.title}</span>
                            </div>

                        )
                    })
                }

            </div>
            <div className={`${Css.bordersecurity}`}>
                <div className={`${Css.bordersecurity1}`}></div>
            </div>
        </div>

        <div className={Css.AllSlide_Moblie_security}>
            <Carousel activeIndex={index} onSelect={handleSelect}>
                {arr_feature.map((obj, index) => (
                    <Carousel.Item key={index}>
                        <div className={`${Css.slide_security}`} key={index} style={{ obj }}>
                            <img alt={''} height={100} src={require(`images/${obj.image}`)} />
                            <label className={Css.fontsecurity}> {obj.title}</label>
                        </div>
                        <div className={Css.bordersecurity}/>
                    </Carousel.Item>
                ))}
            </Carousel>

            <div className={Css.slideshowDots}>
                {arr_feature.map((_, idx) => (
                    <div
                        key={idx}
                        className={handleGetClass(idx)}
                        onClick={() => { setIndex(idx); }}
                    ></div>
                ))}
            </div>
        </div>
        </>
        

    )
}