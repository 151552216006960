import LayoutModulePage from "components/layout/LayoutModulePage";

export default function Module() {
    return <LayoutModulePage>

        <div className="container m-auto text-center overflow-hidden pb-2 pt-2" style={{ height: '450px', backgroundColor: '#dfdfdf' }}>
            <img src={require('images/module-bg.png')} className="" alt="ISO DCaaS" height={400} />
        </div>
        <div className="container m-auto text-center overflow-hidden d-flex justify-content-center pb-2 pt-2">
            <div className="col-3 text-center p-4 m-2">
                <img src={require('images/dashboard02.png')} height={100} />
                <h4 className="fs-2 p-3">dashboard</h4>
                <p>หน้าจอสรุปข้อมูลทุกอย่างให้อยู่ในหน้าจอเดียว เพื่อให้ผู้บริหาร ดูข้อมูลแล้วเข้าใจได้ทันที </p>
            </div>
            <div className="col-3 text-center p-4 m-2">
                <img src={require('images/doc-control02.png')} height={100} />
                <h4 className="fs-2 p-3">document control</h4>
                <p>ระบบควบคุมเอกสารคุณภาพ : ควบคุมเอกสารบังคับใช้, เอกสารฉบับเดิม และเอกสารยกเลิก</p>
            </div>
            <div className="col-3 text-center p-4 m-2">
                <img src={require('images/doc-record02.png')} height={100} />
                <h4 className="fs-2 p-3">document record</h4>
                <p>ระบบการจัดทำบันทึก : มี Workflow, จัดเก็บเอกสารเป็นหมวดหมู่ ค้นหาได้ง่าย และรวดเร็ว</p>
            </div>

        </div>
    </LayoutModulePage>
}