import { useNavigate } from "react-router-dom";
import Css from 'components/pages/customer/Customer.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import ATSApiConnection from "api_ref/ATSApiConnection";
import QuontationStatus1 from "./QuontationStatus1";
import Paymentcom from "./CustomerPaymentNoHave";
import NotificationSetting from "./NotificationSetting";
import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import UtilClass from "api_ref/UtilClass";

export default function CustomerNotification() {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');
    const register = util.getSessionStorage('register');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [notificationList, setNotificationList] = useState([]);

    const navigate = useNavigate();

    const getNotificationSetting = async () => {
        const resposne = await api.getData(`api/customerprofile/${customer.user_id}/register/${register.register_number}/notification-setting`);

        if (resposne.status === true) {
            setNotificationList(resposne.data);
        }
    }

    const setNotification = async (notification_id, active) => {
        const request = {
            active: active
        }
        const resposne = await api.postData(`api/customerprofile/${customer.user_id}/register/${register.register_number}/notification-setting/${notification_id}`
            , request);
    }
    useEffect(() => {
        getNotificationSetting();
    }, []);

    return (
        <LayoutCustomerMain role="customer"  >
            <QuontationStatus1 />
            <div className={Css.Block_Transparent}><br />
                <h5 className={Css.color1}>ตั้งค่าการแจ้งเตือน</h5>
                <div className={Css.Graph_Group}>
                    <NotificationSetting
                        data={notificationList}
                        handleParentSubmit={setNotification} />
                </div>

            </div>
        </LayoutCustomerMain>
    )
}