import { AddCommar, ButtonUploadFile, DateToText, TextDate } from "components/MyControl";
import Css from "./Register.module.css";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MethodBBL from "components/pages/customer/payment/MethodBBL";

export default function ServicePayment({ data,
    onChangeData = undefined,
    error = '',
    loading = false,
    className = '',
    showPayNow = true }) {
    const [hour, setHour] = useState([]);
    const [minute, setMinute] = useState([]);

    const GetHour = () => {
        let data = [];
        for (let i = 0; i <= 23; i++) {
            let text = "00" + i;
            text = text.substr(text.length - 2, 2);
            data.push(text);
        }
        setHour(data);
    };

    const GetMinute = () => {
        let data = [];
        for (let i = 0; i < 60; i++) {
            let text = "00" + i;
            text = text.substr(text.length - 2, 2);
            data.push(text);
        }
        setMinute(data);
    };

    useEffect(() => {
        GetHour();
        GetMinute();
    }, []);

    return (
        <div className={`${Css.Register_customer_layout4} ${className}`}>
            <h6 className={`text-start text-blue-01`}>การชำระค่าบริการ</h6>
            {
                data?.arr_package[0].package_type !== 'free' &&
                showPayNow === true &&
                <div className={` ${Css.form}`}>
                    <div className={Css.switchField}>
                        <input
                            type="radio"
                            id="radio-two"
                            name="is_pay_now"
                            value={false}
                            defaultChecked={data?.is_pay_now == false || data?.is_pay_now == 'false'}
                            onChange={(event) => onChangeData(event)}
                        />
                        <label htmlFor="radio-two">ชำระภายหลัง</label>
                        <input
                            type="radio"
                            id="radio-one"
                            name="is_pay_now"
                            value={true}
                            defaultChecked={data?.is_pay_now == true || data?.is_pay_now == 'true'}
                            onChange={(event) => onChangeData(event)}
                        />
                        <label htmlFor="radio-one">ชำระทันที</label>

                    </div>
                </div>
            }

            {(data?.is_pay_now == true || data?.is_pay_now == 'true') && (
                <>
                    <h6 className="mt-5 mb-3">วิธีชำระค่าบริการ</h6>
                    <div
                        className={` ${Css.body2} ${Css.transform} ${Css.primarycolor}`}
                    >
                        โอนผ่านบัญชีธนาคาร
                    </div>
                    <Row className="m-0">
                        <Col className={`me-1 `}>
                            <MethodBBL />
                        </Col>
                    </Row>
                    <h6 className="mt-5 mb-3">แนบหลักฐานการชำระค่าบริการ</h6>
                    <div >
                        <Row >
                            <Col sm={6}>
                                <ButtonUploadFile
                                    name={"paid_file_slip"}
                                    className={"btn btn-outline-secondary " + Css.insertpic}
                                    label={"คลิกเพื่อแนบเอกสาร"}
                                    value={data?.paid_file_slip}
                                    onChange={(event) => onChangeData(event)}
                                    required={true}
                                />
                            </Col>

                            <Col sm={6}>
                                <Form.Group className="">
                                    <Form.Label>ธนาคารที่โอน</Form.Label>
                                    <Form.Control name={'paid_bank'}
                                        value={data?.paid_bank}
                                        onChange={(event) => onChangeData(event)}
                                        required={true}
                                        autoComplete={"off"} />
                                </Form.Group>

                                <Form.Group className="mt-2">
                                    <Form.Label>วันที่โอนเงิน</Form.Label>
                                    <TextDate
                                        name={"paid_date_only"}
                                        value={data?.paid_date_only}
                                        onChange={(event) => onChangeData(event)}
                                        maxDate={new Date()}
                                        required={true}
                                        autoComplete={"off"}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label className="mt-2">เวลาที่โอน</Form.Label>
                                    <InputGroup>
                                        <Form.Select
                                            name={"paid_hour"}
                                            className="no-icon border-end-0"
                                            value={data?.paid_hour}
                                            onChange={(event) => onChangeData(event)}
                                        >
                                            {hour.map((obj, index) => {
                                                return (
                                                    <option key={index} value={obj}>
                                                        {obj}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                        <InputGroup.Text className="bg-white p-2">
                                            :
                                        </InputGroup.Text>
                                        <Form.Select
                                            name={"paid_minute"}
                                            className="no-icon border-start-0 border-end-0"
                                            value={data?.paid_minute}
                                            onChange={(event) => onChangeData(event)}
                                        >
                                            {minute.map((obj, index) => {
                                                return (
                                                    <option key={index} value={obj}>
                                                        {obj}
                                                    </option>
                                                );
                                            })}
                                        </Form.Select>
                                        <InputGroup.Text className="bg-white">
                                            <i className="fa-regular fa-clock"></i>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className="mt-2">
                                    <Form.Label>จำนวนเงินที่โอน</Form.Label>
                                    <Form.Control name={'paid_amount'}
                                        value={data?.paid_amount}
                                        type={'number'}
                                        onChange={(event) => onChangeData(event)}
                                        required={true}
                                        autoComplete={"off"} />
                                </Form.Group>

                            </Col>
                        </Row>
                    </div>
                    <h6 className="mt-5 mb-3">ที่อยู่ในการออกใบเสร็จ/ใบกำกับภาษี</h6>
                    <Form.Group className={`mb-2 ${Css.body2}`}>
                        <Form.Label>ชื่อบริษัท</Form.Label>
                        <Form.Control
                            placeholder=""
                            name={"payee_name"}
                            value={data?.payee_name}
                            required={true}
                            onChange={(event) => onChangeData(event)}
                        />
                    </Form.Group>
                    <Form.Group className={`mb-2 ${Css.body2}`}>
                        <Form.Label>ที่อยู่</Form.Label>
                        <Form.Control
                            placeholder=""
                            name={"payee_address"}
                            value={data?.payee_address}
                            required={true}
                            onChange={(event) => onChangeData(event)}
                        />
                    </Form.Group>
                    <Form.Group className={`mb-2 ${Css.body2}`}>
                        <Form.Label>หมายเลขโทรศัพท์</Form.Label>
                        <Form.Control
                            placeholder=""
                            name={"payee_tel"}
                            value={data?.payee_tel}
                            required={true}
                            onChange={(event) => onChangeData(event)}
                        />
                    </Form.Group>
                    <div>
                        <Row className={`mb-5 ${Css.body2}`}>
                            <Col>
                                <Form.Group>
                                    <Form.Label>เลขประจำตัวผู้เสียภาษีอากร</Form.Label>
                                    <Form.Control
                                        placeholder=""
                                        name={"payee_tax_id"}
                                        value={data?.payee_tax_id}
                                        maxLength={13} pattern="[0-9]{13}"
                                        required={true}
                                        onChange={(event) => onChangeData(event)}
                                    />
                                    <Form.Control.Feedback type={'invalid'}>รูปแบบเลขประจำตัวผู้เสียภาษีไม่ถูกต้อง</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label>สาขา/รหัสสาขา</Form.Label>
                                    <Form.Control
                                        placeholder=""
                                        name={"payee_branch"}
                                        value={data?.payee_branch}
                                        required={true}
                                        onChange={(event) => onChangeData(event)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </>
            )}

            {
                (data?.is_pay_now === false || data?.is_pay_now === 'false') &&
                <>
                    <h6 className="mt-5 mb-3">ที่อยู่ในการออกใบเสร็จ/ใบกำกับภาษี</h6>
                    <Form.Group className={`mb-2 ${Css.body2}`}>
                        <Form.Label>ชื่อบริษัท</Form.Label>
                        <Form.Control
                            placeholder=""
                            name={"payee_name"}
                            value={data?.payee_name}
                            required={true}
                            onChange={(event) => onChangeData(event)}
                        />
                    </Form.Group>
                    <Form.Group className={`mb-2 ${Css.body2}`}>
                        <Form.Label>ที่อยู่</Form.Label>
                        <Form.Control
                            placeholder=""
                            name={"payee_address"}
                            value={data?.payee_address}
                            required={true}
                            onChange={(event) => onChangeData(event)}
                        />
                    </Form.Group>
                    <Form.Group className={`mb-2 ${Css.body2}`}>
                        <Form.Label>หมายเลขโทรศัพท์</Form.Label>
                        <Form.Control
                            placeholder=""
                            name={"payee_tel"}
                            value={data?.payee_tel}
                            required={true}
                            onChange={(event) => onChangeData(event)}
                        />
                    </Form.Group>
                    <div>
                        <Row className={`mb-5 ${Css.body2}`}>
                            <Col>
                                <Form.Group>
                                    <Form.Label>เลขประจำตัวผู้เสียภาษีอากร</Form.Label>
                                    <Form.Control
                                        placeholder=""
                                        name={"payee_tax_id"}
                                        value={data?.payee_tax_id}
                                        maxLength={13} pattern="[0-9]{13}"
                                        required={true}
                                        onChange={(event) => onChangeData(event)}
                                    />
                                    <Form.Control.Feedback type={'invalid'}>รูปแบบเลขประจำตัวผู้เสียภาษีไม่ถูกต้อง</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label>สาขา/รหัสสาขา</Form.Label>
                                    <Form.Control
                                        placeholder=""
                                        name={"payee_branch"}
                                        value={data?.payee_branch}
                                        required={true}
                                        onChange={(event) => onChangeData(event)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div></>
            }

            {
                <Form.Check>
                    <Form.Check.Input
                        name={"is_accept_agreement"}
                        id={"is_accept_agreement"}
                        onChange={(event) => onChangeData(event)}
                        required={true}
                    />
                    <Form.Check.Label

                        className={` ${Css.body2}`}
                    >
                        <span className={`me-1 ${Css.body2} `}>ยอมรับ</span>
                        <Link to={'/register/Termsofuse'} target="_blank" className={`${Css.link_contact + " me-1"} ${Css.body2}`} >เงื่อนไขการใช้บริการ</Link>
                        <span className={`me-1 ${Css.body2} `}>และ</span>
                        <Link to={'/register/Privacypolicy'} target="_blank" className={`${Css.link_contact + " me-1"} ${Css.body2}`} >นโยบายความเป็นส่วนตัว</Link>
                    </Form.Check.Label>
                </Form.Check>
            }

            {
                error !== '' &&
                <div className="alert alert-danger mt-2" role="alert">
                    {error}
                </div>
            }
            <Button type='submit' className={`mt-4 fs-5 ${Css.btnColor}`} disabled={loading}>
                {
                    loading === true &&
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                }
                {
                    (data?.is_pay_now === true || data?.is_pay_now === 'true') &&
                    <>
                        ยืนยันชำระค่าบริการ
                    </>
                }
                {
                    (data?.is_pay_now === false || data?.is_pay_now === 'false') &&
                    <>ยืนยัน</>
                }
            </Button>
            {
                (data?.is_pay_now === false || data?.is_pay_now === 'false') &&
                <div className="mt-4">
                    <h6 className="mb-3">วิธีชำระค่าบริการ</h6>
                    <div>
                        <ul>
                            <li>หลังจากกดปุ่ม ยืนยัน ระบบจะแจ้งอัตราค่าบริการทางอีเมล</li>
                            <li>คุณสามารถกลับมาเลือกชำระค่าบริการ และแนบหลักฐานการชำระได้ที่<br />เมนู<b>ชำระค่าบริการ</b></li>
                            <li>ช่องทางการชำระ
                                <MethodBBL />
                            </li>
                        </ul>
                    </div>
                </div>
            }
        </div>
    );
}