import React, { useEffect, useState } from "react"
import Css from './Manual.module.css'
import { Button } from "bootstrap/dist/js/bootstrap.bundle";

export default function ScrollBackToTop() {
    const [backTopButton, setBackTopButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setBackTopButton(true)
            }
            else {
                setBackTopButton(false)
            }
        })
    }, [])

    const scrollup = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return (
        <div className="position-relative" style={{ zIndex: 10 }}>
            {backTopButton && (
                <button className={Css.roundBtn} onClick={scrollup}><i className="fa-solid fa-chevron-up" style={{ color: "#333333", }}></i></button>
            )}
        </div>
    )
}