import Css from "components/pages/customer/Customer.module.css";
import { useEffect, useState } from "react";
import ApiConection from "api_ref/ApiConection";
import RegisterCustomerPayment from "components/pages/application/register/RegisterCustomerPayment";
import { Form, Button } from "react-bootstrap";

export default function CustomerPaymentServiceStep1({
    data = undefined,
    oldData = undefined,
    onHandleSubmit = undefined,
    onChangeData = undefined,
    onChangeStep = undefined,
    handleSetResultBill = undefined,
}) {
    const api = new ApiConection();

    const [estimateData, setEstimateData] = useState(data);
    const [calData, setCalData] = useState(undefined);
    const [dataChangeCount, setDataChangeCount] = useState(0);

    const checkPrice = async () => {
        let arr_quontations = [];
        //debugger;

        //console.log('คำนวณ');
        estimateData.arr_package.forEach(obj => {
            arr_quontations.push(
                {
                    package_master_id: obj.package_master_id,
                    package_master_period_id: estimateData.package_master_period_id,
                    package_master_users_volume_id: obj.package_master_users_volume_id,
                    package_master_storage_volume_id: obj.package_master_storage_volume_id
                });
        });

        let request = {
            arr_quontations: arr_quontations,
            discount_code: estimateData.discount_code,
            is_with_holding_tax: estimateData.is_with_holding_tax,
            is_pay_now: true,
            is_accept_agreement: true,
        };

        let tmpData = estimateData;

        if (JSON.stringify(oldData?.arr_package) === JSON.stringify(estimateData.arr_package)) {
            const response = await api.postData(
                `api/quontation/${data.quontation_number}/payment/price-check`,
                request
            );

            tmpData = estimateData;

            tmpData.discount = Number(response.data?.discount ?? 0).toFixed(2);
            tmpData.net = Number(response.data?.net ?? 0).toFixed(2);
            tmpData.vat = Number(response.data?.vat ?? 0).toFixed(2);
            tmpData.vat_rate = Number((response.data?.vat_rate ?? 0) * 100).toFixed(0);
            tmpData.tax = Number(response.data?.tax ?? 0).toFixed(2);
            tmpData.tax_rate = Number((response.data?.tax_rate ?? 0) * 100).toFixed(0);
            tmpData.total = Number(response.data?.total ?? 0).toFixed(2);
            tmpData.grand_total = Number(response.data?.grand_total ?? 0).toFixed(2);
            tmpData.grand_total_sub_withholding_tax = Number(response.data.grand_total_sub_withholding_tax).toFixed(2);
        } else {
            const response = await api.postData(
                `api/quontation/register/${data.register_number}/upgrade-package/price-check`,
                request
            );
            tmpData = estimateData;

            tmpData.discount = Number(response.data?.discount ?? 0).toFixed(2);
            tmpData.net = Number(response.data?.net ?? 0).toFixed(2);
            tmpData.vat = Number(response.data?.vat ?? 0).toFixed(2);
            tmpData.vat_rate = Number((response.data?.vat_rate ?? 0) * 100).toFixed(0);
            tmpData.tax = Number(response.data?.tax ?? 0).toFixed(2);
            tmpData.tax_rate = Number((response.data?.tax_rate ?? 0) * 100).toFixed(0);
            tmpData.total = Number(response.data?.total ?? 0).toFixed(2);
            tmpData.grand_total = Number(response.data?.grand_total ?? 0).toFixed(2);
            tmpData.grand_total_sub_withholding_tax = Number(response.data.grand_total_sub_withholding_tax).toFixed(2);
        }

        setEstimateData(tmpData);
        setCalData(tmpData);
    };

    const handleChangeEstimateData = (event) => {
        const { name, value, type, checked } = event.target;
        if (type === "checkbox") {
            setEstimateData((prevData) => {
                return { ...prevData, [name]: checked };
            });
        } else {
            setEstimateData((prevData) => {
                return { ...prevData, [name]: value };
            });
        }
        setDataChangeCount(dataChangeCount + 1);
    };

    const handleThisSubmit = (event) => {
        // const form = event.currentTarget;
        event.preventDefault();
        let tmpData = estimateData;

        tmpData.arr_package = estimateData.arr_package;

        tmpData.discount_code = estimateData.discount_code;
        tmpData.is_with_holding_tax = estimateData.is_with_holding_tax;
        tmpData.net = estimateData.net;
        tmpData.vat = estimateData.vat;
        tmpData.discount = estimateData.discount;
        tmpData.tax = estimateData.tax;
        tmpData.total = estimateData.total;
        tmpData.grand_total = estimateData.grand_total;
        tmpData.grand_total_sub_withholding_tax = estimateData.grand_total_sub_withholding_tax;
        tmpData.is_pay_now = true;

        handleSetResultBill(tmpData);

        onHandleSubmit();
    };

    useEffect(() => {
        const delay = setTimeout(() => {
            checkPrice();
        }, 100);
        return () => clearTimeout(delay);
    }, [dataChangeCount]);

    if (data === undefined || oldData === undefined) return;

    return (
        estimateData !== undefined && (
            <Form
                encType="multipart/form-data"
                onSubmit={(event) => handleThisSubmit(event)}
            >
                <div className={`${Css.foit} ${Css.Graph_Group} flex-column`}>
                    {
                        data?.package_type?.indexOf('renew') > -1 &&
                        <div className={Css.w_480px + ' mb-2'}>
                            <Button variant="outline-secondary" onClick={(_) => onChangeStep(0)}>เปลี่ยนแพ็กเกจ</Button>
                        </div>
                    }

                    <RegisterCustomerPayment
                        className={Css.w_480px}
                        data={estimateData}
                        renewMode={(estimateData?.package_type === 'renew:package') ? true : false}
                        onChangeData={handleChangeEstimateData}
                    />
                </div>
            </Form>
        )
    );
}