import React, { useEffect, useState } from "react";
import Footer4Bottom from "./Foot4Bottom";
import Css from './Layout.module.css'
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ApiConection from 'api_ref/ApiConection';
import UtilClass from "api_ref/UtilClass";

const arr_menu = [
    { seq: 1, name: 'หน้าแรก', icon: 'fa-solid fa-house', link: '/customer/home', desc: 'customer' },
    { seq: 2, name: 'หน้าแรก', icon: 'fa-solid fa-house', link: '/admin/home', desc: 'admin' },
    { seq: 3, name: 'รายการลูกค้า', icon: 'fa-solid fa-house', link: '/admin/company', desc: 'admin' },
    { seq: 4, name: 'การชำระเงิน', icon: 'fa-regular fa-credit-card', link: '/customer/payment', desc: 'customer' },
    { seq: 4, name: 'จัดการแพ็กเกจ', icon: 'fa-solid fa-box-open', link: '/customer/change', desc: 'customer' },
    { seq: 5, name: 'ข้อมูลของคุณ', icon: 'fa-regular fa-address-card', link: '/customer/config', desc: 'customer' },
    { seq: 6, name: 'ตั้งค่า', icon: 'fa-solid fa-layer-group', link: '/admin/config', desc: 'admin' },
    { seq: 7, name: 'ติดต่อเรา', icon: 'fa-solid fa-phone', link: '/customer/contact', desc: 'customer' },
    { seq: 8, name: 'รายการรอตรวจสอบชำระเงิน', icon: 'fa-solid fa-table', link: '/admin/paytransaction/pendding', desc: 'admin' },
    { seq: 9, name: 'รายการทัั้งหมด', icon: 'fa-solid fa-table', link: '/admin/paytransaction', desc: 'admin' },
]

export default function Layout4Main({ children, middle = undefined, role = 'customer', isApproved = null }) {
    const api = new ApiConection();

    const nav = useNavigate();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');

    const [Register, setRegister] = useState({});

    const getRegister = async () => {
        const response = await api.getData(`api/register/customer/${customer.user_id}`);

        if (response.status === true) {
            setRegister(response.data[0]);
        }
    }

    const getMiddleClass = () => {
        if (middle === undefined) return '';
        return Css.Content_center;
    }
    const handleLink = (link) => {
        nav(link);
    }

    const handleLogout = (event) => {
        nav('/login');
    }

    const handleISODCaas = (event) => {
        window.open(process.env.REACT_APP_ATS_POTAL, '_blank');
    }

    const handleChangeProfile = (event) => {
        nav('/customer/config');
    }

    const handleChangePass = () => {
        nav('/customer/configpassword');
    }

    const link = (event) => {
        window.open(Register.full_site_url, '_blank');
        //window.location.href = 'http://thaibanns.mstdcaas.com/';
    }

    const handleGetClass = (path) => {
        let nowPath = window.location.pathname;
        if (nowPath.indexOf(path) > -1) return Css.Selected;
        else return "";
    }

    useEffect(() => {
        getRegister();
    }, [])
    useEffect(() => {
        //console.log(customer.user_id);
        if (customer === null) nav('/login');
    }, [customer])

    return (
        <>
            <div className={Css.Main_Content_Customer}>
                <div className={Css.Menu_Left + ' shadow'}>
                    <div className={Css.Header}>
                        <img src={require('images/DCasS.png')} className="me-2" />
                        <span className="fs-6">Admin Portal</span>
                    </div>
                    <div className={Css.Menu}>
                        <ul>
                            {
                                arr_menu.map((obj, index) => {
                                    if (obj.desc === role) {
                                        return (
                                            <li key={index} className={handleGetClass(obj.link)}>
                                                <a onClick={event => handleLink(obj.link)}><i className={obj.icon}></i>{obj.name}</a>
                                            </li>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className={Css.Content}>
                    <div className={Css.Header}>
                        <Row>
                            <Col className={'text-start'}>
                                <label className="mt-2">รหัสลูกค้า</label>&nbsp;
                                <span>{Register.register_number}</span>
                            </Col>
                            <Col className={'text-end'}>
                                {
                                    isApproved === true &&
                                    <button className="btn btn-primary ms-4 me-4" onClick={link} >ไปยังระบบ ISO DCaaS</button>
                                }
                                <div className="btn-group ">
                                    <a className="profile-dropdown dropdown-toggle" data-bs-toggle="dropdown">
                                        <img window={'auto'} height={30} alt='' src={require('images/profile.png')} />
                                        {customer?.show_name}
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-md-start">
                                        <li>
                                            <div className="text-center p-3">
                                                <img className="d-block m-auto mb-2" width={'auto'} height={50} alt='' src={require('images/profile.png')} />
                                                <span className="d-block fs-7">{customer?.show_name}</span>
                                                <span className="d-block text-secondary">{customer?.email}</span>

                                            </div>
                                            <a className="dropdown-item" onClick={event => handleChangeProfile()}><i className="fa-regular fa-address-card me-2"></i>Edit Profile</a>
                                        </li>
                                        <li><a className="dropdown-item" onClick={event => handleChangePass()} ><i className="fa-solid fa-key me-2"></i>Change Password</a></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item" onClick={handleLogout}><i className="fa-solid fa-arrow-right-from-bracket me-2"></i>Logout</a></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>

                    </div>
                    {children}
                </div>
            </div>
            <Footer4Bottom />
        </>
    );
}