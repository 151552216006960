import Css from 'components/pages/customer/Customer.module.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Col, Row } from 'react-bootstrap';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function AccountUsage({ total = undefined, used = undefined }) {
    //const used = (used==undefined)? 0 : used;
    const usedPercent = Math.floor(used * 100 / total);

    const data = {
        labels: ['Incurrent', 'Left'],
        datasets: [
            {
                label: 'Account',
                data: [used, total - used],
                backgroundColor: [
                    '#A19FFB',
                    '#EDECFE',

                ],
                borderColor: [
                    '#A19FFB',
                    '#EDECFE',

                ],
                borderWidth: 1,
                cutout: '80%',
            },
        ],
    };
    const options = {
        plugins: {
            legend: {
                display: false
            }
        }
    }

    const backgroundCircle = {
        id: 'backgroundCircle',
        beforeDatasetsDraw(chart, args, pluginOptions) {
            const { ctx } = chart;
            ctx.save();
        }
    }

    const textCenter = {
        id: 'textCenter',
        beforeDatasetsDraw(chart, args, pluginOptions) {
            const { ctx, data, chartArea: { left, right, top, bottom, width, height } } = chart;

            ctx.save();
            ctx.font = '28px Prompt';
            ctx.fillStyle = '#A084DC';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(used, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y - 10);
            ctx.restore();

            ctx.font = '18px Prompt';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText('Account', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 20);
            ctx.restore();
        }
    }

    return (
        <div className={Css.Graph_Box + ' shadow'}>
            <div className={Css.Content}>
                <div className={Css.Header}>
                    <h6>Account Usage</h6>
                </div>
                <div className={Css.Content}>
                    <div className={Css.Doughnut_Usage + " flex-fill"}>
                        {
                            total !== undefined && used !== undefined &&
                            <Doughnut data={data} options={options} plugins={[textCenter, backgroundCircle]} ></Doughnut>
                        }
                        {
                            (total === undefined || used === undefined) &&
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        }
                    </div>
                    <div>
                        <Row>
                            <Col>
                                <i className={'fa-solid fa-circle me-1 ' + Css.Color_Purple_02}></i>
                                Package Account
                                <br />
                                {total}
                            </Col>
                            <Col>
                                <i className={'fa-solid fa-circle me-1 ' + Css.Color_Purple_01}></i>
                                Active Account
                                <br />
                                {used}
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className={Css.Footer + ' d-none'}>
                <a href={'#'}>View Full Plan</a>
            </div>
        </div>
    )
}