import Layout4Main from "components/layout/Layout4Main";
import { Row, Col, Form, Button, Tab, Table, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Css from 'components/pages/customer/Customer.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import { AddCommar, DateToText } from "components/MyControl";
import moment from "moment";
import Change1Package from "./Change1Package";
import Change2Transfer from "./Change2Transfer";
import Change3Confirm from "./Change3Confirm";
import QuontationStatus from "../home/QuontationStatus";
import Change0Condition from "./Change0Condition";
import UtilClass from "api_ref/UtilClass";

export default function Change() {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');
    const register = util.getSessionStorage('register');
    const quontation = util.getSessionStorage('quontation');

    const navigate = useNavigate();

    const [subStep, setSubStep] = useState(0);
    const [Register, setRegister] = useState({});
    const [QuontationNext, setQuontationNext] = useState({});
    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingAlertChange, setLoadingAlertChange] = useState(false);
    const [canChange, setCanChange] = useState(false);

    const [changePackage, setChangePackage] = useState({
        company_name: register.company_name,
        quontation_number: '',
        package_id: 1,
        period_id: 1,
        users_valume_id: null,
        storage_valume_id: null,
        users_valume: null,
        storage_valume: null,
        discount_code: '',
        package_name: '',
        package_short_name: '',
        package_period: '',
        price: 0.00,
        price_unit: '',
        tax: 0.00,
        total: 0.00,
        is_want_tax_invoice: false,
        delivery_name: quontation.payment_name,
        delivery_address: quontation.payment_address,
        delivery_tax_id: quontation.payment_tax_id,
        delivery_branch: quontation.payment_branch,
    });
    const getRegister = async () => {
        const response = await api.getData(`api/register/customer/${customer.user_id}`);

        if (response.status === true) {
            setRegister(response.data[0]);
        }
    }

    const getQuonation = async () => {
        setLoading(true);
        const response = await api.getData(`api/quontation/register/${Register.register_number}/next-bill`);

        if (response.status === true) {
            setQuontationNext(response.data);
            //console.log(QuontationNext.payment_status_id)
        }
        setLoading(false);
    }
    const onChangeData = (event) => {
        const { type, name, value, checked, files } = event.target;

        event.target?.setCustomValidity('');

        if (type === 'file') {
            setChangePackage(prevData => {
                return { ...prevData, [name]: files ? files[0] : undefined }
            });
        }
        else if (type === 'checkbox') {
            setChangePackage(prevData => {
                return { ...prevData, [name]: checked }
            });
        }
        else {
            setChangePackage(prevData => {
                return { ...prevData, [name]: value }
            });
        }
    }

    const handleSetChangePackage = (name, value) => {
        //console.log(changePackage);

        setChangePackage(prevData => {
            return { ...prevData, [name]: value }
        });
    }
    const getValue = (name) => {
        return changePackage[name];
    }

    const handleValid = (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        setValidated(false);
        setLoading(false);
        setAlert('');

        setSubStep(3);
    }

    const SetQuontation = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        let data = {
            arr_quontations: [{
                package_master_id: getValue('package_id'),
                package_master_period_id: getValue('period_id'),
                package_master_users_valume_id: getValue('users_valume_id'),
                package_master_storage_valume_id: getValue('storage_valume_id'),
            }],
            is_issue_tax_invoice: getValue('is_want_tax_invoice'),
            discount_code: getValue('discount_code'),
            payment_name: getValue('delivery_name'),
            payment_address: getValue('delivery_address'),
            payment_tax_id: getValue('delivery_tax_id'),
            payment_branch: getValue('delivery_branch')
        }

        let response_status = false;
        let response_message = '';
        let is_free = false;

        setLoading(true);

        const responseCheckAuto = await api.getData(`api/quontation/register/${register.register_number}/check-auto-approved`);

        if (getValue('quontation_number') === '') {
            //---- บันทึก ใบ PO
            const response = await api.postData(`api/quontation/register/${register.register_number}/auto-approved/${responseCheckAuto.status}`, data);
            handleSetChangePackage('quontation_number', response.data.quontation_number);
            handleSetChangePackage('effective_date', response.data.effective_date);
            handleSetChangePackage('expiry_date', response.data.expiry_date);
            response_status = response.status;
            response_message = response.message;
            is_free = response.data.package_type === 'free';

            if (responseCheckAuto.status === true) {
                util.setSessionStorage('quontation', response.data);
            }

            if (response_status === true) {
                navigate('/customer/payment');
            }
        } else {
            const response = await api.putData(`api/quontation/${getValue('quontation_number')}/register/${getValue('register_number')}`, data);

            response_status = response.status;
            response_message = response.message;
            is_free = response.data.package_type === 'free';

            util.setSessionStorage('quontation', response.data);
        }

        setLoading(false);
    }

    const checkCanChange = async () => {
        setLoadingAlertChange(true);
        const response = await api.getData(`api/Quontation/register/${register.register_number}/check-change-package`);
        setCanChange(response);
        setLoadingAlertChange(false);
    }

    useEffect(() => {
        getRegister();
    }, [])

    useEffect(() => {
        if (Register !== undefined) {
            getQuonation();
            checkCanChange();
        }
    }, [Register]);
    return (
        <Layout4Main role="customer">
            <QuontationStatus register_number={Register.register_number} />
            {
                canChange.status === false &&
                <div className={Css.Block_Transparent + ' pb-0'}>
                    <div >
                        <Alert className={'m-0 shadow'} key='warning' variant='warning'>
                            <i className="fa-solid fa-triangle-exclamation me-2"></i>
                            {canChange.message}
                        </Alert>
                    </div>
                </div>
            }
            <div className={Css.Block_Transparent}>
                <div className={Css.Graph_Group}>
                    <div className={Css.Graph_Box + ' shadow'}>
                        <div className={Css.Content}>
                            <div className={Css.Header}>
                                <h6>แพ็กเกจที่ใช้อยู่</h6>
                            </div>
                            <div className={Css.Content + ' d-flex justify-content-center'}>
                                <div className="border rounded w-500px">
                                    <h6 className={'bg-light border-bottom d-block p-2 ps-4 text-start'}>
                                        {quontation.package_master_name}
                                        <i className="fa-solid fa-clock-rotate-left text-black-50 ms-2 me-2"></i>
                                        {quontation.package_master_period_name}
                                    </h6>
                                    <div className="text-start p-2 ps-4">
                                        <i className="fa-solid fa-coins text-black-50 me-2"></i>
                                        <span className="text-black-50 me-2">ค่าบริการ</span>
                                        <span >{AddCommar(quontation.total)}</span> บาท<span></span>
                                    </div>
                                    <div className="text-start p-2 ps-4">
                                        <i className="fa-regular fa-clock text-black-50 me-2" title="ระยะเวลาใช้งาน"></i>
                                        <span className="text-black-50 me-2">ระยะเวลา</span>
                                        {DateToText(quontation.effective_date) + ' - ' + DateToText(quontation.expiry_date)}
                                    </div>
                                    <Row className="p-2 ps-4 pb-4 mt-2">
                                        <Col colSpan={2} className="text-start">
                                            <label >
                                                <i className="fa-solid fa-people-group text-black-50 me-2"></i>
                                                {quontation.user_valume} Account
                                            </label>
                                        </Col>
                                        <Col className="text-start">
                                            <label>
                                                <i className="fa-solid fa-database text-black-50 me-2"></i>
                                                {quontation.storage_valume} GB Storage
                                            </label>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                {
                    <>
                        {
                            subStep === 0 &&
                            <Change0Condition alert={canChange.message} canChange={canChange.status} onSubmit={event => setSubStep(1)} />
                        }
                        {
                            subStep === 1 &&
                            <Change1Package onChangeData={onChangeData} handleSetChangePackage={handleSetChangePackage} getValue={getValue} validated={validated} onSubmit={event => setSubStep(2)} />
                        }
                        {
                            subStep === 2 &&
                            <Change2Transfer onChangeData={onChangeData} handleSetChangePackage={handleSetChangePackage} getValue={getValue} validated={validated} setSubStep={setSubStep} onSubmit={event => handleValid(event)} />
                        }
                        {
                            subStep === 3 &&
                            <Change3Confirm onChangeData={onChangeData} handleSetChangePackage={handleSetChangePackage} getValue={getValue} validated={validated} setSubStep={setSubStep} alert={alert} setAlert={setAlert} loading={loading} onSubmit={event => SetQuontation(event)} />
                        }
                    </>
                }

            </div>
        </Layout4Main>
    )
}