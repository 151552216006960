import Css from 'components/pages/application/login/Login.module.css';
import { Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function CustomerUpPackage({ loading = true, disabled = true, message = '' }) {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const handleLoginPage = () => {
        navigate('/customer/upgrade/package');
    }

    return (
        <div className={`${Css.Login_inner_layout1} `}>
            <Form noValidate validated={validated} onSubmit={handleLoginPage} >
                <h6 className={Css.color}>อัปเกรดแพ็กเกจหลักในรอบบิลถัดไป</h6><br />
                <div className={`pb-2 ${Css.body2}`}>
                    <ul>
                        <li>มีผลในรอบบิลถัดไป</li>
                        <li>ไม่สามารถอัพเดตเป็นแพ็กเกจที่คิดค่าบริการน้อยกว่าแพ็กเกจปัจจุบันได้</li>
                    </ul>
                </div>
                <div className="pt-5 text-end">
                    {
                        loading === true &&
                        <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>

                        </>
                    }
                    {
                        loading === false &&
                        <Button type={'submit'} className={Css.btn} disabled={disabled} >

                            {disabled ?
                                message.search(/คุณมียอดค้างชำระ/) > -1 ? 'คุณมียอดค้างชำระ'
                                    : message.search(/ตรวจสอบ/) > -1 ? 'ระบบกำลังตรวจสอบข้อมูล' : message : 'อัปเกรดเลย'}
                        </Button>
                    }

                </div>
            </Form>
        </div>
    )
}