import Css from 'components/pages/customer/Customer.module.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Col, Row } from 'react-bootstrap';

export default function SystemUploadFileType({ listFileExtension = [] }) {
    const arr_pictures = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.tiff', '.psd', '.swf', '.ai'];
    const arr_documents = ['.doc', '.docx', '.xls', '.xlsx', 'xlw', 'csv', '.pdf', '.ppt', '.pptx', '.dwg', '.txt'];
    const arr_videos = ['.fly', '.mov', '.mp3', '.mp4', '.wav', '.wma', '.xvid'];

    let document_file_count = 0;
    let document_file_type = 0;

    let picture_file_count = 0;
    let picture_file_type = 0;

    let video_file_count = 0;
    let video_file_type = 0;

    let other_file_count = 0;
    let other_file_type = 0;
    listFileExtension.map(obj => {
        if (arr_documents.includes(obj.fileExtension) == true) {
            document_file_count += obj.FileCount;
            document_file_type += obj.FileSize;
        }
        else if (arr_pictures.includes(obj.fileExtension) == true) {
            picture_file_count += obj.FileCount;
            picture_file_type += obj.FileSize;
        }
        else if (arr_videos.includes(obj.fileExtension) == true) {
            video_file_count += obj.FileCount;
            video_file_type += obj.FileSize;
        }
        else {
            other_file_count += obj.FileCount;
            other_file_type += obj.FileSize;
        }

        return 0;
    })

    const data = {
        labels: ['Document', 'Picture', 'Video', 'Other'],
        datasets: [
            {
                label: 'Files',
                data: [document_file_count, picture_file_count, video_file_count, other_file_count],
                backgroundColor: [
                    '#A19FFB',
                    '#55D8FE',
                    '#FFDA83',
                    '#FF8373',

                ],
                borderColor: [
                    '#A19FFB',
                    '#55D8FE',
                    '#FFDA83',
                    '#FF8373',

                ],
                borderWidth: 1,
                cutout: '0%',
            },
        ],
        responsive: true,
        maintainAspectRatio: false,
    };
    const options = {
        plugins: {
            legend: {
                display: false
            },
            responsive: true,
            maintainAspectRatio: false,
        }
    }

    const textCenter = {
        id: 'textCenter',
        beforeDatasetsDraw(chart, args, pluginOptions) {
            const { ctx, data, chartArea: { left, right, top, bottom, width, height } } = chart;
        }
    }

    const CurencyByte = (byte) => {
        if (byte == undefined) {
            return;
        }
        if (byte >= 1024 * 1024 * 1024) {
            let gb = byte / (1024 * 1024 * 1024);
            return gb.toFixed(2) + ' GB';
        } else if (byte >= 1024 * 1024) {
            let mb = byte / (1024 * 1024);
            return mb.toFixed(2) + ' MB';
        } else if (byte >= 1024) {
            let kb = byte / 1024;
            return kb.toFixed(2) + ' KB';
        } else {
            return byte.toFixed(2) + ' bytes';
        }
    }

    return (
        <div className={Css.Graph_Box + ' shadow'}>
            <div className={Css.Content}>
                <div className={Css.Header}>
                    <h6>System Upload File Type</h6>
                </div>
                <div className={Css.Content}>
                    <Row className='pt-4'>
                        <Col>
                            <div style={{ width: '90%' }}>
                                <Doughnut data={data} options={options} plugins={[textCenter]} ></Doughnut>
                            </div>
                        </Col>
                        <Col>
                            <Row className='pt-4 mb-3'>
                                <Col className='text-start'>
                                    <i className={"fa-regular fa-circle me-2 " + Css.Color_Purple_01}></i>
                                    Document</Col>
                                <Col className='text-end text-black-50'>{document_file_count} Files/{CurencyByte(document_file_type)}</Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col className='text-start'>
                                    <i className={"fa-regular fa-circle me-2 " + Css.Color_Blue_03}></i>Picture</Col>
                                <Col className='text-end text-black-50'>{picture_file_count} Files/{CurencyByte(picture_file_type)}</Col>
                            </Row>
                            {/* <Row className='mb-3'>
                                <Col className='text-start'>
                                <i className={"fa-regular fa-circle me-2 " + Css.Color_Orange_03}></i>Video</Col>
                                <Col className='text-end text-black-50'>{video_file_count} Files/{CurencyByte(video_file_type)}</Col>
                            </Row>*/}
                            <Row>
                                <Col className='text-start'>
                                    <i className={"fa-regular fa-circle me-2 " + Css.Color_Orange_04}></i>Other</Col>
                                <Col className='text-end text-black-50'>{other_file_count} Files/{CurencyByte(other_file_type)}</Col>
                            </Row>
                        </Col>
                    </Row>

                </div>
            </div>
        </div>
    )
}