class ATSApiConnection{
    constructor(){
		let thisBaseUrl = process.env.REACT_APP_ATS_API_URL;
        let UserName = process.env.REACT_APP_ATS_USER;
        let Password = process.env.REACT_APP_ATS_PASS;

		this._baseUrl = thisBaseUrl;
		this._baseUser = UserName;
        this._basePassword = Password;
	}

	async fetchWithTimeout (resource, options = {}) {
		const { timeout = 30000 } = options;

		const controller = new AbortController();
		const id = setTimeout(() => controller.abort(), timeout);
		const response = await fetch(resource, {
		  ...options,
		  signal: controller.signal
		});
		clearTimeout(id);
		return response;
	}

	async postData(url,payLoad){
        //let token = await this.admin_athenticationManager();
        payLoad.login = this._baseUser;
        payLoad.password = this._basePassword;

		let myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
        //myHeaders.append("Authorization", `bearer ${token}`);

        let raw = JSON.stringify(payLoad);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            mode:'cors',
            body: raw,
            redirect: 'follow',
			timeout:30000,
          };

		  try{
			const response = await this.fetchWithTimeout(`${this._baseUrl}/${url}`, requestOptions);
			//console.log(response);

			if(!response.ok)
				return ({status:false,name:`${response.statusText}`,message:`${ await response.text()}`});

			let data = await response.json();

		  	return(data);
		  }catch(error){
			//console.log('Ats api error : ' + error)
			return({status:false,message:error});
		  }
	}
}

export default ATSApiConnection;