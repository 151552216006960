import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import { Row, Col, Form, Button, Tab, Table } from "react-bootstrap";
import Css from 'components/pages/customer/Customer.module.css';
import Css2 from 'components/pages/application/login/Login.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import QuontationStatus1 from "../home/QuontationStatus1";
import ContactUs from "./ContactUs";
import UtilClass from "api_ref/UtilClass";
export default function Contact() {
    const api = new ApiConection();
    const util = new UtilClass();

    const [Register, setRegister] = useState({});
    const [QuontationNext, setQuontationNext] = useState({});
    const [loading, setLoading] = useState(false);

    const customer = util.getSessionStorage('customer');
    const getRegister = async () => {
        const response = await api.getData(`api/register/customer/${customer.user_id}`);

        if (response.status === true) {
            setRegister(response.data[0]);
        }
    }
    const handlefacebook = () => {
        window.open('https://www.facebook.com/isodcaas', '_blank');
    }
    const handlelinked = () => {
        window.open('https://www.linkedin.com/company/iso-dcaas', '_blank');
    }
    const handleline = () => {
        window.open('https://lin.ee/TarQeWO', '_blank');
    }
    const getQuonation = async () => {
        setLoading(true);
        const response = await api.getData(`api/quontation/register/${Register.register_number}/next-bill`);

        if (response.status === true) {
            setQuontationNext(response.data);
            //console.log(QuontationNext.payment_status_id)
        }
        setLoading(false);
    }

    useEffect(() => {
        getRegister();
    }, []);

    useEffect(() => {
        if (Register !== undefined) {
            getQuonation();
        }
    }, [Register]);

    return (
        <LayoutCustomerMain role="customer"  >
            <QuontationStatus1 />
            <div className={Css.Block_Transparent}><br />
                <h5 className={Css.color1}>ติดต่อเรา</h5><br />
                <div className={Css.Graph_Group}>
                    <div className='container m-auto'>
                        <Row>
                            <Col>
                                <div className={`${Css2.Login_inner_layout2} w-100`}>
                                    <h6 className='mb-4'>ติดต่อเรา</h6>
                                    <div className=" text-start w-100 m-auto  p-4">
                                        <b>Magic Software (Thailand) Corp., Ltd.</b>
                                        <br />
                                        9/68 Soi Ratchapracha, Ratchadapisek Rd.,
                                        <br />
                                        Chatuchack, Chatuchack,
                                        <br />
                                        Bangkok 10900
                                    </div>
                                    <Row className="p-2 ">
                                        <Col colSpan={2} className="text-start  p-4">
                                            <p className=" text-start w-100 m-auto">
                                                <b>Phone Numbers:</b> +66 (0) 2-911-9988
                                                <br />
                                                <b>Fax:</b> +66 (0) 2-911-9948
                                            </p>
                                        </Col>
                                    </Row>

                                    <p className=" text-start w-100 m-auto  p-4 ">
                                        <b>Office Hours:</b>
                                        <br />
                                        <span>
                                            Monday – Friday : 08:30 – 17:30
                                        </span>
                                    </p>

                                    <p className=" text-start w-100 m-auto  p-4 ">
                                        Email: isodcaas.support@magicsoftware.co.th
                                    </p>
                                    <div className="mb-5">
                                        <a onClick={handlefacebook}><img src={require("images/facebook.png")} width="50" height="50"/></a>
                                        <a className="ms-2" onClick={handlelinked} ><img src={require("images/linkedin.png")} width="50" height="50"/></a>
                                        <a onClick={handleline}  className="ms-2"><img src={require("images/line.png")} width="50" height="50"/></a>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <ContactUs />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        </LayoutCustomerMain>
    )
}