import { AddCommar } from 'components/MyControl';
import Css from './Register.module.css';
import { Row, Col, Form, Button, InputGroup, Spinner } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import ApiConection from 'api_ref/ApiConection';
import userEvent from '@testing-library/user-event';

export default function RegisterCustomerPayment({ data,
    mode = undefined,
    onChangeData = undefined,
    className,
    show_is_accept_agreement = false,
    disabled = false,
    renewMode = false,
    error = '',
    parentLoading = false }) {
    const api = new ApiConection();

    const [useCoupon, setUseCoupon] = useState(false);
    const [discountCode, setDiscountCode] = useState(data?.discount_code);
    const [mastrCoupon, setMasterCoupon] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [couponError, setCouponError] = useState('');
    const [canChange, setCanChange] = useState(true);
    const [noPackageAtUpgradeMode, setNoPackageAtUpgradeMode] = useState(false);
    const [discount, setDiscount] = useState(false);

    //console.log(data);
    const checkDiscount = async () => {
        setCouponError('');
        setLoading(true);
        if (discountCode !== '') {
            const response = await api.getData(`api/Coupon/${discountCode}/check-MasterCoupon`);
            if (response.status === true) {
                setMasterCoupon(response.data);
                if (disabled === false) {
                    if (onChangeData !== undefined)
                        onChangeData({ target: { name: 'discount_code', value: discountCode } });
                }
            } else {
                setCouponError(response.message);
                setMasterCoupon(undefined);
                if (onChangeData !== undefined)
                    onChangeData({ target: { name: 'discount_code', value: '' } });
            }
        }

        setLoading(false);
    }

    const handleCancelCoupon = (event) => {
        event.preventDefault();

        setCouponError('');
        setUseCoupon(false);
        setDiscountCode('');
    }

    const handleonChangeDiscount = () => {
        setDiscount(true);
    }

    const handleCancel = () => {
        setDiscount(false);
    }

    useEffect(() => {
        if (data?.discount_code !== '') {
            checkDiscount();
        }

        if (data?.arr_package[0]?.package_type === 'free') {
            setCouponError('');
            setDiscountCode('');
            setMasterCoupon(undefined);
            if (onChangeData !== undefined)
                onChangeData({ target: { name: 'discount_code', value: '' } });
        }
    }, [data?.discount_code]);

    useEffect(() => {
        if ((data?.compare?.after_users_volume !== undefined) &&
            ((Number(data?.compare?.before_users_volume.replace(' A/C', '')) > Number(data?.compare?.after_users_volume.replace(' A/C', ''))) ||
                (Number(data?.compare?.before_storage_volume.replace(' GB', '')) > Number(data?.compare?.after_storage_volume.replace(' GB', '')))))
            setCanChange(false);
        else
            setCanChange(true);
    }, [data.compare])

    useEffect(() => {
        if (mode === 'upgrade' && data.arr_package.length > 0) {
            if (data.arr_package.find(x => x.package_type === 'package') === undefined) {
                setNoPackageAtUpgradeMode(true);
            } else {
                setNoPackageAtUpgradeMode(false);
            }
        }
    }, [JSON.stringify(data.arr_package)])

    return (
        <div id='div_summary_details' className={`${Css.Register_customer_layout3} ${className}`}>

            <h6 className={`text-start ${Css.colorh6}`}>รายละเอียดการชำระเงิน</h6>
            <table className={`table table-borderless table-summary`}>
                <thead>
                    <tr>
                        <th className='text-start'>แพ็กเกจ</th>
                        <th className='col-4 text-end'>ราคา (บาท)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.arr_package?.length > 0 &&
                        data?.arr_package?.map((obj, index) => (
                            <tr key={index}>
                                <td className='font-color-privary'><pre>{(index + 1) + '. ' + obj.package_master_name_short}</pre></td>
                                <td className='font-color-privary text-end'>{AddCommar(Number(obj?.total?.toString().replace(',', '')).toFixed(2))}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            {
                data?.arr_package[0]?.package_type !== 'free' &&
                <>
                    {
                        loading === true &&
                        <Spinner animation="border" variant="primary" />
                    }
                    {
                        (disabled === false && renewMode === true) &&
                        loading === false && mastrCoupon === undefined &&
                        <>
                            {
                                useCoupon === false &&
                                <Button type={'button'} variant="warning" onClick={(_) => setUseCoupon(true)}>คลิกเพื่อใส่โค้ดส่วนลด</Button>
                            }
                            {
                                useCoupon === true &&
                                <InputGroup className={`${Css.body2}`} >
                                    <Form.Control type={'text'} name={'discount_code'}
                                        value={discountCode}
                                        placeholder="รหัสคูปอง"
                                        onChange={event => { setDiscountCode(event.target.value); setCouponError(''); }} />
                                    <Button type='button' className={Css.btnColor} onClick={(_) => checkDiscount()}>ใช้คูปอง</Button>
                                    <Button type='button' className='btn btn-secondary-trn' onClick={(event) => handleCancelCoupon(event)}>ยกเลิก</Button>
                                </InputGroup>
                            }

                            {
                                couponError !== '' &&
                                <Form.Text name={'discount_code_error'} className='text-danger'>{couponError}</Form.Text>
                            }

                        </>
                    }
                    {
                        (disabled === false && renewMode === true) &&
                        loading === false && mastrCoupon !== undefined &&
                        <>
                            <hr className='mt-2 mb-2' />
                            <Row>
                                <Col>คุณได้รับส่วนลด {mastrCoupon.name} <a className='link' onClick={(event) => { handleCancelCoupon(event); setDiscountCode(''); setMasterCoupon(undefined); onChangeData({ target: { name: 'discount_code', value: '' } }); }} >(ใช้ภายหลัง)</a></Col>
                                <Col sm={4} className='text-end font-color-privary '><label>{AddCommar(Number(data?.discount?.toString().replace(',', '')).toFixed(2))}</label></Col>
                            </Row>

                        </>
                    }
                    {
                        (renewMode == false || disabled === true) &&
                        data?.discount > 0 &&
                        <>
                            <hr className='mt-2 mb-2' />
                            <Row>
                                <Col>ส่วนลด จากโค้ดส่วนลด ({data.discount_code})</Col>
                                <Col sm={4} className='text-end font-color-privary '><label>{AddCommar(Number(data?.discount?.toString().replace(',', '')).toFixed(2))}</label></Col>
                            </Row>
                        </>
                    }

                    {
                        /**
                         *
                        <hr className='mt-2 mb-2'/>
                        <Row>
                            <Col>
                            {data?.is_with_holding_tax}
                                <Form.Check  type={'checkbox'} className={`mt-2 mb-2 ${Css.body2}`} inline
                                name="is_with_holding_tax"
                                id="is_with_holding_tax"
                                label="หักภาษี ณ ที่จ่าย 3%"
                                checked={data?.is_with_holding_tax}
                                onChange={event => onChangeData(event)}
                                disabled={disabled}
                                />
                            </Col>
                            <Col sm={4} className='text-end font-color-privary pt-2'>
                                <label> { (data?.tax == 0)? '' : AddCommar(data?.tax)}</label>
                            </Col>
                        </Row>
                         *
                        */
                    }

                </>
            }
            <hr className='mt-2 mb-4' />
            <h6 className={`text-start m-0 mt-3 mb-4 text-blue-01`}>สรุปค่าบริการ</h6>
            <Row className={`mb-2 text-blue-01`}>
                <Col className='text-start'><span className='d-inline '>รวมมูลค่าสินค้า</span></Col>
                <Col sm={7} className={`text-end`}>
                    <span className='mb-0 text-end'>{data?.arr_package?.length > 0 && AddCommar(data?.total)} บาท</span>
                    <Form.Text className='mt-0 pt-0' ></Form.Text>
                </Col>
            </Row>
            <Row className={`mb-2 text-blue-01`}>
                <Col className='text-start'><span className='d-inline '>ส่วนลด</span></Col>
                <Col sm={7} className={`text-end`}>
                    <span className='mb-0 text-end'>{data?.arr_package?.length > 0 && AddCommar(data?.discount)} บาท</span>
                    <Form.Text className='mt-0 pt-0' ></Form.Text>
                </Col>
            </Row>
            <Row className={`mb-2 text-blue-01`}>
                <Col className='text-start'><span className='d-inline '>ราคาหลังหักส่วนลด</span></Col>
                <Col sm={7} className={`text-end`}>
                    <span className='mb-0 text-end'>{data?.arr_package?.length > 0 && AddCommar(data?.net)} บาท</span>
                    <Form.Text className='mt-0 pt-0' ></Form.Text>
                </Col>
            </Row>
            <Row className={`mb-2  text-blue-01`}>
                <Col className='text-start'><span className='d-inline'>VAT ({data?.vat_rate}%)</span></Col>
                <Col sm={6} className={`text-end`}>
                    <span className='mb-0 text-end'>{data?.arr_package?.length > 0 && AddCommar(data?.vat)} บาท</span>
                    <Form.Text className='mt-0 pt-0' ></Form.Text>
                </Col>
            </Row>
            <Row className={`mb-4 text-blue-01 `}>
                <Col className='text-start'><span className='d-inline'>จำนวนเงินสุทธิ</span></Col>
                <Col sm={8} className={`text-end`}>
                    <span className='mb-0 text-end '>{data?.arr_package?.length > 0 && AddCommar(data?.grand_total)} บาท</span>
                    <Form.Text className='mt-0 pt-0' ></Form.Text>
                </Col>
            </Row>
            <Row className={`mb-2  text-blue-01`}>
                <Col className='text-start'><span className='d-inline'>หักภาษี ณ ที่จ่าย {AddCommar(data?.tax_rate)} %</span></Col>
                <Col sm={6} className={`text-end`}>
                    <span className='mb-0 text-end'>{data?.arr_package?.length > 0 && AddCommar(data?.tax)} บาท</span>
                    <Form.Text className='mt-0 pt-0' ></Form.Text>
                </Col>
            </Row>
            <Row className={`mb-2 text-blue-01 fw-semibold`}>
                <Col className='text-start'><span className='d-inline '>มูลค่ายอดชำระ</span></Col>
                <Col sm={7} className={`text-end`}>
                    <span className='mb-0 text-end'>{data?.arr_package?.length > 0 && AddCommar(data?.grand_total_sub_withholding_tax)} บาท</span>
                    <Form.Text className='mt-0 pt-0' ></Form.Text>
                </Col>
            </Row>
            {
                <Row className='mt-3'>
                    <Col>
                        <i>
                            ท่านสามารถหักภาษี ณ ที่จ่ายได้ ในกรณีที่ท่านมิได้หักไว้<br />
                            ทางบริษัทฯ จะไม่รับผิดชอบในกรณีหักย้อนหลัง
                        </i>

                    </Col>
                </Row>
            }
            {
                disabled === false &&
                <>
                    {
                        show_is_accept_agreement === true &&
                        <>
                            <br />
                            <Form.Check className='mt-2 mb-2'>
                                <Form.Check.Input
                                    name={"is_accept_agreement"}
                                    id={"is_accept_agreement"}
                                    onChange={(event) => onChangeData(event)}
                                    required={true}
                                />
                                <Form.Check.Label className={` ${Css.body2}`} >
                                    <span className={`me-1 ${Css.body2} `}>ยอมรับ</span>
                                    <Link to={'/register/Termsofuse'} target="_blank" className={`${Css.link_contact + " me-1"} ${Css.body2}`} >เงื่อนไขการใช้บริการ</Link>
                                    <span className={`me-1 ${Css.body2} `}>และ</span>
                                    <Link to={'/register/Privacypolicy'} target="_blank" className={`${Css.link_contact + " me-1"} ${Css.body2}`} >นโยบายความเป็นส่วนตัว</Link>
                                </Form.Check.Label>
                            </Form.Check>
                        </>
                    }

                    <div className='mt-2 mb-2'>&nbsp;</div>
                    {
                        !canChange && (data?.compare?.after_storage_volume !== undefined && data?.compare?.after_storage_volume !== '') &&
                        <span className="alert alert-danger">ไม่สามารถลดจำนวนผู้ใช้งาน/พื้นที่ไฟล์แนบได้ ขออภัยในความไม่สะดวก</span>
                    }
                    {
                        error !== '' &&
                        <div className="alert alert-danger mt-2" role="alert">
                            {error}
                        </div>
                    }

                    <Button type={'submit'} className={Css.btnColor}
                        disabled={(data?.arr_package?.length === 0 || !canChange || noPackageAtUpgradeMode ||
                            (show_is_accept_agreement === true && (data?.is_accept_agreement ?? false) === false)) ? true : false ||
                        parentLoading === true
                        }  >
                        {
                            parentLoading === true &&
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        }
                        ดำเนินการต่อ
                    </Button>

                </>
            }

            {//<div className={`mt-3 text-center fs-6 ${Css.msg}`}> * หลังจากกดปุ่มไปยังหน้าชำระเงิน ระบบจะจัดส่งใบสั่งซื้อผ่านทางอีเมล</div>
            }

        </div>
    )
}