import Layout4Main from "components/layout/Layout4Main";
import { Row, Col, Form, Button, Tab, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Css from 'components/pages/customer/Customer.module.css';
import CssRegis from 'components/pages/application/register/Register.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import { AddCommar, DateToText } from "components/MyControl";
import moment from "moment";
import UtilClass from "api_ref/UtilClass";

export default function Change1Package({ handleSetChangePackage = undefined, getValue = undefined, validated = false, onSubmit = undefined }) {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');
    const register = util.getSessionStorage('register');
    const quontation = util.getSessionStorage('quontation');

    const [loadingPeriod, setLoadingPeriod] = useState(false);
    const [loading, setLoading] = useState(false);

    const [packagePeriod, setPackagePeriod] = useState([]);
    const [packageMaster, setPackageMaster] = useState([]);

    const getPackagePeriod = async () => {
        setLoadingPeriod(true);
        const response = await api.getData(`api/master/package-period/quontation_number/${quontation.quontation_number}`);
        if (response.status === true) {
            setPackagePeriod(response.data);
            let first = true;
            response.data.map(obj_period => {
                if (first) {
                    handleSetChangePackage('period_id', obj_period.id);
                    handleSetChangePackage('package_period', obj_period.name);
                    first = false;
                }

                if (obj_period.id === getValue('period_id')) {
                    handleSetChangePackage('package_period', obj_period.name);
                }

                return 0;
            });
        }
        setLoadingPeriod(false);
    }
    const getPackageMaster = async () => {
        if (getValue('period_id') === undefined) return;

        setLoading(true);
        const response = await api.getData(`api/package/type/package/period/${getValue('period_id')}/quontation_number/${quontation.quontation_number}`);
        if (response.status === true) {
            //console.log(response.data);
            setPackageMaster(response.data);
            response.data.map(obj_package => {
                if (obj_package.id === getValue('package_id')) {
                    handleSetChangePackage('package_id', obj_package.id);
                    handleSetChangePackage('users_valume_id', obj_package.users_valume_id);
                    handleSetChangePackage('storage_valume_id', obj_package.storage_valume_id);
                    handleSetChangePackage('package_name', obj_package.name);
                    handleSetChangePackage('package_short_name', obj_package.description);
                    handleSetChangePackage('price', obj_package.net);
                    handleSetChangePackage('price_unit', obj_package.unit);
                    handleSetChangePackage('tax', obj_package.tax);
                    handleSetChangePackage('total', obj_package.total);
                    handleSetChangePackage('users_valume', obj_package.number_of_user);
                    handleSetChangePackage('storage_valume', obj_package.number_of_storage);
                }

                return 0;
            })
        }
        setLoading(false);
    }

    const handleSelectPackage = (id, users_valume_id, storage_valume_id, name, description, net, unit, tax, total, number_of_user, number_of_storage) => {
        handleSetChangePackage('package_id', id);
        handleSetChangePackage('users_valume_id', users_valume_id);
        handleSetChangePackage('storage_valume_id', storage_valume_id);
        handleSetChangePackage('package_name', name);
        handleSetChangePackage('package_short_name', description);
        handleSetChangePackage('price', net);
        handleSetChangePackage('price_unit', unit);
        handleSetChangePackage('tax', tax);
        handleSetChangePackage('total', total);
        handleSetChangePackage('users_valume', number_of_user);
        handleSetChangePackage('storage_valume', number_of_storage);
    }

    const handleSelectPeriod = (id, name) => {
        handleSetChangePackage('period_id', id);
        handleSetChangePackage('package_period', name);
    }

    useEffect(() => {
        getPackagePeriod();
    }, [])

    useEffect(() => {
        getPackageMaster();
    }, [getValue('period_id')])

    return (
        <div className={Css.Graph_Group}>
            <div className={Css.Graph_Box + ' shadow'}>
                <Form noValidate validated={validated} onSubmit={event => onSubmit(event)}>
                    <div className={Css.Content}>
                        <div className={Css.Header}>
                            <h6 className='mb-3'>เลือกแพ็กเกจ</h6>
                        </div>
                        <div className={Css.Content}>
                            <div className='mb-3 text-center'>
                                {
                                    loadingPeriod === false &&
                                    <div className={CssRegis.Package_Period}>
                                        {
                                            packagePeriod.map((obj, index) => (
                                                <Form.Check key={index} type={'radio'} className={CssRegis.Form_Check} name={'period_id'} id={'rdo_period_' + index} label={obj.name} value={obj.id} defaultChecked={obj.id == getValue('period_id')} onChange={event => handleSelectPeriod(obj.id, obj.name)} />
                                            ))
                                        }
                                    </div>
                                }

                            </div>

                            <div className={'mb-3 '}>

                                {
                                    packageMaster.map((obj_package, index) => (
                                        <div key={index} className={CssRegis.Package_box + ' ' + ((obj_package.id === getValue('package_id')) ? CssRegis.Selected : '')}>
                                            {
                                                obj_package.recommand === true &&
                                                <div className={CssRegis.Recommand}>
                                                    <i className="fa-regular fa-star"></i>
                                                    <span>แพ็กเกจแนะนำ</span>
                                                </div>
                                            }
                                            <h4>
                                                {obj_package.name}
                                            </h4>
                                            <h2>

                                                {obj_package.id === 1 && 'ฟรี'}
                                                {obj_package.id > 1 && obj_package.total}
                                            </h2>
                                            <div className={CssRegis.Unit}>
                                                {obj_package.unit}
                                            </div>
                                            <div className="text-center">
                                                <div className={CssRegis.Spac}>
                                                    {obj_package.number_of_user}
                                                </div>
                                                <div className={CssRegis.Spac}>
                                                    {obj_package.number_of_storage}
                                                </div>
                                                <div className={CssRegis.Spac}>
                                                    {obj_package.number_of_document}
                                                </div>
                                            </div>
                                            <div className={CssRegis.Feature}>
                                                <ul>
                                                    {
                                                        obj_package.arr_feature.map((obj_feature, index) => (
                                                            <li key={index}>
                                                                <i className="fa-solid fa-circle-arrow-right text-black-50"></i>
                                                                {obj_feature.description}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                            <div>
                                                <Button type={'button'} onClick={event => handleSelectPackage(obj_package.id, obj_package.users_valume_id, obj_package.storage_valume_id, obj_package.name, obj_package.description, obj_package.net, obj_package.unit, obj_package.tax, obj_package.total, obj_package.number_of_user, obj_package.number_of_storage)} >เลือกแพ็กเกจ</Button>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                        <div className='text-center mt-3'>

                            <Button type={'submit'} disabled={getValue('package_id') === 1} className='btn-primary w-33'>ดำเนินการต่อ &#62;</Button>
                        </div>
                    </div>
                </Form>
            </div>

        </div>
    )
}