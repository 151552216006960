import Css from './Register.module.css';
import { Row, Col, Form, Button } from "react-bootstrap";
import { AddCommar } from 'components/MyControl';
import ApiConection from 'api_ref/ApiConection';
import { useEffect, useRef, useState } from "react";

export default function RegisterCustomerSelectPackage({ data = undefined,
    mode = 'new',
    className = '',
    onChangeData = undefined }) {
    const api = new ApiConection();

    const [packagePeriod, setPackagePeriod] = useState([]);
    const [packageMaster, setPackageMaster] = useState([]);
    const [loadingPackage, setLoadingPackage] = useState(false);
    const [loadingAddon, setLoadingAddon] = useState(false);
    const [packageAddonUser, setpackageAddonUser] = useState([]);
    const [packageAddonStorage, setPackageAddonStorage] = useState([]);
    const [changePackage,setChangePackage] = useState(1)

    const assignEstitmatePeriod = (id, name) => {
        onChangeData({ target: { name: 'package_master_period_id', value: id } });
        onChangeData({ target: { name: 'package_master_period_name', value: name } });
    }
    const assingEstitmatePackage = (package_master_id,
        package_master_name,
        package_master_name_short,
        package_master_users_volume_id,
        package_master_storage_volume_id,
        package_type,
        net,
        vat,
        tax,
        total) => {
        let tmpEstimate = data;
        let index_package = tmpEstimate?.arr_package?.findIndex(x => x.package_type === 'package' || x.package_type === 'free');
        if (index_package > -1) {
            tmpEstimate?.arr_package?.splice(index_package, 1);
        }

        if (package_master_id !== undefined) {
            tmpEstimate?.arr_package.push({
                package_master_id: package_master_id,
                package_master_name: package_master_name,
                package_master_name_short: "แพ็กเกจ : " + package_master_name_short + "\nระยะเวลา" + tmpEstimate.package_master_period_name,
                package_master_users_volume_id: package_master_users_volume_id,
                package_master_storage_volume_id: package_master_storage_volume_id,
                package_type: package_type,
                net: net,
                vat: vat,
                tax: tax,
                total: Number(total.toString().replace(",", "")).toFixed(2),
            });
        }

        if (package_type === 'free') {
            tmpEstimate.discount_code = '';
            tmpEstimate.is_with_holding_tax = false;
            tmpEstimate.is_pay_now = false;

            let index_package = tmpEstimate.arr_package.findIndex(x => x.package_type === 'addon-user');
            if (index_package > -1) {
                tmpEstimate.arr_package.splice(index_package, 1);
            }
            index_package = tmpEstimate.arr_package.findIndex(x => x.package_type === 'addon-storage');
            if (index_package > -1) {
                tmpEstimate.arr_package.splice(index_package, 1);
            }
        } else {
            tmpEstimate.is_pay_now = true;
        }

        tmpEstimate.arr_package.sort((a, b) => (a.package_master_id > b.package_master_id) ? 1 : -1);

        onChangeData({ target: { name: 'discount_code', value: tmpEstimate.discount_code } });
        onChangeData({ target: { name: 'is_with_holding_tax', value: tmpEstimate.is_with_holding_tax } });
        onChangeData({ target: { name: 'is_pay_now', value: tmpEstimate.is_pay_now } });
        onChangeData({ target: { name: 'arr_package', value: tmpEstimate.arr_package } });

        setChangePackage(changePackage + 1);
        
        const element = document.getElementById("div_summary_details");
        element.scrollIntoView();
    }

    const assingEstitmateUserAddon = (event) => {
        const { name, value, type } = event.target;

        let tmpEstimate = data?.arr_package;
        let index_package = tmpEstimate.findIndex(x => x.package_type === 'addon-user');
        if (index_package > -1) {
            tmpEstimate.splice(index_package, 1);
        }

        if (value != 99) {
            const estimat_package = packageAddonUser.find(x => x.users_valume_id == value);
            //console.log(estimat_package);
            if (estimat_package !== undefined) {
                tmpEstimate.push({
                    package_master_id: estimat_package.id,
                    package_master_name: estimat_package.name,
                    package_master_name_short: estimat_package.description + ' ' + estimat_package.number_of_user,
                    package_master_users_volume_id: estimat_package.users_valume_id,
                    package_master_storage_volume_id: estimat_package.storage_valume_id,
                    package_type: estimat_package.type,
                    net: estimat_package.net,
                    vat: estimat_package.vat,
                    tax: estimat_package.tax,
                    total: estimat_package.total
                });
            }
        }

        tmpEstimate.sort((a, b) => (a.package_master_id > b.package_master_id) ? 1 : -1);

        onChangeData({ target: { name: 'arr_package', value: tmpEstimate } });
    }

    const assingEstitmateStorageAddon = (event) => {
        const { name, value, type } = event.target;

        let tmpEstimate = data?.arr_package;
        let index_package = tmpEstimate.findIndex(x => x.package_type === 'addon-storage');
        if (index_package > -1) {
            tmpEstimate.splice(index_package, 1);
        }

        if (value != 99) {
            const estimat_package = packageAddonStorage.find(x => x.storage_valume_id == value);
            //console.log(estimat_package);
            if (estimat_package !== undefined) {
                tmpEstimate.push({
                    package_master_id: estimat_package.id,
                    package_master_name: estimat_package.name,
                    package_master_name_short: estimat_package.description + ' ' + estimat_package.number_of_storage,
                    package_master_users_volume_id: estimat_package.users_valume_id,
                    package_master_storage_volume_id: estimat_package.storage_valume_id,
                    package_type: estimat_package.type,
                    net: estimat_package.net,
                    vat: estimat_package.vat,
                    tax: estimat_package.tax,
                    total: estimat_package.total
                });
            }
        }

        tmpEstimate.sort((a, b) => (a.package_master_id > b.package_master_id) ? 1 : -1);

        onChangeData({ target: { name: 'arr_package', value: tmpEstimate } });
    }

    const getPackagePeriod = async () => {
        const response = await api.getData('api/master/package-period');
        if (response.status === true) {
            setPackagePeriod(response.data);
            let is_declare = false;

            response.data.map(obj => {
                if (data?.package_master_period_id === undefined && !is_declare) {
                    assignEstitmatePeriod(obj.id, obj.name);
                    is_declare = true;
                }
                else if (obj.id == data?.package_master_period_id) {
                    assignEstitmatePeriod(obj.id, obj.name);
                }

                return 0;
            });
            if (response.data.find(x => x.id === data?.package_master_period_id) === undefined) {
                assignEstitmatePeriod(response.data[0].id, response.data[0].name);
            }
        }
    }
    const getPackageMaster = async () => {
        if (data?.package_master_period_id === undefined) return;

        setLoadingPackage(true);

        if (mode === 'new') {
            const response = await api.getData(`api/package/period/${data?.package_master_period_id}`);
            if (response.status === true) {
                setPackageMaster(response.data);

                let is_declare = false;
                response.data.map(obj => {
                    if (data?.arr_package[0]?.package_master_id === undefined && !is_declare) {
                        assingEstitmatePackage(
                            obj.id,
                            obj.name,
                            obj.description,
                            obj.users_valume_id,
                            obj.storage_valume_id,
                            obj.type,
                            obj.net == "ฟรี" ? 0 : obj.net,
                            obj.vat == "ฟรี" ? 0 : obj.vat,
                            obj.tax == "ฟรี" ? 0 : obj.tax,
                            obj.total == "ฟรี" ? 0 : obj.total
                        );
                        is_declare = true;
                    }
                    else if (obj.id == data?.arr_package[0]?.package_master_id) {
                        assingEstitmatePackage(
                            obj.id,
                            obj.name,
                            obj.description,
                            obj.users_valume_id,
                            obj.storage_valume_id,
                            obj.type,
                            obj.net == "ฟรี" ? 0 : obj.net,
                            obj.vat == "ฟรี" ? 0 : obj.vat,
                            obj.tax == "ฟรี" ? 0 : obj.tax,
                            obj.total == "ฟรี" ? 0 : obj.total
                        );
                    }

                    return 0;
                })
            }
        } else {
            const response = await api.getData(`api/package/type/package/period/${data?.package_master_period_id}/register/${data?.register_number}`);
            if (response.status === true) {
                setPackageMaster(response.data);

                response.data.map(obj => {
                    if (obj.id == data?.arr_package[0]?.package_master_id) {
                        assingEstitmatePackage(
                            obj.id,
                            obj.name,
                            obj.description,
                            obj.users_valume_id,
                            obj.storage_valume_id,
                            obj.type,
                            obj.net == "ฟรี" ? 0 : obj.net,
                            obj.vat == "ฟรี" ? 0 : obj.vat,
                            obj.tax == "ฟรี" ? 0 : obj.tax,
                            obj.total == "ฟรี" ? 0 : obj.total
                        );
                    }

                    return 0;
                });
            }
        }
        setChangePackage(changePackage + 1);
        setLoadingPackage(false);
    }

    const getPackageAddonUser = async () => {
        if (data?.package_master_period_id === undefined) return;

        console.log(data);
        setLoadingAddon(true);
        if (mode === 'new') {
            const response6 = await api.getData(`api/Package/type/addon-users/period/${data?.package_master_period_id}/register/${data?.register_number}/master_package_id/${data?.arr_package[0]?.package_master_id}`);

            response6.splice(0, 0, { users_valume_id: 99, number_of_user: 'N/A', total: '0' });
            setpackageAddonUser(response6);

            const response7 = await api.getData(`api/Package/type/addon-storages/period/${data?.package_master_period_id}/register/${data?.register_number}/master_package_id/${data?.arr_package[0]?.package_master_id}`);
            response7.splice(0, 0, { storage_valume_id: 99, number_of_storage: 'N/A', total: '0' });
            setPackageAddonStorage(response7);
        } else {
            let package_master_id = data?.arr_package[0]?.package_master_id;
            if(mode === 'addon')
                package_master_id = data?.package_master_id;

            const response6 = await api.getData(`api/Package/type/addon-users/period/${data?.package_master_period_id}/register/${data?.register_number}/mode/${mode}/master_package_id/${package_master_id}`);

            response6.splice(0, 0, { users_valume_id: 99, number_of_user: 'N/A', total: '0' });
            setpackageAddonUser(response6);
            
            const response7 = await api.getData(`api/Package/type/addon-storages/period/${data?.package_master_period_id}/register/${data?.register_number}/mode/${mode}/master_package_id/${package_master_id}`);
            response7.splice(0, 0, { storage_valume_id: 99, number_of_storage: 'N/A', total: '0' });
            setPackageAddonStorage(response7);
        }

        setLoadingAddon(false);
    }

    const getPacakgeSelectClass = (package_master_id) => {
        if (data?.arr_package?.findIndex(x => x.package_master_id === package_master_id) > -1)
            return ' ' + Css.Selected;
        else
            return '';
    }

    useEffect(() => {
        getPackagePeriod();
    }, []);

    useEffect(() => {
        if (data?.package_master_period_id !== undefined) {
            getPackageMaster();
            getPackageAddonUser();
        }
    }, [data?.package_master_period_id]);

    useEffect(()=>{

        const delay = setTimeout( async() => {
            // เรียกใช้ฟังก์ชันสำหรับการคำนวณผลลัพธ์ที่ต้องการ
          
            await getPackageAddonUser();

        }, 100);

        return () => clearTimeout(delay);

        
    },[changePackage])

    useEffect(() => {
        packageAddonStorage.map(obj => {
            if (obj.storage_valume_id == data?.arr_package.find(x => x.package_type === 'addon-storage')?.package_master_storage_volume_id) {
                assingEstitmateStorageAddon({ target: { name: 'package_addon_storage', value: obj.storage_valume_id } });
            }

            return 0;
        })
    }, [packageAddonStorage]);

    useEffect(() => {
        packageAddonUser.map(obj => {
            if (obj.users_valume_id == data?.arr_package.find(x => x.package_type === 'addon-user')?.package_master_users_volume_id) {
                assingEstitmateUserAddon({ target: { name: 'package_addon_user', value: obj.users_valume_id } });
            }

            return 0;
        })
    }, [packageAddonUser]);

    return (
        <div className={`${Css.Register_customer_layout2} + ' ' +  ${className}`}>
            <div className="custom accordion" id="accordionExample">
                {
                    mode !== 'addon' &&
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button no-icon" type="button"  >
                                ระยะเวลา
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" >
                            <div className="accordion-body text-center">
                                <div className={Css.Package_Period}>
                                    {
                                        packagePeriod.map((obj, index) => (
                                            <Form.Check key={index} type={'radio'} className={`${Css.Form_Check} ${Css.body2}`} name={'package_master_period_id'} id={'package_master_period_id_' + index} label={obj.name} value={obj.id} defaultChecked={obj.id == data?.package_master_period_id}
                                                onChange={event => { assignEstitmatePeriod(obj.id, obj.name) }} />
                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    data?.total !== undefined && mode !== 'addon' &&
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                            {/*data-bs-toggle="collapse" data-bs-target=".pack" aria-expanded="true" aria-controls="collapseTwo collapseThree"*/}
                            <button className="accordion-button" type="button" >
                                แพ็กเกจ {data?.arr_package != undefined &&
                                    data?.arr_package?.length > 0 &&
                                    data?.arr_package.find(x => x.package_type === 'package') !== undefined &&
                                    data?.arr_package.find(x => x.package_type === 'package')?.package_master_name + ' ' + AddCommar(data?.arr_package[0]?.total) + ' บาท'
                                }
                                {
                                    (mode === 'upgrade' || mode === 'new') &&
                                    data?.arr_package?.length > 0 &&
                                    data?.arr_package.find(x => x.package_type === 'package') === undefined &&
                                    <span className='alert alert-warning p-0 ps-3 pe-3 m-0 ms-2'>
                                        <i className="fa-solid fa-triangle-exclamation me-2" title=''></i>
                                        กรุณาเลือกแพ็กเกจหลัก!
                                    </span>
                                }
                            </button>
                        </h2>
                        <div id="collapseTwo" className={`accordion-collapse collapse pack show`} >
                            <div className="accordion-body text-center">
                                <div className={'mb-3 ' + Css.Package_Slide}>
                                    {
                                        packageMaster.map((obj_package, index) => (
                                            <div key={index} className={Css.Package_box + getPacakgeSelectClass(obj_package.id)}>
                                                {
                                                    obj_package.recommend === true &&
                                                    <div className={Css.Recommand}>
                                                        <i className="fa-regular fa-star"></i>
                                                        <span>แพ็กเกจแนะนำ</span>
                                                    </div>
                                                }
                                                <h4>
                                                    {
                                                        loadingPackage === true &&
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    }
                                                    {obj_package.name}
                                                </h4>
                                                <h2>
                                                    {obj_package.total}
                                                </h2>
                                                <div className={Css.Unit}>
                                                    {obj_package.unit}
                                                </div>
                                                <div className="text-center">
                                                    <div className={Css.Spac}>
                                                        {obj_package.number_of_user}
                                                    </div>
                                                    <div className={Css.Spac}>
                                                        {obj_package.number_of_storage}
                                                    </div>
                                                    <div className={Css.Spac}>
                                                        {obj_package.number_of_document}
                                                    </div>
                                                </div>
                                                <div className={Css.Feature}>
                                                    <ul>
                                                        {
                                                            obj_package.arr_feature.map((obj_feature, index) => (
                                                                <li key={index}>
                                                                    <i className="fa-solid fa-circle-arrow-right"></i>
                                                                    {obj_feature.description}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                                <div>
                                                    {/*data-bs-toggle="collapse" data-bs-target=".pack" aria-expanded="true" aria-controls="collapseTwo collapseThree"*/}
                                                    <Button type={'button'}
                                                        onClick={event => assingEstitmatePackage(
                                                            obj_package.id,
                                                            obj_package.name,
                                                            obj_package.description,
                                                            obj_package.users_valume_id,
                                                            obj_package.storage_valume_id,
                                                            obj_package.type,
                                                            obj_package.net == "ฟรี" ? 0 : obj_package.net,
                                                            obj_package.vat == "ฟรี" ? 0 : obj_package.vat,
                                                            obj_package.tax == "ฟรี" ? 0 : obj_package.tax,
                                                            obj_package.total == "ฟรี" ? 0 : obj_package.total
                                                        )}>สมัครแพ็กเกจนี้</Button>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    data?.total !== undefined && mode === 'addon' &&
                    <div className="accordion-item">
                        <h2 className="accordion-header accordion-button">แพ็กเกจ {data?.package_master_name} {data?.package_master_period_name}</h2>
                    </div>
                }
                <hr className='blue-line' />
                {
                    data?.total !== undefined && data?.arr_package[0]?.package_type !== 'free' &&
                    <div id='div_add_on' className="accordion-item">
                        <h2 className="accordion-header">
                            <button className="accordion-button" type="button" >
                                ส่วนเสริม
                            </button>
                        </h2>
                        <div id="collapseThree" className={`accordion-collapse pack ${(mode != 'addon') ? 'show' : 'show'}`} >{/*collapse */}
                            <div className="accordion-body text-center">
                                <Row className={` m-0 ${Css.extra} ${Css.body2}`}>
                                    <Col className={Css.checkboxSize}>
                                        <Form.Check
                                            inline
                                            label="เพิ่มผู้ใช้งาน"
                                            name="is_addon_user"
                                            id="is_addon_user"
                                            type={'checkbox'}
                                            checked={data?.is_addon_user}
                                            onChange={event => { onChangeData(event); assingEstitmateUserAddon(event); }}
                                        />
                                    </Col>
                                    <Col >
                                        <Form.Select name={'package_addon_user'}
                                            disabled={data?.is_addon_user === false}
                                            value={data?.arr_package?.find(x => x.package_type === 'addon-user')?.package_master_users_volume_id ?? 99}
                                            onChange={event => assingEstitmateUserAddon(event)} >
                                            {
                                                packageAddonUser.map((obj, index) => (
                                                    <option key={index} value={obj.users_valume_id} >{obj.number_of_user}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Col>

                                    <Col className={`text-end font-color-privary`}>
                                        {data?.arr_package?.find(x => x.package_type === 'addon-user')?.total ?? '-'} บาท
                                    </Col>

                                </Row>

                                <Row className={`m-0 mt-2 ${Css.extra} ${Css.body2}`}>
                                    <Col className={Css.checkboxSize}>
                                        <Form.Check
                                            inline
                                            label="เพิ่มพื้นที่ไฟล์แนบ"
                                            name="is_addon_storage"
                                            id="is_addon_storage"
                                            type={'checkbox'}
                                            checked={data?.is_addon_storage}
                                            onChange={event => { onChangeData(event); assingEstitmateStorageAddon(event); }}
                                        />
                                    </Col>

                                    <Col >
                                        <Form.Select name={'package_addon_storage'}
                                            disabled={data?.is_addon_storage === false}
                                            value={data?.arr_package?.find(x => x.package_type === 'addon-storage')?.package_master_storage_volume_id ?? 99}
                                            onChange={event => assingEstitmateStorageAddon(event)} >
                                            {
                                                packageAddonStorage.map((obj, index) => (
                                                    <option key={index} value={obj.storage_valume_id} >{obj.number_of_storage}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Col>
                                    <Col className={`text-end  font-color-privary`}>
                                        {data?.arr_package?.find(x => x.package_type === 'addon-storage')?.total ?? '-'} บาท
                                    </Col>

                                </Row>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>

    );
}