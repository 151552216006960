import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import QuontationStatus1 from "./QuontationStatus1";
import Css from 'components/pages/customer/Customer.module.css';
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import ProfileCompany from "./ProfileCompany";
import { ToastError, ToastSuccess } from 'components/MyControl';
import UtilClass from "api_ref/UtilClass";

export default function CustomerProfileCompany() {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');
    const register = util.getSessionStorage('register');

    const [company, setCompany] = useState(undefined);
    const [alert, setAlert] = useState('');
    const [showToastSuccess, setShowToastSuccess] = useState(false);
    const [showToastError, setShowToastError] = useState(false);

    const getData = async () => {
        const response = await api.getData(`api/customerprofile/${register.user_id}/register/${register.register_number}/company`);
        //console.log(response);
        if (response.status === true) {
            setCompany(response.data);
        }
    }

    const setData = async (Childdata) => {
        let request = {
            //"company_name": Childdata.company_name,
            "company_address": Childdata.company_address,
            "company_tel": Childdata.company_tel,
            "tax_id": Childdata.tax_id,
            "site_url": Childdata.site_url,
            "full_site_url": Childdata.full_site_url,
            "business_type_id": Childdata.business_type_id,
            "business_type_name": Childdata.business_type_name,
            "business_category_id": Childdata.business_category_id,
            "business_category_name": Childdata.business_category_name,
            "employee_count_id": Childdata.employee_count_id,
            "employee_count_name": Childdata.employee_count_name,
            "description": Childdata.description
        }

        const response = await api.putData(`api/customerprofile/${register.user_id}/register/${register.register_number}/company`, request);

        if (response.status === false) {
            setAlert(response.message);
            setShowToastError(true);
        }
        else {
            setAlert('');
            await getData();
            setShowToastSuccess(true);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <LayoutCustomerMain role="customer" >
            <QuontationStatus1 />
            <div className={Css.Block_Transparent}><br />
                <h5 className={Css.color1}>ข้อมูลบริษัท</h5><br />
                <div className={Css.Graph_Group}>
                    {company != undefined &&
                        <ProfileCompany
                            data={company}
                            callSubmitParentData={setData}
                            parentAlert={alert} />}
                </div>

            </div>
            <ToastSuccess show={showToastSuccess} closeFc={setShowToastSuccess} message='ระบบได้บันทึกข้อมูลแล้ว' />
            <ToastError show={showToastError} closeFc={setShowToastError} message={'เกิดข้อผิดพลาด!!'} />
        </LayoutCustomerMain>
    )
}