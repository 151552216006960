import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom";
import UtilClass from "api_ref/UtilClass";
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";
import Notices from "components/mockup/Notices";
import Attribute from "components/mockup/Attribute";
import KeyFeatures from "components/mockup/KeyFeatures";
import Security from "components/mockup/Security";
import PackageMockup from "components/mockup/PackageMockup";
import ApiConection from "api_ref/ApiConection";
import FeatureMockup from "components/mockup/FeatureMockup";
import Banner2 from "components/mockup/Banner2";
import ParticlesFireworks from "components/mockup/ParticlesFireworks";
import { useState } from "react";
import moment from "moment/moment";
import YouTubeVideo from "components/mockup/YouTubeVideo";

export default function Home() {
    const util = new UtilClass();
    const [notice, setnotice] = useState(false)
    const [fireworks,setFireworks] = useState(false)
    
    /*useEffect(() => {
        //Set time foe notice
        const now = moment().format("YYYYMMDD")
        if (now > 20231031) {
            setnotice(false)
            setFireworks(false)
        } 
        else{
            setInterval(()=>{
                setFireworks(false)
            },12000)
        }

        window.scrollTo(0, 0);
    }, [])*/

    useEffect(()=>{

        window.setTimeout(()=>{
            // 1. อ่านและแยกวิเคราะห์พารามิเตอร์ URL
            const urlParams = new URLSearchParams(window.location.search);
            const targetSection = urlParams.get('section');

            // 2. เลื่อนหน้าเว็บไปยังส่วนที่ต้องการ
            if (targetSection == 'package') {
                const yOffset = -150;
                const targetElement = document.getElementById('div_package_mockup');
                const y = targetElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

                if (targetElement) {
                    
                    // ใช้ Element.scrollIntoView() เพื่อเลื่อนไปยัง targetElement
                    //targetElement.scrollIntoView({ behavior: 'smooth' });
                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            }
        },500)
        

    },[])
    
    const navigate = useNavigate();

    return (
        <LayoutLandingPageV2  >
            {
                notice === true && <Notices/>
            }
            {
                fireworks === true && <ParticlesFireworks/>
            }
           
            <Banner2 />
            <YouTubeVideo/>
            <FeatureMockup />
            <Attribute />
            <KeyFeatures />
            <Security />
            <PackageMockup />
        </LayoutLandingPageV2>
    );
}