import React from "react";
import Css from './Compare.module.css'
import LandingPageHeadMenu from "components/layout/LandingPageHeadMenu";
import ApiConection from 'api_ref/ApiConection';
import { useEffect, useState } from "react";
import LayoutLandingPage from "components/layout/LayoutLandingPage";
import UtilClass from "api_ref/UtilClass";
import LayoutLandingPageV2 from "components/layout/LayoutLandingPageV2";

export default function Compare() {
    const api = new ApiConection();
    const util = new UtilClass();

    const PackageSession = util.getSessionStorage('Package');

    const [compare, setCompare] = useState([]);

    const getData = async () => {
        //console.log(1)
        const response = await api.callLandingPageGet(`api/Package/compare-packages`);

        if (response.status === true) {
            setCompare(response.data);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getData();
        //console.log(compare)
    }, []);

    return (
        <>
            <LayoutLandingPageV2 showFooter={false}>
                <div className={Css.Head_Feature}>

                    <div className={Css.Top}>
                        <div className={`text-center ${Css.Feature}`}>
                            <span className={`text-center ${Css.fontsize}`}>
                                <i className="fa-regular fa-star "></i>
                                <label className="text-center ps-2">
                                    เปรียบเทียบแพ็คเกจ
                                </label>
                            </span>

                        </div>
                    </div>
                    <div className={Css.Bottom}></div>

                </div>
                <div className={Css.Login_inner_layout + ' mb-4'} >
                    <div className={Css.pad}>
                        <table >
                            <thead>
                                <tr>
                                    <th className='pb-4 pt-4  text-center fs-5 border border-bottom-1 bg-gray-02' width={400}>แพ็กเกจ</th>
                                    {/*<th className={`'pb-4 pt-4 text-center border border-white' ${Css.colorback}`}width={230}>Free Trial</th>*/}
                                    <th className={`pb-4 pt-4 text-center fs-5 border bg-blue-01 text-white`} width={220}>Beginner </th>
                                    <th className={`pb-4 pt-4 text-center fs-5 border  bg-blue-01 text-white`} width={220}>Standard</th>
                                    <th className={`pb-4 pt-4 text-center fs-5 border  bg-blue-01 text-white`} width={220}>Professional</th>
                                </tr>
                            </thead>
                            {
                                compare !== undefined && compare?.length > 0 &&
                                <tbody >
                                    {/*<tr className={` ${Css.fontpage}`} >
                                    <td className='pb-3 pt-3 border  border-top-0 border-bottom-0 ps-4' colSpan={5}>แพ็กเกจ</td>
                                </tr>

                                  <tr >
                                    <td className='border border-top-0 border-bottom-0 ps-4 pb-3 pt-3'>รายเดือน</td>
                                    {<td className='border border-top-0 border-bottom-0 text-center pb-3 pt-2 pt-3'>
                                        {compare[0].unit_one_month}
                                    </td>}
                                    <td className='border border-top-0 border-bottom-0 text-center pb-3 pt-3'>
                                        {compare[1].unit_one_month}
                                    </td>
                                    <td className='border border-top-0 border-bottom-0 text-center pb-3 pt-3'>
                                        {compare[2].unit_one_month}
                                    </td>
                                    <td className='border border-top-0 border-bottom-0 text-center pb-3 pt-3'>
                                        {compare[3].unit_one_month}
                                    </td>
                                </tr>
                                */}

                                    <tr>
                                        <td className='border border-top-0 border-bottom-0 ps-4 pb-3'>ราย 3 เดือน</td>
                                        {/*<td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                        {compare[0].unit_three_month}
                                </td>*/}
                                        <td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                            {compare[1].unit_three_month}
                                        </td>
                                        <td className='border border-top-0 border-bottom-0 text-center pb-2 '>
                                            {compare[2].unit_three_month}
                                        </td>
                                        <td className='border border-top-0 border-bottom-0 text-center pb-2 '>
                                            {compare[3].unit_three_month}
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className='border border-top-0 border-bottom-0 ps-4 pb-3'>ราย 6 เดือน</td>
                                        {  /*<td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                        {compare[0].unit_six_month}
                            </td>*/}
                                        <td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                            {compare[1].unit_six_month}
                                        </td>
                                        <td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                            {compare[2].unit_six_month}
                                        </td>
                                        <td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                            {compare[3].unit_six_month}
                                        </td>
                                    </tr >
                                    <tr >
                                        <td className='border border-top-0 border-bottom-0 ps-4 pb-3'>รายปี</td>
                                        {/* <td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                        {compare[0].unit_year}
                        </td>*/}
                                        <td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                            {compare[1].unit_year}
                                        </td>
                                        <td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                            {compare[2].unit_year}
                                        </td>
                                        <td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                            {compare[3].unit_year}
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className='border border-top-0 border-bottom-0 ps-4 pb-3'>จำนวนผู้ใช้งาน</td>
                                        {/* <td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                        {compare[0].number_of_user}
                    </td>*/}
                                        <td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                            {compare[1].number_of_user}
                                        </td>
                                        <td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                            {compare[2].number_of_user}
                                        </td>
                                        <td className='border border-top-0 border-bottom-0 text-center pb-2'>
                                            {compare[3].number_of_user}
                                        </td>
                                    </tr>
                                    <tr >
                                        <td className='border border-top-0  ps-4 pb-3'>พื้นที่เก็บข้อมูล</td>
                                        {/* <td className='border border-top-0  text-center pb-2'>
                                        {compare[0].number_of_storage}
                </td>*/}
                                        <td className='border border-top-0 text-center pb-2'>
                                            {compare[1].number_of_storage}
                                        </td>
                                        <td className='border border-top-0 text-center pb-2'>
                                            {compare[2].number_of_storage}
                                        </td>
                                        <td className='border border-top-0 text-center pb-2'>
                                            {compare[3].number_of_storage}
                                        </td>
                                    </tr>
                                    {/*<tr >
                                    <td className='border border-top-0  ps-4 pb-4'>รูปแบบเอกสาร</td>
                                    <td className='border border-top-0  text-center pb-4'>
                                        {compare[0].number_of_document}
                                    </td>
                                    <td className='border border-top-0  text-center pb-4'>
                                        {compare[1].number_of_document}
                                    </td>
                                    <td className='border border-top-0  text-center pb-4'>
                                        {compare[2].number_of_document}
                                    </td>
                                    <td className='border border-top-0  text-center pb-4'>
                                        {compare[3].number_of_document}
                                    </td>
                                 </tr>*/}
                                </tbody>
                            }
                        </table>
                    </div>
                </div>
            </LayoutLandingPageV2>

        </>
    )
}