import ApiConection from 'api_ref/ApiConection';
import { useEffect, useState } from 'react';
import Css from 'components/pages/customer/Customer.module.css';
import { AddCommar, DateToText } from 'components/MyControl';
import moment from "moment";

export default function SubscriptionHistory({ user_id, register_number }) {
    const api = new ApiConection();

    const [quontation, setQuontation] = useState([]);

    const getSubScription = async () => {
        let data = {
            search: {},
            pagination: {
                page: 1,
                size: 5,
                order_by:'quontation_number',
                order_mode:'desc'
            }
        }
        const response = await api.postData(`api/quontation/register/${register_number}/customer/${user_id}/result`, data);
        if (response.status === true) {
            setQuontation(response.data);
        } else {
            setQuontation([]);
        }
    }
    useEffect(() => {
        getSubScription();
    }, [user_id, register_number])

    return (
        <div className={Css.Graph_Box + ' shadow'}>
            <div className={Css.Content}>
                <div className={Css.Header}>
                    <h6>Subscription History</h6>
                </div>
                <div className={Css.Content}>
                    <div className="table-responsive rounded border">
                        <table className="table m-0">
                            <thead className="table-light">
                                <tr>

                                    <th >วันที่แพ็กเกจ</th>
                                    <th>แพ็กเกจ</th>
                                    <th>สถานะชำระ</th>
                                    <th>สถานะแพ็กเกจ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    quontation.map((obj, index) => {
                                        return (
                                            <tr key={index}>

                                                <td className='text-start'>{DateToText(obj?.effective_date) + ' - ' + DateToText(obj?.expiry_date)}</td>
                                                <td>{obj.package_master_name_short}</td>
                                                <td>{obj.payment_status_name}</td>
                                                <td>
                                                    {
                                                    (obj.active === true &&  (moment() < moment(obj.effective_date)) ) && <span className="alert d-inline p-0 ps-2 pe-2 alert-warning">Pending</span>
                                                    }
                                                    {
                                                    (obj.active === true &&  (moment() >= moment(obj.effective_date))) && <span className="alert d-inline p-0 ps-2 pe-2 alert-success">Active</span>
                                                    }
                                                    {
                                                    (obj.active === false ) && <span className="alert d-inline  p-0 ps-2 pe-2 alert-danger">Expiry</span>
                                                    }    
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className={Css.Footer + ' d-none'}>
                <a href={'#'}>Show More</a>
            </div>
        </div>
    )
}