import React from "react";
import { Nav, Navbar, Button, Container, NavDropdown } from "react-bootstrap";
import Css from './Mockup.module.css'
import { NavLink, useNavigate } from "react-router-dom";
import UtilClass from "api_ref/UtilClass";
import ApiConection from "api_ref/ApiConection";
import { useState } from "react";
import { useEffect } from "react";

export default function HeaderMockup() {
    const [atsUrl, setAtsUrl] = useState();

    const api = new ApiConection();
    const util = new UtilClass();

    const navigate = useNavigate();

    const getATSUrl = async () => {
        const response = await api.callLandingPageGet(`api/ConfigApp/54`);
        if (response.status === true) {
            setAtsUrl(response.data.value);
        } else {
            setAtsUrl(process.env.REACT_APP_ATS_POTAL);
        }
    }

    const handleATSPotral = (event) => {
        window.open(atsUrl, '_blank');
    }
    const handleModule = (event) => {
        navigate('/module');
    }

    const handleLogin = (event) => {
        navigate('/login');
    }

    const handleGetClass = (path) => {
        let urlHome = process.env.PUBLIC_URL.substring(9, 0);
        let nowPath = window.location.pathname;

        if (nowPath.indexOf(path) > -1) {
            if (nowPath === path) {
                return Css.Selected;
            }
        }
    }

    const handleFreeRegister = (event) => {
        //---- save period = 1 = month , package free id = 1;
        util.setCookie('period_id', 1, 1);
        util.setCookie('package_id', 1, 1);
        navigate('/register');
    }

    const handleHome = (event) => {
        event.preventDefault();

        navigate('/');

        window.setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100)
    }

    const handleAboutUs = (event) => {
        event.preventDefault();

        navigate('/');

        window.setTimeout(() => {
            const yOffset = -150;
            const element = document.getElementById('div_feature_mockup');
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }, 100)
    }

    const handlePackageMockup = (event) => {
        event.preventDefault();
        navigate('/');

        window.setTimeout(() => {
            const yOffset = -150;
            const element = document.getElementById('div_package_mockup');
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }, 100)
    }

    useEffect(() => {
        getATSUrl();
    }, []);

    return (
        <div className={`${Css.Menu} ${Css.Fixed} ${Css.Menu_Mobile}`}>
            <span className="d-block text-center fs-2 text-white bg-danger">{process.env.REACT_APP_ENVIRONMENT}</span>
            <Container className={Css.MenuContainer1}>

                <Navbar expand="sm" className={`${Css.width_mobile_nav} flex-wrap`}>
                    <Nav className={Css.WidthHeader}>
                        <Navbar.Toggle style={{ border: "none" }}>
                            <i className="fa-solid fa-bars" style={{ color: "#1E1E1E" }} />
                        </Navbar.Toggle>
                        <Navbar >
                            <img src={require('../../images/DCasS.png')} className={Css.img_nav_mobile_header} alt={''} height={'56px'} />

                        </Navbar>
                        <div className={`${Css.paddingNav} ${Css.Login}`}>
                            <NavDropdown title="เข้าสู่ระบบ" id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={handleLogin} className={`text-start ${Css.smt}`}>ระบบ Admin</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={handleATSPotral} className={`text-start ${Css.smt}`}>ระบบ ISO DCaaS</NavDropdown.Item>
                            </NavDropdown>
                        </div>

                        <Navbar.Collapse id="navbarScroll" style={{ backgroundColor: "#fff", padding: "15px 15px 0 15px" }}>
                            <Nav className={`me-auto my-2 my-lg-0 ${Css.paddingNav1}`} navbarScroll >
                                <Nav.Link to={'/'} className={Css.smt + ' ' + handleGetClass('/dcaasweb')} onClick={(event) => handleHome(event)} >หน้าหลัก</Nav.Link>
                                <Nav.Link to={'#'} className={Css.smt + ' ' + handleGetClass('#')} onClick={(event) => handleAboutUs(event)}>เกี่ยวกับเรา</Nav.Link>
                                <Nav.Link to={'#'} className={Css.smt + ' ' + handleGetClass('#')} onClick={(event) => handlePackageMockup(event)} >ราคาแพ็กเกจ</Nav.Link>
                                <NavLink to={'/article'} className={Css.smt1 + ' ' + handleGetClass('/dcaasweb/article')} >บทความ</NavLink>
                                <NavLink to={'/manual'} className={Css.smt1 + ' ' + handleGetClass('/dcaasweb/manual')}>ช่วยเหลือ</NavLink>

                                <NavDropdown title="เข้าสู่ระบบ" id="basic-nav-dropdown" className={Css.Login1}>
                                    <NavDropdown.Item onClick={handleLogin} className={`text-start ${Css.smt}`}>ระบบ Admin</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={handleATSPotral} className={`text-start ${Css.smt}`}>ระบบ ISO DCaaS</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                            <Nav className={`${Css.Nav_Mobile2} ${Css.paddingNav1}`}>
                                <Button type="button" className={`${Css.btn_blue} ${Css.btn_round}`} onClick={handleFreeRegister}>ทดลองใช้งานฟรี</Button>
                            </Nav>
                        </Navbar.Collapse>
                    </Nav>
                    <Nav className={`${Css.Nav_Medium}`}>
                        <Button type="button" className={`${Css.btn_blue} ${Css.btn_round}`} onClick={handleFreeRegister}>ทดลองใช้งานฟรี</Button>
                    </Nav>
                </Navbar>
            </Container>

            <Container className={Css.MenuContainer}>
                <Navbar expand="lg" className={`${Css.width_mobile_nav}`}>
                    <Navbar.Toggle style={{ border: "none" }}>
                        <i className="fa-solid fa-bars" style={{ color: "#1E1E1E" }} />
                    </Navbar.Toggle>
                    <Navbar >
                        <img src={require('../../images/DCasS.png')} className={Css.img_nav_mobile_header} alt={''} height={'56px'} />
                    </Navbar>
                    <div className={`${Css.paddingNav} ${Css.Login}`}>
                        <NavDropdown title="เข้าสู่ระบบ" id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={handleLogin} className={`text-start ${Css.smt}`}>ระบบ Admin</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={handleATSPotral} className={`text-start ${Css.smt}`}>ระบบ ISO DCaaS</NavDropdown.Item>
                        </NavDropdown>
                    </div>

                    <Navbar.Collapse id="navbarScroll" style={{ backgroundColor: "#fff", padding: "15px" }}>
                        <Nav className={`me-auto my-2 my-lg-0 ${Css.paddingNav1}`} navbarScroll >
                            <Nav.Link to={'/'} className={Css.smt + ' ' + handleGetClass('/dcaasweb')} onClick={(event) => handleHome(event)} >หน้าหลัก</Nav.Link>
                            <Nav.Link to={'#'} className={Css.smt + ' ' + handleGetClass('#')} onClick={(event) => handleAboutUs(event)}>เกี่ยวกับเรา</Nav.Link>
                            <Nav.Link to={'#'} className={Css.smt + ' ' + handleGetClass('#')} onClick={(event) => handlePackageMockup(event)} >ราคาแพ็กเกจ</Nav.Link>
                            <NavLink to={'/article'} className={Css.smt1 + ' ' + handleGetClass('/dcaasweb/article')} >บทความ</NavLink>
                            <NavLink to={'/manual'} className={Css.smt1 + ' ' + handleGetClass('/dcaasweb/manual')}>ช่วยเหลือ</NavLink>

                            <NavDropdown title="เข้าสู่ระบบ" id="basic-nav-dropdown" className={Css.Login1}>
                                <NavDropdown.Item onClick={handleLogin} className={`text-start ${Css.smt}`}>ระบบ Admin</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={handleATSPotral} className={`text-start ${Css.smt}`}>ระบบ ISO DCaaS</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                        <Nav className={`${Css.Nav_Mobile2} ${Css.paddingNav1}`}>
                            <Button type="button" className={`${Css.btn_blue} ${Css.btn_round}`} onClick={handleFreeRegister}>ทดลองใช้งานฟรี</Button>
                        </Nav>
                    </Navbar.Collapse>
                    <Nav className={`${Css.Nav_Medium}`}>
                        <Button type="button" className={`${Css.btn_blue} ${Css.btn_round}`} onClick={handleFreeRegister}>ทดลองใช้งานฟรี</Button>
                    </Nav>
                </Navbar>
            </Container>
        </div>
    );
}