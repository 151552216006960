import React from "react";
import { Nav, Navbar } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Css from './Mockup.module.css';

export default function FooterMockup() {
    const navigate = useNavigate();

    const handlePrivacypolicy = (event) => {
        navigate('/register/Privacypolicy');
    }

    const handleTermsofuse = (event) => {
        navigate('/register/Termsofuse');
    }
    return (
        <div className={`${Css.FooterMock} ${Css.PaddingFooter}`}>
            <Navbar className={`justify-content-center py-0`}>
                <Nav>
                    <Nav.Link onClick={handleTermsofuse} className={`${Css.ColorWhite}`}>เงื่อนไขบริการ</Nav.Link>
                    <Nav.Link disabled className={`${Css.ColorWhite}`}>|</Nav.Link>
                    <Nav.Link onClick={handlePrivacypolicy} className={`${Css.ColorWhite}`}>นโยบายความเป็นส่วนตัว</Nav.Link>
                    {/*<Nav.Link disabled className={Css.ColorWhite}>|</Nav.Link>
                        <Nav.Link disabled className={`${Css.ColorGrey}`}>สอบถาม</Nav.Link>*/}
                    </Nav>
                </Navbar>
                <label className={`${Css.ColorWhite} ${Css.maginfooter1} d-block text-center`}>© Copyright 2023 ISO DCaaS - All Rights Reserved by Magic Software (Thailand) Corp., Ltd. Version : {process.env.REACT_APP_VERSION}</label>
            </div>
        )
    
}