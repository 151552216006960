import Css from 'components/pages/customer/Customer.module.css';
import { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import ApiConection from 'api_ref/ApiConection';

export default function Change0Condition({ alert = '', canChange = false, onSubmit = undefined }) {
    return (
        <>
            {
                canChange === true &&
                <div className={Css.Graph_Group}>
                    <div className={Css.Graph_Box + ' shadow'}>
                        <div className={Css.Content}>
                            <div className={Css.Header}>
                                <h6 className='mb-3'>ข้อกำหนดและเงื่อนไข</h6>
                            </div>
                            <div className={Css.Content + ' p-4'}>
                                <div className='text-start text-black-50'>
                                    <h6 >แพ็กเกจทดลองใช้ (Trial)</h6>
                                    <div>
                                        <ul>
                                            <li>ผู้ใช้บริการสามารถเปลี่ยนจากแพ็กเกจทดลองใช้ (Trial) เป็นแพ็กเกจหลักได้ทันทีที่ต้องการ</li>
                                            <li>ระบบจะเริ่มนับระยะเวลาการให้บริการใหม่ ตามแพ็กเกจหลักที่เปลี่ยนทันที ที่ยืนยันการเปลี่ยน</li>
                                            <li>เมื่อเริ่มนับระยะเวลาการให้บริการตามแพ็กเกจหลักที่เปลี่ยนแล้ว ผู้ใช้บริการสามารถเลือกชำระค่าบริการทันที หรือเลือกชำระภายหลังได้ แต่ไม่ควรเกินวันที่ครบกำหนดชำระ</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='text-start text-black-50'>
                                    <h6 >แพ็กเกจหลัก</h6>
                                    <div>
                                        <ul>
                                            <li>แพ็กเกจหลักเดิมของผู้ใช้บริการต้องชำระค่าบริการแล้ว</li>
                                            <li>แพ็กเกจหลักที่ผู้ใช้บริการเปลี่ยนจะเริ่มนับระยะเวลาให้บริการ ต่อจากวันที่สิ้นสุดการให้บริการของแพ็กเกจเดิม</li>
                                            <li>แพ็กเกจหลักที่ผู้ใช้บริการเปลี่ยนจะมีผลหลังจาก ผู้ใช้บริการชำระค่าบริการล่วงหน้าแล้ว</li>
                                            <li>ผู้ใช้บริการไม่สามารถเลือกแพ็กเกจที่ค่าบริการต่ำกว่าแพ็กเกจปัจจุบันได้</li>
                                            <li>ผู้ใช้บริการสามารถเปลียนแพ็กเกจหลักได้ล่วงหน้าไม่เกิน 1 รอบบิล</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center">
                                <Button type='button' className='btn-primary w-33' disabled={!canChange} onClick={event => onSubmit(event)} >ดำเนินการต่อ<i className="fa-solid fa-chevron-right ms-2"></i></Button>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    );
}