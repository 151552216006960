import { useNavigate } from "react-router-dom";
import Css from "components/pages/customer/Customer.module.css";
import { useEffect, useState } from "react";
import ApiConection from "api_ref/ApiConection";
import ATSApiConnection from "api_ref/ATSApiConnection";
import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import QuontationStatus1 from "./QuontationStatus1";
import CustomerWithholdingTaxTable from "./CustomerWithholdingTaxTable";
import UtilClass from "api_ref/UtilClass";

export default function CustomerWithholdingTax() {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');
    const register = util.getSessionStorage('register');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState("");
    const [loading, setLoading] = useState(false);
    const [billData, setBillData] = useState({});
    const [fileList, setFileList] = useState([]);

    const [loadPage, setLoadPage] = useState(false);

    const navigate = useNavigate();

    const getBills = async () => {
        const response = await api.getData(
            `api/quontation/register/${register.register_number}/withholding-tax/wait`
        );
        if (response.status === true) {
            setBillData(response.data);
        } else {
            setBillData([]);
        }
    };

    useEffect(() => {
        getBills();
    }, []);

    return (
        <LayoutCustomerMain role="customer">
            <QuontationStatus1 />
            <div className={Css.Block_Transparent}>
                <br />
                <h5 className={Css.color1}>แนบเอกสารหัก ณ ที่จ่าย</h5>
                <br />
                <div className={Css.Graph_Group}>
                    <CustomerWithholdingTaxTable data={billData} reloadTable={getBills} />
                </div>
            </div>
        </LayoutCustomerMain>
    );
}