import React, { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Css from 'components/mockup/Mockup.module.css'
import UtilClass from "api_ref/UtilClass";
import ApiConection from "api_ref/ApiConection";
import ContactUs from "components/mockup/ContactUs";
import FooterMockup from "components/mockup/FooterMockup";
import HeaderMockup from "components/mockup/HeaderMockup";
import PolicyCookie from "components/pages/application/PolicyCookie";
import ScrollBackToTop from "components/pages/manual/ScrollBackToTop";
import FeatureMockup from "components/mockup/FeatureMockup";
import Footer4Bottom from "./Foot4Bottom";
import ParticlesFireworks from "components/mockup/ParticlesFireworks";
import moment from "moment/moment";

export default function LayoutLandingPageV2(
    { children,
        showContactUs = true,
        showFooter = true }) {
    const util = new UtilClass();
    const api = new ApiConection();

    

    const [loginSecurity, setLoginSecurity] = useState(false);

    

    useEffect(() => {
        //window.sessionStorage.clear();

        //Set time for fireworks
        
        
        const fetchData = async () => {
            if (util.getSessionStorage('_8086') === null || util.getSessionStorage('_8086') === undefined) {
                await api.loginLandingPage();
                setLoginSecurity(true);
            } else {
                setLoginSecurity(true);
            }
        }

        let version = util.getLocalStorage('dcaas_web_version');
        if(version !== process.env.REACT_APP_VERSION)
        {
            if('caches' in window){
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach(name => {
                        //console.log(name);
                        caches.delete(name);
                    }) 
                });
            }
           
            window.localStorage.removeItem('dcaas_web_version');
            util.setLocalStorage('dcaas_web_version',process.env.REACT_APP_VERSION);

           window.location.reload(true);
        }
        //fetchData().catch((error)=> { console.log(error); setLoginSecurity(true);});
    }, [])

    return (
        <Container>
            
            <HeaderMockup />
            <div className={Css.Main_Content_Home + ' fade-in'}>
                {children}
                {showContactUs && <ContactUs />}
                {showFooter && <FooterMockup />}
                <PolicyCookie />
                <ScrollBackToTop />

                
                
            </div>
        </Container>

    )
}