import React, { useEffect, useState } from "react"
import Css from './Manual.module.css'
import { Row, Col, Form, Button } from "react-bootstrap";

export default function ManualFreeTrial2() {
    return (
        <div className={Css.imgManaul}>
            <h3 className="mb-4">ทดลองใช้งานฟรี</h3>
            <label className="mt-0">1. สามารถสมัครสมาชิกได้โดยกดไปที่ ทดลองใช้งานฟรี</label>
            <img src={require("images/logindcaas1.png")} className='mt-3' />
            <label>หรือให้ท่านกดเลือกราคาแพ็กเกจ และกดทดลองใช้งานฟรีที่แพ็กเกจ Free Trial</label>
            <img src={require("images/upgrade15.png")} className='mt-3' />
            <label>2. กรอกรายละเอียดของผู้สมัคร เมื่อกรอกข้อมูลเสร็จเรียบร้อยแล้วให้กด ยืนยัน</label>
            <img src={require("images/logindcaas2.png")} className='mt-3' />
            <label>3. เมื่อกดยืนยันเป็นที่เรียบร้อยแล้วก็จะแสดงว่า ลงทะเบียนเรียบร้อยแล้ว</label>
            <img src={require("images/logindcaas3.png")} className='mt-3' />
            <label>4. จากนั้นให้ไปตรวจสอบเมลที่ได้ทำการสมัครไว้ เพื่อกดยืนยันการสมัคร ซึ่งจะมีให้ท่านเลือก 2 ช่องทางในการยืนยันความถูกต้องของอีเมล</label>
            <img src={require("images/logindcaas4.png")} className='mt-3' />
            <label>5. เมื่อท่านยืนยันการสมัครสำเร็จท่านจะสามารถเข้าสู่ระบบได้ และท่านจะได้รับอีเมลว่าท่านได้ยืนยันการสมัครสมบูรณ์แล้ว</label>
            <img src={require("images/logindcaas5.png")} className='mt-3' />
            <img src={require("images/logindcaas6.png")} className='mt-3' />
            <label>6. ท่านทำการใส่อีเมล และรหัสผ่าน ที่ท่านได้ทำการสมัครสมาชิกเอาไว้</label>
            <img src={require("images/logindcaas7.png")} className='mt-3' />
            <label>7. หลังจากที่เข้าสู่ระบบเรียบร้อยแล้วก็จะมาหน้าการสร้างเว็บไซต์ ซึ่งท่านทำการกรอกรายละเอียดให้ถูกต้องครบถ้วนทุกช่อง และกดดำเนินการต่อ</label>
            <img src={require("images/logindcaas8.png")} className='mt-3' />
            <label>8. เมื่อสร้างเว็บไซต์สำเร็จ ท่านจะได้รับแพ็กเกจทดลองใช้งานฟรี 30 วัน แล้วกดยืนยันเพื่อดำเนินงานต่อ</label>
            <img src={require("images/logindcaas9.png")} className='mt-3' />
            <label>9. ท่านทำรายการสำเร็จเรียบร้อย กรุณาตรวจสอบที่อีเมลของคุณ หากไม่พบใน inbox ของคุณให้ตรวจสอบที่ Junk หรือ Spam Mail</label>
            <img src={require("images/logindcaas10.png")} className='mt-3' />
            <label>10. ท่านจะได้รับอีเมลสรุปรายการคำสั่งซื้อ กดลิงก์ "ที่นี่" เพื่อสมัครเข้าสู่ระบบ ISO DCaaS </label>
            <img src={require("images/logindcaas11.png")} className='mt-3' />
            <label>11. นำรหัส OTP ที่ได้จากอีเมลมากรอก กรอกอีเมลและรหัสผ่านเพื่อสมัครระบบ ISO DCaaS </label>
            <img src={require("images/logindcaas12.png")} className='mt-3' />
            <label>12. เข้าสู่ระบบของ ISO DCaaS ได้โดยกรอกอีเมลและรหัสผ่านเพื่อเข้าสู่ระบบ</label>
            <img src={require("images/loginiso7.png")} className='mt-3' />
            <label>13. เมื่อทำการเข้าสู่ระบบสำเร็จท่านสามารถเริ่มต้นใช้งานได้หน้า ISO DCaaS </label>
            <img src={require("images/loginiso8.png")} className='mt-3' />
        </div>
    )
}