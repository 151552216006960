import { useEffect } from 'react';
import Css from './Mockup.module.css'

export default function ContactUs() {

    useEffect(()=>{
        const script = document.createElement('script');
        script.id = 'dbd-init';
        script.src = 'https://www.trustmarkthai.com/callbackData/initialize.js?t=1784d50e-23-6-96562430d458b4d112952027210d8f4687cc7c';
        script.async = true;

        script.onload = () => {
        // สคริปต์ถูกโหลดและเริ่มทำงาน
        // คุณสามารถเพิ่มโค้ดเพิ่มเติมที่ต้องการให้ทำหลังจากโหลดสคริปต์ได้ที่นี่
        };

        document.body.appendChild(script);

        // เพื่อความสมบูรณ์ คุณควรลบสคริปต์เมื่อคอมโพเนนต์ถูก unmounted
        return () => {
        document.body.removeChild(script);
        };
    },[])

    const handlefacebook = () => {
        window.open('https://www.facebook.com/isodcaas', '_blank');
    }
    const handlelinked = () => {
        window.open('https://www.linkedin.com/company/iso-dcaas', '_blank');
    }
    const handleline = () => {
        window.open('https://lin.ee/TarQeWO', '_blank');
    }

    return (
        <>
            <div className={Css.contactus}>
                <img src={require("images/contactusBG1.jpg")} className={`${Css.layout_contactus} text-center`} />
                <div className={`${Css.contactusfont}`}>
                    <p className={`${Css.contactusfont1}`}>CONTACT US</p>
                    <div className={`${Css.fontcontactus}`}>
                        <img src={require("images/address.png")} className={Css.sizeiconcontactus} />
                        <label>บริษัท เมจิกซอฟท์แวร์ (ประเทศไทย) จำกัด <br /> 9/68 ซอยรัชประชา ถนนรัชดาภิเษก แขวงจตุจัตร เขตจตุจักร กรุงเทพฯ 10900</label>
                    </div>
                    <div className={`${Css.fontcontactus}`}>
                        <img src={require("images/telephone.png")} className={Css.sizeiconcontactus} />
                        <label>02-911-9988 ต่อ 332<br />เวลาทำการ : จันทร์ - ศุกร์ เวลา 08.30 น. - 17.30 น.</label>
                    </div>
                    <div className={`${Css.fontcontactus}`}>
                        <img src={require("images/email.png")} className={Css.sizeiconcontactus} />
                        <label>isodcaas.info@magicsoftware.co.th</label>
                    </div>
                </div>
                <div className={`${Css.contactusimg}`}>
                    <div id="div-DBD-Certificate" className='d-block d-flex text-start'>
                        <label className='align-self-center text-nowrap'>รับรองโดย </label>
                        <div id="Certificate-banners"></div>
                    </div>
                    <div className='d-block'>
                        <span>
                            <a onClick={(_) => handlefacebook()}><img src={require("images/fb1.png")} className={Css.sizeiconcontactus1} /></a>
                        </span>
                        <span onClick={(_) => handlelinked()}>
                            <a><img src={require("images/LinkedIn1.png")} className={Css.sizeiconcontactus1} /></a>
                        </span>
                        <span onClick={(_) => handleline()}>
                            <a><img src={require("images/line1.png")} className={Css.sizeiconcontactus1} /></a>
                        </span>
                    </div>
                    
                </div>
            </div>
        </>
    )
}