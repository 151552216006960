import moment from "moment/moment";
import { Row, Col, Form, Button, Tab, Table, Modal, Toast, ToastContainer } from "react-bootstrap";
import "/node_modules/vanillajs-datepicker/dist/css/datepicker-bs5.min.css";
import Styles from './TextDate.module.css';
import { Datepicker } from "vanillajs-datepicker";
import HtmlDecode from 'html-react-parser';
import { useEffect, useState } from "react";

export function RandomId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function AddCommar(param) {
    if (param == undefined) return param;
    const value = param;
    //console.log(value)
    if (value == null) return value;
    if (value == undefined) return value;
    return (value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
}

export function DateToText(param) {
    if (param === undefined) return param;

    let date = moment(param, 'YYYY-MM-DDTHH:mm:ss');
    if (!date.isValid()) return '';

    return date.format('DD/MM/YYYY');
}

export function ConvertDateToText(param) {
    if (param === null) return '-';
    return moment(param).format('DD/MM/YYYY');
}

export function DateTimeToText(param) {
    if (param === undefined) return param;

    let date = moment(param, 'YYYY-MM-DDTHH:mm:ss');
    if (!date.isValid()) return '';

    return date.format('DD/MM/YYYY HH:mm');
}

export function DateToMonth(param) {
    if (param === undefined) return param;

    const month = moment(param, 'YYYY-MM-DDTHH:mm:ss').format('MM');
    let month_text = '';
    switch (month) {
        case '01': month_text = 'มกราคม'; break;
        case '02': month_text = 'กุมภาพันธ์'; break;
        case '03': month_text = 'มีนาคม'; break;
        case '04': month_text = 'เมษายน'; break;
        case '05': month_text = 'พฤษภาคม'; break;
        case '06': month_text = 'มิถุนายน'; break;
        case '07': month_text = 'กรกฎาคม'; break;
        case '08': month_text = 'สิงหาคม'; break;
        case '09': month_text = 'กันยายน'; break;
        case '10': month_text = 'ตุลาคม'; break;
        case '11': month_text = 'พฤศจิกายน'; break;
        case '12': month_text = 'ธันวาคม'; break;
        default: month_text = '';
    };

    return month_text;
}

export function TextDate({ name = 'txt', className = '', placeholder = '', value = undefined
    , onChange = undefined
    , autoComplete = "on"
    , required
    , minDate = undefined, maxDate = undefined }) {
    useEffect(() => {
        let txt_date = document.querySelector(`[name=${name}]`)
        if (txt_date.classList.contains("datepicker-input")) return;

        new Datepicker(txt_date, {
            autohide: true,
            format: "dd/mm/yyyy",
            todayBtn: true,
            clearBtn: true,
            todayBtnMode: 1,
            todayHighlight: true,
            minDate: (minDate !== undefined ? minDate : null),
            maxDate: (maxDate !== undefined ? maxDate : null)
        });

        txt_date.addEventListener('changeDate', (event) => onChange(event));
        // eslint-disable-next-line
    }, [])

    return (
        <div className={`${Styles.has_date} ${className}`}>
            <input type="text" required={required} name={name} className={`form-control no-icon ${Styles.design_input} `} placeholder={placeholder} value={value} onChange={(event) => onChange(event)} onBlur={(event) => onChange(event)} maxLength='10' autoComplete={autoComplete} />
            <i className={`fa-solid fa-calendar-days ${Styles.feedback}`}></i>
        </div>

    );
}

export function ButtonUploadFile({ name = 'upload',
    className,
    label = 'upload',
    value = undefined,
    onChange = undefined,
    required }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectFile, setSelectFile] = useState();
    const [preview, setPreview] = useState();

    useEffect(() => {
        if (value === undefined) {
            setPreview(undefined);
            document.querySelector(`input[type=file][name=${name}]`).value = null;
            //document.querySelector(`.${Styles.drop_zone}`).style.display = 'block';
        }
    }, [value])

    useEffect(() => {
        if (!selectFile) {
            setPreview(undefined);
            return;
        }
        //console.log(selectFile.type.indexOf('image'));
        //document.querySelector(`.${Styles.drop_zone}`).style.display = 'none';
        const objURl = URL.createObjectURL(selectFile);
        setPreview(objURl);

        //return ()=> URL.revokeObjectURL(objURl);
        // eslint-disable-next-line
    }, [selectFile]);

    const handleClearValue = () => {
        setPreview(undefined);
        setSelectFile(undefined);
        document.querySelector(`input[type=file][name=${name}]`).value = null;

        if (onChange !== undefined)
            onChange({ target: { name: name, type: 'file', value: '', files: [undefined] } });
    }

    const handlefireSelectFile = (event) => {
        if (preview !== undefined) {
            setShow(true);
            return;
        }
        const input = event.target.querySelector('input');
        if (input !== null)
            input.click();
    }

    const onSelectFile = (event) => {
        if (event.target.files === null || event.target.files.length === 0) {
            setSelectFile(undefined);
            return;
        }

        setSelectFile(event.target.files[0]);

        if (onChange !== undefined)
            onChange(event);
    }

    return (
        <>
            <div className={Styles.UploadFile}>
                {
                    preview &&
                    <button type={'button'} className={"btn btn-danger " + Styles.BtnClearFile} onClick={handleClearValue}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                }

                <button type='button' className={'btn btn-upload-file ' + Styles.BtnUploadFile + ' ' + className} onClick={handlefireSelectFile}>
                    <input type="file" name={name} onChange={onSelectFile}
                        accept="image/*,application/pdf"
                        required={required} />
                    {
                        !selectFile && !preview &&

                        <>
                            <i className="fa-regular fa-image"></i><br />
                            {label}
                        </>
                    }
                    {
                        (selectFile && selectFile?.type.indexOf('image') > -1) &&
                        preview &&
                        <>
                            <img alt='' src={preview} style={{ marginTop: '0', width: 'auto', height: '100%' }} />
                        </>
                    }
                    {
                        (selectFile && selectFile?.type.indexOf('image') === -1) &&
                        preview &&
                        <>
                            <img alt='' src={require('images/document-901.png')} style={{ marginTop: '0', width: 'auto', height: '80%' }} />
                            <br />
                            <span>{selectFile.name}</span>
                        </>
                    }
                </button>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {
                        selectFile?.type?.indexOf('image') > -1 &&
                        <img alt='' src={preview} style={{ marginTop: '0', width: '100%', height: 'auto' }} />
                    }
                    {
                        selectFile?.type?.indexOf('image') === -1 &&
                        <span>ไม่สามารถแสดงตัวอย่างที่ไม่ใช่รูปภาพได้</span>
                    }
                </Modal.Body>
            </Modal>
        </>

    )
}

export function ButtonUploadFileMini({ name = 'upload',
    className,
    label = 'upload',
    value = undefined,
    onChange = undefined,
    required }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectFile, setSelectFile] = useState();
    const [preview, setPreview] = useState();

    useEffect(() => {
        if (value === undefined) {
            setPreview(undefined);
            document.querySelector(`input[type=file][name=${name}]`).value = null;
            //document.querySelector(`.${Styles.drop_zone}`).style.display = 'block';
        }
    }, [value])

    useEffect(() => {
        if (!selectFile) {
            setPreview(undefined);
            return;
        }
        //console.log(selectFile.type.indexOf('image'));
        //document.querySelector(`.${Styles.drop_zone}`).style.display = 'none';
        const objURl = URL.createObjectURL(selectFile);
        setPreview(objURl);

        //return ()=> URL.revokeObjectURL(objURl);
        // eslint-disable-next-line
    }, [selectFile]);

    const handleClearValue = () => {
        setPreview(undefined);
        setSelectFile(undefined);
        document.querySelector(`input[type=file][name=${name}]`).value = null;

        if (onChange !== undefined)
            onChange({ target: { name: name, type: 'file', value: '', files: [undefined] } });
    }

    const handlefireSelectFile = (event) => {
        if (preview !== undefined) {
            setShow(true);
            return;
        }
        const input = event.target.querySelector('input');
        if (input !== null)
            input.click();
    }

    const onSelectFile = (event) => {
        if (event.target.files === null || event.target.files.length === 0) {
            setSelectFile(undefined);
            return;
        }

        setSelectFile(event.target.files[0]);

        if (onChange !== undefined)
            onChange(event);
    }

    return (
        <>
            <div className={Styles.UploadFile}>
                {
                    preview &&
                    <button type={'button'} className={"btn btn-danger " + Styles.BtnClearFile} onClick={handleClearValue}>
                        <i className="fa-solid fa-xmark"></i>
                    </button>
                }

                <button type='button' className={'btn btn-outline-primary w-100 ' + className} onClick={handlefireSelectFile}>
                    <input type="file" name={name} onChange={onSelectFile}
                        accept="image/*,application/pdf"
                        required={required} />
                    {
                        !selectFile && !preview &&
                        <>
                            {label}
                        </>
                    }
                    {
                        (selectFile && preview) &&
                        <>
                            <span className={Styles?.FileName} >คลิกเพื่อดูไฟล์แนบ</span>
                        </>
                    }
                </button>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {
                        selectFile?.type?.indexOf('image') > -1 &&
                        <img alt='' src={preview} style={{ marginTop: '0', width: '100%', height: 'auto' }} />
                    }
                    {
                        selectFile?.type?.indexOf('image') === -1 &&
                        <span>ไม่สามารถแสดงตัวอย่างที่ไม่ใช่รูปภาพได้</span>
                    }
                </Modal.Body>
            </Modal>
        </>

    )
}

export function ButtonUploadImage({ name = 'upload', className, label = 'upload', value = undefined, onChange = undefined }) {
    const [selectFile, setSelectFile] = useState();
    const [preview, setPreview] = useState();

    useEffect(() => {
        if (value === undefined) {
            setPreview(undefined);
            document.querySelector(`input[type=file][name=${name}]`).value = null;
            //document.querySelector(`.${Styles.drop_zone}`).style.display = 'block';
        }
    }, [value])

    useEffect(() => {
        if (!selectFile) {
            setPreview(undefined);
            return;
        }
        //console.log(selectFile.type.indexOf('image'));
        //document.querySelector(`.${Styles.drop_zone}`).style.display = 'none';
        const objURl = URL.createObjectURL(selectFile);
        setPreview(objURl);

        //return ()=> URL.revokeObjectURL(objURl);
        // eslint-disable-next-line
    }, [selectFile])

    const handlefireSelectFile = (event) => {
        const input = event.target.querySelector('input');
        if (input !== null)
            input.click();
    }

    const onSelectFile = (event) => {
        if (event.target.files === null || event.target.files.length === 0) {
            setSelectFile(undefined);
            return;
        }

        setSelectFile(event.target.files[0]);

        if (onChange !== undefined)
            onChange(event);
    }

    return (
        <div className={Styles.UploadImage}>
            <button type='button' className={'btn ' + className} onClick={handlefireSelectFile}>

                {
                    !selectFile && !preview &&

                    <>
                        <i className="fa-regular fa-image"></i>
                        {label}
                    </>
                }
                {
                    (selectFile && selectFile.type.indexOf('image') > -1) &&
                    preview &&
                    <>
                        <img alt='' src={preview} height={100} />
                    </>
                }

                <input type="file" name={name} onChange={onSelectFile} />
            </button>
            <div>

            </div>
        </div>
    )
}
 ///////////////////////////////////////////////////////////
export function GenPaging({ page_no = 1, page_count = 1, page_show = 3, onPageChange = undefined }) {
    const [pagination, setPagination] = useState([]);

    const getPaging = () => {
        const max_page = page_show;
        const middle = Math.floor(max_page / 2);

        let tmp_paging = [];

        let start = 1;
        let end = max_page;

        start = page_no - middle;
        start = ((start + max_page - 1) > page_count) ? (page_count - max_page + 1) : start;
        start = (start < 1) ? 1 : start;

        end = start + max_page - 1;
        end = (end > page_count) ? page_count : end;

        for (let i = start; i <= end; i++)
            tmp_paging.push({ page: i });

        setPagination(tmp_paging);
    }

    useEffect(() => {
        getPaging();
    }, [page_no, page_count])

    return (
        <nav aria-label="Page navigation" className="p-0">
            <ul className="pagination justify-content-end mb-0">
                <li className="page-item">
                    <a className="page-link" aria-label="Previous" onClick={(_) => onPageChange(1)}>
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                {
                    pagination?.map((obj, index) => (
                        <li key={index} className={`page-item ${(parseInt(page_no) === parseInt(obj.page)) ? 'active' : ''}`}>
                            <a className={`page-link`} onClick={(_) => onPageChange(obj?.page)}>{obj.page}</a></li>
                    ))
                }

                <li className="page-item">
                    <a className="page-link" aria-label="Next" onClick={(_) => onPageChange(page_count)}>
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export function DisplayPaging({ rec_start = 1, rec_end = 1, rec_count = 1, onSizeChange = undefined
    , cur_page, size, page_count, onPagingChange = undefined }) {
    return (
        <div className="table bottom-tool pt-3 pb-3">
            <Row>
                {/* <Col className="text-start" >
                    <Form.Label>Showing {rec_start} to {rec_end} of {rec_count} </Form.Label>
                </Col>
                <Col className="text-center">
                    <Form.Label className={'d-inline-block'}>Result per page : </Form.Label>
                    <Form.Select className={'d-inline-block w-auto'} style={{ paddingRight: ' 2.25rem' }} onChange={(event) => onSizeChange(event)}>
                        <option value={10} >10</option>
                        <option value={20} >20</option>
                        <option value={30} >30</option>
                    </Form.Select>

                </Col> */}
                <Col >
                    {
                        <GenPaging page_no={cur_page} page_show={5} page_count={page_count} onPageChange={onPagingChange} />
                    }
                </Col>
            </Row>
        </div>
    )
}

export function ToastSuccess({ show = false, closeFc = undefined, message = '' }) {
    return (
        <ToastContainer className="position-fixed bottom-0 end-0 p-3">
            <Toast className="bg-opacity-75 bg-success fs-6 text-white p-2" onClose={() => closeFc(false)} show={show} delay={3000} autohide>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        </ToastContainer>

    )
}

export function ToastError({ show = false, closeFc = undefined, message = '' }) {
    return (
        <ToastContainer className="position-fixed bottom-0 end-0 p-3">
            <Toast className="bg-opacity-75 bg-danger fs-6 text-white p-2" onClose={() => closeFc(false)} show={show} delay={3000} autohide>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        </ToastContainer>

    )
}