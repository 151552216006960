import React, { useEffect, useState } from "react";
import Footer4Bottom from "./Foot4Bottom";
import Css from './Layout.module.css'
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ApiConection from 'api_ref/ApiConection';
import UtilClass from "api_ref/UtilClass";
import FreePackageCountDown from "components/pages/customer/home/FreePackageCountDown";

const arr_menu = [
    { seq: 1, name: 'หน้าแรก', icon: 'fa-solid fa-house', link: '/customer/home', desc: 'customer', type: 'main' },
    { seq: 2, name: 'แพ็กเกจ', icon: 'fa-solid fa-box-open', link: '#', desc: 'customer', type: 'headsub' },
    { seq: 3, name: 'อัปเกรดแพ็กเกจ', link: '/customer/upgrade', desc: 'customer', type: 'sub' },
    { seq: 4, name: 'แพ็กเกจเคยใช้', link: '/customer/package-used', desc: 'customer', type: 'sub' },
    { seq: 5, name: 'การชำระเงิน', icon: 'fa-regular fa-credit-card', link: '#', desc: 'customer', type: 'headsub' },
    { seq: 6, name: 'ชำระค่าบริการ', link: '/customer/payment', desc: 'customer', type: 'sub' },
    { seq: 7, name: 'แนบใบหัก ณ ที่จ่าย', link: '/customer/send-withholding-tax', desc: 'customer', type: 'sub' },
    { seq: 8, name: 'ใบเสร็จและใบแจ้งค่าบริการ', link: '/customer/service-transaction', desc: 'customer', type: 'sub' },
    { seq: 9, name: 'ข้อมูลของคุณ', icon: 'fa-regular fa-address-card', link: '#', desc: 'customer', type: 'headsub' },
    { seq: 10, name: 'ข้อมูลส่วนตัว', link: '/customer/profile/user', desc: 'customer', type: 'sub' },
    { seq: 11, name: 'ข้อมูลบริษัท', link: '/customer/profile/company', desc: 'customer', type: 'sub' },
    { seq: 12, name: 'ที่อยู่ในการออกใบเสร็จ', link: '/customer/profile/payee', desc: 'customer', type: 'sub' },
    { seq: 16, name: 'ติดต่อเรา', icon: 'fa-solid fa-phone', link: '/customer/contact', desc: 'customer', type: 'main' },

]

export default function LayoutCustomerMain({ children, middle = undefined, role = 'customer', isApproved = null }) {
    const api = new ApiConection();
    const util = new UtilClass();

    const nav = useNavigate();
    const [billingStatus, setBillsStatus] = useState(false);
    const [withholdingTaxStatus, setWithholdingTax] = useState(false);

    const customer = util.getSessionStorage('customer');
    const register = util.getSessionStorage('register');

    const handleLink = (link) => {
        nav(link);
    }

    const handleLogout = (event) => {
        nav('/login');
    }

    const handleChangeProfile = (event) => {
        nav('/customer/profile/user');
    }

    const handleChangePass = () => {
        nav('/customer/change/password');
    }

    const link = (event) => {
        window.open(register?.full_site_url, '_blank');
        //window.location.href = 'http://thaibanns.mstdcaas.com/';
    }

    const handleHelp = (event) => {
        window.open(process.env.PUBLIC_URL + '/manual', '_blank');
        //window.location.href = 'http://thaibanns.mstdcaas.com/';
    }

    const handleGetClass = (path) => {
        let nowPath = window.location.pathname;
        if (nowPath.indexOf(path) > -1) return Css.Selected;
        else return "";
    }

    const getBills = async () => {
        const response = await api.getData(`api/quontation/register/${register.register_number}/next-bill`);
        setBillsStatus(response.status);
    }

    const getWithHoldingTaxs = async () => {
        const response = await api.getData(
            `api/quontation/register/${register.register_number}/withholding-tax/wait`
        );
        setWithholdingTax(response.status);
    };

    const showNotics = (menu_id) => {
        switch (menu_id) {
            case 6:
                if (billingStatus === true) return true;
                break;
            case 7:
                if (withholdingTaxStatus === true) return true;
                break;
        }

        return false;
    }

    useEffect(() => {
        getBills();
        getWithHoldingTaxs();
    }, [])
    useEffect(() => {
        //console.log(customer.user_id);
        if (customer === null) nav('/login');
    }, [customer])

    return (
        <>
            <div className={`${Css.bg} ${Css.Main_Content_Customer}`}>
                <div className={Css.Menu_Left + ' shadow'}>
                    <div className={Css.Header}>
                        <img src={require('images/DCasS.png')} className="me-2" />
                        <span className={`fs-6 ${Css.body2}`}>Admin Portal</span>
                    </div>
                    <div className={Css.Menu}>
                        <ul>
                            {
                                arr_menu.map((obj, index) => {
                                    if (obj.desc === role) {
                                        if (obj.type === 'main') {
                                            return (
                                                <li key={index} className={`${handleGetClass(obj.link)} ${Css.menu_item}`}>
                                                    <a onClick={event => handleLink(obj.link)}><i className={obj.icon}></i>{obj.name}</a>
                                                </li>
                                            )
                                        }else if (obj.type === 'sub') {
                                            return (
                                                <div className={Css.marginSub} key={index}>
                                                    <li key={index} className={`${handleGetClass(obj.link)} ${Css.menu_item_sub}`}>
                                                        <a onClick={event => handleLink(obj.link)} className={`${Css.fontLeft} `}>
                                                            <i className={obj.icon}></i>
                                                            <span className="position-relative">
                                                                {obj.name}
                                                                {
                                                                    showNotics(obj.seq) &&
                                                                    <span className="position-absolute border top-0 start-100 translate-middle badge rounded-pill bg-danger fade-in">N</span>
                                                                }
                                                            </span>

                                                        </a>
                                                    </li>
                                                </div>
                                            )
                                        }
                                        else {
                                            return (
                                                <div key={index}>
                                                    <li key={index}>
                                                        <a className={Css.nocurser}><i className={obj.icon}></i>{obj.name}</a>
                                                    </li>
                                                </div>
                                            )
                                        }
                                    }
                                })
                            }
                            <li className={` ${Css.menu_item}`}>
                                <a onClick={event => handleHelp(event)}><i className={'fa-regular fa-circle-question me-2'}></i>{'แนะนำการใช้งาน'}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={Css.Content}>
                    <div className={Css.Header}>
                        <Row>
                            <Col className={'text-start'}>
                                <div className="customer-id">
                                    <label>รหัสลูกค้า</label>
                                    <label>{register?.register_number}</label>
                                </div>

                            </Col>
                            <Col className={'text-end'}>
                                {
                                    <>
                                        {/*<OverlayTrigger
                                        trigger="click"
                                        placement='bottom'
                                        overlay={
                                            <Popover id={`popover-positioned-bottom`} className="mt-2">
                                            <Popover.Header as="h3">Notification</Popover.Header>
                                            <Popover.Body>
                                                <p>This is your CustomerNotification</p>
                                                <p>This is your notification2</p>
                                            </Popover.Body>
                                            </Popover>
                                        }
                                        >
                                        <i className={`fa-regular fa-bell ms-4 me-4 fa-lg ${Css.bell}`} ></i>
                                        </OverlayTrigger>*/}
                                        <button className="btn btn-primary ms-4 me-4" onClick={link} >ไปยังระบบ ISO DCaaS</button>
                                    </>
                                }
                                <div className="btn-group ">
                                    <a className="profile-dropdown dropdown-toggle" data-bs-toggle="dropdown">
                                        <img window={'auto'} height={30} alt='' src={require('images/profile.png')} />
                                        {customer?.show_name}
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-md-start">
                                        <li>
                                            <div className="text-center p-3">
                                                <img className="d-block m-auto mb-2" width={'auto'} height={50} alt='' src={require('images/profile.png')} />
                                                <span className="d-block fs-7">{customer?.show_name}</span>
                                                <span className="d-block text-secondary">{customer?.email}</span>

                                            </div>
                                            <a className="dropdown-item" onClick={event => handleChangeProfile()}><i className="fa-regular fa-address-card me-2 "></i>Edit Profile</a>
                                        </li>
                                        <li><a className="dropdown-item" onClick={event => handleChangePass()} ><i className="fa-solid fa-key me-2"></i>Change Password</a></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><a className="dropdown-item" onClick={handleLogout}><i className="fa-solid fa-arrow-right-from-bracket me-2"></i>Logout</a></li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>

                    </div>
                    <div className={Css.Content + " fade-in"}>
                        {children}
                    </div>

                </div>
            </div>
            <FreePackageCountDown />
            <Footer4Bottom />
        </>
    );
}