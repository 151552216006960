import UtilClass from "api_ref/UtilClass";
import moment from "moment";
import { useState } from "react";

export default function FreePackageCountDown() {
    const util = new UtilClass();

    const register = util.getSessionStorage('register');
    const [close, setClose] = useState(false)

    const calculateDate = () => {
        const dueDate = moment(register?.quontation?.expiry_date).startOf('day');
        const currentDate = moment().startOf('day');

        const duration = moment.duration(dueDate.diff(currentDate));
        const daysRemaining = duration.asDays();
        if (currentDate.isAfter(dueDate)) {
            const daysOverdue = currentDate.diff(dueDate, 'days');
            const overDue = Math.ceil(daysOverdue);
            return (`เกิน ${overDue}`);
        }
        else {
            const dueDatetime = Math.ceil(daysRemaining);
            return (`เหลือ ${dueDatetime + 1}`);
        }
    };

    return <>
        {
            register?.quontation?.package_type === 'free' &&
            <>
                {
                    close === false &&
                    <div className="popup-free-alert d-flex flex-row position-fixed alert alert-warning bottom-0 end-0">
                        <div>
                            <i className="fa-solid fa-triangle-exclamation me-1"></i>
                            ระยะเวลาการใช้งานฟรี {calculateDate()} วัน
                        </div>
                        <div className="text-end ms-3">
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => setClose(true)}></button>
                        </div>
                    </div>
                }
            </>
        }
    </>
}