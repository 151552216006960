export default function MethodBBL() {
    return <div className="d-block text-center border rounded p-4 mt-1 mb-1">
        <img src={require("images/bbl.png")} alt={"BBL"} height={36} className="mb-2 d-block m-auto" />
        <label className='d-block'>ธนาคารกรุงเทพ</label>
        <label className='d-block'> บริษัท เมจิกซอฟท์แวร์ (ประเทศไทย) จำกัด  </label>
        <label className={`mb-3 mt-3 d-block text-blue-01 fs-4`}> เลขที่บัญชี : 101-7-99730-3 </label>
        <i className={`mt-2 text-center d-block`} >
            หลังจากแนบหลักฐานการชำระเงิน<br />
            และกดชำระเงินเรียบร้อย แล้วเริ่มต้นใช้งานได้ทันที
        </i>
    </div>
}