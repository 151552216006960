import React from "react";
import { Nav, Navbar, Button, Container } from "react-bootstrap";
import Css from './Layout.module.css';
import { NavLink, useNavigate } from "react-router-dom";
import UtilClass from "api_ref/UtilClass";
import ApiConection from "api_ref/ApiConection";
import { useState } from "react";
import { useEffect } from "react";

export default function LandingPageHeadMenu() {
    const [atsUrl, setAtsUrl] = useState();

    const api = new ApiConection();

    const util = new UtilClass();

    const navigate = useNavigate();

    const getATSUrl = async () => {
        const response = await api.callLandingPageGet(`api/ConfigApp/54`);
        if (response.status === true) {
            setAtsUrl(response.data.value);
        } else {
            setAtsUrl(process.env.REACT_APP_ATS_POTAL);
        }
    }
    const handleATSPotral = (event) => {
        window.open(atsUrl, '_blank');
    }

    const handleModule = (event) => {
        navigate('/module');
    }

    const handleLogin = (event) => {
        navigate('/login');
    }
    const handleFreeRegister = (event) => {
        //---- save period = 1 = month , package free id = 1;
        util.setCookie('period_id', 1, 1);
        util.setCookie('package_id', 1, 1);
        navigate('/register');
    }

    useEffect(() => {
        getATSUrl();
    }, [util.getSessionStorage('_8086')]);

    return (
        <div className={`${Css.Menu} ${Css.Fixed} ${Css.headBottom} ${Css.Menu_Mobile}`}>
            <Navbar expand="lg">
                <Container fluid className={Css.Container_Fluid}>
                    <Navbar.Brand >
                        <img src={require('../../images/DCasS.png')} className={Css.img_nav_mobile} alt={''} height={'38px'} />
                    </Navbar.Brand>
                    <Nav className={Css.Nav_Mobile}>
                        <Nav.Link href='#' onClick={handleATSPotral} className={`text-center ${Css.blueline} ${Css.btn_nav_mobile}`}>ISO DCaaS</Nav.Link>
                        <Button type="button" className={`${Css.btn_blue} ${Css.btn_round} ${Css.btn_nav_mobile}`} onClick={handleFreeRegister}>ทดลองใช้ฟรี</Button>
                    </Nav>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" className={Css.NavTog} />
                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className={`me-auto `}>
                            <NavLink to={'/'} className={`nav-link text-center ${Css.smt}`}>หน้าหลัก</NavLink>
                            <NavLink to={'#'} className={`nav-link text-center ${Css.nolink}`}>เกี่ยวกับเรา</NavLink>
                            <NavLink to={'#'} className={`nav-link text-center  ${Css.nolink}`}>ราคาแพ็กเกจ</NavLink>
                            <NavLink to={'/manual'} className={`nav-link text-center ${Css.smt}`}>ช่วยเหลือ</NavLink>
                            <NavLink to={'#'} className={`nav-link text-center ${Css.nolink}`}>บทความ</NavLink>
                            {/*<NavLink to={'#'} className={`nav-link text-center ${Css.nolink}`}>ติดต่อเรา</NavLink>*/}
                            <NavLink to={'/login'} className={`nav-link text-center ${Css.smt} ${Css.Nav_Mobile3}`}>เข้าสู่ระบบ</NavLink>
                        </Nav>
                        <Nav className={Css.Nav_Mobile2}>
                            <button className="btn btn-secondary-trn" onClick={handleLogin}>เข้าสู่ระบบ Admin</button>
                            <button className="btn btn-secondary-trn" onClick={handleATSPotral}>เข้าสู่ระบบ ISO DCaaS</button>
                            <Button type="button" className={`${Css.btn_blue} ${Css.btn_round} `} onClick={handleFreeRegister}>ทดลองใช้งานฟรี</Button>

                            {/*<Form className={'d-flex'}>
                                <Form.Select >
                                    <option>TH</option>
                                </Form.Select>
                            </Form>*/}
                        </Nav>
                    </Navbar.Collapse>
                </Container>

            </Navbar>
        </div>
    );
}