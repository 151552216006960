import CustomerUpPackageFlowStep2 from "./CustomerUpPackageFlowStep2";

export default function CustomerUpAddonFlowStep2({ data = undefined,
    onChangeData = undefined,
    onHandleSubmit = undefined,
    error = '',
    SetRegisterData = undefined,
    onChangeStep = undefined }) {
    return <CustomerUpPackageFlowStep2
        data={data}
        onChangeData={onChangeData}
        onHandleSubmit={onHandleSubmit}
        error={error}
        SetRegisterData={SetRegisterData}
        onChangeStep={onChangeStep} />
}