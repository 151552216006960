import Css from './Register.module.css';
import { useEffect, useState } from "react";
import { Row, Col, Form, Button, OverlayTrigger, Tooltip, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ApiConection from 'api_ref/ApiConection';
import UtilClass from 'api_ref/UtilClass';
import { ButtonUploadImage } from 'components/MyControl';

export default function RegisterCustomerStep1p2({
    data = undefined,
    onChangeData = undefined,
    onHandleSubmit = undefined }) {
    const api = new ApiConection();

    const util = new UtilClass();

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [doamin, setDomain] = useState();
    const [protocal, setProtocal] = useState();

    const getSubDomain = async () => {
        const response = await api.getData(`api/master/sub-domain`);
        //debugger;
        if (response.status === true) {
            setDomain(response.data);
        }
    }

    const getProtocal = async () => {
        const response = await api.getData(`api/Master/protocal`);
        if (response.status === true) {
            setProtocal(response.data);
        }
    }

    const handleThisSubmit = async (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        setValidated(false);
        setAlert('');
        setLoading(true);

        if (document.querySelector('[name=tax_id]').setCustomValidity)
            document.querySelector('[name=tax_id]').setCustomValidity('');

        if (document.querySelector('[name=site_url]')?.setCustomValidity)
            document.querySelector('[name=site_url]')?.setCustomValidity('');

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoading(false);
            return;
        }

        const IdCard = data.tax_id;

        const check = await checkRegister();
        if (!check) {
            document.querySelector('[name=site_url]')?.setCustomValidity('invalid');

            event.stopPropagation();
            setValidated(true);
            setLoading(false);

            return;
        }
        if (!util.checkID(IdCard)) {
            //form.classList.remove('was-validated');
            document.querySelector('[name=tax_id]')?.setCustomValidity('invalid');

            event.stopPropagation();
            setValidated(true);
            setLoading(false);

            return;
        }

        await onHandleSubmit();

        setLoading(false);
    }

    const checkRegister = async () => {
        let request = {
            company_name: data.company_name,
            //tax_id : data.tax_id,
            domain_name: data.site_url
        }

        if (data.register_number !== '') {
            const response = await api.postData(`api/register/${data.register_number}/check-company`, request);

            if (response.status === false) {
                setAlert(response.data);

                return false;
            }
            return true;
        } else {
            const response = await api.postData(`api/register/check-company`, request);

            if (response.status === false) {
                setAlert(response.data);

                return false;
            }
            return true;
        }
    }

    const [businessTypeData, setBusinessTypeData] = useState([]);
    const getBusinessTypeData = async () => {
        const response = await api.getData(`api/master/business-type`);
        if (response.status == true) {
            setBusinessTypeData(response.data);
        }

        //console.log(businessTypeData);
    }

    const [businessCategoryData, setBusinessCategoryData] = useState([]);
    const getBusinessCategoryData = async () => {
        const response = await api.getData(`api/master/business-category`);
        if (response.status == true) {
            setBusinessCategoryData(response.data);
        }
    }

    const [employeeCount, setEmployeeCount] = useState([]);
    const getEmployeeCount = async () => {
        const response = await api.getData(`api/master/employee-count`);
        if (response.status == true) {
            setEmployeeCount(response.data);
        }
    }

    useEffect(() => {
        //console.log(data.company_name);
        getSubDomain();
        getProtocal();
        getBusinessTypeData();
        getBusinessCategoryData();
        getEmployeeCount();
    }, [])

    return (
        <>
            <Form className={`w-100 d-flex flex-column`} noValidate validated={validated} onSubmit={handleThisSubmit}>
                <div className='flex-fill'>
                    <h5 className={`text-pink-02 m-0`}>สร้างเว็บไซต์</h5>
                    <Form.Text >กรุณากรอกข้อมูลตามจริง เพื่อประโยชน์ในการใช้งาน ISO DCaaS ของคุณ</Form.Text>

                    <Form.Group className='mb-3 mt-4' >
                        <Form.Label >ชื่อบริษัท</Form.Label>
                        <Form.Control type={'text'} placeholder="เพิ่มชื่อบริษัทของคุณ" name={'company_name'} value={data.company_name} onChange={(event) => onChangeData(event)} required={true} />
                    </Form.Group>
                    <Form.Group className='mb-3' >
                        <Form.Label >สาขา/รหัสสาขา</Form.Label>
                        <Form.Control type={'text'} name={'payee_branch'} value={data.payee_branch} placeholder="สาขา/รหัสสาขา" onChange={(event) => onChangeData(event)} required={true} />
                    </Form.Group>
                    <Form.Group className='mb-3' >
                        <Form.Label>ที่อยู่</Form.Label>
                        <Col>
                            <Form.Control as="textarea" rows={3} placeholder="เพิ่มที่อยู่" name={'company_address'} value={data.company_address} onChange={(event) => onChangeData(event)} required={true} />
                        </Col>
                    </Form.Group>
                    <Form.Group className='mb-3' >
                        <Form.Label>หมายเลขโทรศัพท์</Form.Label>
                        <Col>
                            <Form.Control type={'tel'} placeholder="หมายเลขโทรศัพท์" name={'company_tel'} value={data.company_tel} onChange={(event) => onChangeData(event)} required={true} />
                        </Col>
                    </Form.Group>
                    <Form.Group className='mb-3' >
                        <Form.Label>เลขประจำตัวผู้เสียภาษีอากร</Form.Label>
                        <Col>
                            <Form.Control type={'text'} placeholder="0000000000000" name={'tax_id'} value={data.tax_id} maxLength={13} pattern="[0-9]{13}" onChange={(event) => onChangeData(event)} required={true} />
                        </Col>
                    </Form.Group>
                    <Form.Group className='mb-3'  >
                        <Form.Label >ประเภทธุรกิจ</Form.Label>
                        <Form.Select name={'business_type_id'}
                            value={data?.business_type_id}
                            required={true}
                            placeholder="0000000000000"
                            onChange={(event) => onChangeData(event)} >
                            <option value={''} >-- กรุณาเลือก --</option>
                            {
                                businessTypeData.map((obj, index) => {
                                    return (
                                        <option key={index} value={obj.id} >{obj.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mb-3'  >
                        <Form.Label >ลักษณะธุรกิจ</Form.Label>
                        <Form.Select name={'business_category_id'}
                            value={data?.business_category_id}
                            required={true}
                            onChange={(event) => onChangeData(event)}
                        >
                            <option value={''} >-- กรุณาเลือก --</option>
                            {
                                businessCategoryData.map((obj, index) => {
                                    return (
                                        <option key={index} value={obj.id} >{obj.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                        {
                            (parseInt(data?.business_category_id) === 99) &&
                            (<Form.Control as={'textarea'} className='mt-1' rows={3} placeholder="โปรดระบุ" name={'business_category_name'}
                                value={data?.business_category_name}
                                required={true}
                                onChange={(event) => onChangeData(event)} />)
                        }
                    </Form.Group>
                    <Form.Group className='mb-3'  >
                        <Form.Label >จำนวนพนักงาน</Form.Label><br />
                        {employeeCount.map((obj, index) => (
                            <Form.Check type="radio" inline
                                key={index}
                                name={"employee_count_id"}
                                id={'employee_count_id_' + obj.id}
                                label={obj.name}
                                defaultChecked={obj.id == data?.employee_count_id}
                                value={obj.id}
                                required={true}
                                onChange={(event) => onChangeData(event)}
                            />
                        ))}
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label>ชื่อเว็บไซต์ (Site URL)
                            <button type='button' className='hint' title=' - ตัวอักษรภาษาอังกฤษพิมพ์เล็ก หรือ ตัวเลข (0-9) &#013; - ความยาวรวมกันอย่างน้อย 3 ตัวอักษร &#013; - ห้ามใช้คำว่า www, mail, api, frontendapi, backendapi, admin'>i</button>
                        </Form.Label>
                        <InputGroup className='has-validation'>
                            <InputGroup.Text>{protocal}://</InputGroup.Text>
                            <Form.Control type={'text'} name={'site_url'} value={data.site_url} pattern='[a-z0-9]{3,}' title='ตัวอักษรภาษาอังกฤษพิมพ์เล็ก หรือ ตัวเลข (0-9).ความยาวรวมกันอย่างน้อย 4 ตัวอักษร.' onChange={(event) => onChangeData(event)} required={true} />
                            <InputGroup.Text>{doamin}</InputGroup.Text>
                            <Form.Control.Feedback type={'invalid'}>
                                ตัวอักษรภาษาอังกฤษพิมพ์เล็ก หรือ ตัวเลข (0-9).<br />
                                ความยาวรวมกันอย่างน้อย 3 ตัวอักษร.<br />
                                ห้ามใช้คำว่า www, mail, api, frontendapi, backendapi, admin
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className='mb-2 d-none'>
                        <Form.Label>โลโก้บริษัท<label className='hint'>i</label></Form.Label>
                        <ButtonUploadImage label='เพิ่มรูป' name={'logo_file'} value={''} onChange={(event) => onChangeData(event)} />
                    </Form.Group>
                </div>
                {
                    alert !== '' &&
                    <div className="alert alert-danger" role="alert">
                        <div className="text-end">
                            <button type="button" className="btn-close" onClick={(event) => setAlert('')}></button>
                        </div>
                        {alert}
                    </div>
                }
                <div className='text-start mt-3'>
                    <Button type={'submit'} disabled={loading} className='btn-primary' style={{ width: '180px' }}>
                        {
                            loading === true &&
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        }
                        ดำเนินการต่อ
                    </Button>
                </div>
            </Form>
        </>
    );
}