import Css from 'components/pages/application/login/Login.module.css';
import { Form } from "react-bootstrap";
import { useState } from "react";
import { AddCommar, DateToText } from 'components/MyControl';

export default function TableCustomer({ data }) {
    //const api = new ApiConection();
    //const navigate = useNavigate();
    //const {id} = useParams();

    const [validated, setValidated] = useState(false);
    //const [alert,setAlert] = useState('');
    //const [loading,setLoading] = useState(false);

    const getStatusDisplay = (id) => {
        if (id === 4) {
            return 'text-black-50 text-decoration-line-through';
        }
    }
    return (
        <div >
            <div className={`${Css.Login_inner_layout2} w-100`}>
                <Form noValidate validated={validated} >
                    <h6 className={Css.color}>รายละเอียดแพ็กเกจ</h6>
                    <div className={'rounded'}>
                        <table className={`table border rounded`}>
                            <thead  >
                                <tr className={`'text-start border' ${Css.tablecolor}`}>
                                    <th className='text-end'> </th>
                                    <th className='text-center'>ใบสั่งซื้อ</th>
                                    <th className='text-center '>วันที่</th>
                                    <th className='text-start '>แพ็กเกจ</th>
                                    <th className='text-start '>ระยะเวลา</th>
                                    <th className='text-end  ' >ค่าบริการ (บาท)</th>
                                    <th className='text-center '>สถานะ</th>
                                </tr>
                            </thead>
                            {
                                data?.map((obj, index) => (
                                    <tbody key={index} className={Css.body2}>
                                        <tr className=''>
                                            <td className='text-end '  >{index + 1}</td>
                                            <td className={'text-center ' + getStatusDisplay(obj.payment_status_id)}  >{obj.quontation_number}</td>
                                            <td className={'text-center ' + getStatusDisplay(obj.payment_status_id)}>{DateToText(obj.create_date)}</td>
                                            <td className={`text-start  ${Css.fontcolor} ` + getStatusDisplay(obj.payment_status_id)} >{obj.package_master_name_short}</td>
                                            <td className={'text-start ' + getStatusDisplay(obj.payment_status_id)} >
                                                ระยะเวลา {obj.package_master_period_name}
                                                <br />
                                                {
                                                    DateToText(obj.effective_date) + ' - ' + DateToText(obj.expiry_date)
                                                }
                                            </td>
                                            <td className={'text-end ' + getStatusDisplay(obj?.payment_status_id)} >{AddCommar(obj?.grand_total_sub_withholding_tax?.toFixed(2))}</td>
                                            <td className={'text-center '}><pre>{obj?.payment_status_name}</pre></td>
                                        </tr>
                                    </tbody>
                                ))
                            }

                        </table>
                    </div>
                </Form>
            </div>
        </div>
    )
}