import { useNavigate } from "react-router-dom";
import Css from 'components/pages/customer/Customer.module.css';
import CssRgt from "components/pages/application/register/Register.module.css";
import { useEffect, useState } from "react";
import ApiConection from 'api_ref/ApiConection';
import QuontationStatus1 from "./QuontationStatus1";
import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import { Col, Row } from "react-bootstrap";
import CustomerUpPackageFlowStep1 from "./CustomerUpPackageFlowStep1";
import CustomerUpPackageFlowStep2 from "./CustomerUpPackageFlowStep2";
import CustomerupPackageFlowStep3 from "./CustomerUpPackageFlowStep3";
import UtilClass from "api_ref/UtilClass";

const arr_images_step = [
    { seq: 1, name: "แพ็กเกจ", img: "Group 2311.png" },
    { seq: 2, name: "ชำระค่าบริการ", img: "Group 2325.png" },
    { seq: 3, name: "สำเร็จ", img: "Group 2304.png" },
];

export default function CustomerUpPackageFlow() {
    const api = new ApiConection();
    const util = new UtilClass();

    const customer = util.getSessionStorage('customer');
    const register = util.getSessionStorage('register');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [changeCount, setChangeCound] = useState(0);

    const navigate = useNavigate();

    const [step, setStep] = useState(1);

    const [upgradeData, setUpgradeData] = useState(register.quontation);
    /*
     {
        id: null,
        register_number: register.register_number,
        company_name: register.company_name,
        company_address: "",
        company_tel: "",
        tax_id: "",
        site_url: "",
        business_type_id: 1,
        business_type_name: "",
        business_category_id: 7,
        business_category_name: "",
        employee_count_id: 1,
        description: "",

        package_master_period_id: register.quontation.package_master_period_id,
        package_master_period_name: register.quontation.package_master_period_id,

        arr_package: [
          {
            package_master_id: undefined,
            package_master_name: "",
            package_master_name_short: "",
            package_master_users_volume_id: 1,
            package_master_storage_volume_id: 1,
            package_type:'free',
            net: 0,
            vat: 0,
            tax: 0,
            total: 0,
          },
        ],

        is_addon_user:false,
        is_addon_storage:false,

        discount_code: "",
        is_with_holding_tax: false,

        is_pay_now: true,
        payment_method_id: 3,
        payee_name: "",
        payee_address: "",
        payee_tel: "",
        payee_tax_id: "",
        payee_branch: "",
        paid_file_slip : null,// input file slip
        slip_file_id: null,

        is_accept_agreement: false,
      }
    */

    const getStepSelectClass = (seq) => {
        return seq === step ? CssRgt.active : "";
    };

    const handleChangeUpgradeData = (event) => {
        const { name, value, type, checked, files } = event.target;

        if (event.target.setCustomValidity) {
            event.target?.setCustomValidity("");
        }

        if (type === 'file') {
            setUpgradeData(prevData => {
                return { ...prevData, [name]: (files ? files[0] : undefined || value) }
            })
        } else if (type === 'checkbox') {
            setUpgradeData((prevData) => {
                return { ...prevData, [name]: checked };
            });
        } else {
            setUpgradeData((prevData) => {
                return { ...prevData, [name]: value };
            });
        }

        setChangeCound(changeCount + 1);
        //console.log(registerData);
    };

    const handleChangeStep = (i_step) => {
        setUpgradeData((prevData) => {
            return { ...prevData, arr_package: [] };
        });
        setUpgradeData((prevData) => {
            return { ...prevData, compare: undefined };
        });

        setStep(i_step);
        window.scrollTo(0, 0);
    };

    const handleGotoStpe2 = () => {
        setStep(2);
        window.scrollTo(0, 0);
    }

    const handleGotoStpe3 = async () => {
        setAlert('');

        let arr_quontations = []
        upgradeData.arr_package.forEach(obj => {
            if (obj.package_type !== 'free') {
                arr_quontations.push({
                    package_master_id: obj.package_master_id,
                    package_master_period_id: upgradeData.package_master_period_id,
                    package_master_users_volume_id: obj.package_master_users_volume_id,
                    package_master_storage_volume_id: obj.package_master_storage_volume_id
                });
            }
        })
        const request = {
            arr_quontations: arr_quontations,
            discount_code: upgradeData.discount_code,
            is_with_holding_tax: upgradeData.is_with_holding_tax,
            is_pay_now: (upgradeData.is_pay_now == true || upgradeData.is_pay_now == 'true'),
            payment: {
                payment_method_id: 3,// ผ่านบัญชีธนาคาร
                payment_method_name: '',
                paid_bank: upgradeData.paid_bank,
                slip_file_id: upgradeData.slip_file_id,
                paid_amount: upgradeData.paid_amount === undefined ? parseFloat(upgradeData.grand_total_sub_withholding_tax?.toString().replace(',', '')) : upgradeData.paid_amount,
                paid_date: upgradeData.paid_date,
            },
            payee: {
                is_issue_tax_invoice: false,
                payee_name: upgradeData.payee_name,
                payee_address: upgradeData.payee_address,
                payee_tel: upgradeData.payee_tel,
                payee_tax_id: upgradeData.payee_tax_id,
                payee_branch: upgradeData.payee_branch
            },
            is_accept_agreement: true
        }

        const response = await api.postData(
            `api/quontation/register/${upgradeData.register_number}/upgrade-package`,
            request
        );
        if (response.status === true) {
            setStep(3);
            setAlert('');
        } else {
            setAlert(response.message);
        }
    }

    useEffect(() => {
        let quontation = register.quontation;
        quontation.paid_amount = null;
        quontation.paid_bank = '';

        setUpgradeData(quontation);
    }, [])

    return (
        <LayoutCustomerMain role="customer" >
            <QuontationStatus1 />
            <div className={Css.Block_Transparent}><br />
                <h5 className={Css.color1}>อัปเกรดแพ็กเกจ &gt; อัปเกรดเพ็กเกจในรอบบิลถัดไป</h5><br />
                <div>
                    <div className={`mb-2 mt-3 ${CssRgt.Nav_step}`}>
                        <ul className={CssRgt.progressbar}>
                            {arr_images_step.map((obj, index) => (
                                <li key={index} className={getStepSelectClass(obj.seq)}>
                                    <span>{obj.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="container w-1000px">
                        {
                            step === 1 &&
                            <CustomerUpPackageFlowStep1
                                data={upgradeData}
                                onChangeData={handleChangeUpgradeData}
                                onHandleSubmit={handleGotoStpe2}
                                error={alert}
                                SetRegisterData={setUpgradeData}
                                onChangeStep={handleChangeStep} />
                        }
                        {
                            step === 2 &&
                            <CustomerUpPackageFlowStep2
                                data={upgradeData}
                                onChangeData={handleChangeUpgradeData}
                                onHandleSubmit={handleGotoStpe3}
                                error={alert}
                                SetRegisterData={setUpgradeData}
                                onChangeStep={handleChangeStep} />
                        }
                        {
                            step === 3 &&
                            <CustomerupPackageFlowStep3 />
                        }
                    </div>

                </div>
            </div>

        </LayoutCustomerMain>
    )
}