import React, { useEffect, useState } from "react"
import Css from './Manual.module.css'

export default function ManualLoginISO() {
    return (
        <div className={Css.imgManaul}>
            <h3 className="mb-4">การเข้าระบบ ISO DCaaS</h3>
            <label className="mt-0" >1. ให้ท่านกดเข้าสู่ระบบ ISO DCaaS </label>
            <img src={require("images/loginiso1.png")} className='mt-3' />
            <label>2. เมื่อกดเข้าสู่ระบบแล้วให้ท่านใส่ URL ที่ได้สมัครเข้าใช้งานทางระบบ ISO DCaaS และกด Proceed </label>
            <img src={require("images/loginiso2.png")} className='mt-3' />
            <label>3. เข้าสู่ระบบของ ISO DCaaS ได้โดยกรอกอีเมลและรหัสผ่านเพื่อเข้าสู่ระบบ</label>
            <img src={require("images/loginiso7.png")} className='mt-3' />
            <label>4. เมื่อทำการเข้าสู่ระบบสำเร็จท่านสามารถเริ่มต้นใช้งานได้หน้า ISO DCaaS </label>
            <img src={require("images/loginiso8.png")} className='mt-3' />
        </div>
    )
}