import ApiConection from 'api_ref/ApiConection';
import LayoutCustomerMain from "components/layout/LayoutCustomerMain";
import { useState } from "react";
import Css from 'components/pages/customer/Customer.module.css';
import CssLogin from 'components/pages/application/login/Login.module.css';
import QuontationStatus1 from "components/pages/customer/home/QuontationStatus1";
import { Col, Form, Row, Button, Alert } from 'react-bootstrap';
import UtilClass from 'api_ref/UtilClass';
import { ToastError, ToastSuccess } from 'components/MyControl';

export default function ChangePass() {
    const util = new UtilClass();
    const api = new ApiConection();

    const customer = util.getSessionStorage('customer');
    const register = util.getSessionStorage('register');

    const [validated, setValidated] = useState(false);
    const [alert, setAlert] = useState('');
    const [success, setSuccess] = useState('');
    const [showToastSuccess, setShowToastSuccess] = useState(false);
    const [showToastError, setShowToastError] = useState(false);

    const [user, setUser] = useState({});
    const [userEdit, setUserEdit] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [input, setInput] = useState({
        password: '',
        confirm_password: '',
        showPassword: false,
        showPassword2: false,
        showPassword3: false
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        event.target?.setCustomValidity('');

        setInput(prevData => {
            return { ...prevData, [name]: value }
        });
    }

    const handleSetInput = (name, value) => {
        setInput(prevData => {
            return { ...prevData, [name]: value }
        });
    }

    const handleClickShowPassword = (name) => {
        //console.log(input[name])
        setInput({ ...input, [name]: !input[name] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handlePasswordChange = (prop) => (event) => {
        setInput({ ...input, [prop]: event.target.value });
    };

    const handleSave = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();

        setValidated(false);
        setAlert('');
        setSuccess('');
        setLoadingSave(true);

        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            setLoadingSave(false);
            return;
        }

        if (input.confirm_password !== input.password) {
            //form.classList.remove('was-validated');
            document.querySelector('[name=confirm_password]')?.setCustomValidity('invalid');

            event.stopPropagation();
            setValidated(true);
            setLoadingSave(false);

            return;
        }

        let data = {
            old_password: input.old_password,
            password: input.password,
            confirm_password: input.password
        }

        const response = await api.postData(`api/customerprofile/${customer.user_id}/register/${register.register_number}/change-password`, data);
        if (response.status === true) {
            setUser(response.data);

            setShowToastSuccess(true);

            handleSetInput('old_password', '');
            handleSetInput('password', '');
            handleSetInput('confirm_password', '');
        } else {
            setShowToastError(true);
            setAlert(response.message);
        }
        setLoadingSave(false);
    }

    return (
        <LayoutCustomerMain role="customer">
            <QuontationStatus1 />
            <div className={Css.Block_Transparent}>
                <h5 className={Css.color1}>เปลี่ยนรหัสผ่าน</h5><br />
                <div className={Css.Graph_Group}>
                    {
                        loading === false &&
                        <>

                            <div className={'container'}>
                                <Form noValidate validated={validated} onSubmit={event => handleSave(event)} >
                                    <div className={`${CssLogin.Login_inner_layout2} w-100`}>
                                        <h6 className='mb-4'>ตั้งรหัสผ่านใหม่</h6>
                                        <div >
                                            <Form.Group as={Row} className='mb-4' >
                                                <Form.Label className="label-title">รหัสผ่านเดิม</Form.Label>
                                                <Col className={CssLogin.pwd1}>
                                                    <Form.Control type={input.showPassword ? "text" : "password"} className={CssLogin.pwd2 + ' CustomPwd'} pattern="(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*]).{8,}" name={'old_password'} value={input.old_password} onChange={handleChange} required={true} />
                                                    <i className={CssLogin.pwd3}
                                                        onClick={event => handleClickShowPassword('showPassword')}
                                                        onMouseDown={handleMouseDownPassword}>{input.showPassword ?
                                                            <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</i>
                                                    <Form.Control.Feedback type={'invalid'}>
                                                        1 ตัวอักษรภาษาอังกฤษพิมพ์เล็ก และ 1 ตัวอักษรภาษาอังกฤษพิมพ์ใหญ่.<br />
                                                        1 ตัวเลข (0-9).<br />
                                                        1 อัขระพิเศษดังนี้ (!@#$%^&*).<br />
                                                        ความยาวรวมกันอย่างน้อย 8 ตัวอักษร.<br />
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className='mt-2'>
                                                <Form.Label className="label-title">รหัสผ่านใหม่</Form.Label>
                                                <Col className={CssLogin.pwd1}>
                                                    <Form.Control type={input.showPassword2 ? "text" : "password"} className={CssLogin.pwd2 + ' CustomPwd'} pattern="(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*]).{8,}" name={'password'} value={input.password} onChange={handleChange} required={true} />
                                                    <i className={CssLogin.pwd3}
                                                        onClick={event => handleClickShowPassword('showPassword2')}
                                                        onMouseDown={handleMouseDownPassword}>{input.showPassword2 ?
                                                            <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</i>
                                                    <Form.Control.Feedback type={'invalid'}>
                                                        1 ตัวอักษรภาษาอังกฤษพิมพ์เล็ก และ 1 ตัวอักษรภาษาอังกฤษพิมพ์ใหญ่.<br />
                                                        1 ตัวเลข (0-9).<br />
                                                        1 อัขระพิเศษดังนี้ (!@#$%^&*).<br />
                                                        ความยาวรวมกันอย่างน้อย 8 ตัวอักษร.<br />
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className='mb-2 mt-3' >
                                                <Form.Label className="label-title">ยืนยันรหัสผ่าน</Form.Label>
                                                <Col className={CssLogin.pwd1}>
                                                    <Form.Control type={input.showPassword3 ? "text" : "password"} className={CssLogin.pwd2 + ' CustomPwd'} name={'confirm_password'} value={input.confirm_password} onChange={handleChange} required={true} />
                                                    <i className={CssLogin.pwd3}
                                                        onClick={event => handleClickShowPassword('showPassword3')}
                                                        onMouseDown={handleMouseDownPassword}>{input.showPassword3 ?
                                                            <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}</i>
                                                    <Form.Control.Feedback type={'invalid'}>
                                                        รหัสผ่าน ต้องเหมือนกับ ยืนยันรหัสผ่าน
                                                    </Form.Control.Feedback>
                                                </Col>
                                            </Form.Group>
                                        </div>
                                        {
                                            alert !== '' &&
                                            <div className="alert alert-danger" role="alert">
                                                <div className="text-end">
                                                    <button type="button" className="btn-close" onClick={(event) => setAlert('')}></button>
                                                </div>

                                                {alert}
                                            </div>
                                        }
                                        {
                                            success !== '' &&
                                            <div className=''>
                                                <Alert key={'success'} variant={'success'} onClose={() => setSuccess('')} dismissible>

                                                    <p>เปลี่ยนรหัสผ่านแล้ว</p>
                                                </Alert>
                                            </div>
                                        }
                                        <div className='mt-4'>
                                            <Button type='submit' disabled={loadingSave} className='ps-5 pe-5' >
                                                {
                                                    loadingSave === true &&
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                }
                                                บันทึก</Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </>
                    }
                </div>
            </div>
            <ToastSuccess show={showToastSuccess} closeFc={setShowToastSuccess} message='เปลี่ยนรหัสผ่านแล้ว' />
            <ToastError show={showToastError} closeFc={setShowToastError} message={'เกิดข้อผิดพลาด!!'} />
        </LayoutCustomerMain>
    )
}